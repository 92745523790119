import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalHeader } from "../../../components/Style";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import { device } from "../../../hooks/device";
import { bannerOn } from "../../../atoms";
import { useRecoilValue } from "recoil";
import customAxios from "../../../api/handler";
import ModalPageBack from "../../../atomic/molecule/ModalPageBack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PointCharge = ({ setPointChargeModal }) => {
  const { t } = useTranslation();
  const [couponCode, setCouponCode] = useState();
  const banneris = useRecoilValue(bannerOn);
  const navigate = useNavigate();

  const [state, setState] = useState(false);
  const [error, setError] = useState(null);

  const couponSubmit = () => {
    customAxios
      .post(`/api/v1/coupons/use`, {
        couponCode: couponCode,
      })
      .then(({ data }) => {
        navigate(`/myPoint/couponApplied`, {
          state: {
            couponTitle: data.title,
            point: data.credits,
          },
        });
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "COUPON-001") {
          setError(t(`errorCode.coupon_001`));
        } else if (response.data.errorCode === "COUPON-002") {
          setError(t(`errorCode.coupon_002`));
        }
      });
  };

  useScrollLockThree();

  return (
    <>
      <ModalPageBack />
      <StPointCharge banneris={banneris} couponCode={couponCode} error={error}>
        <div className="wrapper">
          <ModalHeader
            title={"무료 적립금 적립하기"}
            cancelIs={state && setPointChargeModal}
          />
          <div className="bodyBox">
            <span>이벤트/쿠폰 포인트 코드</span>
            <div className="titlePart">
              <input
                placeholder="코드 입력"
                onChange={(e) => {
                  setCouponCode(e.target.value);
                  setError(null);
                }}
              />
              <div onClick={() => couponSubmit()}>등록하기</div>
            </div>
            <div className="errorMessage">{error}</div>
            <div className="infoBox">
              <div className="infoPart">
                * 유효기간이 만료된 코드는 등록이 불가해요.
              </div>
              <div className="infoPart">
                * 이벤트 또는 쿠폰에 따라 계정당 등록 여부와 횟수가 달라질 수
                있어요.
              </div>
            </div>
          </div>
        </div>
      </StPointCharge>
    </>
  );
};

const StPointCharge = styled.div`
  position: fixed;
  z-index: 50;
  background-color: white;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
  .bodyBox {
    display: flex;
    flex-direction: column;
    .titlePart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${(props) => (props.error ? "5px" : "14px")};
      input {
        border: none;
        width: 235px;
        height: 36px;
        box-sizing: border-box;
        border-bottom: 1px solid
          ${(props) => (props.error ? "#C83B38" : "#dfdfdf")};
        &::placeholder {
          font-size: 16px;
          font-family: "Light";
        }
        &:focus {
          outline: none;
        }
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 31px;
        background: ${(props) => (props.couponCode ? "#20315B" : "#D9D9D9")};
        color: white;
        font-size: 11px;
        border-radius: 6px;
        cursor: ${(props) => (props.couponCode ? "pointer" : "")};
      }
    }
    .infoPart {
      font-size: 11px;
      font-family: "regular";
      line-height: 13px;
      color: #444444;
    }
    .errorMessage {
      color: #c83b38;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 16px;
    }
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .bodyBox {
      margin-top: 48px;
      span {
        font-size: 15px;
        font-family: "medium";
        line-height: 22px;
      }
      .titlePart {
        margin-top: 10px;
        input {
          flex-grow: 1;
          margin-right: 7px;
          &::placeholder {
            font-family: "light";
            font-size: 16px;
            line-height: 24px;
            color: #bdbdbd;
          }
        }
        div {
          font-size: 14px;
          font-family: "regular";
          line-height: 14px;
          width: 101px;
          height: 37px;
        }
      }
      .infoBox {
        display: grid;
        gap: 8px;
        margin: 2px 0 16px;
        .infoPart {
          /* margin-bottom: 8px; */
        }
      }
      .pointBox {
        text-align: center;
        font-size: 28px;
        font-family: bold;
        line-height: 41px;
        color: #2276dc;
        span {
          font-family: regular;
          font-size: 28px;
          line-height: 41px;
        }
      }
      .titleBox {
        margin: 48px 0;
        font-family: "medium";
        line-height: 27px;
        font-size: 18px;
      }
      .buttonBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        font-size: 17px;
        font-family: "medium";
        border: 1px solid black;
        border-radius: 43px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    padding: 0 24px;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    .wrapper {
      width: 100%;
      height: -webkit-fill-available;
    }
    .bodyBox {
      max-width: 312px;
      padding: 14px 0px;
      span {
        font-size: 13px;
        font-family: "medium";
        line-height: 19px;
        margin-bottom: 5px;
      }
      .infoPart {
        margin-bottom: 2.5px;
      }
    }
  }
`;

export default PointCharge;
