import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { modalPadding } from "../atoms";

const ScrollRestoration = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState(pathname);
  const temp = pathname.split("/");
  const pageArray = page.split("/");

  // Noah, modal이 켜져있을 때는 스크롤 상단으로 이동X
  const modalOn = useRecoilValueLoadable(modalPadding);

  useEffect(() => {
    // if (
    //   temp[temp.length - 2] === pageArray[pageArray.length - 1] ||
    //   temp[temp.length - 1] === pageArray[pageArray.length - 1]
    // ) {
    //   setPage(pathname);
    // } else {
    //   window.scrollTo(0, 0);
    //   setPage(pathname);
    // }

    if(!modalOn.contents) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return null;
};

export default ScrollRestoration;
