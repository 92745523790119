import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import {
  ModalBack,
  ModalBackD,
  ModalHeaderB,
  ModalHeaderD,
} from "../components/Style";
import { device } from "../hooks/device";
import useGetEnterpriseChargeConfirm from "../hooks/useGetEnterpriseChargeConfirm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import spinner from "../img/spinner.svg";
import sentMail from "../img/sentMail.svg";
import { languageIs, userInfo } from "../atoms";
import { useOutletContext } from "react-router-dom";
import { useOutletData } from "../components/OutletDataContext";
import useToast from "../hooks/useToast";

const Charge = () => {
  const navigate = useNavigate();
  const preUid = useParams().preuid;
  const user = useRecoilValue(userInfo);
  const country = useRecoilValue(languageIs);
  const showToast = useToast();
  const { data } = useGetEnterpriseChargeConfirm({ preUid });
  const { search } = useLocation();
  const [loading, setLoading] = useState("pending");

  const goBack = () => {
    navigate(`/myCredit`);
  };

  const { data: outletData } = useOutletData();

  /** 쿼리스트링 값으로 들어오는 정보를 객체로 변경 */
  function parseQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    const keyValuePairs = {};

    for (const [key, value] of params.entries()) {
      keyValuePairs[key] = value;
    }

    return keyValuePairs;
  }
  const pair = parseQueryString(search);

  useLayoutEffect(() => {
    if (pair.imp_success === "false") {
      window.history.go(-2);
    }
  }, [pair]);

  /** pulling 되는 상태 값에 따라 보여주는 api 변경 */
  useEffect(() => {
    if (data?.status === "pending") {
      setLoading("pending");
    } else if (data?.status === "success") {
      setLoading("success");
    } else if (data?.status === "fail") {
      setLoading("fail");
    }
  }, [data]);

  // fail, success, pending

  /** loading값이 fail일 경우 뒤로 보내주고 토스트 띄워주기 */
  const purchaseFail = () => {
    if (loading === "fail") {
      showToast({
        message:
          "알 수 없는 오류가 발생하였어요. 결제한 내역에 대해서는 고객센터를 통해 연락주시면 신속하게 도와드릴게요. (오류 코드 : OOOOOOOO)",
      });
      window.history.go(-2);
    }
  };

  useEffect(() => {
    purchaseFail();
  }, [loading]);

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => window.removeEventListener("popstate", goBack);
  }, []);

  return pair.imp_success !== "true" ? (
    <>
      <ModalBackD />
      {loading === "pending" && (
        <StTestConfirm>
          <ModalHeaderD title="결제 확인 중..." />
          <img className="spinner" src={spinner} />
        </StTestConfirm>
      )}
      {loading === "success" && (
        <StTestConfirm>
          <ModalHeaderD title="적립금 구매 완료" />
          <div className="bodyBox">구매가 정상적으로 완료되었어요!</div>
          <div
            className="buttonBox"
            onClick={() => {
              outletData.setCharge(!outletData.charge);
              navigate(`/myCredit`);
            }}
          >
            확인
          </div>
        </StTestConfirm>
      )}
    </>
  ) : (
    window.history.go(-2)
  );
};

const StTestConfirm = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .spinner {
    animation: rotate_image 1.5s linear infinite;
  }
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    .bodyBox {
      margin: 48px 0;
      line-height: 27px;
      font-size: 18px;
      font-family: "medium";
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      box-sizing: border-box;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      margin: 0 auto;
      cursor: pointer;
    }
    .spinner {
      width: 67px;
      height: 67px;
      margin: 70px auto 37px;
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyBox {
      margin: 20px 0 24px;
      line-height: 24px;
    }
    .buttonBox {
      color: #0072de;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      text-align: center;
      cursor: pointer;
    }
  }
`;

export default Charge;
