import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { passwordChange } from "../api/axiosTwo";
import styled from "styled-components";

// img
import deleting from "../img/delete.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bannerOn, userInfo } from "../atoms";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { ModalBack, ModalHeader } from "../components/Style";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import TextLine from "../atomic/atom/TextLine";
import focusing from "../hooks/focusing";

const PasswordSetting = ({ setPasswordModalOn }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const legPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;

  const uid = useRecoilValue(userInfo).owner_uid;
  const banneris = useRecoilValue(bannerOn);

  const height = window.innerHeight - 394;

  const [errPwMessage, setErrPwMessage] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [passErr, setPassErr] = useState(false);
  // const [errPwMessage, setErrPwMessage] = useState();
  const [active, setActive] = useState(false);
  const [checkError, setCheckError] = useState(false); // 비밀번호 변경 중복확인
  const [wrongError, setWrongError] = useState(false); // 기존 비밀번호 api 통신 확인
  const [validation, setValidation] = useState(false); // 재설정 비밀번호 api 통신 확인

  const showToast = useToast();

  const [one, setOne] = useState(false); // true 일 경우 delete 이미지가 보이게 됨
  const [oneFocus, setOneFocus] = useState(false);
  const [oneValue, setOneValue] = useState(false); // 기존 비밀번호 입력란 공백 유무 // 공백일 경우 '' 으로 들어가기때문에 값이 입력되어 값자체를 boolean으로 사용 불가
  const [two, setTwo] = useState(false); // true 일 경우 delete 이미지가 보이게 됨
  const [twoFocus, setTwoFocus] = useState(false);
  const [twoValue, setTwoValue] = useState(false); // 재설정 비밀번호 확인 입력란 공백 유무
  const [three, setThree] = useState(false); // true 일 경우 delete 이미지가 보이게 됨
  const [threeFocus, setThreeFocus] = useState(false);
  const [threeValue, setThreeValue] = useState(false); // 비밀번호 변경 중복확인 입력란 공백 유무

  const wrongFocus = useRef(); // 기존 비밀번호 확인 입력란
  const validationFocus = useRef(); // 재설정 비밀번호 입력란
  const checkFocus = useRef(); // 비밀번호 변경 중복확인 입력란

  // 뒤로 가기시 모달 꺼주기
  const goBack = () => {
    setPasswordModalOn(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useEffect(() => {
    if (
      password?.target?.value &&
      newPassword?.target?.value &&
      newPasswordCheck?.target?.value
    ) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [password, newPassword, newPasswordCheck]);

  const { mutate } = useMutation(passwordChange, {
    onSuccess: () => {
      showToast({ message: t(`errorCode.reset_password`) });
      navigate(-1);
    },
    onError: ({ response }) => {
      console.log("dndn");
      if (response.data.errorCode === "INCORECT-DB-PWD") {
        setWrongError(true);
        wrongFocus.current.focus();
      } else if (response.data.errorCode === "INCORECT-PWD") {
        setCheckError(true);
        checkFocus.current.focus();
      } else if (!passErr) {
        setValidation(true);
        validationFocus.current.focus();
      }
    },
  });

  // 비밀번호 변경 api (재설정하기 버튼 클릭시 발동)
  const change = () => {
    if (!legPass.test(newPassword.target.value)) {
      setErrPwMessage(t(`errorCode.pw_form_error`));
      focusing({ ref: validationFocus });
    } else {
      mutate({
        hostPassword: password.target.value,
        newHostPassword: newPassword.target.value,
        newHostPasswordCheck: newPasswordCheck.target.value,
        uid: uid,
      });
    }
  };

  // 패스워드 validation 체크
  const Password_check = (element) => {
    const legPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (legPass.test(element)) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
  };

  useEffect(() => {
    Password_check(newPassword?.target?.value);
  }, [newPassword]);

  useEffect(() => {
    if (oneValue && oneFocus) {
      setOne(true);
    } else {
      setOne(false);
    }
  }, [oneValue, oneFocus, password]);

  useEffect(() => {
    if (twoValue && twoFocus) {
      setTwo(true);
    } else {
      setTwo(false);
    }
  }, [twoValue, twoFocus, newPassword]);

  useEffect(() => {
    if (threeValue && threeFocus) {
      setThree(true);
    } else {
      setThree(false);
    }
  }, [threeValue, threeFocus, newPasswordCheck]);

  // 기존 비밀번호 입력란에 포커스 이벤트 주기
  useEffect(() => {
    function handleOutside(e) {
      if (wrongFocus.current && !wrongFocus.current.contains(e.target)) {
        setOneFocus(false);
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [wrongFocus]);

  // 비밀번호 재설정 입력란에 포커스 이벤트 주기
  useEffect(() => {
    function handleOutside(e) {
      if (
        validationFocus.current &&
        !validationFocus.current.contains(e.target)
      ) {
        setTwoFocus(false);
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [validationFocus]);

  // 비밀번호 재설정 체크 입력란에 포커스 이벤트 주기
  useEffect(() => {
    function handleOutside(e) {
      if (checkFocus.current && !checkFocus.current.contains(e.target)) {
        setThreeFocus(false);
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [checkFocus]);

  useScrollLockThree();

  return (
    <>
      <BackGround>
        <ModalBack />
        <StBack
          checkError={checkError}
          wrongError={wrongError}
          validation={validation}
          errPwMessage={errPwMessage}
          banneris={banneris}
          active={active}
          one={one}
          two={two}
          three={three}
          innerHeight={height}
        >
          <div className="contents">
            <div className="headerBox">
              <ModalHeader title="비밀번호 재설정" />
            </div>
            <div className="what">
              <div className="inputs">
                <div className="inputBoxOne">
                  <input
                    ref={wrongFocus}
                    onFocus={() => {
                      setOneFocus(true);
                    }}
                    onBlur={() => {
                      setWrongError(false);
                    }}
                    onChange={(e) => {
                      setPassword(e);
                      setWrongError(false);
                      if (e.target.value) {
                        setOneValue(true);
                      } else if (e.target.value === "") {
                        setOneValue(false);
                      }
                    }}
                    type="password"
                    className="inputOne"
                    placeholder="기존 비밀번호 입력"
                  />
                  <img
                    className="deleteOne"
                    src={deleting}
                    alt=""
                    onClick={() => {
                      wrongFocus.current.value = "";
                      setOneFocus(false);
                      setOneValue(false);
                      setPassword("");
                    }}
                  />
                </div>
                <div className="lineOne" />
                {wrongError && (
                  <div className="wrongPassword">
                    {t(`errorCode.incorect_pwd`)}
                  </div>
                )}
                <div className="inputBoxTwo">
                  <input
                    ref={validationFocus}
                    onFocus={() => {
                      setTwoFocus(true);
                    }}
                    onBlur={() => {
                      setValidation(false);
                    }}
                    onChange={(e) => {
                      setNewPassword(e);
                      setValidation(false);
                      setErrPwMessage();
                      if (e.target.value) {
                        setTwoValue(true);
                      } else if (e.target.value === "") {
                        setTwoValue(false);
                      }
                    }}
                    type="password"
                    className="input"
                    placeholder="새로운 비밀번호 입력"
                  />
                  <img
                    className="deleteTwo"
                    src={deleting}
                    alt=""
                    onClick={() => {
                      validationFocus.current.value = "";
                      setTwoFocus(false);
                      setTwoValue(false);
                      setNewPassword("");
                    }}
                  />
                </div>
                <div className="lineTwo" />
                {errPwMessage && (
                  <TextLine
                    text={errPwMessage}
                    fontsize={12}
                    lineheight={18}
                    fontcolor="#C83B38"
                  />
                )}
                {validation && (
                  <div className="validation">
                    문자, 숫자, 기호를 조합하여 8자 이상 입력하세요.
                  </div>
                )}
                <div className="inputBoxThree">
                  <input
                    ref={checkFocus}
                    onFocus={() => {
                      setThreeFocus(true);
                    }}
                    onBlur={() => {
                      setCheckError(false);
                    }}
                    onChange={(e) => {
                      setNewPasswordCheck(e);
                      setCheckError(false);
                      if (e.target.value) {
                        setThreeValue(true);
                      } else if (e.target.value === "") {
                        setThreeValue(false);
                      }
                    }}
                    type="password"
                    className="inputTwo"
                    placeholder="새로운 비밀번호 확인"
                  />
                  <img
                    className="deleteThree"
                    src={deleting}
                    alt=""
                    onClick={() => {
                      checkFocus.current.value = "";
                      setThreeFocus(false);
                      setThreeValue(false);
                      setNewPasswordCheck("");
                    }}
                  />
                </div>
                {checkError && <div className="lineThree" />}
                {checkError && (
                  <div className="checkPassword">
                    새로운 비밀번호가 서로 일치하지 않아요.
                  </div>
                )}
              </div>
              <div className="info">
                * 비밀번호는 문자, 숫자, 기호를 조립하여 8자 이상을 사용하세요.
              </div>
            </div>
            <div className="mobiles">
              <button
                disabled={!active}
                onClick={() => {
                  change();
                  // if(!passErr){change();}
                  // else{
                  //     setValidation(true);
                  //     validationFocus.current.focus();
                  // }
                }}
                className="button"
              >
                재설정 하기
              </button>
            </div>
            <div className="pc">
              <button
                disabled={!active}
                onClick={() => {
                  change();
                }}
                className="button"
              >
                재설정 하기
              </button>
            </div>
          </div>
        </StBack>
      </BackGround>
    </>
  );
};

const StBack = styled.div`
  @media ${device.tabMob} {
    position: absolute;
    bottom: 0px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    box-sizing: border-box;
    transform: translate(-50%, 0);
    background-color: #f0f1f2;
    z-index: 49;
    .pc {
      display: none;
    }
    .mobiles {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 293px 0 22px;
      box-sizing: border-box;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 204px;
        min-height: 42px;
        color: white;
        font-size: 17px;
        font-family: "Medium";
        background: ${(props) => (props.active ? "#ED893E;" : "#D9D9D9")};
        border: none;
        border-radius: 43px;
        /* margin-top : ${(props) => props.innerHeight}px;  */
        cursor: ${(props) => (props.active ? "pointer" : "")};
      }
    }
    .headerBox {
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
    }
    .validation {
      margin-top: 5px;
      font-size: 10px;
      color: red;
      width: 86%;
    }
    .wrongPassword {
      margin-top: 5px;
      font-size: 10px;
      color: red;
      width: 86%;
    }
    .checkPassword {
      margin-top: 5px;
      font-size: 10px;
      color: red;
      width: 86%;
      padding-bottom: 14px;
    }
    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: -webkit-fill-available;

      max-width: 731px;
    }
    .what {
      width: 100%;
    }
    .lineOne {
      width: 86%;
      height: 1px;
      background-color: ${(props) => (props.wrongError ? "red" : "#D9D9D9")};
    }
    .lineTwo {
      width: 86%;
      height: 1px;
      background-color: #d9d9d9;
      background-color: ${(props) =>
        props.validation || props.errPwMessage ? "red" : "#D9D9D9"};
    }
    .lineThree {
      width: 86%;
      height: 1px;
      background-color: #d9d9d9;
      background-color: ${(props) => (props.checkError ? "red" : "#D9D9D9")};
    }
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 157px;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 24px;
      margin-bottom: 16px;
    }
    .for {
      margin-left: 18.6px;
    }
    .arrow {
      width: 11px;
      height: 18px;
      cursor: pointer;
    }
    .mid {
      display: none;
    }
    .email {
      width: 86%;
      height: 52px;
      font-size: 15px;
      color: #2276dc;
      padding: 14px 0;
      box-sizing: border-box;
    }
    .inputBoxThree {
      display: flex;
      align-items: center;
      width: 86%;
      height: 52px;
      font-size: 15px;
      padding: 14px 0;
      box-sizing: border-box;
      position: relative;
      .deleteThree {
        display: ${(props) => (props.three ? "" : "none")};
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputBoxTwo {
      display: flex;
      align-items: center;
      width: 86%;
      height: 52px;
      font-size: 15px;
      padding: 14px 0;
      box-sizing: border-box;
      position: relative;
      .deleteTwo {
        display: ${(props) => (props.two ? "" : "none")};
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputBoxOne {
      display: flex;
      align-items: center;
      width: 86%;
      height: 52px;
      font-size: 15px;
      padding: 14px 0;
      box-sizing: border-box;
      border-bottom: ${(props) => (props.errorr ? "1px solid red" : "")};
      position: relative;
      .deleteOne {
        display: ${(props) => (props.one ? "" : "none")};
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputOne {
      border: none;
      width: 100%;
      height: 17px;
      /* font-size : 50px; */
      margin: 0 auto;
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
      &:focus {
        outline: none;
        /* border-bottom: 1px solid #2276DC; */
      }
    }
    .inputTwo {
      border: none;
      width: 100%;
      height: 17px;
      /* font-size : 50px; */
      margin: 0 auto;
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
      &:focus {
        outline: none;
        /* border-bottom: 1px solid #2276DC; */
      }
    }
    .input {
      border: none;
      width: 100%;
      height: 17px;
      /* font-size : 50px; */
      margin: 0 auto;
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
      &:focus {
        outline: none;
        /* border-bottom: 1px solid #2276DC; */
      }
    }

    .info {
      font-size: 11px;
      font-family: "light";
      color: #6e6e6e;
      margin-bottom: 10px;
      padding: 0 24px;
      margin-top: 16px;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 19px;
      width: 100%;
      text-align: center;
      padding: 16px 24px;
      box-sizing: border-box;
      font-family: "Bold";
    }
    .headerBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .logo {
        display: none;
      }
      .x {
        display: none;
      }
    }
  }
  @media ${device.pc} {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    z-index: 70;
    .contents {
      width: 100%;
      .what {
        margin-top: 90px;
      }
    }
    .mobiles {
      display: none;
    }
    .validation {
      margin-top: 5px;
      font-size: 12px;
      color: red;
    }
    .wrongPassword {
      margin-top: 5px;
      font-size: 12px;
      color: red;
    }
    .checkPassword {
      margin-top: 5px;
      font-size: 12px;
      color: red;
    }
    .arrow {
      display: none;
    }
    .mid {
      font-size: 15px;
      font-family: "Medium";
      margin-bottom: 10px;
    }
    .email {
      font-size: 15px;
      color: #2276dc;
    }
    .inputBoxOne {
      position: relative;
      /* width : 100%; */
      .deleteOne {
        display: ${(props) => (props.one ? "" : "none")};
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputBoxTwo {
      position: relative;
      .deleteTwo {
        display: ${(props) => (props.two ? "" : "none")};
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputBoxThree {
      position: relative;
      .deleteThree {
        display: ${(props) => (props.three ? "" : "none")};
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        right: 5px;
        cursor: pointer;
      }
    }
    .inputOne {
      position: relative;
      z-index: 5;
      border: none;
      width: 100%;
      height: 35px;
      font-size: 15px;
      margin: 2.5px 0;
      padding: 1px 0px;
      border-bottom: ${(props) =>
        props.wrongError
          ? "1px solid red"
          : "1px solid rgba(217, 217, 217, 0.5)"};
      &:focus {
        outline: none;
        border-bottom: ${(props) =>
          props.wrongError ? "1px solid red" : "0.5px solid #2276DC;"};
      }
      &::placeholder {
        color: #bdbdbd;
        font-family: "light";
      }
    }
    .input {
      border: none;
      width: 100%;
      height: 35px;
      font-size: 15px;
      margin: 2.5px 0;
      padding: 1px 0px;
      border-bottom: ${(props) =>
        props.validation
          ? "1px solid red"
          : "1px solid rgba(217, 217, 217, 0.5)"};
      &:focus {
        outline: none;
        border-bottom: ${(props) =>
          props.validation ? "1px solid red" : "0.5px solid #2276DC;"};
      }
      &::placeholder {
        color: #bdbdbd;
        font-family: "light";
      }
    }
    .inputTwo {
      border: none;
      width: 100%;
      height: 35px;
      font-size: 15px;
      margin: 2.5px 0;
      padding: 1px 0px;
      border-bottom: ${(props) =>
        props.checkError
          ? "1px solid red"
          : "1px solid rgba(217, 217, 217, 0.5)"};
      &:focus {
        outline: none;
        border-bottom: ${(props) =>
          props.checkError ? "1px solid red" : "0.5px solid #2276DC;"};
      }
      &::placeholder {
        color: #bdbdbd;
        font-family: "light";
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 204px;
      height: 52px;
      color: white;
      font-size: 17px;
      border: none;
      font-family: "medium";
      background: ${(props) => (props.active ? "#ED893E;" : "#D9D9D9")};
      border-radius: 43px;
      margin: 67px auto 0;
      cursor: ${(props) => (props.active ? "pointer" : "")};
    }
    .info {
      font-size: 12px;
      font-family: "light";
      color: #444444;
      margin-bottom: 10px;
      margin-top: 16px;
    }
    .title {
      font-size: 26px;
      width: 360px;
      height: 38px;
      text-align: center;
      font-family: "Bold";
      margin: 44px 0 90px 0;
    }
    .headerBox {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .logo {
      }
      .x {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
`;

const BackGround = styled.div`
  @media ${device.mobile} {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #f0f1f2;
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    top: 0;
    left: 0;
    z-index: 49;
  }
  @media ${device.tablet} {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: #f0f1f2;
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    top: 0;
    left: 0;
    z-index: 49;
  }
`;

export default PasswordSetting;
