import styled from "styled-components";
import { device } from "../hooks/device";
import whiteCheck from "../img/white_ch.svg";
import TextLine from "../atomic/atom/TextLine";

/**
 * 티켓링크 : https://www.notion.so/edint/WEB-efcb61baf6c44e73a6f4c4cf9adbc368?pvs=4
 * 주석작성자 : Noah
 * 주석작성일자 : 2024.05.08
 * 티켓내용 : 프록토매틱 가입 시 ‘모두 동의’ 기능
 * 주석설명 : 아래 약관에 모두 동의 버튼 추가
 */
const Terms = ({
  // checkBox1,
  // setCheckBox1,
  // checkBox2,
  // setCheckBox2,
  // checkBox3,
  // setCheckBox3,
  // checkBox4,
  // setCheckBox4,
  // checkBox5,
  // setCheckBox5,
  checkedState,
  setCheckedState,
  isAllChecked,
  setAllCheckBox,
}) => {
  const onChangeSetCheckBox = (e, i) => {
    const updateCheckedState = checkedState.map((item, index) =>
      index === i ? e.target.checked : item
    );
    setCheckedState(updateCheckedState);
    const checkedLength = updateCheckedState.reduce((sum, state) => {
      if(state === true) {
        return sum + 1;
      }
      return sum;
    }, 0);
    setAllCheckBox(checkedLength === updateCheckedState.length);
  };
  const onChangeSetAllCheckBox = (e) => {
    setAllCheckBox(e.target.checked);
    setCheckedState(checkedState.map(() => e.target.checked));
  };

  return (
    <>
      <StTerms>
        <TextLine
          text="프록토매틱 이용약관과 개인정보처리방침"
          fontsize={13}
          pc_fontsize={15}
          fontFamily="medium"
          pc_margin="0 0 15px 0"
          padding="14px 0 5px"
          pc_padding="0"
        />
        <StCheckbox whiteCheck={whiteCheck}>
          <div className="checkBoxList">
            <div className="checkList">
              <input
                type="checkbox"
                id="checkboxall"
                onChange={(e) => onChangeSetAllCheckBox(e)}
                checked={isAllChecked}
              />
              <label htmlFor="checkboxall" />
              <p>아래 약관에 모두 동의</p>
            </div>
            <div className="grey" />
            <div className="checkList">
              <input
                type="checkbox"
                id="checkbox1"
                // onChange={(e) => onChangeSetCheckBox(e, setCheckBox1)}
                //checked={checkBox1}
                onChange={(e) => onChangeSetCheckBox(e, 0)}
                checked={checkedState[0]}
              />
              {/* <div htmlFor="checkbox1">여기야</div> */}
              <label htmlFor="checkbox1" />
              <p>만 14세 이상 동의 (필수)</p>
            </div>
            <div className="checkList">
              <input
                type="checkbox"
                id="checkbox2"
                // onChange={(e) => onChangeSetCheckBox(e, setCheckBox2)}
                // checked={checkBox2}
                onChange={(e) => onChangeSetCheckBox(e, 1)}
                checked={checkedState[1]}
              />
              <label htmlFor="checkbox2" />
              <p>
                <span
                  className="detailBtn"
                  onClick={() => {
                    window.open(
                      `https://edint.notion.site/ba7e64b525b44c26939a5e15efd00b9f?pvs=4`
                    );
                  }}
                >
                  이용약관
                </span>{" "}
                동의 (필수)
              </p>
            </div>
            <div className="checkList">
              <input
                type="checkbox"
                id="checkbox5"
                // onChange={(e) => onChangeSetCheckBox(e, setCheckBox3)}
                // checked={checkBox3}
                onChange={(e) => onChangeSetCheckBox(e, 2)}
                checked={checkedState[2]}
              />
              <label htmlFor="checkbox5" />
              <p>
                <span
                  className="detailBtn"
                  onClick={() => {
                    window.open(
                      `https://edint.notion.site/45222e54e558415487d672680ef1a310?pvs=4`
                    );
                  }}
                >
                  위치정보서비스 이용약관
                </span>{" "}
                동의 (필수)
              </p>
            </div>
            <div className="checkList">
              <input
                type="checkbox"
                id="checkbox3"
                // onChange={(e) => onChangeSetCheckBox(e, setCheckBox4)}
                // checked={checkBox4}
                onChange={(e) => onChangeSetCheckBox(e, 3)}
                checked={checkedState[3]}
              />
              <label htmlFor="checkbox3" />
              <p>
                <span
                  className="detailBtn"
                  onClick={() => {
                    window.open(
                      `https://edint.notion.site/672a5db0a38c4299bc4ef72494b06b84?pvs=4`
                    );
                  }}
                >
                  개인정보처리방침
                </span>{" "}
                동의 (필수)
              </p>
            </div>
            {/* {setCheckBox5 && ( */}
            {checkedState.length === 5 && (
              <div className="checkList">
                <input
                  type="checkbox"
                  id="checkbox4"
                  // onChange={(e) => onChangeSetCheckBox(e, setCheckBox5)}
                  // checked={checkBox5}
                  onChange={(e) => onChangeSetCheckBox(e, 4)}
                  checked={checkedState[4]}
                />
                <label htmlFor="checkbox4" />
                <p>마케팅 활용 및 광고 수신 동의 (선택)</p>
              </div>
            )}
          </div>
        </StCheckbox>
      </StTerms>
    </>
  );
};

const StTerms = styled.section`
  display: grid;
  .checkBoxList {
    width: 100%;
    padding-top: 5px;

    .checkList:not(:first-child):not(:last-child) {
        margin-bottom: 15px;
    }
  }

  .checkList {
    display: flex;

    p {
      font-family: "Regular";
      font-size: 15px;
      line-height: 22px;
    }
  }

  @media ${device.tablet} {
    .mainDescription {
      font-size: 16px;
      line-height: 24px;
      font-family: "Regular";
      margin: 10px 0 16px;
    }
    .description {
      color: #6e6e6e;
      font-size: 11px;
      line-height: 16px;
    }
  }

  @media ${device.mobile} {
    .mainDescription {
      font-family: "Regular";
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0 16px;
    }
    .description {
      color: #6e6e6e;
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

const StCheckbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  p {
    padding: 0 0 0 12px;
    margin: 0;
    font-family: "light";
    span {
      display: inline-block;
      cursor: pointer;
      color: #2276dc;
      text-decoration: underline;
      text-underline-position: under;
    }
  }
  .grey {
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #dfdfdf;
    cursor: default;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    //체크 안된 상태의 라벨
    content: "";
    display: flex;
    width: 22px;
    height: 22px;
    border: 0.6px solid #818181;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
  }
  input[type="checkbox"]:checked + label:before {
    //체크된 상태의 라벨
    content: "";
    display: flex;
    width: 22px;
    height: 22px;
    border: none;
    border-radius: 4px;
    background-color: #20315b;
    background-image: url(${(props) => props.whiteCheck});
    background-repeat: no-repeat;
    background-position: center;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 21px;
    padding-top: 5px;
    margin-bottom: 14px;
    p {
      span {
        height: 18px;
        line-height: 21px;
      }
    }
  }

  @media ${device.mobile} {
    font-size: 14px;
    line-height: 21px;
    padding-top: 5px;
    margin-bottom: 14px;

    p {
      span {
        height: 18px;
        line-height: 21px;
      }
    }
  }
`;

const TermsDetail = styled.section`
  @media ${device.pc} {
  }
  @media (max-width: 1139px) {
    .bodys {
      margin: 0 auto !important;
      left: 0 !important;
      transform: translate(0%, 0%) !important;
      .bodyHead {
        font-size: 22px !important;
      }
      .contents {
        padding: 0 !important;
      }
    }
  }
`;

export default Terms;
