import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// import Input from "../components/Input";
import EmailInput from "../components/EmailInput";
import BasicFinish from "../components/BasicFinish";

//style
import styled from "styled-components";
import style from "../modal/modal.module.css";

//components

// img

import greyX from "../img/delete.png";

//hooks
import { device } from "../hooks/device";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { bannerOn, userEmail } from "../atoms";
import { ModalBackTwo } from "../components/Style";
import axios from "axios";
import { baseURL } from "../api/handler";
import useScrollLockThree from "../hooks/useScrollLockThree";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import Input from "../atomic/atom/Input";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import OnChangeInput from "../atomic/atom/OnchangeInput";

const ResetPassword = ({
  setResetPasswordModal,
  setModalLevel,
  setLoginModalOn,
  modalLevel,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { certified } = useRecoilValue(userEmail);
  const resetUserEmailData = useResetRecoilState(userEmail);
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(null);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(null);
  const [emailAuthModal, setEmailAuthModal] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [errPwMessage, setErrPwMessage] = useState("");
  const [errPwChMessage, setErrPwChMessage] = useState("");
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordCheckRef = useRef();
  const legPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  const showToast = useToast();
  const banneris = useRecoilValue(bannerOn);

  const setNameAction = (value) => {
    setName(value);
  };

  const goBack = () => {
    if (modalLevel === 1) {
      setResetPasswordModal(false);
    } else if (modalLevel === 2) {
      setModalLevel(1);
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, [modalLevel]);

  const nameCheck = (text) => {
    dateErrScroll(nameRef);
    nameRef.current.focus();
    setNameErr(text);
  };

  useEffect(() => {
    setNameErr(null);
  }, [name]);

  const emailCheck = (text) => {
    dateErrScroll(emailRef);
    emailRef.current.focus();
    setEmailErr(text);
  };

  const pwCheck = (text) => {
    dateErrScroll(passwordRef);
    passwordRef.current.focus();
    setErrPwMessage(text);
  };
  const pwChCheck = (text) => {
    dateErrScroll(passwordCheckRef);
    passwordCheckRef.current.focus();
    setErrPwChMessage(text);
  };

  const findPasswordAction = () => {
    axios
      .put(`${baseURL}/api/v1/hosts/find-password`, {
        hostName: name,
        hostEmail: email,
        newHostPassword: password,
        newHostPasswordCheck: passwordCheck,
      })
      .then(() => {
        setLoginModalOn(true);
        setResetPasswordModal(false);
        resetUserEmailData();
        showToast({ message: t("errorCode.reset_password") });
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "HOST-001") {
          setNameErr(t("errorCode.host_001"));
        } else if (errorCode === "HOST-013") {
          setNameErr(t("errorCode.host_013"));
        } else if (errorCode === "VERIFY-001") {
          emailCheck(t("errorCode.verify_001"));
        } else if (errorCode === "INCORECT-PWD") {
          passwordCheck(t("errorCode.incorect_pwd_new"));
        } else {
          showToast({
            message: t("errorCode.unknown_server_error"),
          });
        }
      });
  };

  const dateErrScroll = (ref) => {
    ref.current.scrollIntoView({ block: "center" });
  };

  const findPassword = (e) => {
    e.preventDefault();
    if (!certified) {
      emailCheck(t("errorCode.verify_001"));
      return;
    } else if (!legPass.test(password)) {
      pwCheck(t("errorCode.pw_form_error"));
      return;
    } else if (password !== passwordCheck) {
      pwChCheck(t("errorCode.incorect_pwd_new"));
      return;
    }
    findPasswordAction();
  };

  const disabled = () => {
    if (name && password && passwordCheck) {
      return false;
    } else {
      return true;
    }
  };

  useScrollLockThree();
  console.log(errPwMessage);
  return (
    <>
      <ModalBackTwo />
      <Container banneris={banneris}>
        <div className="wrapper">
          <img
            src={greyX}
            onClick={() => setResetPasswordModal(false)}
            className="cancelButton onlyForPc"
            alt=""
          />
          {!completionStatus ? (
            <form action="" onSubmit={findPassword}>
              <div className="titleBox">
                <div className="titleUp">비밀번호 재설정</div>
                <div className="titleDown">
                  비밀번호가 기억나지 않으신가요?
                  <br />
                  간편하게 다시 설정할 수 있어요!{" "}
                </div>
              </div>
              <section className="inputSection">
                {/* <Input
                  subTitle={"주최자 이름"}
                  placeholder={"이름 입력"}
                  setFc={setNameAction}
                  inputValue={name}
                  elementRef={nameRef}
                  err={nameErr}
                  max={25}
                /> */}
                <DivTwoPartImprove display="grid" gap={5}>
                  <TextLine
                    text="주최자 이름"
                    fontsize={13}
                    lineheight={19}
                    fontFamily="medium"
                  />
                  <OnChangeInput
                    placeholderText="이름 입력"
                    padding="6px 0"
                    ref={nameRef}
                    fontsize={16}
                    border="none"
                    border_bottom={
                      nameErr ? "1px solid #C83B38" : "1px solid #dfdfdf"
                    }
                    onchange={(e) => setName(e.target.value)}
                  />
                </DivTwoPartImprove>
                {nameErr && <div className={style.errorMessage}>{nameErr}</div>}
              </section>
              <section className="emailSection">
                <EmailInput
                  setEmailAuthModal={setEmailAuthModal}
                  emailAuthModal={emailAuthModal}
                  entryPoint="resetPassword"
                  setPassword={setPassword}
                  password={password}
                  setPasswordCheck={setPasswordCheck}
                  passwordCheck={passwordCheck}
                  email={email}
                  setEmail={setEmail}
                  emailErr={emailErr}
                  setEmailErr={setEmailErr}
                  errPwMessage={errPwMessage}
                  setErrPwMessage={setErrPwMessage}
                  errPwChMessage={errPwChMessage}
                  setErrPwChMessage={setErrPwChMessage}
                  emailRef={emailRef}
                  setModalLevel={setModalLevel}
                  passwordRef={passwordRef}
                  passwordCheckRef={passwordCheckRef}
                  pathname={location.pathname}
                  purpose="resetPassword"
                  // writingData={writingData}
                  /** 티켓 41관련 추가, Noah */
                  hostName={name}
                />
              </section>
              <div className="btnBox">
                <button className={style.orangeButton} disabled={disabled()}>
                  재설정 하기
                </button>
              </div>
            </form>
          ) : (
            <BasicFinish
              content={`<span>${name}</span> 님,<br />비밀번호가 재설정 되었어요!`}
              buttonText="재설정 완료"
              setResetPasswordModal={setResetPasswordModal}
            />
          )}
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  /* overflow: scroll; */
  ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    margin-top: 100px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #c6c6c6;
    border-radius: 2px;
    width: 2px;
    height: 100px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 1rem;
  }
  .cancelButton {
    margin: 0 0 8.5px auto;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .titleBox {
    display: flex;
    flex-direction: column;
    text-align: center;
    .titleUp {
      font-family: "bold";
    }
    .titleDown {
      font-family: "regular";
      color: #6e6e6e;
    }
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .inputSection {
    margin-bottom: 32px;
  }
  .emailSection {
    margin-bottom: 67px;
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${device.pc} {
    z-index: 54;
    left: 50%;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    form {
      width: 360px;
      margin: 0 auto;
    }
    .titleBox {
      display: grid;
      gap: 16px;
      margin: 48px 0 67px;
      .titleUp {
        font-size: 26px;
        line-height: 38px;
      }
      .titleDown {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 49;
    bottom: 0;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    /* padding: 24px 0 0; */
    box-sizing: border-box;
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 24px 0px;
      box-sizing: border-box;
      width: 100%;
      height: -webkit-fill-available;
      /* height: 100%; */
      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 5px;

        height: 10px;

        margin-top: 100px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;

        border-radius: 100px;
      }

      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    form {
      max-width: 752px;
    }
    .onlyForPc {
      display: none;
    }
    .titleBox {
      display: grid;
      gap: 15px;
      margin-bottom: 44px;
      .titleUp {
        font-size: 22px;
        line-height: 33px;
      }
      .titleDown {
        font-size: 15px;
        line-height: 22px;
      }
    }
    .inputSection {
      margin-bottom: 14px;
    }
    .emailSection {
      margin-bottom: 0;
    }
    .btnBox {
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding-bottom: 22px;
      margin-top: auto;
      padding-top: 67px;
    }
  }
`;

export default ResetPassword;
