import React, { useEffect, useState } from "react";
import { ModalBack, ModalHeader } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";

// img
import upArrow from "../img/upArrow.svg";
import arrow3 from "../img/arrow3.svg";
import useScrollLockThree from "../hooks/useScrollLockThree";
import ModalPageTemplate from "../atomic/template/ModalPageTemplate";

const Policy = () => {
  const [policyOne, setPolicyOne] = useState();
  const [policyTwo, setPolicyTwo] = useState();

  // useEffect(() => {
  //   window.history.pushState(null, "", window.location.pathname);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("popstate", goBack);
  //   return () => {
  //     window.removeEventListener("popstate", goBack);
  //   };
  // }, []);

  // Noah, 중복 주석 처리
  // useScrollLockThree();

  return (
    <>
      {/* <ModalBack /> */}
      <ModalPageTemplate>
        <StPolicy>
          <div className="wrapper">
            <ModalHeader title="취소/환불 정책" />
            <div className="listPart">
              <div className="listOne">
                <div
                  className="listClick"
                  onClick={() => setPolicyOne(!policyOne)}
                >
                  <div>시험 취소 관련 안내</div>
                  {!policyOne ? (
                    <img src={arrow3} alt="" />
                  ) : (
                    <img src={upArrow} alt="" />
                  )}
                </div>
                {policyOne && (
                  <div className="listDown">
                    <span>취소 마감 시간</span>
                    <ul>
                      <li>
                        응시 시작시간 3시간 전까지 취소가 가능해요. <br />
                        예) 13시에 시험 시작 시 10시 전까지 취소 가능
                      </li>
                      <li style={{ color: "#C83B38" }}>
                        취소 마감 시간 이후에는 취소가 불가해요.{" "}
                      </li>
                      <li>
                        취소 불가에 따른 미응시 시험의 경우, 미응시 관련 환불
                        정책에 따라 처리돼요.
                        <br />
                        (자세한 내용은 취소/미응시 관련 환불 안내 참조)
                      </li>
                    </ul>
                    <span style={{ marginTop: "14px" }}>취소 수수료 정책</span>
                    <ul>
                      <li>
                        취소 시 별도의 수수료는 없으며 전액 환불돼요.
                        <br />
                        (자세한 내용은 취소/미응시 관련 환불 안내 참조)
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {!policyOne ? <div className="rowGrayBar" /> : <></>}
              <div className="listOne">
                <div
                  className="listClick"
                  onClick={() => setPolicyTwo(!policyTwo)}
                >
                  <div>취소/미응시 관련 적립금 환원 안내</div>
                  {!policyTwo ? (
                    <img src={arrow3} alt="" />
                  ) : (
                    <img src={upArrow} alt="" />
                  )}
                </div>
                {policyTwo && (
                  <div className="listDown">
                    <span>취소 마감 시간 전</span>
                    <ul>
                      <li>
                        취소 마감시간 : 응시 시작시간 기준 3시간 전<br />
                        예) 시작 시간이 13시라면 취소 마감시간은 10시 전액
                        환불(즉시 취소 가능)
                      </li>
                    </ul>
                    <span>취소 마감 시간 이후</span>
                    <ul>
                      <li>
                        시험 참여 여부와 관계없이 결제한 금액은 환불 불가(시험
                        취소도 불가)
                      </li>
                      <li>
                        다만, 시험 종료 후 미응시 인원에 한해 해당 인원분 만큼
                        포인트 또는 적립금으로 환불
                        <br />
                        a. 개인 회원 : 미응시 인원분 만큼 포인트 환불
                        <br />
                        b. 기업 회원 : 미응시 인원분 만큼 적립금 환원
                      </li>
                    </ul>
                    <span>취소 수수료 및 환불 방법</span>
                    <ul>
                      <li>
                        취소 수수료 : 없음 <br />
                      </li>
                      <li>
                        회원 유형별 환불 방법
                        <br />
                        a. 개인 회원 : 결제한 방법에 맞춰 전액 결제 취소
                        <br />
                        b. 기업 회원 : 전액 적립금 환원
                      </li>
                    </ul>
                    <span>미응시 인원에 속하는 대상 (포인트/적립금 환불)</span>
                    <ul>
                      <li>시험 URL에 접속하지 않은 응시자</li>
                      <li>
                        시험 URL에 접속하여 준비 과정(로그인, 디바이스 점검,
                        환경점검, 카메라 거치 등)까지는 진행했으나, 응시
                        시작시간에 ‘시험 시작’을 진행하지 않은 응시자
                      </li>
                    </ul>
                    <span>미응시 인원에 속하지 않는 대상 (환불 불가)</span>
                    <ul>
                      <li>
                        시험 URL에 접속 후 응시 시작시간에 ‘시험 시작’을 진행한
                        모든 응시자. ‘시험 시작’ 직후에는 몇 분을 진행했는지
                        여부와 상관없이 응시 인원(과금 대상)으로 간주함
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </StPolicy>
      </ModalPageTemplate>
    </>
  );
};

const StPolicy = styled.div`
  .listPart {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .listOne {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* padding: 14px 0px; */
      box-sizing: border-box;
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
      .listClick {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
      }
      .listDown {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        background-color: #fafafa;
        border-top: 1px solid #d9d9d9;
        span {
          font-family: "medium";
          font-size: 15px;
          margin-bottom: 8px;
        }
        ul {
          margin: 0;
          font-family: "medium";
          font-size: 14px;
          padding-left: 17px;
        }
      }
      img {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #bdbdbd;
  }
  @media ${device.pc} {
    .listPart {
      width: 100%;
      margin-top: 48px;
      .listOne {
        .listClick {
          padding: 16px 0;
          cursor: pointer;
        }
        .listDown {
          padding: 0 24px 24px;
          height: 252px;
          overflow-x: hidden;
          ::-webkit-scrollbar {
            width: 5px;
            height: 10px;
            margin-top: 100px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
            border-radius: 100px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
          span {
            margin-top: 24px;
          }
        }
      }
      .rowGrayBar {
        height: 1px;
        background-color: #d9d9d9;
      }
    }
  }
  @media ${device.tabMob} {
    .wrapper {
      width: 100%;
      height: calc(100% - 60px);
      overflow-x: hidden;
      max-width: 752px;
      margin: 0 auto;
      ::-webkit-scrollbar {
        width: 2px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 2px;
        width: 2px;
        height: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .listPart {
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 0 24px;
      background-color: #ffffff;
      .listOne {
        .listClick {
          padding: 14px 0;
        }
        .listDown {
          padding: 0 12px 14px;
          span {
            margin-top: 14px;
          }
        }
      }
    }
  }
`;

export default Policy;
