import React, { useEffect, useState } from "react";
import styled from "styled-components";
import instagram from "../img/Instagram.svg";
import facebook from "../img/Facebook.svg";
import linkedIn from "../img/LinkedIn.svg";
import youtube from "../img/Youtube.svg";
import langDown from "../img/langDown.svg";
import blueCheck from "../img/blueCheck.svg";
// import edintLogo from ' ../img/edintLogo.png';
import edint from "../img/edintt.png";
import { device } from "../hooks/device";
import FootBar from "./FootBar";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { languageIs, userInfo, userlang } from "../atoms";
import LanguageChangeButton from "../atomic/molecule/LanguageChangeButton";

const Footer = () => {
  const user = useRecoilValue(userInfo);
  const navigate = useNavigate();
  const [country, setCountry] = useRecoilState(languageIs);
  const [langClick, setLangClick] = useState();
  const [lang, setLang] = useState("");
  const [nowLang, countryChange] = useRecoilState(userlang);

  useEffect(() => {
    if (user?.hostLanguage === "KR") {
      setLang("KOR");
    } else if (user?.hostLanguage === "EN") {
      setLang("ENG");
    }
  }, [user]);

  useEffect(() => {
    if (nowLang === "KR") {
      setLang("KOR");
    } else {
      setLang("ENG");
    }
  }, [nowLang]);

  return (
    <>
      <div style={{ marginTop: "auto" }}>
        <FootBar />
        <StBody lang={lang}>
          <div className="inBox">
            <div className="pcLeft">
              <img
                className="edint"
                alt=""
                onClick={() => {
                  window.open("https://www.edint.kr/");
                }}
                src={edint}
              />
              <div className="center">
                <div className="line">(주) 에딘트 | 대표 : 원동일</div>
                <div className="line">사업자 등록번호 : 462-81-02559</div>
                <div className="line">통신판매번호 : 2023-대전유성-0826</div>
                <div className="line">
                  대전광역시 유성구 가정로 218, 3층 302-2-1호
                </div>
                <div className="lineBox">
                  <div className="line">이메일 : hello@edint.io</div>
                  <div className="line">전화 : 070-8065-0811</div>
                </div>
              </div>
            </div>
            <div className="pcRight">
              <div className="iconBox">
                {/* <LanguageChangeButton /> */}
                <div className="onlyForPc" />
                {/* 위의 div 는 LanguageChangeButton 이 활성화되면 지울것 */}
                <div className="icons">
                  <img
                    className="icon"
                    alt=""
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/edintkor.official/"
                      );
                    }}
                    src={instagram}
                  />
                  <img
                    className="icon"
                    alt=""
                    onClick={() => {
                      window.open("https://www.linkedin.com/company/edint/");
                    }}
                    src={linkedIn}
                  />
                  <img
                    className="icon"
                    alt=""
                    onClick={() => {
                      window.open("https://www.youtube.com/@edint");
                    }}
                    src={youtube}
                  />
                </div>
              </div>
              <div className="bottom">
                ⓒ 2022. EDINT Inc. All Rights Reserved
              </div>
            </div>
          </div>
        </StBody>
      </div>
    </>
  );
};

const StBody = styled.div`
  display: grid;
  width: 100%;
  background-color: #15254e;
  box-sizing: border-box;
  color: #bdbdbd;
  @media ${device.pc} {
    min-width: 1440px;
    .inBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      min-width: 1440px;
      width: 1;
      padding: 70px 124px;
      box-sizing: border-box;
      margin: 0 auto;
      .pcLeft {
        display: grid;
        grid-template-columns: 99.315px auto;
        align-items: center;
        gap: 30px;
        .edint {
          width: 99.315px;
          height: 107.65px;
          cursor: pointer;
        }
        .center {
          display: grid;
          /* flex-direction: column; */
          line-height: 18px;
          font-size: 12px;
          font-family: "regular";
          gap: 6px;
        }
        .lineBox {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 16px;
        }
      }
      .pcRight {
        display: grid;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        gap: 66px;
        .iconBox {
          display: grid;
          grid-template-columns: 1fr auto;
          align-items: center;
          margin-left: auto;
          gap: 19px;
          .icons {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 19px;
            .icon {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
          }
        }
        .bottom {
          font-size: 12px;
          font-family: "regular";
          line-height: 18px;
          margin-left: auto;
        }
        .line {
          margin-bottom: 3.25px;
        }
      }
    }
  }
  @media ${device.tabMob} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    font-size: 10px;
    .onlyForPc {
      display: none;
    }
    .pcLeft {
      display: flex;
      flex-direction: column;
      align-items: center;
      .edint {
        width: 75px;
        height: 82px;
        cursor: pointer;
      }
      .center {
        display: grid;
        text-align: center;
        align-items: center;
        margin: 40px 0;
        gap: 6px;
      }
      .lineBox {
        display: grid;
        flex-direction: column;
        gap: 6px;
      }
    }
    .pcRight {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .iconBox {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 19px;
      margin: 0 auto 40px;
      width: 110px;
      .langButton {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 12px;
        margin: 0 auto;
        box-sizing: border-box;
        width: 88px;
        height: 30px;
        font-size: 14px;
        font-family: "regular";
        line-height: 21px;
        border: 1px solid #bdbdbd;
        color: #bdbdbd;
        .langShow {
          display: flex;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
        }
        .langBack {
          position: fixed;
          z-index: 50;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .optionBox {
          position: absolute;
          z-index: 50;
          left: 0;
          bottom: 0;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          width: 188px;
          height: 108px;
          .optionOne {
            width: 100%;
            height: 54px;
            padding: 15px 24px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
            color: black;
            cursor: pointer;
          }
          .optionTwo {
            width: 100%;
            height: 54px;
            padding: 15px 24px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
            color: black;
            cursor: pointer;
          }
        }
      }
      .icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 19px;
        .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
    .line {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default Footer;
