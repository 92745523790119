import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import useScrollLockThree from "../../../hooks/useScrollLockThree";

const CreditChargeRequestConfirm = () => {
  useScrollLockThree();
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title="적립금 충전 요청 완료" />
      <DivTwoPartImprove
        margin="24px 0"
        pc_margin="48px 0"
        lineheight={24}
        pc_lineheight={27}
        pc_font_size={18}
        pc_font_family="medium"
      >
        <DivTwoPartImprove display="grid">
          <TextLine text="적립금 충전 요청이 완료되었어요." />
          <TextLine text="관리자가 확인 후 이메일로 인보이스를 보내 드릴게요. " />
        </DivTwoPartImprove>
        <br />
        <TextLine text="* 최대 24시간 내에 연락드려요." />
      </DivTwoPartImprove>
      <Button
        text="확인"
        height={35}
        border="none"
        font_size={18}
        font_family="medium"
        font_color="#0072DE"
        pc_fontcolor="black"
        pc_border="1px solid #000"
        pc_border_radius={43}
        pc_height={52}
        backgroundcolor="white"
        margin="0 auto"
        onclick={() => {
          window.history.back();
          window.history.back();
        }}
      />
    </ModalOneButtonTemplate>
  );
};

export default CreditChargeRequestConfirm;
