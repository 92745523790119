import React, { useEffect, useRef, useState } from "react";
import InputBoxWithTitle from "../molecule/InputBoxWithTitle";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import TextLine from "../atom/TextLine";
import AlertMessage from "../molecule/AlertMessage";

interface TesterCountChargeProps {
  setChargeAmount: React.Dispatch<React.SetStateAction<number>>;
}

const TesterCountCharge: React.FC<TesterCountChargeProps> = (props) => {
  const testTimeList = ["60분", "50분", "40분", "30분"];
  const [testTime, setTestTime] = useState<string | null>(null);
  const [testerCount, setTesterCount] = useState<number>(0);
  const [chargePrice, setChargePrice] = useState<number>(0);
  const [chargeCredit, setChargeCredit] = useState<number>(0);

  useEffect(() => {
    setChargePrice(
      ((testerCount * Number(testTime?.replace(/분/g, ""))) / 10) * 1500
    );
  }, [testerCount]);

  useEffect(() => {
    setChargeCredit(chargePrice / 100);
    props.setChargeAmount(chargePrice);
  }, [chargePrice]);

  useEffect(() => {
    setChargePrice(
      Math.ceil(
        (((testerCount * Number(testTime?.replace(/분/g, ""))) / 10) * 1500) /
          10000
      ) * 10000
    );
  }, [testTime]);

  return (
    <DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        grid_template_column="1fr"
        pc_grid_template_column="1fr 1fr"
        gap={12}
      >
        <InputBoxWithTitle
          title="시험 시간"
          optional={true}
          option_list={testTimeList}
          optionChoose={testTime}
          setOptionChoose={setTestTime}
          placeholder="시험 시간"
          option_list_border="1px solid #D9D9D9"
          option_list_border_radius={8}
        />
        <InputBoxWithTitle
          title="응시자 수"
          placeholder="응시자 수"
          height={56}
          pc_height={60}
          onchange={(e) => setTesterCount(e.target.value)}
          input_border_radius={8}
          input_type="number"
          onblur={() => setChargePrice(Math.ceil(chargePrice / 10000) * 10000)}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove display="grid" gap={2} margin="16px 0 0 0">
        <TextLine
          text="충전 할 금액 (원)"
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
        />
        <TextLine
          border_radius={8}
          lineheight={36}
          height={50}
          pc_height={54}
          padding="10px 16px"
          pc_padding="12px 16px"
          backgroundcolor="#F0F1F2"
          text={chargePrice > 0 ? chargePrice.toLocaleString() : ""}
          // text={chargePrice > 0 ? chargePrice.toLocaleString() + "원" : ""}
        />
        <AlertMessage
          text="10,000원 단위로 충전이 가능합니다."
          pc_fontsize={16}
          fontsize={14}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove display="grid" gap={2} margin="16px 0 0 0">
        <TextLine
          text="충전 할 적립금 (C)"
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
        />
        <TextLine
          border_radius={8}
          lineheight={36}
          height={50}
          pc_height={54}
          fontcolor="#2276DC"
          padding="10px 16px"
          pc_padding="12px 16px"
          backgroundcolor="#F0F1F2"
          text={chargeCredit > 0 ? chargeCredit.toLocaleString() : ""}
          // text={chargeCredit > 0 ? chargeCredit.toLocaleString() + "c" : ""}
        />
        <AlertMessage
          text="100c 단위로 충전이 가능합니다."
          pc_fontsize={16}
          fontsize={14}
        />
      </DivTwoPartImprove>
    </DivTwoPartImprove>
  );
};

export default TesterCountCharge;
