import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { languageIs, userInfo } from "../atoms";
import CreditQuestion from "../modal/CreditQuestion";

// img
import vector from "../img/3dots.svg";
import companyMember from "../img/companyMembership.svg";
import indiMember from "../img/individualMembership.svg";
import what from "../img/what.svg";
import arrow from "../img/arrow.svg";
import arrow2 from "../img/vector.svg";
import arrow3 from "../img/arrow3.svg";
import upArrow from "../img/upArrow.svg";
import ConvertIndividual from "../modal/ConvertIndividual";
import { device } from "../hooks/device";
import { baseURL } from "../api/handler";
import LeftTest from "../modal/ServicePlanLeftTest";
import customAxios from "../api/handler";
import useToast from "../hooks/useToast";
import getApi from "../api/getApi";

const ServicePlan = ({ setModalOn }) => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const user = useRecoilValue(userInfo);
  const [policyOne, setPolicyOne] = useState();
  const [policyTwo, setPolicyTwo] = useState();
  const showToast = useToast();

  // modal state
  const [questionModal, setQuestionModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [convertModalAsk, setConvertModalAsk] = useState(false);
  const [leftToPayModal, setLeftToPayModal] = useState(false);
  const [leftTestModal, setLeftTestModal] = useState(false);

  // plan state
  const [plan, setPlan] = useState();

  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-Flow-1-a649ddc3a7bc4646a65ef9537c7f7624?pvs=4
   * 주석작성자 : Noah
   * 주석작성일자 : 2024.04.18
   * 티켓내용 : 마이 페이지(기업/구성원) → 서비스 플랜 → 개인 회원으로 전환 시나리오 Flow 순서 변경 건
   * 주석설명 : 토스트 메시지 보여주기 위해 추가
   */
  const [convertError, setConverError] = useState(false);

  useEffect(() => {
    if(convertError) {
      showToast({
        message:
          "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
      });
    }
  }, [convertError]);
  /************************************************************************************************ */ 

  useEffect(() => {
    if (user?.hostPlan === "individualPlan") {
      setPlan("무료 이용");
    } else if (user?.hostPlan === "memberPlan") {
      setPlan("기업용 (구성원)");
    } else {
      setPlan("기업용 (관리자)");
    }
  }, [user?.hostPlan]);

  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-Flow-1-a649ddc3a7bc4646a65ef9537c7f7624?pvs=4
   * 주석작성자 : Noah
   * 주석작성일자 : 2024.04.18
   * 티켓내용 : 마이 페이지(기업/구성원) → 서비스 플랜 → 개인 회원으로 전환 시나리오 Flow 순서 변경 건
   * 주석설명 : 유효성 검사 API호출 위치 변경 -> ConvertIndividual.jsx로 이동
   */ 
  // const beforeConvert = () => {
  //   getApi
  //     .getProgressingTest()
  //     .then(() => {
  //       setConvertModal(true);
  //     })
  //     .catch(({ response }) => {
  //       if (
  //         response.data.errorCode === "ENTERPRISE-010" ||
  //         response.data.errorCode === "TIMEZONE-007"
  //       ) {
  //         setLeftTestModal(true);
  //       } else {
  //         showToast({
  //           message:
  //             "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
  //         });
  //       }
  //     });
  // };

  return (
    <>
      {questionModal && <CreditQuestion setQuestionModal={setQuestionModal} />}
      {convertModal && 
      <ConvertIndividual 
        setConvertModal={setConvertModal} 
        setLeftTestModal={setLeftTestModal}
        setLeftToPayModal={setLeftToPayModal}
        setConverError={setConverError}
      />}
      {leftTestModal && (
        <LeftTest
          close={setLeftTestModal}
          title="개인 회원으로 전환 불가"
          textOne="‘내 시험 관리’ 내 시작 직전(시작 시간 기준 3시간 전) 또는 현재 진행 중에 있는 시험이 있어 개인 회원으로 전환 할 수 없어요. 
            해당 시험의 모든 응시자들이 영상 업로드 단계까지는 완료해야만 회원 전환을 문제없이 진행할 수 있어요."
        />
      )}
      {leftToPayModal && (
        <LeftTest
          title="개인 회원으로 전환 불가"
          textOne="‘내 시험 관리’ 내 추가요금 결제가 필요한 시험이 있어 개인 회원으로 전환 할 수 없어요. 전환을 진행하기 전에 남은 모든 추가 요금은 반드시 결제가 완료되어야만 해요."
          close={setLeftToPayModal}
        />
      )}
      <StServicePlan hostPlan={user?.hostPlan}>
        <div className="layerUp">
          <div className="titleBox">
            <div className="titleLeft">
              <img
                src={arrow}
                alt=""
                onClick={() => {
                  navigate(`/myPage`);
                }}
              />
              <span>서비스 플랜</span>
            </div>
            {user?.hostPlan === "memberPlan" && (
              <div style={{ position: "relative" }}>
                <img
                  src={vector}
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => {
                    setConvertModalAsk(true);
                  }}
                />
                {convertModalAsk && (
                  <>
                    <div
                      onClick={() => setConvertModalAsk(false)}
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "60",
                      }}
                    />
                    <div
                      className="convert"
                      onClick={() => {
                        // 유효성 검사 Api 호출 위치 변경
                        // beforeConvert();
                        setConvertModalAsk(false);
                        setConvertModal(true);
                        window.history.pushState(
                          null,
                          "",
                          window.location.href
                        );
                      }}
                    >
                      개인 회원으로 전환
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="inBox">
            <div className="imgPart">
              <div className="imgPLeft">
                <div className="individual">
                  {user?.hostPlan === "individualPlan"
                    ? "개인회원"
                    : "기업 회원"}
                </div>
                <span className="individualInfo">
                  {user?.hostPlan === "individualPlan"
                    ? "인원수 제한없는"
                    : "적립금 구매와"}{" "}
                  <br className="onlyForTabMob" />
                  {user?.hostPlan === "individualPlan"
                    ? "자유로운 시험 생성과 결제"
                    : "공유를 통한 편리한 시험 운영"}
                </span>
              </div>
              {user?.hostPlan === "individualPlan" ? (
                <img src={indiMember} alt="" />
              ) : (
                <img src={companyMember} alt="" />
              )}
            </div>
            <div className="planPart">
              <div className="planPTitle">서비스 플랜 및 요금</div>
              <div className="pcArrayPlan">
                <div className="planPPrice">
                  <div className="priceLeft">{plan}</div>
                  <div className="priceRight">
                    <div>
                      60<span>C</span>
                    </div>
                    <span>&nbsp;(60분 기준)</span>
                  </div>
                </div>
                <div className="pcArrayPlanInfo">
                  <div className="planPInfo">
                    <div className="infoUnder">
                      <div>과금 분단위</div>
                      <span>
                        10<div>분</div>
                      </span>
                    </div>
                    <div className="columnGrayBar" />
                    <div className="infoUnder">
                      <div>10분당</div>
                      <span>
                        10<div>C</div>
                      </span>
                    </div>
                  </div>
                  <div className="planPBottom">
                    * 10분 단위로 과금을 적용하여 서비스 이용료가 산정돼요.
                  </div>
                </div>
              </div>
            </div>
            <div className="manageBox">
              <div className="manageRight">
                <div className="managePart">
                  <div className="managePTitle">시험 운영 및 관리</div>
                  <div className="rowGrayBar" />
                  <div className="managePlist">
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>시험장 개설</div>
                      <span>무제한</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>응시 인원</div>
                      <span>무제한</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>응시 URL 링크</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>응시 전용 QR</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>결제 방식</div>
                      <span>
                        {user?.hostPlan === "individualPlan"
                          ? "무료 적립금"
                          : "적립금(후불제)"}
                      </span>
                    </div>
                    <div className="listOne">
                      {user?.hostPlan === "individualPlan" ? (
                        <div className="check" />
                      ) : (
                        <img className="check" src={arrow2} alt="" />
                      )}
                      <div>
                        적립금 운용
                        <img
                          src={what}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setQuestionModal(true);
                          }}
                          alt=""
                        />
                      </div>
                      {user?.hostPlan === "individualPlan" && <span>제공</span>}
                      {user?.hostPlan === "memberPlan" && (
                        <span>결제만 가능</span>
                      )}
                      {user?.hostPlan === "managerPlan" && (
                        <div className="rightOne">기업회원 한정</div>
                      )}
                    </div>
                    <div className="listOne">
                      {user?.hostPlan === "managerPlan" ? (
                        <img className="check" src={arrow2} alt="" />
                      ) : (
                        <div className="check" />
                      )}
                      <div>기업회원 우대 혜택</div>
                      {user?.hostPlan === "individualPlan" && (
                        <div className="rightOne">기업회원 한정</div>
                      )}
                      {user?.hostPlan === "memberPlan" && (
                        <div className="rightOne">관리자 한정</div>
                      )}
                      {user?.hostPlan === "managerPlan" && (
                        <span>추가 혜택 제공</span>
                      )}
                    </div>
                    <div className="listOne">
                      {user?.hostPlan === "managerPlan" ? (
                        <img className="check" src={arrow2} alt="" />
                      ) : (
                        <div className="check" />
                      )}
                      <div>기업 적립금 공유</div>
                      {user?.hostPlan === "individualPlan" && (
                        <div className="rightOne">기업회원 한정</div>
                      )}
                      {user?.hostPlan === "memberPlan" && (
                        <div className="rightOne">관리자 한정</div>
                      )}
                      {user?.hostPlan === "managerPlan" && (
                        <span>구성원 대상 무제한</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="manageRight">
                <div className="managePart">
                  <div className="managePTitle">AI 관리감독 서비스</div>
                  <div className="rowGrayBar" />
                  <div className="managePlist">
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>기기 상태 자동 점검</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>주변환경 AI 스캔</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>카메라 AI 세팅</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>실시간 응시 영상 녹화</div>
                      <span>제공</span>
                    </div>
                  </div>
                </div>
                <div className="managePart">
                  <div className="managePTitle">AI 자동검증 서비스</div>
                  <div className="rowGrayBar" />
                  <div className="managePlist">
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>업로드 영상 위변조 검증</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>부정행위 AI 검증 (영상)</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>이상행동 AI 검증 (영상)</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>감지된 전체 이벤트 데이터</div>
                      <span>제공</span>
                    </div>
                    <div className="listOne">
                      <img className="check" src={arrow2} alt="" />
                      <div>종합 결과 보고서</div>
                      <span>제공</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lastBox">
          <div className="titlePart">취소/환원 정책</div>
          <div className="listPart">
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyOne(!policyOne)}
              >
                <div>시험 취소 관련 안내</div>
                {!policyOne ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyOne && (
                <div className="listDown">
                  <span>취소 마감 시간</span>
                  <ul>
                    <li>
                      응시 시작시간 3시간 전까지 취소가 가능해요. <br />
                      예) 13시에 시험 시작 시 10시 전까지 취소 가능
                    </li>
                    <li style={{ color: "#C83B38" }}>
                      취소 마감 시간 이후에는 취소가 불가해요.{" "}
                    </li>
                    <li>
                      취소 불가에 따른 미응시 시험의 경우, 미응시 관련 적립금
                      환원 정책에 따라 처리돼요.
                      <br />
                      (자세한 내용은 취소/미응시 관련 적립금 환원 안내 참조)
                    </li>
                  </ul>
                  <span style={{ marginTop: "14px" }}>취소 수수료 정책</span>
                  <ul>
                    <li>
                      취소 시 별도의 수수료는 없이 적립금 전체가 환원돼요.
                      <br />
                      (자세한 내용은 취소/미응시 관련 적립금 환원 안내 참조)
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {!policyOne ? <div className="rowGrayBar" /> : <></>}
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyTwo(!policyTwo)}
              >
                <div>취소/미응시 관련 환원 안내</div>
                {!policyTwo ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyTwo && (
                <div className="listDown">
                  <span>취소 마감 시간 전</span>
                  <ul>
                    <li>
                      취소 마감시간 : 응시 시작시간 기준 3시간 전<br />
                      예) 시작 시간이 13시라면 취소 마감시간은 10시
                    </li>
                    <li>적립금 전체 환원(즉시 취소 가능)</li>
                  </ul>
                  <span>취소 마감 시간 이후</span>
                  <ul>
                    <li>
                      시험 참여 여부와 관계없이 결제한 금액은 환불 불가(시험
                      취소도 불가)
                    </li>
                    <li>
                      단 기업 회원의 경우, 시험 종료 후 미응시 인원에 한해 해당
                      인원분 만큼 환불 불가 적립금으로 환원
                      <br />
                      a. 개인 회원 : 적립금 환원 불가
                      <br />
                      b. 기업 회원 : 미응시 인원분 만큼 적립금 환원
                    </li>
                  </ul>
                  <span>취소 수수료 및 환불 방법</span>
                  <ul>
                    <li>
                      취소 수수료 : 없음 <br />
                    </li>
                    <li>
                      회원 유형별 적립금 환원 방법
                      <br />
                      a. 개인 회원 : 사용한 전체 적립금 환원
                      <br />
                      b. 기업 회원 : 사용한 전체 적립금 환원
                    </li>
                  </ul>
                  <span>미응시 인원에 속하는 대상</span>
                  <ul>
                    <li>시험 URL에 접속하지 않은 응시자</li>
                    <li>
                      시험 URL에 접속하여 준비 과정(로그인, 디바이스 점검,
                      환경점검, 카메라 거치 등)까지는 진행했으나, 응시
                      시작시간에 ‘시험 시작’을 진행하지 않은 응시자
                    </li>
                  </ul>
                  <span>미응시 인원에 속하지 않는 대상 (환불 불가)</span>
                  <ul>
                    <li>
                      시험 URL에 접속 후 응시 시작시간에 ‘시험 시작’을 진행한
                      모든 응시자. ‘시험 시작’ 직후에는 몇 분을 진행했는지
                      여부와 상관없이 응시 인원(과금 대상)으로 간주함
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </StServicePlan>
    </>
  );
};

const StServicePlan = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f0f1f2;
  .columnGrayBar {
    width: 0.5px;
    height: 100%;
    background-color: #d6d9dd;
  }
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #d9d9d9;
  }
  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
    margin-bottom: 36px;
    div {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 12px;
      .convert {
        position: absolute;
        top: -9px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 26px;
        width: 168px;
        height: 49px;
        font-size: 16px;
        font-family: "regular";
        z-index: 61;
        cursor: pointer;
      }
    }
    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  .inBox {
    display: flex;
    flex-direction: column;
    max-width: 865px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .imgPart {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      background-color: ${(props) =>
        props.hostPlan === "individualPlan" ? "#20315B" : "#F97E0D"};
      border-radius: 12px 12px 0px 0px;
      gap: 20px;
      .imgPLeft {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        font-family: "extraBold";
        color: white;
        line-height: 36px;
        span {
          font-size: 12px;
          font-family: "semibold";
          line-height: 18px;
        }
      }
    }
    .planPart {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .planPPrice {
        display: flex;
        margin-top: 16px;
        .priceLeft {
          color: #2276dc;
          font-size: 20px;
          font-family: "semiBold";
          line-height: 33px;
        }
        .priceRight {
          display: grid;
          gap: 3px;
          div {
            display: flex;
            font-size: 24px;
            font-family: "bold";
            line-height: 33px;
            span {
              font-size: 16px;
              font-family: "regular";
              line-height: 33px;
              margin-left: 3px;
            }
          }
          span {
            font-size: 14px;
            font-family: "regular";
            line-height: 17px;
            margin-left: auto;
          }
        }
      }
      .planPInfo {
        display: flex;
        height: 44px;
        border-radius: 8px;
        border: 0.6px solid #bdbdbd;
        padding: 16px 0;
        margin-top: 24px;
        /* box-sizing: border-box; */
        .infoUnder {
          width: 49%;
          display: grid;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 7px;
          text-align: center;
          div {
            color: #818181;
            font-size: 12px;
            font-family: "medium";
            line-height: 14px;
          }
          span {
            display: flex;
            justify-content: center;
            color: black;
            font-size: 19px;
            font-family: "bold";
            line-height: 23px;
            div {
              margin-left: 3px;
              font-size: 14px;
              font-family: "regular";
              line-height: 23px;
            }
          }
        }
      }
      .planPBottom {
        margin-top: 12px;
        font-size: 11px;
        font-family: "regular";
        line-height: 16px;
        color: #444444;
      }
    }
    .managePart {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px;
      box-sizing: border-box;
      .managePTitle {
        font-size: 13px;
        font-family: "semibold";
        line-height: 19px;
        margin-bottom: 4px;
      }
      .managePlist {
        display: grid;
        flex-direction: column;
        margin-top: 24px;
        gap: 6px;
        .listOne {
          display: flex;
          align-items: center;
          line-height: 21px;
          color: #55595f;
          font-size: 14px;
          div {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 4px;
          }
          .check {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
          span {
            font-family: "medium";
            font-size: 14px;
            color: #2276dc;
            margin-left: auto;
          }
          .rightOne {
            font-family: "regular";
            font-size: 14px;
            color: #bdbdbd;
            margin-left: auto;
          }
        }
      }
    }
  }
  .lastBox {
    margin-top: 36px;
    .listPart {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .listOne {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 14px 0px; */
        box-sizing: border-box;
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        .listClick {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
        }
        .listDown {
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          background-color: #fafafa;
          border-top: 1px solid #d9d9d9;
          span {
            font-family: "medium";
            font-size: 15px;
            margin-bottom: 8px;
          }
          ul {
            margin: 0;
            font-family: "medium";
            font-size: 14px;
            padding-left: 17px;
            li {
              font-family: "regular";
            }
          }
        }
        img {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
  @media ${device.pc} {
    display: grid;
    gap: 60px;
    padding: 80px 0px;
    margin: 0 auto;
    box-sizing: border-box;
    .onlyForTabMob {
      display: none;
    }
    .layerUp {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      min-width: 1228px;
    }
    .titleBox {
      display: flex;
      margin: 0 auto 60px;
      width: 100%;
      .titleLeft {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 19px;
        span {
          font-size: 28px;
          font-family: "bold";
          line-height: 41px;
        }
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .inBox {
      .imgPart {
        display: flex;
        justify-content: space-between;
        padding: 36px 60px;
        .imgPLeft {
          display: grid;
          gap: 18px;
          .individual {
            font-size: 32px;
            font-family: "extraBold";
            line-height: 38px;
          }
          .individualInfo {
            font-size: 18px;
            font-family: "semiBold";
            line-height: 27px;
          }
        }
      }
      .planPart {
        padding: 60px 60px 12px;
        .planPTitle {
          font-size: 18px;
          font-family: "medium";
          line-height: 27px;
        }
        .pcArrayPlan {
          display: flex;
          justify-content: space-between;
          .planPPrice {
            display: grid;
            flex-direction: column;
            margin: auto 0;
            gap: 6px;
            .priceRight {
              display: flex;
              align-items: baseline;
              div {
                font-size: 32px;
                font-family: "bold";
                line-height: 47px;
                align-items: baseline;
              }
              span {
                font-size: 20px;
                line-height: 40px;
                font-family: "regular";
              }
            }
            .priceLeft {
              font-size: 22px;
              font-family: "semibold";
              line-height: 33px;
            }
          }
          .pcArrayPlanInfo {
            display: flex;
            flex-direction: column;
            width: 50%;
            .planPInfo {
              height: 115px;
              box-sizing: border-box;
              .infoUnder {
                margin: auto 0;
                div {
                  font-size: 13px;
                  font-family: "medium";
                  line-height: 19px;
                }
                span {
                  display: flex;
                  font-size: 22px;
                  font-family: "bold";
                  line-height: 33px;
                  align-items: baseline;
                  div {
                    display: flex;
                    font-size: 15px;
                    font-family: "regular";
                    color: black;
                  }
                }
              }
            }
            .planPBottom {
              margin-left: auto;
            }
          }
        }
      }
      .manageBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        padding: 48px 60px 60px;
        .manageRight {
          display: grid;
          flex-direction: column;
          gap: 60px;
          width: 342.5px;
          .managePart {
            padding: 0;
            .managePTitle {
              font-size: 18px;
              font-family: "medium";
              line-height: 27px;
            }
            .managePlist {
              display: grid;
              gap: 14px;
              .listOne {
                .check {
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
    }
    .lastBox {
      max-width: 990px;
      width: 100%;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 40px;
      box-sizing: border-box;
      .titlePart {
        padding: 20px 24px;
        box-sizing: border-box;
        font-size: 28px;
        font-family: "medium";
        line-height: 33px;
        color: black;
      }
      .listPart {
        width: 100%;
        padding: 0 24px;
        .listOne {
          .listClick {
            padding: 16px 0;
            cursor: pointer;
          }
          .listDown {
            padding: 0 24px 24px;
            span {
              margin-top: 24px;
            }
          }
        }
        .rowGrayBar {
          height: 1px;
          background-color: #d9d9d9;
        }
      }
    }
  }
  @media ${device.tabMob} {
    padding: 24px 24px 15px;
    .inBox {
      .imgPart {
        padding: 22px 24px;
        .imgPLeft {
          display: grid;
          gap: 8px;
        }
      }
      .planPart {
        padding: 24px 24px 12px;
        .planPTitle {
          font-size: 13px;
          font-family: "semibold";
          line-height: 19px;
        }
        .planPPrice {
          justify-content: space-between;
          .priceRight {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .lastBox {
      padding: 0 0 38px;
      box-sizing: border-box;
      .titlePart {
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        color: #909090;
        margin: 0 0 5px 0px;
      }
      .listPart {
        display: grid;
        grid-template-rows: auto auto auto;
        gap: 14px;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 14px 24px;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        background-color: #ffffff;
        .listOne {
          .listClick {
            /* padding: 14px 0; */
          }
          .listDown {
            padding: 14px 12px;
            margin-top: 14px;
            span {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
`;

export default ServicePlan;
