import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ModalBackFour } from "../components/Style";
import Timer from "../components/Timer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { bannerOn, userEmail } from "../atoms";
import axios from "axios";
import { baseURL } from "../api/handler";
import { device } from "../hooks/device";

//img
import greyX from "../img/delete.png";
import arrow from "../img/arrow.svg";
import useToast from "../hooks/useToast";

const SignUpEmail = ({ setModal, setModalLevel, email, purpose }) => {
  const goBack = () => {
    setModal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.href);
    window.addEventListener("popstate", goBack);
    setModalLevel(2);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const utcInfo = new Date().getTimezoneOffset();
  const verifyRef = useRef();
  const setUserEmailInfo = useSetRecoilState(userEmail);
  const banneris = useRecoilValue(bannerOn);

  const [resendEmailTime, setResendEmailTime] = useState(0);
  const [min, setMin] = useState(5);
  const [sec, setSec] = useState(0);
  const [detailOpen, setDetailOpen] = useState(false);
  const [authNum, setAuthNum] = useState();
  const [verifyError, setVerifyError] = useState();
  const showToast = useToast();

  /** 인증번호 확인 */
  const verifyAuthNum = () => {
    axios
      .patch(`${baseURL}/api/v1/verify?offset=${utcInfo}&lang=kr`, {
        purpose: purpose,
        email: email,
        authnum: authNum,
      })
      .then(() => {
        setModal(false);
        setUserEmailInfo((prevState) => ({ ...prevState, certified: true }));
      })
      .catch(({ response }) => {
        if (response?.data?.errorCode === "VERIFY-007") {
          setVerifyError(
            "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요"
          );
        } else if (
          response?.data?.errorCode === "VERIFY-004" ||
          response?.data?.errorCode === "VERIFY-005"
        ) {
          setVerifyError("올바르지 않거나 더 이상 유효하지 않은 인증번호에요.");
        } else {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (Number(resendEmailTime) <= 10 && Number(resendEmailTime) >= 1) {
        setResendEmailTime(Number(resendEmailTime) - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [resendEmailTime]);

  const reSendEmail = () => {
    if (resendEmailTime <= 0) {
      setResendEmailTime(10);
      axios
        .post(`${baseURL}/api/v1/verify?offset=${utcInfo}&lang=kr`, {
          purpose: purpose,
          email: email,
        })
        .then(() => {
          showToast({ message: `인증번호를 이메일로 재발송 하였습니다.` });
          setMin(5);
          setSec(0);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showToast({
        message: `인증번호 재발송은 ${resendEmailTime}초 후 다시 진행할 수 있어요.`,
      });
    }
  };

  return (
    <>
      <ModalBackFour />
      <StEmail
        banneris={banneris}
        detailOpen={detailOpen}
        verifyError={verifyError}
        authNum={authNum}
      >
        <div className="wrapperEmail">
          <StModalHeader>
            <img
              className="mobileButton"
              src={arrow}
              onClick={() => {
                window.history.back();
              }}
              alt=""
            />
            <div>이메일 인증</div>
            <img
              className="pcButton"
              src={greyX}
              onClick={() => {
                window.history.back();
              }}
              alt=""
            />
          </StModalHeader>
          <div className="emailBody">
            <div className="infoBox">
              <span>{email}</span> 으로 인증번호가 발송되었어요. <br />
              인증번호를 입력 후 아래 인증하기를 누르면 인증이 완료됩니다.
            </div>
            <div className="inputBox">
              <div className="inputTitle">인증번호</div>
              <input
                placeholder="인증번호 입력"
                type="number"
                ref={verifyRef}
                onChange={(e) => {
                  setAuthNum(e.target.value);
                  setVerifyError();
                }}
              />
              <div className="inputError">{verifyError}</div>
              <Timer min={min} setMin={setMin} sec={sec} setSec={setSec} />
              <div className="leftTime"></div>
            </div>
            <div className="detailBox">
              <div
                className="detailTitle"
                onClick={() => {
                  setDetailOpen(!detailOpen);
                }}
              >
                인증 시 유의사항 <div className="triangle" />
              </div>
              {detailOpen && (
                <div className="detailItem">
                  <div>
                    * 개인정보 보호를 위해 발송 시점으로부터 5분 동안만
                    유효해요.
                  </div>
                  <div>
                    * 인증번호 재발송 시 기존에 발송된 인증번호는 만료되요.
                  </div>
                  <div>
                    * 메일이 계속 도착하지 않을 경우, 스팸함을 확인해주세요.
                  </div>
                </div>
              )}
            </div>
            <div className="resendBox">
              메일이 도착하지 않았나요?{" "}
              <span onClick={reSendEmail}>인증번호 재발송</span>
            </div>
            <div className="buttonBox">
              <div
                className="cancelButton"
                onClick={() => window.history.back()}
              >
                취소
              </div>
              <div
                className="verifyButton"
                onClick={() => {
                  if (authNum) {
                    verifyAuthNum();
                  }
                }}
              >
                인증하기
              </div>
            </div>
          </div>
        </div>
      </StEmail>
    </>
  );
};

const StEmail = styled.div`
  position: fixed;
  background-color: white;
  z-index: 58;
  .emailBody {
    display: flex;
    flex-direction: column;
    .infoBox {
      padding: 20px 0;
      span {
        color: #2276dc;
      }
      .inputTitle {
        font-size: 13px;
        font-family: "medium";
        line-height: 19px;
      }
    }
    .inputBox {
      padding: 20px 0;
      .inputTitle {
        margin-bottom: 5px;
      }
      .inputError {
        color: #c83b38;
        font-size: 12px;
        font-family: "regular";
        line-height: 18px;
        margin-bottom: 12px;
      }
      .leftTime {
        font-size: 12px;
        font-family: "regular";
        line-height: 14px;
        color: #2276dc;
      }
      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid
          ${(props) =>
            props.verifyError ? "#C83B38" : "rgba(217, 217, 217, 0.5)"};
        padding: 6px 0;
        margin-top: 5px;
        font-family: "light";
        &::placeholder {
          font-family: "light";
          font-size: 16px;
          line-height: 24px;
          color: #bdbdbd;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .detailBox {
      display: flex;
      flex-direction: column;
      margin: 20px 0 36px;
      .detailTitle {
        display: flex;
        align-items: center;
        font-size: 12.5px;
        font-family: "regular";
        line-height: 19px;
        cursor: pointer;
        .triangle {
          width: 0;
          height: 0;
          border-bottom: ${(props) =>
            props.detailOpen ? "7.65px solid #BDBDBD" : ""};
          border-top: ${(props) =>
            props.detailOpen ? "" : "7.65px solid #BDBDBD"};
          border-left: 4.5px solid transparent;
          border-right: 4.5px solid transparent;
          margin-left: 8px;
        }
      }
      .detailItem {
        display: grid;
        flex-direction: column;
        gap: 6px;
        font-family: "regular";
        line-height: 16px;
        margin-top: 8px;
        color: #444444;
      }
    }
    .resendBox {
      text-align: center;
      font-family: "regular";
      line-height: 16px;
      padding: 14px 24px 25px;
      span {
        color: #2276dc;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 10px;
      margin: 0 auto;
      padding: 15px 0;
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 42px;
        border: 1px solid #000000;
        border-radius: 42px;
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .verifyButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 42px;
        border: 1px solid #d9d9d9;
        border-radius: 42px;
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        background-color: ${(props) => (props.authNum ? "#ED893E" : "#D9D9D9")};
        box-sizing: border-box;
        color: white;
        cursor: ${(props) => (props.authNum ? "pointer" : "")};
      }
    }
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .emailBody {
      .infoBox {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
      }
      .inputBox {
        .inputTitle {
          font-size: 15px;
          font-family: "medium";
          line-height: 22px;
        }
      }
      .detailBox {
        .detailItem {
          font-size: 12px;
        }
      }
      .resendBox {
        font-size: 12px;
      }
      .buttonBox {
        gap: 21px;
        .cancelButton {
          width: 204px;
          height: 52px;
        }
        .verifyButton {
          width: 204px;
          height: 52px;
        }
      }
    }
  }
  @media ${device.tabMob} {
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    box-sizing: border-box;
    .wrapperEmail {
      height: -webkit-fill-available;
      overflow-x: hidden;
    }
    .emailBody {
      padding: 0 24px;
      .infoBox {
        font-size: 15px;
        font-family: "regular";
        line-height: 22px;
      }
      .inputBox {
        .inputTitle {
          font-size: 13px;
          font-family: "medium";
          line-height: 22px;
        }
      }
      .detailBox {
        .detailItem {
          font-size: 11px;
        }
      }
      .resendBox {
        font-size: 11px;
      }
    }
  }
`;

const StModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    cursor: pointer;
  }
  @media ${device.pc} {
    font-size: 28px;
    font-family: "bold";
    line-height: 41px;
    .mobileButton {
      display: none;
    }
    .pcButton {
      width: 24px;
      height: 24px;
      margin-left: auto;
    }
  }
  @media ${device.tabMob} {
    padding: 16px 24px;
    box-sizing: border-box;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
    .mobileButton {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }
    .pcButton {
      display: none;
    }
  }
  .cancelButton {
    margin-left: auto;
  }
`;
export default SignUpEmail;
