import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBack, ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useRecoilValue } from "recoil";
import { userInfo } from "../atoms";
import { device } from "../hooks/device";

const ConnectionConfirm = ({
  connectData,
  setByeModalOn,
  setByeConnectionConfirmModal,
  refundableCredit,
}) => {
  const goBack = () => {
    setByeConnectionConfirmModal(false);
  };
  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const user = useRecoilValue(userInfo);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StConnectionConfirm>
        {/* <div className='title'>구성원 계정 연결 확인</div> */}
        <ModalHeaderD title="구성원 계정 연결 확인" />
        {user?.hostPlan === "managerPlan" ? (
          <div>
            <div className="info">
              {connectData?.[0]?.hostGroupName} 관리자 계정에 구성원 계정이
              연결되어 있어요. 회원 탈퇴 시 해당 구성원들은 자동으로 개인
              회원으로 전환되며, 예정되어 있던 시험들은 전부 취소돼요.
            </div>
            <div className="middleTitle">※ 연결된 구성원 계정</div>
            <div className="list">
              {connectData?.map((value, index) => (
                <div className="accountLine" key={index}>
                  <div className="lineFront" />
                  <span>
                    {value.hostName} ({value?.hostEmail})
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className="info">
              {connectData?.[0]?.hostGroupName} 관리자 계정에 구성원으로
              연결되어 있어요. 회원 탈퇴 시 자동으로 기업 구성원 자격이
              해지되며, 예정되어 있던 시험들은 전부 취소돼요.
            </div>
            <div className="middleTitle">※ 연결된 관리자 계정</div>
            <div className="list">
              {connectData?.map((value, index) => (
                <div className="accountLine" key={index}>
                  <div className="lineFront" />
                  <span>
                    {value.hostName} ({value?.hostEmail})
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="buttonBox">
          <div className="cancelButton" onClick={() => window.history.back()}>
            취소
          </div>
          <div className="columnBar" />
          <div
            className="confirmButton"
            onClick={() => {
              if (user?.hostPlan === "managerPlan") {
                refundableCredit();
              } else {
                setByeModalOn(true);
              }
              setByeConnectionConfirmModal(false);
            }}
          >
            계속하기
          </div>
        </div>
      </StConnectionConfirm>
    </>
  );
};

const StConnectionConfirm = styled.div`
  position: fixed;
  z-index: 53;
  left: 50%;
  bottom: 16px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4.5px 0;
    box-sizing: border-box;
    margin-top: 24px;
    .columnBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
    }
    .cancelButton {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    bottom: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, 50%);
    .info {
      margin: 48px 0;
      font-family: "medium";
      font-size: 18px;
      line-height: 27px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      gap: 21px;
      .columnBar {
        display: none;
      }
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        box-sizing: border-box;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #20315b;
        border-radius: 43px;
        color: white;
        width: 204px;
        height: 52px;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    border-radius: 24px;
    padding: 24px;
    transform: translate(-50%, 0);
    .info {
      margin-bottom: 20px;
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 41px;
      .cancelButton {
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
      }
      .confirmButton {
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
      }
    }
  }
  .title {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .middleTitle {
    font-size: 16px;
    font-family: "semibold";
    line-height: 24px;
    margin-bottom: 4px;
  }
  .list {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
    .accountLine {
      display: flex;
      align-items: center;
      .lineFront {
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background-color: black;
        margin: 0 9px 0 4px;
      }
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export default ConnectionConfirm;
