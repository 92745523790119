import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";

//style
import styled from "styled-components";
import style from "../modal/modal.module.css";

//components

// img
import greyX from "../img/delete.png";
import blueCheck from "../img/blueCheck.svg";
import spinner from "../img/spinner.svg";

//hooks
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { bannerOn } from "../atoms";
import { ModalBackTwo } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import dayjs from "dayjs";
import { useMutation } from "react-query";
import { findingId } from "../api/axiosTwo";
import useModal from "../hooks/useModal";

const FindId = ({ setFindIdModal, setLoginModalOn }) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(null);
  const [completionStatus, setCompletionStatus] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(true);
  const nameRef = useRef();
  const [yearHide, setYearHide] = useState(true);
  const [monthHide, setMonthHide] = useState(true);
  const [dateHide, setDateHide] = useState(true);
  const [dates, setDates] = useState(Array.apply(null, Array(31)));
  const [responseEmail, setResponseEmail] = useState([]);
  const [birthDate, setBirthDate] = useState(
    dayjs("2000-01-01").format("YYYY-MM-DD")
  );
  const banneris = useRecoilValue(bannerOn);

  useModal(setFindIdModal);

  const setNameAction = (value) => {
    setName(value);
  };

  let years = Array.apply(null, Array(100));
  let months = Array.apply(null, Array(12));

  const { mutate, isLoading } = useMutation(findingId, {
    onSuccess: (data) => {
      // console.log(data)
      setResponseEmail(data.getFindHostEmail);
    },
    onError: ({ response }) => {
      const errorCode = response.data.errorCode;
      if (errorCode) {
      }
    },
  });

  const findClicked = () => {
    mutate({
      hostName: name,
      hostBirthdayAt: birthDate,
    });
  };
  useScrollLockThree();

  useEffect(() => {
    if (Number(dayjs(birthDate).format("M")) === 2) {
      setDates(Array.apply(null, Array(29)));
    } else if (Number(dayjs(birthDate).format("M")) < 8) {
      if (Number(dayjs(birthDate).format("M")) % 2 === 1) {
        setDates(Array.apply(null, Array(31)));
      } else {
        setDates(Array.apply(null, Array(30)));
      }
    } else {
      if (Number(dayjs(birthDate).format("M")) % 2 === 1) {
        setDates(Array.apply(null, Array(30)));
      } else {
        setDates(Array.apply(null, Array(31)));
      }
    }
  }, [birthDate]);

  return (
    <>
      <ModalBackTwo />
      <Container name={name} banneris={banneris} responseEmail={responseEmail}>
        <div className="wrapper">
          <img
            src={greyX}
            className="cancelButton"
            onClick={() => {
              setFindIdModal(false);
            }}
          />
          {currentStatus === true ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="titleBox">
                <div className="titleUp">아이디 찾기</div>
                <div className="titleDown">
                  로그인 ID가 기억나지 않으신가요?
                  <br />
                  검색을 통해 쉽게 찾을 수 있어요!
                </div>
              </div>
              <section className="inputSection">
                <Input
                  subTitle={"주최자 이름"}
                  placeholder={"이름 입력"}
                  setFc={setNameAction}
                  inputValue={name}
                  elementRef={nameRef}
                  err={nameErr}
                  max={25}
                />
                {nameErr && <div className={style.errorMessage}>{nameErr}</div>}
              </section>
              <div className="birthBox">
                <div className="title">생년월일</div>
                <div className="birthInput">
                  <div className="yearBox">
                    <div
                      className="show"
                      onClick={() => {
                        setYearHide(!yearHide);
                      }}
                    >
                      {dayjs(birthDate).format("YYYY")}년
                    </div>
                    {yearHide || (
                      <>
                        <div
                          className="back"
                          onClick={() => setYearHide(true)}
                        />
                        <div className="dropDown">
                          {years.map((value, index) => {
                            return (
                              <div
                                className="yearLine"
                                onClick={() => {
                                  setBirthDate(
                                    dayjs(birthDate)
                                      .set("y", 2023 - index)
                                      .format("YYYY-MM-DD")
                                  );
                                  setYearHide(true);
                                }}
                              >
                                <div
                                  className="realYear"
                                  style={
                                    2023 - index ===
                                    Number(dayjs(birthDate).format("YYYY"))
                                      ? { color: "#0381FE" }
                                      : {}
                                  }
                                  key={index}
                                >
                                  {2023 - index}년
                                </div>
                                {2023 - index ===
                                  Number(dayjs(birthDate).format("YYYY")) && (
                                  <img src={blueCheck} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="monthBox">
                    <div
                      className="show"
                      onClick={() => {
                        setMonthHide(!monthHide);
                      }}
                    >
                      {dayjs(birthDate).format("M")}월
                    </div>
                    {monthHide || (
                      <>
                        <div
                          className="back"
                          onClick={() => setMonthHide(true)}
                        />
                        <div className="dropDown">
                          {months.map((value, index) => {
                            return (
                              <div
                                className="monthLine"
                                onClick={() => {
                                  setBirthDate(
                                    dayjs(birthDate)
                                      .set("M", index)
                                      .format("YYYY-MM-DD")
                                  );
                                  setMonthHide(true);
                                }}
                              >
                                <div
                                  className="realYear"
                                  style={
                                    1 + index ===
                                    Number(dayjs(birthDate).format("M"))
                                      ? { color: "#0381FE" }
                                      : {}
                                  }
                                  key={index}
                                >
                                  {1 + index}월
                                </div>
                                {1 + index ===
                                  Number(dayjs(birthDate).format("M")) && (
                                  <img src={blueCheck} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="dateBox">
                    <div
                      className="show"
                      onClick={() => {
                        setDateHide(!dateHide);
                      }}
                    >
                      {dayjs(birthDate).format("D")}일
                    </div>
                    {dateHide || (
                      <>
                        <div
                          className="back"
                          onClick={() => setDateHide(true)}
                        />
                        <div className="dropDown">
                          {dates.map((value, index) => {
                            return (
                              <div
                                className="dayLine"
                                onClick={() => {
                                  setBirthDate(
                                    dayjs(birthDate)
                                      .set("D", 1 + index)
                                      .format("YYYY-MM-DD")
                                  );
                                  setDateHide(true);
                                }}
                              >
                                <div
                                  className="realYear"
                                  style={
                                    1 + index ===
                                    Number(dayjs(birthDate).format("D"))
                                      ? { color: "#0381FE" }
                                      : {}
                                  }
                                  key={index}
                                >
                                  {1 + index}일
                                </div>
                                {1 + index ===
                                  Number(dayjs(birthDate).format("D")) && (
                                  <img src={blueCheck} />
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="explanation">
                  * 프록토매틱 서비스에서는 이름과 생년월일로 아이디를 찾아요.
                </div>
              </div>

              <div className="btnBox">
                <button
                  type="button"
                  className="searchButton"
                  onClick={() => {
                    setCompletionStatus(true);
                    setCurrentStatus(false);
                    findClicked();
                  }}
                  disabled={!name}
                >
                  검색하기
                </button>
              </div>
            </form>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {isLoading ? (
                <div className="pageBody">
                  <div className="titleBox">아이디 검색 결과</div>
                  <div className="stateBox">
                    입력한 정보로 아이디를 찾는 중이에요...
                  </div>
                  <div className="middle">
                    <div className="listBox">
                      <img className="spinner" src={spinner} />
                      <div className="searching">아이디 검색 중...</div>
                    </div>
                    <div className="noticeBox">
                      * 보안을 위해 아이디의 일부만 표기되어요.
                    </div>
                  </div>
                  <div className="buttonBox">로그인 바로가기</div>
                </div>
              ) : (
                <div className="pageBody">
                  <div className="titleBox">아이디 검색 결과</div>
                  {responseEmail?.length > 0 ? (
                    <div className="stateBox">
                      아래와 같이 {responseEmail?.length}개의 아이디를 찾았어요!
                    </div>
                  ) : (
                    <div className="stateBox">아이디를 찾지 못했어요 :(</div>
                  )}
                  <div className="middle">
                    <div className="listBox">
                      {responseEmail?.length > 0 ? (
                        responseEmail.map((value, index) => (
                          <div>
                            <div className="foundEmail">{value.hostEmail}</div>
                            <div className="fountBirth">
                              ({value.hostCreatedAt} 가입)
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="notFound">검색된 아이디가 없음</div>
                      )}
                    </div>
                    <div className="noticeBox">
                      * 보안을 위해 아이디의 일부만 표기되어요.
                    </div>
                  </div>
                  {responseEmail?.length > 0 ? (
                    <div
                      className="buttonBox"
                      onClick={() => {
                        setLoginModalOn(true);
                        setFindIdModal(false);
                      }}
                    >
                      로그인 바로가기
                    </div>
                  ) : (
                    <div className="buttons">
                      <div
                        className="cancelButton"
                        onClick={() => {
                          setFindIdModal(false);
                        }}
                      >
                        취소
                      </div>
                      <div
                        className="confirmButton"
                        onClick={() => setCurrentStatus(true)}
                      >
                        검색 다시하기
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  background-color: white;
  box-sizing: border-box;
  .titleBox {
    display: flex;
    flex-direction: column;
    text-align: center;
    .titleUp {
      font-family: "bold";
    }
    .titleDown {
      font-family: "regular";
      color: #6e6e6e;
    }
  }
  .pageBody {
    .titleBox {
      padding: 48px 0 16px;
      margin: 0;
    }
  }
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  z-index: 51;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  form {
    .goToLogin {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 42px;
      background-color: #20315b;
      color: white;
      border-radius: 42px;
      cursor: pointer;
    }
    .buttonBox {
      display: grid;
      .cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 42px;
        border: 1px solid black;
        box-sizing: border-box;
        border-radius: 42px;
        cursor: pointer;
      }
      .again {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 151px;
        height: 42px;
        border-radius: 42px;
        color: white;
        background-color: #20315b;
        cursor: pointer;
      }
    }
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .explain {
      margin-top: 12px;
      color: #444444;
      font-size: 11px;
      line-height: 16px;
    }
    .emailBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 345px;
      border: 1px solid #dfdfdf;
      margin-top: 44px;
      .nothing {
        color: #bdbdbd;
        margin: auto 0;
      }
      .email {
        color: #2276dc;
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
      }
      .birth {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #818181;
      }
    }
  }
  .inputSection {
    margin-bottom: 32px;
  }
  .birthBox {
    .title {
      font-size: 13px;
      font-family: "medium";
    }
    .explanation {
      font-size: 11px;
      margin: 17px 0;
    }
    .birthInput {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      gap: 9px;
      font-family: "medium";
      margin-top: 10px;
      .yearBox {
        position: relative;
        .show {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98px;
          height: 42px;
          padding: 11.5px 6px;
          box-sizing: border-box;
          border: 0.6px solid #818181;
          border-radius: 6px;
        }
        .dropDown {
          position: absolute;
          top: 0;
          z-index: 20;
          display: flex;
          width: 168px;
          height: 268px;
          overflow-x: hidden;
          flex-direction: column;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          .yearLine {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 24px;
            cursor: pointer;
            img {
              width: 18px;
              height: 12px;
            }
            .realYear {
              display: flex;
            }
          }
          ::-webkit-scrollbar {
            width: 2px;
            height: 10px;
            margin-top: 100px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
            border-radius: 2px;
            width: 2px;
            height: 100px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
        }
        .back {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.2;
          z-index: 10;
        }
      }
      .monthBox {
        position: relative;
        .show {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98px;
          height: 42px;
          padding: 11.5px 6px;
          box-sizing: border-box;
          border: 0.6px solid #818181;
          border-radius: 6px;
        }
        .dropDown {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 0;
          z-index: 20;
          display: flex;
          width: 168px;
          height: 268px;
          overflow-x: hidden;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          .monthLine {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 24px;
            cursor: pointer;
            img {
              width: 18px;
              height: 12px;
            }
            .realYear {
              display: flex;
            }
          }
          ::-webkit-scrollbar {
            width: 2px;
            height: 10px;
            margin-top: 100px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
            border-radius: 2px;
            width: 2px;
            height: 100px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
        }
        .back {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.2;
          z-index: 10;
        }
      }
      .dateBox {
        position: relative;
        .show {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98px;
          height: 42px;
          padding: 11.5px 6px;
          box-sizing: border-box;
          border: 0.6px solid #818181;
          border-radius: 6px;
        }
        .dropDown {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 0;
          right: 0;
          z-index: 20;
          display: flex;
          width: 168px;
          height: 268px;
          overflow-x: hidden;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          ::-webkit-scrollbar {
            width: 2px;
            height: 10px;
            margin-top: 100px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
            border-radius: 2px;
            width: 2px;
            height: 100px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
          .dayLine {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 24px;
            cursor: pointer;
            img {
              width: 18px;
              height: 12px;
            }
            .realYear {
              display: flex;
            }
          }
        }
        .back {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.2;
          z-index: 10;
        }
      }
    }
  }
  .emailSection {
    margin-bottom: 67px;
  }
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0 15px;
  }
  .searchButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: white;
    font-size: 14px;
    font-family: "medium";
    /* margin-top : auto; */
    width: 204px;
    height: 42px;
    border-radius: 42px;
    background-color: ${(props) => (props.name ? "#20315B" : "#DFDFDF")};
    cursor: pointer;
  }
  .pageBody {
    .titleBox {
      font-size: 22px;
      font-family: "bold";
      line-height: 33px;
      text-align: center;
    }
    .stateBox {
      font-family: "regular";
      font-size: 15px;
      line-height: 22px;
      color: #6e6e6e;
      text-align: center;
      margin-bottom: 67px;
    }
    .listBox {
      display: grid;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 14px;
      width: 100%;
      min-height: 208px;
      border: 1px solid #dfdfdf;
      .foundEmail {
        color: #2276dc;
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
        text-align: center;
      }
      .fountBirth {
        color: #818181;
        font-size: 14px;
        font-family: "regular";
        line-height: 24px;
        text-align: center;
      }
      .notFound {
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        color: #bdbdbd;
      }
      .spinner {
        margin: 0 auto;
        animation: rotate_image 2s linear infinite;
        transform-origin: 50% 50%;
      }
      @keyframes rotate_image {
        100% {
          transform: rotate(360deg);
        }
      }
      .searching {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
      }
    }
    .noticeBox {
      font-family: "regular";
      font-size: 11px;
      line-height: 16px;
      margin-top: 12px;
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 44px auto 15px;
      width: 204px;
      height: 42px;
      font-family: "medium";
      font-size: 14px;
      line-height: 25px;
      color: white;
      background-color: ${(props) =>
        props?.responseEmail?.length > 0 ? "#20315B" : "#D9D9D9"};
      border-radius: 42px;
      cursor: ${(props) => (props?.responseEmail?.length > 0 ? "pointer" : "")};
    }
  }
  .buttons {
    display: grid;
    grid-template-columns: auto auto;
    gap: 21px;
    font-size: 17px;
    font-family: "medium";
    line-height: 25px;
    justify-content: center;
    margin-top: 67px;
    .cancelButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 169.5px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      margin: 0;
      cursor: pointer;
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 169.5px;
      height: 52px;
      border-radius: 43px;
      background-color: #20315b;
      color: white;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    top: 50%;
    padding: 60px;
    width: 580px;
    transform: translate(-50%, -50%);
    z-index: 54;
    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    form {
      width: 360px;
    }
    .titleBox {
      display: grid;
      gap: 16px;
      margin: 48px 0 67px;
      .titleUp {
        font-size: 26px;
        line-height: 38px;
      }
      .titleDown {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .birthBox {
      .title {
        font-size: 15px;
        font-family: "medium";
        line-height: 22px;
      }
      .birthInput {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 9px;
        margin-top: 15px;
        .yearBox,
        .monthBox,
        .dateBox {
          .show {
            width: 100%;
          }
        }
      }
      .explanation {
        margin: 16px 0 0 0;
      }
    }
    .cancelButton {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin: 8.5px 0 8.5px auto;
    }
    .btnBox {
      margin-top: 67px;
    }
    .pageBody {
      width: 360px;
      .listBox {
        padding: 48px 24px;
        box-sizing: border-box;
      }
    }
  }
  @media ${device.tabMob} {
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: scroll;
    padding: 0px 24px;
    z-index: 49;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    .wrapper {
      /* min-height: 607px; */
      height: -webkit-fill-available;
    }
    .cancelButton {
      display: none;
    }
    .titleBox {
      display: grid;
      gap: 15px;
      padding: 44px 0;
      .titleUp {
        font-size: 22px;
        line-height: 33px;
      }
      .titleDown {
        font-size: 15px;
        line-height: 22px;
      }
    }
    .pageBody {
      display: flex;
      flex-direction: column;
      width: 100%;
      .stateBox {
        margin-bottom: 44px;
      }
      .middle {
        width: 100%;
        max-width: 704px;
        margin: 0 auto;
        .listBox {
          width: 100%;
          min-height: 320px;
          padding: 24px;
          box-sizing: border-box;
        }
      }
    }
    .buttons {
      display: grid;
      grid-template-columns: auto auto;
      gap: 21px;
      font-size: 14px;
      font-family: "medium";
      line-height: 25px;
      margin-top: 29px;
      padding: 15px 0;
      .confirmButton,
      .cancelButton {
        width: 151px;
        height: 42px;
        cursor: pointer;
      }
    }
  }
`;

export default FindId;
