import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";

const QnADelete = ({ close, deleting, title, textOne, textTwo }) => {
  const goBack = () => {
    close(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <ModalBackD />
      <StQnaDetail>
        <ModalHeaderD title={title} />
        <div className="bodyBox">
          {textOne}
          <br />
          {textTwo}
        </div>
        <div className="buttonBox">
          <div className="cancelButton" onClick={() => window.history.back()}>
            취소
          </div>
          <div className="columnBar" />
          <div className="deleteButton" onClick={() => deleting()}>
            삭제하기
          </div>
        </div>
      </StQnaDetail>
    </>
  );
};

const StQnaDetail = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @media ${device.pc} {
    display: grid;
    flex-direction: column;
    left: 50%;
    top: 50%;
    padding: 60px;
    gap: 48px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .bodyBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      margin: 0 auto;
      gap: 21px;
      .columnBar {
        display: none;
      }
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        box-sizing: border-box;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
        cursor: pointer;
      }
      .deleteButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #20315b;
        border-radius: 43px;
        color: white;
        width: 204px;
        height: 52px;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyBox {
      margin-bottom: 24px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: center;
      align-items: center;
      gap: 41px;
      .cancelButton {
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
        cursor: pointer;
      }
      .deleteButton {
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
        cursor: pointer;
      }
      .columnBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
    }
  }
`;

export default QnADelete;
