import React from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";

const FootBar = () => {
  const language = useRecoilValue(languageIs);

  return (
    <>
      <StFootBar language={language}>
        <div className="inBox">
          <div
            className="button"
            onClick={() => {
              window.open(
                `https://edint.notion.site/ba7e64b525b44c26939a5e15efd00b9f?pvs=4`
              );
            }}
          >
            <TextTwoPart textKor="이용약관" textEng="Terms of Service" />
          </div>
          <div className="line" />
          <div
            className="button"
            onClick={() => {
              window.open(
                `https://edint.notion.site/45222e54e558415487d672680ef1a310?pvs=4`
              );
            }}
          >
            <TextTwoPart
              textKor="위치정보서비스 이용약관"
              textEng="Location-based Service Terms and Conditions"
              textalign="center"
            />
          </div>
          <div className="line" />
          <div
            className="button"
            onClick={() => {
              window.open(
                `https://edint.notion.site/672a5db0a38c4299bc4ef72494b06b84?pvs=4`
              );
            }}
          >
            <TextTwoPart textEng="Privacy Policy" textKor="개인정보처리방침" />
          </div>
        </div>
      </StFootBar>
    </>
  );
};

const StFootBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  @media ${device.pc} {
    min-width: 1440px;
    height: 46px;
    font-family: "regular";
    font-size: 16px;
    line-height: 24px;
    .inBox {
      display: ${(props) => (props.language === "/ko" ? "flex" : "grid")};
      grid-template-columns: 1fr 0.5px 1fr 0.5px 1fr;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 100%;
      max-width: ${(props) => (props.langauge === "/ko" ? "730px" : "")};
    }
    .line {
      width: 0.5px;
      height: 12px;
      background-color: #bdbdbd;
    }
    .button {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      width: ${(props) => (props.language === "/ko" ? "280px" : "")};
      text-decoration: none;
      padding: 10px 0;
      color: black;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    min-height: 35px;
    .inBox {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 730px;
      margin: 0 auto;
    }
    .line {
      width: 0.5px;
      height: 12px;
      background-color: #bdbdbd;
    }
    .button {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      width: 33.33%;
      padding: 10px 0px;
      font-size: 10px;
      line-height: 15px;
      font-family: "Regular";
      text-decoration: none;
      color: black;
      cursor: pointer;
    }
  }
`;

export default FootBar;
