import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../atoms";
import TextTwoPart from "../atomic/atom/TextTwoPart";

const PointInfo = ({ setPointInfoModal }) => {
  useScrollLockThree();
  const user = useRecoilValue(userInfo);
  const language = useRecoilValue(languageIs);

  const goBack = () => {
    setPointInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <ModalBackD />
      <StPaymentInfo>
        <ModalHeaderD
          title={language === "/ko" ? "포인트 정보" : "Points Policy"}
        />
        {user?.hostPlan === "individualPlan" ? (
          <div className="bodyPart">
            <TextTwoPart
              textKor="개인용 플랜의 포인트 유효기간은 적립일로부터 최대 1년까지이며, 1 p
            이상부터 결제금액 제한 없이 자유롭게 사용하실 수 있어요. 그리고
            예정된 시험을 취소할 경우 사용했던 포인트는 모두 자동 환불돼요."
              textEng="In the individual plan, points are valid for 1 year. 
              You can use them starting from 1 point, and if you cancel a scheduled event, 
              the points used will be automatically refunded."
            />
            <br />
            <TextTwoPart
              textKor="먼저 적립된 포인트부터 순서대로 사용되며, 사용하지 않으실 경우
            유효기간이 지나면 자동 소멸돼요."
              textEng="Please keep in mind that points are used in the order of earnings, 
              and if they are not used, they will expire after the validity period."
            />
          </div>
        ) : (
          <div className="bodyPart">
            <TextTwoPart
              textKor="기업용 플랜의 포인트 유효기간은 적립일로부터 최대 1년까지이며, 1 p
              이상부터 결제금액의 최대 50%까지 자유롭게 사용하실 수 있어요. 그리고
              예정된 시험을 취소할 경우 사용했던 포인트는 모두 포인트로 자동
              환불돼요."
              textEng="In the corporate plan, points are valid for 1 year from the date of acquisition. 
              You can use them starting from 1 point, up to 50% of the payment amount.
               If you cancel a scheduled event, the points used will be automatically refunded as points."
            />
            <br />
            <TextTwoPart
              textKor="먼저 적립된 포인트부터 순서대로 사용되며, 사용하지 않으실 경우
              유효기간이 지나면 자동 소멸돼요."
              textEng="Please keep in mind that points are used in the order of acquisition, 
              and if they are not used, they will expire after the validity period."
            />
          </div>
        )}
        <div className="warningPart">
          <span>
            <TextTwoPart textKor="※ 주의 사항" textEng="※ Notice" />
          </span>
          <ul>
            <li>
              <TextTwoPart
                textKor="소멸 예정일의 시간은 당일 자정(00:00)을 기준으로 해요."
                textEng="The expiration time is based on midnight(00:00) of the same day."
              />
            </li>
            <li>
              <TextTwoPart
                textKor="시험 취소 등의 사유로 유효기간이 지난 후 환불받을 포인트는 다시
              사용이 불가하여 환불되지 않고 자동 소멸돼요."
                textEng="Expired points due to test cancellations can't be 
                reused and will automatically expire without a refund."
              />
            </li>
            <li>
              <TextTwoPart
                textKor="포인트는 회원탈퇴 시 모두 소멸돼요."
                textEng="All points will expire upon membership withdrawal."
              />
            </li>
          </ul>
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          <TextTwoPart textKor="확인" textEng="Confirm" />
        </div>
      </StPaymentInfo>
    </>
  );
};

const StPaymentInfo = styled.div`
  position: fixed;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  z-index: 53;
  @media ${device.pc} {
    width: 580px;
    padding: 60px;
    top: 50%;
    transform: translate(-50%, -50%);
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 48px auto 0;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
    }
    .bodyPart {
      margin: 48px 0;
      font-family: "medium";
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    bottom: 16px;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyPart {
      margin-bottom: 20px;
    }
    .confirmButton {
      color: #0072de;
    }
  }
  .bodyPart {
    line-height: 24px;
  }
  .warningPart {
    line-height: 24px;
    span {
      font-family: "semibold";
    }
    ul {
      display: grid;
      flex-direction: column;
      gap: 4px;
      padding-left: 20px;
      margin-top: 4px;
      color: #444444;
    }
  }
  .confirmButton {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }
`;

export default PointInfo;
