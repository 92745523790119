import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import useModal from "../hooks/useModal";
import { useSetRecoilState } from "recoil";
import { userInfo } from "../atoms";
import { MobileModalBack, ModalHeaderD } from "../components/Style";
import patchApi from "../api/patchApi";
import getApi from "../api/getApi";

const ConvertIndividual = ({ setConvertModal, setLeftTestModal, setLeftToPayModal, setConverError }) => {
  const setUserData = useSetRecoilState(userInfo);
  useModal(setConvertModal);
  useScrollLockThree();

  const converting = () => {
    patchApi
      .memberWithdrawal()
      .then(() => {
        getApi
          .getUserData()
          .then(({ data }) => {
            setUserData(data?.getHost);
            window.history.back();
          })
          .catch(({ data }) => {
            console.log(data);
          });
        window.history.back();
      })
      .catch(({ data }) => {
        console.log(data);
      });
  };

  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-Flow-1-a649ddc3a7bc4646a65ef9537c7f7624?pvs=4
   * 주석작성자 : Noah
   * 주석작성일자 : 2024.04.18
   * 티켓내용 : 마이 페이지(기업/구성원) → 서비스 플랜 → 개인 회원으로 전환 시나리오 Flow 순서 변경 건
   * 주석설명 : 유효성 검사 API호출 ServicePlan.jsx에서 이동
   */
  const beforeConvert = () => {
    getApi
      .getProgressingTest()
      .then(() => {
        converting()
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "ENTERPRISE-010" ||
          response.data.errorCode === "TIMEZONE-007"
        ) {
          setLeftTestModal(true);
          // window.history.back();
        } else {
          // showToast({
          //   message:
          //     "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          // });
          setConverError(true);
        }
      });
      window.history.back();
  };

  return (
    <>
      <MobileModalBack />
      <StModal>
        <ModalHeaderD title="개인 회원으로 전환" />
        <div className="titleInfo">
          기업 관리자 계정과 연결이 해제되며, 기업 회원에서 개인 회원으로 자동
          전환돼요. 예정되어 있던 시험들은 전부 취소되며 결제한 방법과 동일하게
          자동으로 환원처리 돼요.
        </div>
        <div className="middlePart">※ 전환 시 주의사항 </div>
        <ul>
          <li>
            기업 적립금은 더 이상 사용 불가
            <br />
            (단, ‘내 적립금’은 사용 활성화 됨)
          </li>
          <li>
            이미 완료한 시험들은 개인 회원으로 전환 후에도 ‘내 시험 관리’ 안에
            그대로 유지됨
          </li>
          <li>
            한번 전환 처리되면 취소 할 수 없음
            <br />
            (단, 기업 관리자가 다시 초대 가능)
          </li>
        </ul>
        <div className="confirmPart">
          <span
            className="cancelButton"
            onClick={() => {
              window.history.back();
            }}
          >
            취소
          </span>
          <div className="columnBar" />
          {/* <span className="convertButton" onClick={converting}> */}
          <span className="convertButton" onClick={beforeConvert}>
            전환하기
          </span>
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  z-index: 70;
  background-color: white;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
  }
  .confirmPart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    color: #0072de;
    padding: 4.5px 0;
    span {
      font-size: 18px;
      font-family: "medium";
    }
    .columnBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
      margin: 0 42.5px 0 57px;
    }
  }
  ul {
    padding-left: 20px;
    margin-top: 4px;
    line-height: 24px;
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .middlePart {
      margin-top: 48px;
    }
    .confirmPart {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      margin-top: 32px;
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 43px;
        color: black;
        cursor: pointer;
      }
      .columnBar {
        display: none;
      }
      .convertButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        background: #20315b;
        border-radius: 43px;
        color: white;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    padding: 24px 24px 24px;
    box-sizing: border-box;
    border-radius: 24px;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 53;
    width: 344px;
    .titleInfo {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .middlePart {
      margin-top: 20px;
    }
    .confirmPart {
      margin: 0 auto;
    }
  }
`;

export default ConvertIndividual;
