import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";
import scanEnvironment from "../img/scanEnvironment.svg";

const SettingInfo = ({ setSettingInfoModal }) => {
  const goBack = () => {
    setSettingInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();
  return (
    <>
      <ModalBackD />
      <StPaymentInfo>
        <ModalHeaderD title="재접속 시 주변환경 재점검 진행 정보" />
        <div className="bodyPart">
          {/**
            * 티켓링크 : https://www.notion.so/edint/WEB-1-f2ad947f86534c609b9b430f600a5762?pvs=4
            * 주석작성자 : Noah
            * 주석작성일자 : 2024.05.08
            * 티켓내용 : 재접속 시 주변환경 스캔정보환경 모달 내 이해를 돕는 이미지 추가 (1)
            * 주석설명 : 모달 내 이미지 추가
            */}
          <img className="scanEnvironment" alt="" src={scanEnvironment} />
          <ul>
            <li>
              항상 진행 시에는 응시자가 시험 시간 도중 접속이 끊겨 재접속을 하게
              되면 주변 환경 점검부터 매번 다시 진행하게 돼요.
            </li>
            <li>
              진행 안함 시에는 재접속을 하게 되면 카메라 세팅만 다시 진행하게 되어
              절차를 줄일 수 있어요.
            </li>
          </ul>
          엄중한 감시가 필요한 시험에서는 ‘항상 진행’, 유연한 운영이 중요한
          시험에서는 ‘진행 안함’을 추천드려요.
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          확인
        </div>
      </StPaymentInfo>
    </>
  );
};

const StPaymentInfo = styled.div`
  position: fixed;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  z-index: 53;
  @media ${device.pc} {
    width: 580px;
    padding: 60px;
    top: 50%;
    transform: translate(-50%, -50%);
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 48px auto 0;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
    }
    .bodyPart {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      .scanEnvironment {
        width: 100%;
      }
      ul {
        margin: 28px 0px;
        display: grid;
        flex-direction: column;
        gap: 4px;
        padding-left: 20px;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    bottom: 16px;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyPart {
      line-height: 24px;
      margin-bottom: 20px;
      .scanEnvironment {
        width: 100%;
      }
      ul {
        width: 270px;
        display: grid;
        flex-direction: column;
        gap: 4px;
        padding-left: 20px;
      }
    }
    .confirmButton {
      color: #0072de;
    }
  }
  .confirmButton {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }
`;

export default SettingInfo;
