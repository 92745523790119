import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Calendar from '../components/Calendar.jsx';
import { useRecoilValue } from 'recoil';

import startTimeClock from '../img/timeStart.svg';
import endTimeClock from '../img/timeEnd.svg';
import questionMk from '../img/questionMark.svg';
import creatingExam from '../img/creatingExam.svg';
import createExamCheck from '../img/createExamCheck.png';
import inputReset from '../img/inputClean.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { device } from '../hooks/device.jsx';
import { PageHeader } from '../components/Style.jsx';
import SettingInfo from '../modal/CreatingExamSettingInfo.jsx';
import ExitInfo from '../modal/CreatingExamExitInfo.jsx';
import 'dayjs/locale/ko';
import useLoginCheck from '../hooks/useLoginCheck.jsx';
import { getCookie } from '../Cookie.jsx';
import useToast from '../hooks/useToast.js';
import TextTwoPart from '../atomic/atom/TextTwoPart.tsx';
import { languageIs, userInfo } from '../atoms.ts';
import { useTranslation } from 'react-i18next';

interface PageScroll {
  setMyPageScroll: React.Dispatch<React.SetStateAction<string>>;
}

interface CreateExamStyle {
  test_title: string | null;
  leaving: boolean;
  reconfirm: boolean;
  start_process: string;
  end_process: string;
  start_alert: boolean;
  end_alert: boolean;
  title_focus: boolean;
  country: string;
}

const CreatingExam: React.FC<PageScroll> = ({ setMyPageScroll }) => {
  const { t } = useTranslation();
  const [startAlert, setStartAlert] = useState<boolean>(false);
  const [endAlert, setEndAlert] = useState<boolean>(false);
  const testTimeRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const showToast = useToast();
  const [titleFocus, setTitleFocus] = useState<boolean>(false);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const regex = /[^0-9]/g;

  // modal
  const [settingInfoModal, setSettingInfoModal] = useState(false);
  const [exitInfoModal, setExitInfoModal] = useState(false);

  // 수정 시 가져온 state 값을 저장해줌
  const [navigateState, setNavigateState] = useState();
  const state = useLocation().state;

  // 유저 정보 불러오기
  const user = useRecoilValue(userInfo);

  // 시험 제목 저장하기
  const [testTitle, setTestTitle] = useState<string | null>(null);

  // 응시자 자율 퇴장 여부
  const [leaving, setLeaving] = useState<boolean>(true);

  // 재접속시 응시환경 재점검 진행
  const [reConfirm, setReConfirm] = useState<boolean>(false);

  // 응원 메세지 상태
  const [courageMessage, setCourageMessage] = useState<any>(null);

  // 시험 시간 및 날짜 관련 함수들

  let testDay = dayjs();
  const tempForm = 'YYYY-MM-DD H:mm';
  const [startCalendar, setStartCalendar] = useState(false);
  const [endCalendar, setEndCalendar] = useState(false);
  const [tempStartTime, setTempStartTime] = useState(
    testDay.add(1, 'h').format(tempForm)
  );
  const [startHour, setStartHour] = useState<string | number>(
    testDay.format('h')
  );
  const [startMin, setStartMin] = useState<string>(testDay.format('mm'));
  const [tempEndTime, setTempEndTime] = useState(
    testDay.add(2, 'h').format(tempForm)
  );
  const [endHour, setEndHour] = useState<string | number>(
    testDay.add(1, 'h').format('h')
  );
  const [endMin, setEndMin] = useState<string | number>(testDay.format('mm'));
  const [startProcess, setStartProcess] = useState<string>(
    testDay.add(1, 'h').format('A')
  );
  const [endProcess, setEndProcess] = useState(testDay.add(2, 'h').format('A'));
  const [endClick, setEndClick] = useState(false);
  let dayjsStartTime = dayjs(tempStartTime);
  let dayjsEndTime = dayjs(tempEndTime);

  const [test, setTest] = useState(false);
  useLoginCheck(test);

  useEffect(() => {
    setTest(true);
  }, []);

  useEffect(() => {
    if (getCookie('token')) {
    } else {
      // navigate(`/`)
    }
  });

  useEffect(() => {
    if (country === '/ko') {
      dayjs.locale('ko');
    } else {
      dayjs.locale('en');
    }
  }, [country]);

  /** tempStartTime 이 변경될 경우 부수적인 값들도 모두 변경 */
  useEffect(() => {
    setStartHour(dayjsStartTime.format('hh'));
    setStartMin(dayjsStartTime.format('mm'));
    checkStartTimeWithHour();
    checkStartTimeWithTestTime();
    if (
      dayjsStartTime.format('A') === 'AM' ||
      dayjsStartTime.format('A') === '오전'
    ) {
      setStartProcess('AM');
    } else {
      setStartProcess('PM');
    }
  }, [tempStartTime]);

  /** tempEndTime 이 변경될 경우 부수적인 값들도 모두 변경 */
  useEffect(() => {
    setEndHour(dayjsEndTime.format('hh'));
    setEndMin(dayjsEndTime.format('mm'));
    if (
      dayjsEndTime.format('A') === 'AM' ||
      dayjsEndTime.format('A') === '오전'
    ) {
      setEndProcess('AM');
    } else {
      setEndProcess('PM');
    }
    if (endClick) {
      checkEndTimeWithTestTime();
    }
  }, [tempEndTime]);

  /** 종료 시간을 설정 시 총 시험시간이 30분 이하일 경우 시작 시간 기준 30분 추가 || 120 분 이상일 경우 시작 시간 기준 120분 추가 + 토스트 메세지 띄워줌 */
  const checkEndTimeWithTestTime = () => {
    if (dayjsEndTime.diff(tempStartTime, 'm') < 30) {
      setTempEndTime(dayjsStartTime.add(30, 'm').format(tempForm));
      showToast({ message: t(`errorCode.timezone_005`) });
    } else if (dayjsEndTime.diff(tempStartTime, 'm') > 120) {
      setTempEndTime(dayjsStartTime.add(120, 'm').format(tempForm));
      showToast({ message: t(`errorCode.timezone_003`) });
    }
  };

  /** 시작 시간 설정 시 시작 시간이 현재 시간보다 30분 뒤로 설정했는지 체크, 아닐 경우 시작시간을 30분 뒤로 보내줌 */
  const checkStartTimeWithHour = () => {
    if (dayjsStartTime.diff(testDay, 'm') < 30) {
      setTempStartTime(testDay.add(30, 'm').format(tempForm));
      showToast({
        message: t(`errorCode.after30min`),
      });
    }
  };

  /** 시작 시간 설정 시 총 시험시간이  120분을 초과 할 시에 종료시간을 시작 시간 기준 30분 뒤로 자동 조정*/
  const checkStartTimeWithTestTime = () => {
    if (dayjsEndTime.diff(tempStartTime, 'm') > 120) {
      setTempEndTime(dayjsStartTime.add(30, 'm').format(tempForm));
    } else if (dayjsEndTime.diff(tempStartTime, 'm') < 30) {
      setTempEndTime(dayjsStartTime.add(30, 'm').format(tempForm));
    }
  };

  /** 시작 시간 시를 입력하고 저장 ( 내부에서 오전 오후 처리를 다 해줌 // AM,PM 선택 시 또는 Hour onBlur시 사용됨 ) */
  const controlStartHour = (prop: string | number) => {
    const hour = Number(prop);
    if (startProcess === 'AM' || startProcess === '오전') {
      if (hour >= 12) {
        setTempStartTime(dayjsStartTime.set('h', 11).format(tempForm));
        setStartHour(11);
        setStartMin(dayjsStartTime.format('mm'));
      } else if (hour <= 0) {
        setTempStartTime(dayjsStartTime.set('h', 0).format(tempForm));
      } else {
        setTempStartTime(dayjsStartTime.set('h', hour).format(tempForm));
      }
    } else {
      if (hour > 12) {
        setTempStartTime(dayjsStartTime.set('h', 23).format(tempForm));
        setStartHour(11);
        setStartMin(dayjsStartTime.format('mm'));
      } else if (hour <= 0) {
        setTempStartTime(dayjsStartTime.set('h', 12).format(tempForm));
      } else if (hour === 12) {
        setTempStartTime(dayjsStartTime.set('h', 12).format(tempForm));
      } else {
        setTempStartTime(dayjsStartTime.set('h', hour + 12).format(tempForm));
      }
    }
  };

  /** 응시 시작의 AM 또는 PM 버튼을 눌렀을 때 시간을 바꿔준다. */
  const controlStartAmMPm = (prop: string) => {
    if (
      (prop === 'AM' || prop === '오전') &&
      (dayjsStartTime.format('A') === 'PM' ||
        dayjsStartTime.format('A') === '오후')
    ) {
      setTempStartTime(dayjsStartTime.subtract(12, 'h').format(tempForm));
    } else if (
      (prop === 'PM' || prop === '오후') &&
      (dayjsStartTime.format('A') === 'AM' ||
        dayjsStartTime.format('A') === '오전')
    ) {
      setTempStartTime(dayjsStartTime.add(12, 'h').format(tempForm));
    }
  };

  /** 응시 종료의 AM 또는 PM 버튼을 눌렀을 때 시간을 바꿔준다. */
  const controlEndAmPm = (prop: string) => {
    if (
      (prop === 'AM' || prop === '오전') &&
      (dayjsEndTime.format('A') === 'PM' || dayjsEndTime.format('A') === '오후')
    ) {
      if (dayjsEndTime.subtract(12, 'h').diff(tempStartTime, 'm') > 120) {
        setTempEndTime(dayjsStartTime.add(2, 'h').format(tempForm));
      } else {
        setTempEndTime(dayjsEndTime.subtract(12, 'h').format(tempForm));
      }
    } else if (
      (prop === 'PM' || prop === '오후') &&
      (dayjsEndTime.format('A') === 'AM' || dayjsEndTime.format('A') === '오전')
    ) {
      if (dayjsEndTime.add(12, 'h').diff(tempStartTime, 'm') > 120) {
        setTempEndTime(dayjsStartTime.add(2, 'h').format(tempForm));
      } else {
        setTempEndTime(dayjsEndTime.add(12, 'h').format(tempForm));
      }
    }
  };

  /** 시작 시간 분을 입력하고 저장 */
  const controlStartMin = (prop: string) => {
    let min = Number(prop);
    if (min >= 60) {
      setTempStartTime(dayjsStartTime.set('m', 59).format(tempForm));
    } else {
      setTempStartTime(dayjsStartTime.set('m', min).format(tempForm));
    }
  };

  /** 종료 시간 시를 입력하고 저장 */
  const controlEndHour = (prop: string | number) => {
    const hour = Number(prop);
    if (endProcess === 'AM' || endProcess === '오전') {
      if (hour >= 12) {
        setTempEndTime(dayjsEndTime.set('h', 11).format(tempForm));
        setEndHour(11);
      } else if (hour <= 0) {
        setTempEndTime(dayjsEndTime.set('h', 0).format(tempForm));
      } else {
        setTempEndTime(dayjsEndTime.set('h', hour).format(tempForm));
      }
    } else {
      if (hour > 12) {
        setTempEndTime(dayjsEndTime.set('h', 23).format(tempForm));
        setEndHour(11);
      } else if (hour <= 0) {
        setTempEndTime(dayjsEndTime.set('h', 12).format(tempForm));
      } else if (hour === 12) {
        setTempEndTime(dayjsEndTime.set('h', 12).format(tempForm));
      } else {
        setTempEndTime(dayjsEndTime.set('h', hour + 12).format(tempForm));
      }
    }
  };

  /** 종료 시간 분을 입력하고 저장 */
  const controlEndMin = (props: number | string) => {
    const min = Number(props);
    if (min >= 60) {
      setTempEndTime(dayjsEndTime.set('m', 59).format(tempForm));
    } else {
      setTempEndTime(dayjsEndTime.set('m', min).format(tempForm));
    }
  };

  /** 달력 선택시 날짜 변경해주기 */
  const settingDates = (date: string, ddd: any, thisIsFor: string) => {
    if (dayjs(date).diff(dayjs(), 'M') >= 2) {
      setTempStartTime(dayjs().add(2, 'M').format(tempForm));
      setTempEndTime(dayjs().add(2, 'M').add(30, 'minute').format(tempForm));
      showToast({
        message: t(`errorCode.timezone_010`),
      });
    } else if (
      thisIsFor === 'end' &&
      dayjs(date).diff(dayjs(tempStartTime), 'm') < 30
    ) {
      setTempEndTime(dayjs(tempStartTime).add(30, 'm').format(tempForm));
      showToast({
        message: t(`errorCode.timezone_006`),
      });
    } else {
      if (thisIsFor === 'start') {
        setTempStartTime(date);
      } else if (thisIsFor === 'end') {
        setTempEndTime(date);
      }
    }
  };

  /** 결제 직전 시험 수정하기를 위한 state값 받아오기 */
  useEffect(() => {
    setNavigateState(state);
    if (state) {
      setTempStartTime(dayjs(state.startTime).format(tempForm));
      setTempEndTime(dayjs(state.endTime).format(tempForm));
      setTestTitle(state.title);
      setLeaving(state.leaving);
      setReConfirm(state.reConfirm);
      setCourageMessage(state.testComment);
    }
  }, []);

  /** 다음 과정을 위해 상태 값들을 들고 다음 페이지로 이동 */
  const nextStepForCreatingExam = () => {
    if (
      dayjs(tempEndTime).diff(tempStartTime) / 60000 >= 30 &&
      dayjs(tempEndTime).diff(tempStartTime) / 60000 <= 120
    ) {
      navigate(`/createExamFinal`, {
        state: {
          tempStartTime,
          tempEndTime,
          testTitle,
          leaving,
          reConfirm,
          courageMessage,
          type: user.plan,
        },
      });
    }
  };

  useEffect(() => {
    const handlePress = (e: KeyboardEvent) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handlePress);
    return () => document.removeEventListener('keydown', handlePress);
  }, []);

  /** 텍스트 박스 높이 자동 조절 */
  const autoResize = () => {
    const textarea = document.getElementById('textBox');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body?.addEventListener('click', (e) => {
      if (e.target !== titleRef.current) {
        setTitleFocus(false);
      }
    });
  }, []);

  return (
    <>
      {settingInfoModal && (
        <SettingInfo setSettingInfoModal={setSettingInfoModal} />
      )}
      {exitInfoModal && <ExitInfo setExitInfoModal={setExitInfoModal} />}
      <StCreateExam
        test_title={testTitle}
        leaving={leaving}
        reconfirm={reConfirm}
        start_process={startProcess}
        end_process={endProcess}
        start_alert={startAlert}
        end_alert={endAlert}
        title_focus={titleFocus}
        country={country}
      >
        <div className="mainFrame">
          <PageHeader
            title={country === '/ko' ? '시험 예약하기' : 'Create Event'}
            page=""
            img={null}
            nav={null}
            text={null}
          />
          <div className="bucket">
            <div className="hostInfoBox">
              <div className="smallTitle">
                <TextTwoPart
                  textKor="주최자 정보"
                  textEng="Administrator Information"
                />
              </div>
              <div className="hostInfo">
                {user.hostName} ({user.hostEmail})
              </div>
            </div>
            <div className="titleBox">
              <div className="smallTitle">
                <TextTwoPart textKor="시험 제목" textEng="Title" />
              </div>
              <div className="inputLine">
                <input
                  className="testInput"
                  placeholder={
                    country === '/ko' ? '시험 제목 입력' : 'Enter Event Title'
                  }
                  value={testTitle || ''}
                  // onInput={autoResizeTitle}
                  onChange={(e) => {
                    setTestTitle(e.target.value);
                  }}
                  maxLength={45}
                  ref={titleRef}
                  onFocus={() => setTitleFocus(true)}
                />
                {titleFocus && testTitle ? (
                  <img
                    onClick={() => setTestTitle(null)}
                    src={inputReset}
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="underLine" />
            </div>
            <div className="testDateBox" ref={testTimeRef}>
              <div className="smallTitle">
                <TextTwoPart
                  textKor={
                    user?.hostPlan === 'individualPlan'
                      ? '시험 날짜와 시간 (최대 60분)'
                      : '시험 날짜와 시간 (최대 120분)'
                  }
                />
              </div>
              <div className="testTimePart">
                <div className="testStartToFinish">
                  <div className="testStart">
                    <img className="endTimeClock" src={startTimeClock} alt="" />
                    <TextTwoPart textKor="응시 시작 :" textEng="Start Time:" />
                  </div>
                  <div className="times">
                    <div
                      className="dateToday"
                      onClick={() => {
                        setStartCalendar(true);
                      }}
                    >
                      {country === '/ko'
                        ? dayjsStartTime.format('YYYY-MM-DD')
                        : dayjsStartTime.format('MM/DD/YYYY')}
                    </div>
                    <Calendar
                      tempTime={tempStartTime}
                      tempStart={tempStartTime}
                      tempEnd={tempEndTime}
                      setTime={settingDates}
                      isFor={'start'}
                      open={startCalendar}
                      setOpen={setStartCalendar}
                      setEndClick={null}
                    />
                    <div
                      className="testTimeOne"
                      onClick={() => {
                        setStartAlert(false);
                        setEndAlert(false);
                      }}
                    >
                      <div
                        className="startAM"
                        onClick={() => controlStartAmMPm('AM')}
                      >
                        AM
                      </div>
                      <div
                        className="startPM"
                        onClick={() => controlStartAmMPm('PM')}
                      >
                        PM
                      </div>
                      <div className="realTime">
                        <input
                          className="hourPart"
                          type="number"
                          value={startHour || ''}
                          max={24}
                          min={0}
                          onChange={(e) => {
                            setStartHour(e.target.value.replace(regex, ''));
                          }}
                          onBlur={(e) => {
                            controlStartHour(startHour);
                          }}
                        />
                        <div className="between">:</div>
                        <input
                          className="minPart"
                          type="number"
                          value={startMin || ''}
                          max={59}
                          min={0}
                          onChange={(e) => setStartMin(e.target.value)}
                          onBlur={() => {
                            controlStartMin(startMin);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="testStartToFinish"
                  onClick={() => {
                    setStartAlert(false);
                    setEndAlert(false);
                  }}
                >
                  <div className="testStart">
                    <img className="endTimeClock" src={endTimeClock} alt="" />
                    <div></div>
                    <TextTwoPart textKor="응시 종료 :" textEng="End Time:" />
                  </div>
                  <div className="times">
                    <div
                      className="dateToday"
                      onClick={() => {
                        setEndCalendar(true);
                      }}
                    >
                      {country === '/ko'
                        ? dayjsEndTime.format('YYYY-MM-DD')
                        : dayjsEndTime.format('MM/DD/YYYY')}
                    </div>
                    <Calendar
                      tempTime={tempEndTime}
                      tempStart={tempStartTime}
                      tempEnd={tempEndTime}
                      setTime={settingDates}
                      isFor={'end'}
                      open={endCalendar}
                      setOpen={setEndCalendar}
                      setEndClick={setEndClick}
                    />
                    <div
                      className="testTimeTwo"
                      onClick={() => setEndAlert(false)}
                    >
                      <div
                        className="endAM"
                        onClick={() => {
                          controlEndAmPm('AM');
                          setEndClick(true);
                        }}
                      >
                        AM
                      </div>
                      <div
                        className="endPM"
                        onClick={() => {
                          controlEndAmPm('PM');
                          setEndClick(true);
                        }}
                      >
                        PM
                      </div>
                      <div className="realTime">
                        <input
                          className="hourPart"
                          type="number"
                          value={endHour || ''}
                          max={24}
                          min={0}
                          onChange={(e) => {
                            setEndHour(e.target.value);
                            setEndClick(true);
                          }}
                          onBlur={() => {
                            controlEndHour(endHour);
                          }}
                        />
                        <div className="between">:</div>
                        <input
                          className="minPart"
                          type="number"
                          value={endMin || ''}
                          max={59}
                          min={0}
                          onChange={(e) => {
                            if (e.target.value.length < 3) {
                              setEndMin(e.target.value);
                              setEndClick(true);
                            }
                          }}
                          onBlur={() => {
                            controlEndMin(endMin);
                          }}
                          // maxLength="2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="infoLine">
                  <TextTwoPart
                    textKor="* 응시자는 시험 시작하기 30분 전부터 입장이 가능해요."
                    textEng="* Test-takers can enter 30 minutes before the proctoring session starts."
                  />
                </div>
                <div className="infoLine">
                  <TextTwoPart
                    textKor="* 지각한 응시자는 시험 시작 후 15분 이내까지만 입장이 가능해요."
                    textEng="* Late arrivals can enter within 15 minutes of the proctoring start."
                  />
                </div>
              </div>
            </div>
            <div className="middleRow">
              <div className="middleRowLeft">
                <div className="checkBox">
                  <div className="smallTitle">
                    <TextTwoPart
                      textKor="응시자 자율 퇴장"
                      textEng="Voluntary Withdrawal"
                    />
                    <img
                      src={questionMk}
                      alt=""
                      onClick={() => {
                        setExitInfoModal(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="checkTime">
                    <div
                      className="leavingCheckTrue"
                      onClick={() => {
                        setLeaving(true);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <TextTwoPart textKor="허용" textEng="Allow" />
                    </div>
                    <div
                      className="leavingCheckFalse"
                      onClick={() => {
                        setLeaving(false);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <TextTwoPart textKor="허용 안함" textEng="Don't Allow" />
                    </div>
                  </div>
                </div>
                <div className="checkBox">
                  <div className="smallTitle">
                    <TextTwoPart
                      textKor="재접속 시 주변환경 재점검 진행"
                      textEng="Recheck Environment"
                    />
                    <img
                      src={questionMk}
                      alt=""
                      onClick={() => setSettingInfoModal(true)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="checkTime">
                    <div
                      className="reConfirmFalse"
                      onClick={() => {
                        setReConfirm(false);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <TextTwoPart
                        textKor="진행 안함"
                        textEng="Don't Proceed"
                      />
                    </div>
                    <div
                      className="reConfirmTrue"
                      onClick={() => {
                        setReConfirm(true);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <TextTwoPart
                        textKor="항상 진행"
                        textEng="Always Proceed"
                      />
                    </div>
                  </div>
                </div>
                <div className="fightingBox">
                  <div className="smallTitle">
                    <TextTwoPart
                      textKor="응원 메세지 (선택)"
                      textEng="Support Message (Optional)"
                    />
                  </div>
                  <textarea
                    id="textBox"
                    placeholder={
                      country === '/ko'
                        ? '내용 입력 (최대 100자)'
                        : 'Enter Message'
                    }
                    cols={5}
                    value={courageMessage}
                    onInput={autoResize}
                    onChange={(e) => {
                      if (e.target.value.length <= 100)
                        setCourageMessage(e.target.value);
                    }}
                    // maxLength="100"
                  />
                </div>
              </div>
              <div className="imgBox">
                <img className="creatingExam" src={creatingExam} alt="" />
              </div>
            </div>
            <div className="checkPointBox">
              <div className="checkPointTitle">
                <img src={createExamCheck} />
                <div>CHECK POINT</div>
              </div>
              <div className="check">
                {country === '/ko' ? (
                  <>
                    1. 시험 생성 완료 시, 시험 당일 응시 및 녹화 영상 업로드가
                    가능한{' '}
                    <span className="bolding">
                      전용 QR과 URL 링크가 자동 발급
                    </span>
                    돼요.
                  </>
                ) : (
                  <>
                    1. After successfully creating the event, you will receive
                    a&nbsp;
                    <span className="bolding">QR code and URL</span> for
                    test-takers to access the proctored session and upload their
                    exam videos on the day of the exam.
                  </>
                )}
              </div>
              <div className="check">
                {country === '/ko' ? (
                  <>
                    2. 생성된 시험 정보(QR, URL 링크 등)는 주최자의{' '}
                    <span className="bolding">
                      이메일 주소로 응시자용 가이드와 함께 발송
                    </span>
                    돼요.{' '}
                  </>
                ) : (
                  <>
                    2. After the exam details are generated,{' '}
                    <span className="bolding">
                      an instruction for test-takers{' '}
                    </span>
                    will be sent to the administrator's email address along with
                    the QR codes and URLs.
                  </>
                )}
              </div>
              <div className="check">
                {country === '/ko' ? (
                  <>
                    3. 각 응시자는 프록토매틱 앱을 통해{' '}
                    <span className="bolding">
                      시작 시간 30분 전부터 입장 가능하며, 응시환경에 대한 사전
                      점검(환경 점검, 카메라 거치)을 수행
                    </span>
                    할 수 있어요.
                  </>
                ) : (
                  <>
                    3. Test-takers can enter the proctored session{' '}
                    <span className="bolding">
                      30 minutes before the scheduled time
                    </span>{' '}
                    and check their exam environment using the Proctormatic app.
                  </>
                )}
              </div>
              <div className="check">
                {country === '/ko' ? (
                  <>
                    4. 시험 종료 후 모든 응시자의 영상 업로드가 완료되면,{' '}
                    <span className="bolding">
                      자동 AI 영상분석을 거쳐 24시간 내 결과보고서를 작성
                    </span>
                    하여 주최자의 이메일 주소로 전달드려요.{' '}
                  </>
                ) : (
                  <>
                    4. After all test-takers upload their exam videos,{' '}
                    <span className="bolding">an automated AI analysis</span> is
                    conducted. A report is generated and sent to the
                    administrator’s email{' '}
                    <span className="bolding">within 24 hours.</span>
                  </>
                )}
              </div>
              <div className="check">
                {country === '/ko' ? (
                  <>
                    5. 생성한 모든 시험은 프록토매틱 홈페이지의{' '}
                    <span className="bolding">‘내 시험 관리’</span>를 통해
                    언제든 다시 확인 가능해요.
                  </>
                ) : (
                  <>
                    5. The events you have created can be reviewed at any time
                    through the{' '}
                    <span className="bolding">"View My Reports"</span> section
                    on the Proctormatic website.
                  </>
                )}
              </div>
            </div>
            <div className="lastBox">
              {country === '/ko' ? (
                <>
                  서비스 이용에 어려움이 있으신가요? &nbsp;
                  <br className="onlyForMob" />
                  <span
                    className="blueing"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/mypage`);
                      setMyPageScroll('customerCenter');
                    }}
                  >
                    고객센터
                  </span>
                  를 통해 연락주시면 신속하게 도와드릴게요.
                </>
              ) : (
                <>
                  If you need assistance, feel free to reach out to our &nbsp;
                  <span
                    className="blueing"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/mypage`);
                      setMyPageScroll('customerCenter');
                    }}
                  >
                    Customer Support
                  </span>
                  . We're here to assist you promptly.
                </>
              )}
            </div>
            <button
              className="nextButton"
              disabled={!testTitle}
              onClick={nextStepForCreatingExam}
            >
              <TextTwoPart textKor="다음" textEng="Next" />
            </button>
          </div>
        </div>
      </StCreateExam>
    </>
  );
};

const StCreateExam = styled.div<CreateExamStyle>`
  padding: 0 24px 37px;
  box-sizing: border-box;
  .mainTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 132px;
    align-items: center;
    padding: 22px 0 44px;
    box-sizing: border-box;
    text-align: center;
    line-height: 33px;
    font-size: 22px;
    font-family: 'bold';
  }
  .hostInfoBox {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .smallTitle {
      font-family: 'medium';
      font-size: 13px;
    }
    .hostInfo {
      color: #2276dc;
      line-height: 24px;
      margin-top: 11px;
    }
  }
  .titleBox {
    box-sizing: border-box;
    .smallTitle {
      font-family: 'medium';
      font-size: 13px;
    }
    .testInput {
      border: none;
      padding: 0;
      line-height: 24px;
      margin-top: 11px;
      font-size: 16px;
      width: 100%;
      font-family: 'regular';
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: 'light';
      }
    }
    .underLine {
      width: 100%;
      height: 1px;
      background-color: ${(props) =>
        props.title_focus ? '#2276DC' : '#dfdfdf'};
      margin-top: 6px;
    }
  }
  .testDateBox {
    box-sizing: border-box;
    margin-bottom: 44px;
    .modalForCalendar {
      position: relative;
    }
    .smallTitle {
      font-family: 'medium';
      font-size: 13px;
      margin-bottom: 18px;
    }
    .times {
      width: 312px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      align-items: center;
      gap: 8px;
    }
    .dateToday {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: 'regular';
      width: 100%;
      max-width: 152px;
      height: 42px;
      border: 0.6px solid #818181;
      border-radius: 6px;
      box-sizing: border-Box;
    }
    .testStartToFinish {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      /* align-items: center; */
      box-sizing: border-box;
      margin-top: 12px;
      .testStart {
        display: flex;
        font-size: 14px;
        font-family: 'medium';
        line-height: 21px;
        align-items: center;
        margin-bottom: 8px;
        .endTimeClock {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
      .testTimeOne {
        display: grid;
        grid-template-columns: auto auto auto;
        align-items: center;
        gap: 5px;
        width: 100%;
        max-width: 152px;
        height: 42px;
        padding: 7px 6px;
        box-sizing: border-box;
        border-radius: 6px;
        border: ${(props) =>
          props.start_alert ? '1px solid #C83B38' : '0.6px solid #818181'};
        .startAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.start_process === 'AM' ? '#20315B' : '#D9D9D9'};
          color: white;
          cursor: pointer;
        }
        .startPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.start_process === 'AM' ? '#D9D9D9' : '#20315B'};
          color: white;
          cursor: pointer;
        }
        .endAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.end_process === 'AM' ? '#20315B' : '#D9D9D9'};
          color: white;
          cursor: pointer;
        }
        .endPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.end_process === 'AM' ? '#D9D9D9' : '#20315B'};
          color: white;
          cursor: pointer;
        }
        .realTime {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: center;
          align-items: center;
          width: 75px;
          gap: 3px;
          .hourPart {
            width: 20px;
            border: none;
            line-height: 16px;
            font-size: 16px;
            font-family: 'regular';
            &:focus {
              outline: none;
            }
            /* padding-top: 3px; */
            /* background-color : blue; */
          }
          .between {
            line-height: 26px;
          }
          .minPart {
            width: 20px;
            border: none;
            /* padding-top: 3px; */
            line-height: 16px;
            font-size: 16px;
            font-family: 'regular';
            &:focus {
              outline: none;
            }
          }
        }
      }
      .testTimeTwo {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 5px;
        width: 100%;
        max-width: 152px;
        height: 42px;
        padding: 7px 6px;
        box-sizing: border-box;
        border-radius: 6px;
        border: 0.6px solid
          ${(props) => (props.end_alert ? '#C83B38' : '#818181')};
        .startAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.start_process === 'AM' ? '#20315B' : '#D9D9D9'};
          color: white;
          cursor: pointer;
        }
        .startPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.start_process === 'AM' ? '#D9D9D9' : '#20315B'};
          color: white;
          cursor: pointer;
        }
        .endAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.end_process === 'AM' ? '#20315B' : '#D9D9D9'};
          color: white;
          cursor: pointer;
        }
        .endPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: 'medium';
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.end_process === 'AM' ? '#D9D9D9' : '#20315B'};
          color: white;
          cursor: pointer;
        }
        .realTime {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: center;
          align-items: center;
          width: 75px;
          gap: 3px;
          .hourPart {
            width: 20px;
            border: none;
            /* padding-top: 3px; */
            font-size: 16px;
            font-family: 'regular';
            &:focus {
              outline: none;
            }
          }
          .between {
          }
          .minPart {
            width: 20px;
            border: none;
            /* padding-top: 3px; */
            font-size: 16px;
            font-family: 'regular';
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .info {
      display: grid;
      flex-direction: column;
      gap: 6px;
      margin-top: 14px;
      .infoLine {
        line-height: 16px;
        font-size: 11px;
        color: #444444;
      }
    }
  }
  .checkBox {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    box-sizing: border-box;
    .smallTitle {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      font-family: 'medium';
      font-size: 13px;
      gap: 6px;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .checkTime {
      display: grid;
      flex-direction: column;
      gap: 15px;
      margin-top: 15px;
      .leavingCheckTrue {
        display: grid;
        grid-template-columns: repeat(2, auto) 1fr;
        gap: 12px;
        width: 94px;
        cursor: default;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.leaving ? '#0381FE' : ' #8C8C8C')};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.leaving ? '#0381FE' : '')};
          }
        }
      }
      .leavingCheckFalse {
        display: grid;
        grid-template-columns: auto 1fr;
        width: 120px;
        gap: 12px;
        cursor: default;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.leaving ? '#8C8C8C' : '#0381FE')};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.leaving ? '' : '#0381FE')};
          }
        }
      }
      .reConfirmTrue {
        display: grid;
        gap: 12px;
        grid-template-columns: auto 1fr;
        width: 150px;
        cursor: default;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.reconfirm ? '#0381FE' : '#8C8C8C')};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.reconfirm ? '#0381FE' : '')};
          }
        }
      }
      .reConfirmFalse {
        display: grid;
        gap: 12px;
        grid-template-columns: auto 1fr;
        width: 140px;
        cursor: default;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.reconfirm ? '#8C8C8C' : '#0381FE')};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.reconfirm ? '' : '#0381FE')};
          }
        }
      }
    }
  }
  .fightingBox {
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    .smallTitle {
      font-family: 'medium';
      font-size: 13px;
    }
    textarea {
      ::-webkit-scrollbar {
        display: none;
      }
      &:focus {
        outline: none;
      }
      padding: 11.5px 16px;
      height: auto;
      font-size: 16px;
      border: 0.6px solid #818181;
      border-radius: 6px;
      box-sizing: border-box;
      margin-top: 10px;
      resize: none;
      font-family: 'regular';
      &::placeholder {
        color: #bdbdbd;
        font-family: 'light';
      }
    }
  }
  .imgBox {
    display: flex;
    box-sizing: border-box;
    margin: 0 auto;
    .creatingExam {
      margin: 0 auto;
      width: 233px;
      height: 233px;
    }
  }
  .checkPointBox {
    display: grid;
    flex-direction: column;
    background-color: #fff4d8;
    padding: 40px 24px;
    box-sizing: border-box;
    border-radius: 12px;
    gap: 24px;
    .checkPointTitle {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      color: #2276dc;
      font-size: 18px;
      font-family: 'bold';
      gap: 8px;
      /* margin-bottom: 24px; */
    }
    .check {
      font-size: 15px;
      line-height: 22px;
      text-indent: -14px;
      padding-left: 23px;
    }
    .bolding {
      font-family: 'bold';
    }
  }
  .lastBox {
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    margin-top: 16px;
    padding: ${(props) => (props.country === '/ko' ? '0' : '0 5px')};
    .blueing {
      color: #2276dc;
      text-decoration: underline;
    }
  }
  .nextButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 204px;
    height: 42px;
    font-size: 14px;
    font-family: 'medium';
    background-color: ${(props) => (props.test_title ? '#20315B' : '#D9D9D9')};
    border: none;
    color: white;
    border-radius: 42px;
    margin: 37px auto 0;
    cursor: ${(props) => (props.test_title ? 'pointer' : '')};
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 1440px;
    height: 100%;
    background: #f0f1f2;
    .onlyForMob {
      display: none;
    }
    .nextButton {
      height: 52px;
      font-size: 17px;
      margin-top: 40px;
    }
    .mainFrame {
      width: 1228px;
      padding: 80px 0px;
      box-sizing: border-box;
      margin: 0 auto;
      .bucket {
        width: 865px;
        background-color: white;
        margin: 60px auto 0;
        padding: 60px;
        box-sizing: border-box;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        .hostInfoBox {
          .smallTitle {
            font-size: 15px;
            font-family: 'semibold';
            line-height: 27px;
          }
        }
        .titleBox {
          /* gap :  */
          width: 649px;
          margin: 34px 0;
          .inputLine {
            display: grid;
            grid-template-columns: auto auto;
            .testInput {
              width: 623px;
            }
            img {
              margin: 12px 0 0 auto;
              cursor: pointer;
            }
          }
          .smallTitle {
            font-size: 15px;
            font-family: 'semibold';
            line-height: 27px;
          }
        }
        .testDateBox {
          .endTimeClock {
            width: 17px;
            height: 17px;
          }
          .smallTitle {
            font-size: 15px;
            font-family: 'semibold';
            line-height: 27px;
          }
          .testTimePart {
            display: grid;
            grid-template-columns: auto auto 1fr;
            gap: 32px;
          }
        }
        .middleRow {
          display: grid;
          grid-template-columns: auto auto;
          gap: 48px;
          margin-bottom: 72px;
          .middleRowLeft {
            display: grid;
            flex-direction: column;
            gap: 34px;
            width: 464px;
            .fightingBox {
              margin: 0;
              .smallTitle {
                font-size: 15px;
                font-family: 'semibold';
                line-height: 27px;
              }
            }
            .checkBox {
              /* justify-content: center; */
              padding: 0;
              .smallTitle {
                display: flex;
                align-items: center;
                font-size: 15px;
                font-family: 'semibold';
                line-height: 27px;
                img {
                  width: 16px;
                  height: 16px;
                  margin-bottom: 3px;
                }
              }
            }
          }
          .imgBox {
            margin-top: auto;
            img {
              width: 233px;
              height: 233px;
            }
          }
        }
        .checkPointBox {
          padding: 40px;
        }
      }
    }
  }
  @media ${device.tablet} {
    .middleRow {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 60.36px;
      .middleRowLeft {
        flex-grow: 1;
      }
      .imgBox {
        display: flex;
        margin: 0 !important;
        padding: 0 !important;
        .creatingExam {
          width: 208px;
          margin: auto 0 58px;
        }
      }
    }
  }
  @media ${device.mobile} {
    textarea {
      max-width: 314.64px;
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .mainFrame {
      width: 100%;
      max-width: 752px;
      box-sizing: border-box;
      margin: 0 auto;
    }
    .inputLine {
      display: grid;
      grid-template-columns: 1fr auto;
      .testInput {
        width: calc(100% - 32px);
      }
      img {
        margin: 12px 0 0 auto;
        cursor: pointer;
      }
    }
    .bucket {
      .hostInfoBox {
        height: 88px;
        padding: 14px 0;
        .smallTitle {
        }
      }
      .titleBox {
        height: 88px;
        padding: 14px 0;
        .smallTitle {
        }
      }
      .testDateBox {
        padding: 14px 0;
        .smallTitle {
        }
        .testTimePart {
        }
      }
      .imgBox {
        margin: 0 auto;
        padding: 0px 37px 28px;
      }
    }
  }
`;

export default CreatingExam;
