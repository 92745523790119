import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { languageIs } from "../atoms";
import useGetNoticeDetail from "../hooks/useGetNoticeDetail";
import arrow from "../img/arrow.svg";
import { device } from "../hooks/device";
import dayjs from "dayjs";
import Photo from "../modal/Photo";

const NoticeDetail = ({ setModalOn }) => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const id = useParams();
  const { data } = useGetNoticeDetail(id);
  const [backGroundColor, setBackGroundColor] = useState();
  const [color, setColor] = useState();
  const [clickedNumber, setClickedNumber] = useState();

  // modal
  const [pictureModalOn, setPictureModalOn] = useState(false);

  const notice = data?.notice;

  useEffect(() => {
    if (notice?.category === "일반") {
      setBackGroundColor("#F1F1F1");
      setColor("#888888");
    } else if (notice?.category === "주최자") {
      setBackGroundColor("#FFEDDC");
      setColor("#ED893E");
    } else if (notice?.category === "응시자") {
      setBackGroundColor("#E2EEFF");
      setColor("#1A75FF");
    }
  }, [notice?.category]);

  const renderHtml = () => {
    return {
      __html: notice?.context,
    };
  };

  return (
    <>
      {pictureModalOn && (
        <Photo
          list={data?.notice?.signedImageList}
          clickedNumber={clickedNumber}
          setPictureModalOn={setPictureModalOn}
        />
      )}
      <StNoticeDetail
        color={color}
        category={notice?.category}
        backGroundColor={backGroundColor}
      >
        <div className="container">
          <div className="title">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/notice/1`);
              }}
              src={arrow}
              alt=""
            />
            고객센터
          </div>
          <div className="middleTitleBox">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/notice/1`);
              }}
              src={arrow}
              alt=""
            />
            <div className="middleTitle">공지사항</div>
          </div>
          <div className="noticeDetailBody">
            <div className="noticeDetailTitleBox">
              {notice?.isImportant ? (
                <div className="noticeDetailImportant">중요</div>
              ) : (
                <div className="noticeDetailCategory">{notice?.category}</div>
              )}
              <div className="noticeDetailTitle">{notice?.title}</div>
              <div className="noticeDetailTime">
                {dayjs(notice?.createdAt).format("A HH:mmㆍYYYY-MM-DD")}
              </div>
            </div>
            <div className="greyBar" />
            {/* <div className='noticeDetailContext'>
                        {notice?.context}
                    </div> */}
            <div
              className="noticeDetailContext"
              dangerouslySetInnerHTML={renderHtml()}
            />
            <div className="noticeDetailImgBox">
              {data?.notice?.signedImageList?.map((value, index) => (
                <img
                  className="noticeDetailImg"
                  alt=""
                  src={value}
                  onClick={() => {
                    setClickedNumber(index + 1);
                    setPictureModalOn(true);
                  }}
                  key={index}
                />
              ))}
            </div>
            <div
              className="noticeDetailButton"
              onClick={() => {
                navigate(`/notice/1`);
              }}
            >
              목록으로
            </div>
          </div>
        </div>
      </StNoticeDetail>
    </>
  );
};

const StNoticeDetail = styled.div`
  .container {
    box-sizing: border-box;
    .noticeDetailBody {
      .noticeDetailTitleBox {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .noticeDetailImportant {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #fee0e0;
          color: #ef4444;
          border-radius: 4px;
        }
        .noticeDetailCategory {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${(props) => props.backGroundColor};
          color: ${(props) => props.color};
          padding: 3px 8px;
          font-size: 9px;
          border-radius: 4px;
          box-sizing: border-box;
        }
      }
      .noticeDetailContext {
        width: 100%;
        box-sizing: border-box;
      }
      .noticeDetailButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 77px;
        height: 32px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-top: auto;
        font-family: "medium";
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
      }
    }
    .title {
      display: none;
    }
  }
  @media ${device.pc} {
    width: 100%;
    background-color: #f0f1f2;
    padding: 80px 0;
    min-height: calc(100% - 353px);
    box-sizing: border-box;
    .onlyForMobile {
      display: none !important;
    }
    .container {
      width: 1228px;
      margin: 0 auto;
      .middleTitleBox {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: "bold";
        line-height: 41px;
        .arrow {
          width: 28px;
          height: 28px;
          margin-right: 19px;
          cursor: pointer;
        }
      }
      .noticeDetailBody {
        padding: 60px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin-top: 60px;
        .noticeDetailTitleBox {
          .noticeDetailImportant {
            padding: 4px 8px;
            border-radius: 4px;
            width: 37px;
            font-size: 12px;
            font-family: "regular";
            line-height: 16px;
            box-sizing: border-box;
          }
          .noticeDetailCategory {
            /* display: inline-block; */
            padding: 4px 8px;
            border-radius: 4px;
            /* width : 40px; */
            width: ${(props) => (props.category === "일반" ? "37px" : "48px")};
            font-size: 12px;
            font-family: "regular";
            line-height: 21px;
            box-sizing: border-box;
          }
          .noticeDetailTitle {
            font-size: 22px;
            font-family: "semibold";
            line-height: 33px;
            margin: 12px 0 10px;
          }
          .noticeDetailTime {
            font-size: 16px;
            font-family: "regular";
            line-height: 24px;
            color: #888888;
          }
        }
        .noticeDetailContext {
          min-height: 156px;
          line-height: 160%;
        }
        .noticeDetailImgBox {
          display: grid;
          grid-template-columns: repeat(auto-fill, 96px);
          gap: 12px;
          width: 100%;
          margin: 32px 0 48px;
          .noticeDetailImg {
            width: 96px;
            height: 96px;
            object-fit: cover;
            border-radius: 12px;
            cursor: pointer;
          }
        }
        .greyBar {
          width: 100%;
          height: 1px;
          background-color: rgba(217, 217, 217, 0.5);
          margin: 32px 0;
        }
      }
      .title {
        .arrow {
          cursor: pointer;
        }
      }
    }
  }
  @media ${device.tabMob} {
    min-width: 360px;
    .container {
      width: 100%;
      height: 100%;
      max-width: 731px;
      padding: 16px 24px;
      .middleTitleBox {
        display: flex;
        align-items: center;
        margin: 8px 0 8px;
        padding-bottom: 16px;
        .middleTitle {
          font-size: 19px;
          font-family: "bold";
          line-height: 28px;
        }
        .arrow {
          /* width : 18px; */
          height: 18px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
      .noticeDetailBody {
        .noticeDetailTitleBox {
          min-height: 132px;
          padding: 24px 0 36px;
          .noticeDetailTitle {
            font-family: "medium";
            font-size: 20px;
            line-height: 28px;
            margin: 8px 0 4px;
          }
          .noticeDetailTime {
            font-size: 12px;
            color: #888888;
            line-height: 14px;
          }
          .noticeDetailCategory {
            width: ${(props) => (props.category === "일반" ? "34px" : "42px")};
            height: 21px;
            font-size: 10px;
          }
          .noticeDetailImportant {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fee0e0;
            color: #ef4444;
            width: 32px;
            height: 18px;
            font-size: 9px;
            border-radius: 4px;
          }
        }
        .noticeDetailContext {
          padding: 24px 0;
          min-height: 280px;
        }
        .noticeDetailImgBox {
          display: grid;
          grid-template-columns: repeat(auto-fill, 96px);
          margin-bottom: 61px;
          gap: 12px;
          img {
            width: 96px;
            height: 96px;
            object-fit: cover;
          }
        }
        .greyBar {
          width: calc(100% + 48px);
          margin-left: -24px;
          height: 1px;
          background-color: rgba(217, 217, 217, 0.5);
        }
      }
    }
  }
`;

export default NoticeDetail;
