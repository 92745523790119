import React, { useEffect } from "react";
import { ModalBackD } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";
import chromeIcon from "../img/chrome.svg";
import whaleIcon from "../img/whale.svg";
import edgeIcon from "../img/edge.svg";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { setCookie } from "../Cookie";

const BroswerPopUp = ({ setBrowserModal }) => {
  const goBack = () => {
    setBrowserModal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StBrowserPopUp>
        <div className="bodyBox">
          <div className="titlePart">
            본 사이트는 최적화를 위해
            <br className="onlyForPc" />
            아래 브라우저만 지원하고 있어요.
          </div>
          <div className="suggestBluePart">
            프록토매틱의 안전하고 원활한 서비스 접속을 위해{" "}
            <span>구글 크롬, MS 엣지, 네이버 웨일 브라우저</span>를 사용해
            주세요:)
          </div>
          <div className="suggestPart">
            기존 IE를 계속 사용할 경우 이미지 깨짐, 사이즈 이상 현상 및 접속
            오류를 비롯하여 보안에 취약할 수 있으니 최신 웹브라우저로 업데이트를
            해주세요!
          </div>
          <div className="downLinkPart">
            <div
              className="linkForm"
              onClick={(e) => {
                window.location.href = `https://www.google.com/intl/ko_kr/chrome/`;
                e.preventDefault();
              }}
            >
              <img src={chromeIcon} />
              <div>구글 크롬</div>
              <span>다운로드</span>
            </div>
            <div
              className="linkForm"
              onClick={(e) => {
                window.location.href = `https://www.microsoft.com/ko-kr/edge/download?form=MA13FJ`;
                e.preventDefault();
              }}
            >
              <img src={edgeIcon} />
              <div>MS 엣지</div>
              <span>다운로드</span>
            </div>
            <div
              className="linkForm"
              onClick={(e) => {
                window.location.href = `https://whale.naver.com/ko/download/win/`;
                e.preventDefault();
              }}
            >
              <img src={whaleIcon} />
              <div>네이버 웨일</div>
              <span>다운로드</span>
            </div>
          </div>
        </div>
        <div className="buttonBox">
          <div
            onClick={() => {
              setBrowserModal(false);
              setCookie("browserPopup", true);
            }}
          >
            오늘 그만 보기
          </div>
          <div className="greyBar" />
          <div
            onClick={() => {
              setBrowserModal(false);
            }}
          >
            닫기
          </div>
        </div>
      </StBrowserPopUp>
    </>
  );
};

const StBrowserPopUp = styled.div`
  position: fixed;
  background-color: #eeeff0;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  .bodyBox {
    padding: 32px 24px;
    display: grid;
    .titlePart {
      font-size: 23px;
      font-family: "bold";
      line-height: 34px;
      word-break: keep-all;
    }
    .suggestBluePart {
      color: #2276dc;
      line-height: 24px;
      margin: 24px 0 14px;
      span {
        font-family: "bold";
      }
    }
    .suggestPart,
    .suggestBluePart {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .downLinkPart {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      margin-top: 28px;
      .linkForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-family: "regular";
        line-height: 18px;
        border-radius: 9.176px;
        background: #fff;
        padding: 12.5px;
        cursor: pointer;
        img {
          width: 29px;
          height: 29px;
          margin-bottom: 6px;
        }
        span {
          color: #2276dc;
        }
      }
    }
  }
  .buttonBox {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    padding: 0 24px;
    height: 60px;
    align-items: center;
    background-color: white;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    .greyBar {
      width: 1px;
      height: 14px;
      background-color: #d6d9dd;
    }
  }
  @media ${device.pcTab} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 560px;
    z-index: 100;
    .bodyBox {
      padding: 46px;
      .titlePart {
        font-size: 30px;
        line-height: 44px;
      }
      .suggestBluePart {
        /* font-size: 16px; */
        margin: 30px 0 15px;
      }
      .downLinkPart {
        margin-top: 30px;
        .linkForm {
          width: 125px;
          height: 125px;
          box-sizing: border-box;
          font-size: 16px;
          line-height: 24px;
          img {
            width: 38px;
            height: 38px;
          }
        }
      }
    }
  }
  @media ${device.mobile} {
    .onlyForPc {
      display: none;
    }
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 100;
    border-radius: 32px 32px 0px 0px;
  }
`;

export default BroswerPopUp;
