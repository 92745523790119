import React from "react";
import ModalOneButtonTemplate from "../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../atomic/molecule/ModalHeader";
import ConfirmButton from "../atomic/atom/ConfirmButton";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

interface SignupProps {
  modalOn: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignupRequestComplete: React.FC<SignupProps> = (props) => {
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title="회원가입 신청 완료" />
      <DivTwoPartImprove
        lineheight={24}
        font_size={16}
        margin="20px 0 24px"
        pc_margin="48px 0"
        pc_font_size={18}
        pc_lineheight={27}
      >
        프록토매틱 서비스 관리자에게 기업 회원 가입 신청서를 제출했어요. 24시간
        이내 확인 후 연락드릴게요.
      </DivTwoPartImprove>
      <ConfirmButton
        text="확인"
        font_size={17}
        font_family="medium"
        margin="0 auto"
        tab_width="158px"
        tab_height={35}
        tab_fontsize={18}
        tab_fontcolor="#0072DE"
        onclick={() => props.modalOn(false)}
        cursor="pointer"
      />
    </ModalOneButtonTemplate>
  );
};

export default SignupRequestComplete;
