import React from "react";
import styled from "styled-components";
import style from "../modal/modal.module.css";

//img
import completionGreen_svg from "../img/CompletionGreen.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";

const BasicFinish = ({
  content,
  buttonText,
  setSignUpInputModal,
  setResetPasswordModal,
}) => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  return (
    <BasicFinishContent>
      <section>
        <h1>
          AI 온라인 시험&nbsp; <br className="onlyForTabMob" />
          자동 관리감독 서비스{" "}
        </h1>
        <h3>
          어렵고 피곤한 시험 감시와 검증은 그만!
          <br />
          이젠 프록토매틱에게 맡기세요.
        </h3>
      </section>
      <section>
        <img src={completionGreen_svg} alt="" />
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </section>
      <div className="buttonArea">
        <button
          className={style.blackLineButton}
          onClick={() => {
            if (buttonText === "재설정 완료") {
              setResetPasswordModal(false);
            } else {
              navigate(`/`);
              setSignUpInputModal(false);
            }
          }}
        >
          {buttonText}
        </button>
      </div>
    </BasicFinishContent>
  );
};

const BasicFinishContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 44px;
  box-sizing: border-box;
  height: 100%;
  section:nth-child(2) {
    margin: 108px 0 146px;
  }
  h1 {
    font-size: 26px;
    line-height: 38px;
    font-family: "Bold";
    font-weight: normal;
    margin: 0;
  }
  h3 {
    font-size: 16px;
    line-height: 24px;
    font-family: "Regular";
    font-weight: normal;
    margin: 16px 0 0;
  }
  img {
    width: 61px;
    height: 61px;
    vertical-align: top;
  }
  p {
    font-size: 22px;
    line-height: 33px;
    font-family: "Medium";
    padding-top: 22px;
    span {
      color: #2276dc;
    }
  }
  div.buttonArea {
    margin-bottom: 20px;
    /* margin-top: auto; */
  }

  @media (max-width: 1139px) {
    .onlyForTabMob {
      display: none;
    }
    h1 {
      font-size: 22px;
      line-height: 33px;
    }
    h3 {
      font-size: 15px;
      line-height: 22px;
      color: #6e6e6e;
      margin: 15px 0 0;
    }

    img {
      width: 42px;
      height: 42px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
      padding-top: 14px;
    }
    section:nth-child(2) {
      margin: 75px 0 0;
    }
    div.buttonArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      width: 100%;
      margin-bottom: 22px;
      padding-top: 170px;
      button {
        width: 204px;
        height: 42px;
        min-height: 42px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

export default BasicFinish;
