import React from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import styled from "styled-components";
import { device } from "../../hooks/device";
import TextLine from "../atom/TextLine";

interface ListProps extends ListStyle {
  listArray: string[];
}

interface ListStyle {
  marker_gap?: string;
  margin?: string;
  fontsize?: number;
  pc_fontsize?: number;
}

const WarningList: React.FC<ListProps> = (props) => {
  return (
    <StList
      marker_gap={props.marker_gap}
      margin={props.margin}
      fontsize={props.fontsize}
      pc_fontsize={props.pc_fontsize}
    >
      <DivTwoPartImprove
        padding="0 0 0 6px"
        font_size={16}
        lineheight={24}
        font_family="semibold"
      >
        <TextLine text="※" fontFamily="light" fontsize={20} /> 주의사항
      </DivTwoPartImprove>
      <ul>
        {props.listArray.map((value, index) => (
          <li key={index}>{value}</li>
        ))}
      </ul>
    </StList>
  );
};

const StList = styled.div<ListStyle>`
  margin: ${(props) => props.margin};
  ul {
    padding: 0 0 0 25px;
    line-height: 28px;
    margin: 4px 0 0px;
    font-size: ${(props) => props.fontsize}px;
    li ::marker {
      margin: ${(props) => props.marker_gap};
    }
  }
  @media ${device.pc} {
    font-size: ${(props) => props.pc_fontsize}px;
  }
`;

export default WarningList;
