import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const AbandonMember = ({ setAbandonModal, disconnect }) => {
  useScrollLockThree();

  const goBack = () => {
    setAbandonModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <ModalBackD />
      <StAbandonMember>
        <ModalHeaderD title="구성원 계정과 연결 해제" />
        <div className="bodyPart">
          해당 구성원은 자동으로 개인 회원으로 전환되며, 예정되어 있던 시험들은
          전부 취소돼요. 취소된 시험은 결제한 방법과 동일하게 자동으로 환불처리
          돼요.
        </div>
        <div className="buttonBox">
          <div className="cancelButton" onClick={() => window.history.back()}>
            취소
          </div>
          <div className="greyBar" />
          <div
            className="confirmButton"
            onClick={() => {
              disconnect();
            }}
          >
            연결 해제
          </div>
        </div>
      </StAbandonMember>
    </>
  );
};

const StAbandonMember = styled.div`
  position: fixed;
  z-index: 53;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    gap: 48px;
    .bodyPart {
      line-height: 27px;
      font-size: 18px;
      font-family: "medium";
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      gap: 21px;
      .columnBar {
        display: none;
      }
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        box-sizing: border-box;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #20315b;
        border-radius: 43px;
        color: white;
        width: 204px;
        height: 52px;
        font-size: 17px;
        font-family: "medium";
        line-height: 25px;
      }
    }
  }
  @media ${device.tabMob} {
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 24px;
    .bodyPart {
      line-height: 24px;
      margin-bottom: 24px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 41px;
      .cancelButton {
        width: 63px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
      }
      .confirmButton {
        width: 67px;
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        color: #0072de;
      }
      .greyBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
    }
  }
  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4.5px 0;
    box-sizing: border-box;
    .cancelButton {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
`;

export default AbandonMember;
