import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { MobileModalBack, ModalBack, ModalHeaderD } from "../components/Style";

const CreditQuestion = ({ setQuestionModal }) => {
  const goBack = () => {
    setQuestionModal(false);
  };

  useEffect(() => {
    console.log("nnnn");
    window.history.pushState("", null, window.location.pathname);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <MobileModalBack />
      <StModal>
        <ModalHeaderD title="적립금 운용 정보" />
        <div className="titleInfo">
          적립금 운용(충전, 결제, 공유)은 기업 회원 대상 전용 서비스에요. 아래와
          같은 혜택을 통해 보다 저렴한 서비스 이용과 편리한 시험 운영이
          가능해요.
        </div>
        <div className="middlePart">※ 기업 회원 대상 전용 서비스 </div>
        <ul>
          <li>적립금 충전, 결제, 공유 기능 제공</li>
          <li>적립금 충전 시 기업회원 우대 혜택 제공</li>
          <li>기업 구성원 모두가 함께 서비스 이용 가능</li>
          <li>기업 관리자와 구성원 간 기업 적립금을 자유롭게 공유 가능</li>
          <li>실제 응시 인원 만큼만 적립금 차감 (후불제)</li>
        </ul>
        <div
          className="confirmPart"
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  z-index: 70;
  background: #ffffff;
  .titleInfo {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
  }
  .confirmPart {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    cursor: pointer;
  }
  ul {
    padding-left: 20px;
    margin-top: 4px;
    line-height: 24px;
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .confirmPart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      color: black;
      margin: 48px auto 0;
    }
    .middlePart {
      margin-top: 48px;
    }
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 344px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    .confirmPart {
      color: #0072de;
      margin: 0 auto;
    }
    .middlePart {
      margin-top: 20px;
    }
  }
`;

export default CreditQuestion;
