import React, { useEffect, useState } from "react";
import dayjs, { locale } from "dayjs";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import styled from "styled-components";
import back from "../img/backMonth.svg";
import forward from "../img/forwardMonth.svg";

// Noah
import isBetweenPlugin from "dayjs/plugin/isBetween";
import { useTranslation } from 'react-i18next';
import useToast from '../hooks/useToast.js';

const Calendar = ({
  tempTime,
  setTime,
  isFor,
  tempStart,
  setEndClick,
  tempEnd,
  open,
  setOpen,
}) => {
  const tempTiming = dayjs(tempTime);
  const tempStarting = dayjs(tempStart);
  const tempEnding = dayjs(tempEnd);
  const { t } = useTranslation();
  const showToast = useToast();

  useEffect(() => {
    if (tempStarting.diff(tempEnding, "m") > 0) {
      setTime(
        tempStarting.add(1, "hour").format("YYYY-MM-DD H:mm"),
        tempStarting.add(1, "hour").format("YYYY-MM-DD A hh:mm"),
        "end"
      );
      if (isFor === "end") {
        // setEndClick(true);
        setChooseMonth(tempEnding.month());
        setChooseDay(tempEnding.date());
      }
    }
  }, [tempStart, tempEnd]);

  const now = dayjs().locale({
    ...locale,
  });
  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  // Noah
  dayjs.extend(isBetweenPlugin);

  const [chooseDay, setChooseDay] = useState(tempTiming.date());
  const [chooseMonth, setChooseMonth] = useState(tempTiming.month());
  const [currentTime, setCurrentTime] = useState(tempTiming);
  const [arrayOfDays, setArrayOfDays] = useState([]);

  // console.log(tempStarting);

  const nextMonth = () => {
    const plus = currentTime.add(1, "month");
    setCurrentTime(plus);
  };

  const prevMonth = () => {
    const minus = currentTime.subtract(1, "month");
    setCurrentTime(minus);
  };

  useEffect(() => {
    if (isFor === "end") {
      setCurrentTime(tempEnding);
    } else {
    }
  }, [tempStart, chooseMonth]);

  /** 달과 다음달로 넘어가는 버튼 만들어주기 */
  const renderHeader = () => {
    const temp = currentTime;
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
          alignItems: "center",
          width: "200px",
          gap: "50px",
          margin: "0 auto 15px",
        }}
      >
        <img
          src={back}
          onClick={() => {
            prevMonth();
          }}
          style={{ cursor: "pointer" }}
        />
        <div style={{ fontSize: "22px", fontFamily: "medium" }}>
          {temp.format("YYYY.M")}
        </div>
        <img
          src={forward}
          onClick={() => {
            nextMonth();
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  };

  /** 요일을 표시해주기 */
  const renderDays = () => {
    const dateFormat = "ddd";
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <div key={i} className="eachDays">
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="days">{days}</div>;
  };

  const formateDateObject = (date) => {
    const clonedObject = { ...date.toObject() };
    // console.log(clonedObject);
    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentTime.month(),
      isCurrentDay: date.isToday(),
      // Noah
      isSelectableDay: dayjs(date).isBetween(dayjs(), dayjs().add(2, "month"), "day", []),
    };
    return formatedObject;
  };

  // 날짜 받아오기
  const getAllDays = () => {
    let currentDate = currentTime.startOf("month").weekday(0);
    const nextMonth = currentTime.add(1, "month").month();
    let allDates = [];
    let weekDates = [];
    let weekCounter = 1;
    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);
      weekDates.push(formated);
      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }
      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }
    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentTime]);

  useEffect(() => {
    if (isFor === "end") {
      // setEndClick(true);
      setChooseMonth(tempEnding.month());
      setChooseDay(tempEnding.date());
      // setCurrentTime(tempTiming);
    } else {
      setChooseDay(tempStarting.date());
    }
  }, [tempTiming]);

  const renderCells = () => {
    const rows = [];
    let days = [];
    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        days.push(
          <div
            key={i}
            className="eachDay"
            style={
              chooseDay === d.day && d.isCurrentMonth && chooseMonth === d.month
                ? { color: "#FFFFFF", backgroundColor: "#20315B" }
                : {}
            }
            onClick={() => {
              // Noah
              // if (d.isCurrentMonth) {
              if (d.isCurrentMonth && d.isSelectableDay) {
                setChooseDay(d.day);
                setChooseMonth(d.month);
              } else if(d.isCurrentMonth && !d.isSelectableDay) {
                showToast({
                  message: t(`errorCode.timezone_010`),
                });
              }
              // setEndClick(true);
            }}
          >
            <div
              onClick={() => {
                // Noah
                // if (d.isCurrentMonth) {
                if (d.isCurrentMonth && d.isSelectableDay) {
                  if (isFor === "end") {
                    setEndClick(true);
                  }
                  setTime(
                    tempTiming
                      .set("year", d.year)
                      .set("month", currentTime.month())
                      .set("date", d.day)
                      .format("YYYY-MM-DD H:mm"),
                    tempTiming
                      .set("year", d.year)
                      .set("month", currentTime.month())
                      .set("date", d.day)
                      .format("YYYY-MM-DD A hh:mm"),
                    isFor
                  );
                  setOpen(false);
                }
              }}
              style={
                // d.isCurrentMonth ? { cursor: "pointer" } : { color: "#BDBDBD" }
                (d.isCurrentMonth && d.isSelectableDay) ? { cursor: "pointer" } : { color: "#BDBDBD" }
              }
            >
              {d.day}
            </div>
          </div>
        );
      });
      rows.push(
        <div key={index} className="rows">
          {days}
        </div>
      );
      days = [];
    });
    return <div className="cells">{rows}</div>;
  };

  return (
    <>
      <StCalendar open={open}>
        {renderHeader()}
        {renderDays()}
        {renderCells()}
      </StCalendar>
      <StBack
        open={open}
        className="backPage"
        onClick={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

const StCalendar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .days {
    display: grid;
    grid-template-columns: repeat(7, auto);
    gap: 5px;
    .eachDays {
      width: 33px;
      margin-bottom: 10px;
      font-family: "medium";
      text-align: center;
    }
  }
  .cells {
    display: grid;
    flex-direction: column;
    gap: 2px;
    .rows {
      display: grid;
      grid-template-columns: repeat(7, auto);
      align-items: center;
      gap: 5px;
      .eachDay {
        text-align: center;
        line-height: 33px;
        width: 33px;
        height: 33px;
        border-radius: 33px;
        /* background-color: red; */
      }
    }
  }
`;

const StBack = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export default Calendar;
