import { useRef, useState } from "react";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import cancelButton from "../../../img/cancelButton.svg";
import Img from "../../../atomic/atom/Img";
import CheckCircleList from "../../../atomic/organism/CheckCircleList";
import TableBasedOnRow from "../../../atomic/organism/TableBasedOnRow";
import OrangeButton from "../../../atomic/atom/OrangeButton";
import DirectCharge from "../../../atomic/template/DirectCharge";
import TesterCountCharge from "../../../atomic/template/TesterCountCharge";
import WarningList from "../../../atomic/molecule/WarningList";
import customAxios from "../../../api/handler";
import ModalPageTemplate from "../../../atomic/template/ModalPageTemplate";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../atomic/atom/Button";
import ScrollFade from "../../../hooks/scrollFade";

const CreditChargeRequest = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const scrollRef = useRef<HTMLDivElement>();
  const [scrollHide, setScrollHide] = useState<boolean>(true);
  const [chargeWay, setChargeWay] = useState<number>(0);
  const [chargeAmount, setChargeAmount] = useState<number>(0);
  const chargeText: string[] = ["충전 금액 직접 입력", "응시자 인원으로 계산"];
  const tableContent: string[][] = [
    // ["Basic", "Pro"],
    ["Basic"],
    // ["10분당 1,000원 (10C)", "시간당 9,000원 (10분당 1,500원)"],
    ["10분당 1,000원 (10C)"],
  ];
  const tableHeaderColor: string[] = ["#2276DC", "#6B00F2"];
  const warningList = [
    "관리자가 확인후 인보이스를 보내드려요.",
    "환불 유효기간 : 구매일로부터 최대 6개월",
    "환불 유효기간 만료일의 시간은 당일 자정(00:00)을 기준으로 해요.",
    "환불 불가 적립금은 기존 적립금과 동일하게 사용이 가능하나 환불은 가능하지 않아요.",
  ];
  const chargeRequest = () => {
    customAxios
      .post(`/api/v1/payments/enterprise/manager/credits/charge-request`, {
        currencyAmount: chargeAmount,
        credits: chargeAmount / 100,
        isTesterCountBasedCalc: chargeWay,
      })
      .then(() => {
        let temp = pathname.split("/")[1];
        if (temp === "myCredit") {
          navigate(`/myCredit/chargeRequestConfirm`);
        } else if (temp === "createExamFinal") {
          navigate(`/createExamFinal/chargeRequestConfirm`);
        }
      })
      .catch(() => {});
  };

  ScrollFade({ ref: scrollRef, setScrollHide: setScrollHide });

  // Noah, 중복
  // useScrollLockThree();

  return (
    <ModalPageTemplate pc_padding="60px 30px 60px 60px">
      <DivTwoPartImprove
        // height={788}
        divRef={scrollRef}
        pc_height={788}
        // overflow="hidden"
        overflowX="hidden"
        padding="0"
        pc_padding={scrollHide ? "0 33px 0 0" : "0 30px 0 0"}
        scroll_width="3px"
        scroll_hide={scrollHide}
      >
        <DivTwoPartImprove
          display="grid"
          margin="0 0 20px 0"
          padding="28px 0 0 0"
          pc_padding="0"
          pc_margin="0 0 64px 0"
        >
          <Img
            src={cancelButton}
            pc_display="flex"
            display="none"
            margin="8.5px 0 8.5px auto"
            onclick={() => window.history.back()}
            cursor="pointer"
          />
          <TextLine
            text="적립금 충전 요청"
            fontFamily="bold"
            fontsize={20}
            lineheight={30}
            pc_fontsize={28}
            pc_lineheight={41}
            fontcolor="#2276DC"
            textAlign="center"
          />
        </DivTwoPartImprove>
        <CheckCircleList
          list={chargeText}
          choosed={chargeWay}
          setChoosed={setChargeWay}
          item_width="100%"
          display="grid"
          list_justify="center"
          item_justify="start"
          gap={0}
          pc_gap={40}
          grid_template_column="156px 156px"
          pc_grid_template_column="191px 191px"
          fontsize={14}
          pc_fontsize={18}
          pc_item_width="191px"
          fontfamily="medium"
        />
        <TableBasedOnRow
          tableContent={tableContent}
          rowCellCount={1}
          columnCellCount={3}
          cellHeight={27}
          borderSize={1}
          borderColor="#BDBDBD"
          tableHeaderColor={tableHeaderColor}
          margin="30px 0 0 0"
        />
        {chargeWay === 0 ? (
          <DirectCharge setCharge={setChargeAmount} />
        ) : (
          <TesterCountCharge setChargeAmount={setChargeAmount} />
        )}
        <WarningList
          listArray={warningList}
          marker_gap="0 10px 0 0"
          margin="30px 0 48px"
          fontsize={16}
          pc_fontsize={16}
        />
        <DivTwoPartImprove display="flex">
          <Button
            text="적립금 충전 요청"
            width="204px"
            height={42}
            margin="0 auto"
            font_size={14}
            font_family="medium"
            backgroundcolor={
              chargeAmount > 0 && chargeAmount % 10000 === 0
                ? "#ed893e"
                : "#D9D9D9"
            }
            font_color="white"
            border="none"
            border_radius={43}
            pc_height={52}
            pc_fontsize={17}
            cursor={
              chargeAmount > 0 && chargeAmount % 10000 === 0
                ? "pointer"
                : "default"
            }
            disabled={chargeAmount <= 0 || chargeAmount % 10000 !== 0}
            onclick={() => chargeRequest()}
          />
        </DivTwoPartImprove>
      </DivTwoPartImprove>
    </ModalPageTemplate>
  );
};

export default CreditChargeRequest;
