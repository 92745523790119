import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import arrow from "../img/arrow.svg";
import spread from "../img/spread.svg";
import plus from "../img/plus.svg";
import blueCheck from "../img/blueCheck.svg";
import InviteEnterprise from "../modal/InviteEnterprise";
import useGetEnterpriseInvite from "../hooks/useGetEnterpriseInvite";
import useGetEnterPriseTestOnGoing from "../hooks/useGetEnterpriseTestOnGoing";
import dots from "../img/3dot.svg";
import useGetEnterpriseMemberList from "../hooks/useGetEnterpriseMemberList";
import { device } from "../hooks/device";

const MemberManage = ({ setMyPageScroll }) => {
  const navigate = useNavigate();
  const [filterOn, setFilterOn] = useState(false);
  const [filterChoose, setFilterChoose] = useState(0);
  const [inviteOn, setInviteOn] = useState(false);
  const [invited, setInvited] = useState(false);
  const filters = [
    "상위 사용량순",
    "하위 사용량순",
    "최근 사용순",
    "구성원 등록순",
  ];
  const [sort, setSort] = useState("high");
  const { data: invitation } = useGetEnterpriseInvite({ invited });
  const { data: testOnGoing } = useGetEnterPriseTestOnGoing();
  const { data: memberList } = useGetEnterpriseMemberList({ sort });

  // modal
  const [littleModal, setLittleModal] = useState(false);

  return (
    <>
      {inviteOn && (
        <InviteEnterprise
          setInviteOn={setInviteOn}
          setInvited={setInvited}
          invited={invited}
        />
      )}
      <StManage filterOn={filterOn} filterChoose={filterChoose}>
        <div className="bodyPart">
          <div className="headerBox">
            <div className="headerLeft">
              <img
                style={{ cursor: "pointer" }}
                src={arrow}
                alt=""
                onClick={() => {
                  // 마이페이지로 변경
                  // navigate(`/myCredit`);
                  navigate(`/myPage`);
                }}
              />
              <div>구성원 관리하기</div>
            </div>
            <div className="headerRight">
              <div
                className="rightButton"
                onClick={() => {
                  window.history.pushState(null, "", window.location.href);
                  setInviteOn(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={plus} alt="" />
                <div className="onlyForPc">구성원 초대하기</div>
              </div>
              <div style={{ display: "flex" }}>
                <img
                  src={dots}
                  alt=""
                  onClick={() => {
                    setLittleModal(true);
                  }}
                />
                {littleModal ? (
                  <>
                    <div
                      onClick={() => setLittleModal(false)}
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "10",
                      }}
                    />
                    <div className="rightModal">
                      <div
                        onClick={() => {
                          navigate(`/myPage`);
                          setMyPageScroll("customerCenter");
                        }}
                      >
                        고객센터
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="connectBox">
            <div className="connectHead">
              <div className="headLeft">연결된 구성원</div>
              <div className="headRight">
                <span>{testOnGoing?.connectedMember?.connectedCount}</span>명
              </div>
            </div>
            <div className="connectBody">
              <div className="bodyItem">
                <div className="itemUp">시험진행 완료</div>
                <div className="itemDown">
                  {testOnGoing?.connectedMember?.success}
                  <span>건</span>
                </div>
              </div>
              <div className="columnBar" />
              <div className="bodyItem">
                <div className="itemUp">시험진행 예정</div>
                <div className="itemDown">
                  {testOnGoing?.connectedMember?.pending}
                  <span>건</span>
                </div>
              </div>
            </div>
          </div>
          <div className="downBasket">
            <div className="memberBox">
              <div className="memberHead">
                <div className="headLeft">구성원 계정과 사용량</div>
                <div className="headRight">
                  <div
                    className="rightFilter"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFilterOn(true);
                    }}
                  >
                    {filters[filterChoose]}
                    <img src={spread} alt="" />
                  </div>
                  <div className="filterModal">
                    <div
                      className="modalItemOne"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(0);
                        setSort("high");
                      }}
                    >
                      <div>상위 사용량순</div>{" "}
                      {filterChoose === 0 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemTwo"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(1);
                        setSort("low");
                      }}
                    >
                      하위 사용량순{" "}
                      {filterChoose === 1 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemThree"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(2);
                        setSort("latest");
                      }}
                    >
                      최근 사용순{" "}
                      {filterChoose === 2 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemFour"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(3);
                        setSort("registered");
                      }}
                    >
                      구성원 등록순{" "}
                      {filterChoose === 3 && <img src={blueCheck} alt="" />}
                    </div>
                  </div>
                  <div
                    className="filterBack"
                    onClick={() => setFilterOn(false)}
                  />
                </div>
              </div>
              <div className="thickLine onlyForPc" />
              <div className="listTag onlyForPc">
                <div className="tagLeft">구성원 계정</div>
                <div className="tagRight">사용 금액</div>
              </div>
              <div className="blackLine onlyForPc" />
              {memberList?.groupTradeList.length > 0 ? (
                <div className="memberBody">
                  {memberList?.groupTradeList.map((value, index) => (
                    <div
                      className="bodyItem"
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/memberAccount/${value?.hostChildUuid}`);
                      }}
                    >
                      <div className="itemLeft">
                        <div>{value.hostChildName}</div>
                        <span>{value.hostChildEmail}</span>
                      </div>
                      <div className="itemRight">
                        {Number(value?.hostUsageAmounts)?.toLocaleString()}{" "}
                        <span>C</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "151px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#BDBDBD",
                  }}
                >
                  구성원이 없음
                </div>
              )}
            </div>
            <div className="requestBox">
              <div className="requestHead">
                연결 요청한 구성원 계정 ({invitation?.inviteList?.length})
              </div>
              <div className="thickLine onlyForPc" />
              <div className="listTag onlyForPc">
                <div className="tagLeft">구성원 계정</div>
                <div className="tagRight">수락 여부</div>
              </div>
              <div className="blackLine onlyForPc" />
              <div className="requestBody">
                {invitation?.inviteList?.length !== 0 &&
                  invitation?.inviteList?.map((value, index) => {
                    return (
                      <div className="bodyItem" key={index}>
                        <div>{value.hostChildEmail}</div>
                        {value?.isVerifiedHostEmailInvite === "pending" ? (
                          <span>수락대기</span>
                        ) : (
                          <span style={{ color: "#C83B38" }}>수락거절</span>
                        )}
                      </div>
                    );
                  })}
              </div>
              {invitation?.inviteList?.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "151px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#BDBDBD",
                  }}
                >
                  연결 요청한 계정이 없음
                </div>
              )}
            </div>
          </div>
        </div>
      </StManage>
    </>
  );
};

const StManage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: auto auto;
  background-color: #f0f1f2;
  @media ${device.pc} {
    .bodyPart {
      width: 1228px;
      margin: 80px auto;
      .headerBox {
        margin-bottom: 60px;
        .headerLeft {
          font-size: 28px;
          img {
            width: 28px;
            height: 28px;
          }
        }
        .headerRight {
          display: grid;
          grid-template-columns: auto auto;
          position: relative;
          gap: 15px;
          align-items: center;
          .rightButton {
            display: grid;
            grid-template-columns: auto auto;
            justify-content: center;
            align-items: center;
            width: 177px;
            height: 41px;
            gap: 12px;
            background: #ffffff;
            border-radius: 24px;
            font-family: "medium";
            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .connectBox {
        padding: 60px;
        box-sizing: border-box;
        width: 865px;
        .connectHead {
          font-size: 22px;
          font-family: "bold";
          line-height: 33px;
          .headRight {
            span {
              margin-right: 4px;
            }
          }
        }
        .connectBody {
          .bodyItem {
            .itemUp {
              font-size: 13px;
              font-family: "medium";
            }
            .itemDown {
              font-size: 22px;
              span {
                font-size: 15px;
                font-family: "regular";
                line-height: 28px;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .downBasket {
        width: 865px;
        padding: 60px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin: 60px auto 0;
        .thickLine {
          width: 100%;
          height: 4px;
          background-color: black;
          margin-top: 16px;
        }
        .listTag {
          display: flex;
          align-items: center;
          height: 52px;
          font-family: "medium";
          .tagLeft {
            display: flex;
            justify-content: center;
            width: 585px;
          }
          .tagRight {
            display: flex;
            justify-content: center;
            width: 160px;
          }
        }
        .blackLine {
          width: 100%;
          height: 1px;
          background-color: black;
        }
        .memberBox {
          .memberHead {
            .headLeft {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
              color: black;
            }
            .headRight {
              display: flex;
              align-items: center;
              font-size: 16px;
              font-family: "medium";
              .rightFilter {
                img {
                  width: 12.56px;
                  height: 7px;
                }
              }
            }
          }
          .memberBody {
            .bodyItem {
              border-bottom: 1px solid #d9d9d9;
              .itemLeft {
                display: grid;
                flex-direction: column;
                gap: 4px;
                padding: 14px 0;
                div {
                  font-size: 16px;
                  font-family: "semibold";
                  line-height: 24px;
                }
                span {
                  font-size: 14px;
                  line-height: 20px;
                  color: #55595f;
                  font-family: "regular";
                }
              }
              .itemRight {
                padding: 14px 25px;
                box-sizing: border-box;
              }
            }
          }
        }
        .requestBox {
          .requestHead {
            font-size: 18px;
            font-family: "semibold";
            line-height: 27px;
            color: black;
          }
          .requestBody {
            .bodyItem {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #d9d9d9;
              height: 52px;
              div {
                flex-grow: 1;
              }
              span {
                width: 160px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .bodyPart {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
    .headerBox {
      padding: 24px;
      .headerLeft {
        font-size: 19px;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .headerRight {
        display: grid;
        grid-template-columns: auto auto;
        position: relative;
        gap: 15px;
        align-items: center;
        .rightButton {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .connectBox {
      width: calc(100% - 24px);
      padding: 24px;
      .connectHead {
        font-size: 15px;
        font-family: "bold";
        .headRight {
          span {
            margin-right: 4px;
          }
        }
      }
      .connectBody {
        .bodyItem {
          .itemUp {
            font-size: 12px;
          }
          .itemDown {
            font-size: 12px;
          }
        }
      }
    }
    .memberBox {
      margin-top: 36px;
      .memberHead {
        padding: 0 24px;
        .headLeft {
          color: #909090;
          font-size: 14px;
          font-family: "medium";
        }
        .headRight {
          font-size: 14px;
          font-family: "regular";
        }
      }
      .memberBody {
        filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
        border-radius: 24px;
        background-color: white;
        .bodyItem {
          padding: 14px 24px;
          .itemLeft {
            span {
              font-size: 13px;
              font-family: "regular";
              line-height: 19px;
              color: #909090;
            }
          }
        }
      }
    }
    .requestBox {
      margin-bottom: 38px;
      .requestHead {
        padding: 0 24px;
      }
      .requestBody {
        filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
        .bodyItem {
          padding: 14px 24px;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-family: "bold";
    line-height: 28px;
    .headerLeft {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 19px;
    }
    .headerRight {
      display: grid;
      grid-template-columns: auto auto;
      position: relative;
      gap: 15px;
      align-items: center;
      .rightModal {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        width: 168px;
        background-color: #ffffff;
        border-radius: 26px;
        font-size: 16px;
        font-family: "regular";
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        div {
          display: flex;
          align-items: center;
          padding: 15px 24px;
          box-sizing: border-box;
        }
      }
      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
  .connectBox {
    box-sizing: border-box;
    margin: 0 auto;
    background-color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .connectHead {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .headLeft {
        line-height: 18px;
      }
      .headRight {
        margin-left: auto;
        span {
          color: #2276dc;
          font-size: 26px;
          font-family: "extrabold";
        }
      }
    }
    .connectBody {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 85px;
      padding: 16px;
      box-sizing: border-box;
      border: 0.6px solid #bdbdbd;
      border-radius: 8px;
      .columnBar {
        width: 0.5px;
        height: 53px;
        background-color: #d6d9dd;
      }
      .bodyItem {
        display: grid;
        width: 49%;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 7px;
        .itemUp {
          color: #818181;
          font-family: "medium";
          line-height: 18px;
        }
        .itemDown {
          font-family: "bold";
          font-size: 19px;
          line-height: 28px;
          span {
            font-family: "regular";
          }
        }
      }
    }
  }
  .memberBox {
    .memberHead {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      line-height: 21px;
      margin-bottom: 5px;
      .headRight {
        position: relative;
        img {
          margin-left: 10px;
        }
        .filterBack {
          position: fixed;
          display: ${(props) => (props.filterOn ? "" : "none")};
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
        .filterModal {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          display: ${(props) => (props.filterOn ? "flex" : "none")};
          flex-direction: column;
          width: 168px;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          font-size: 16px;
          line-height: 19px;
          img {
            width: 19px;
            height: 19px;
            margin-left: auto;
          }
          .modalItemOne {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
            color: ${(props) =>
              props.filterChoose === 0 ? "#0381FE" : "black"};
            cursor: pointer;
          }
          .modalItemTwo {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
            color: ${(props) =>
              props.filterChoose === 1 ? "#0381FE" : "black"};
            cursor: pointer;
          }
          .modalItemThree {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
            color: ${(props) =>
              props.filterChoose === 2 ? "#0381FE" : "black"};
            cursor: pointer;
          }
          .modalItemFour {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
            color: ${(props) =>
              props.filterChoose === 3 ? "#0381FE" : "black"};
            cursor: pointer;
          }
        }
      }
    }
    .memberBody {
      display: flex;
      flex-direction: column;
      .bodyItem {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .itemLeft {
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-family: "semibold";
          line-height: 24px;
        }
        .itemRight {
          font-size: 16px;
          font-family: "semibold";
          line-height: 24px;
          span {
            font-size: 13px;
            font-family: "regular";
            line-height: 19px;
          }
        }
      }
    }
  }
  .requestBox {
    margin-top: 36px;
    .requestHead {
      display: flex;
      box-sizing: border-box;
      line-height: 21px;
      margin-bottom: 5px;
      font-size: 14px;
      font-family: "medium";
      color: #909090;
    }
    .requestBody {
      display: flex;
      flex-direction: column;
      border-radius: 24px;
      background-color: white;
      .bodyItem {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        width: 100%;
        box-sizing: border-box;
        span {
          color: #2276dc;
        }
      }
    }
  }
`;

export default MemberManage;
