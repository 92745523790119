import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const AutonomyOut = ({ setAutonomyOutModal }) => {
  const goBack = () => {
    setAutonomyOutModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
      // window.history.back();
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StAutonomy>
        <ModalHeaderD title="응시자 자율 퇴장 정보" />
        <div className="middleBox">
          허용 시에는 문제 풀이를 모두 마친 응시자는 ‘촬영 종료’를 통해 시험
          시간 도중에도 자율적으로 퇴장이 가능해요. 반대로 허용 안함 시에는 시험
          종료 시간까지 모든 응시자는 퇴장이 불가능해요.
        </div>
        <div className="listBox">
          <div className="listTitle">※ 주의 사항</div>
          <ul>
            <li>
              허용 안함 시, 응시자는 문제 풀이를 미리 마쳤어도 시험이 끝날
              때까지 녹화 영상에 얼굴과 양손, PC 스크린이 보이게 유지될 수
              있도록 자세에 대한 안내가 필요해요.
            </li>
            <li>
              그렇지 않을 경우, 문제 풀이 이후에 일어난 행동들에 대해 자칫
              부정행위 또는 이상행동 이벤트로 오인되어 감지될 수 있어요. <br />
              예) 엎드려 잠, 팔짱을 끼고 있음 등
            </li>
          </ul>
        </div>
        <div
          className="buttonBox"
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </div>
      </StAutonomy>
    </>
  );
};

const StAutonomy = styled.div`
  position: fixed;
  background-color: white;
  @media ${device.pc} {
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    gap: 48px;
    .middleBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .listBox {
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        display: grid;
        flex-direction: column;
        gap: 5px;
        padding-left: 19px;
        margin-top: 4px;
        color: #444444;
        line-height: 24px;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 0 auto;
      border: 1px solid #000000;
      border-radius: 43px;
      font-size: 17px;
      font-family: "medium";
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: 16px;
    z-index: 53;
    transform: translate(-50%, 0);
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .middleBox {
      line-height: 24px;
    }
    .listBox {
      margin: 20px 0 24px;
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        margin-top: 4px;
        line-height: 24px;
        padding-left: 20px;
      }
    }
    .buttonBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      text-align: center;
      color: #0072de;
    }
  }
`;

export default AutonomyOut;
