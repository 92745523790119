import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import axios from "axios";
import { baseURL } from "../api/handler";
import { getCookie } from "../Cookie";
import { useRecoilValue } from "recoil";
import { userInfo } from "../atoms";
import { ModalBackD } from "../components/Style";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import TextLine from "../atomic/atom/TextLine";

const PointInfo = ({ setPointInfoModal }) => {
  const token = getCookie("token");
  const user = useRecoilValue(userInfo);
  const utcInfo = new Date().getTimezoneOffset();

  const goBack = () => {
    setPointInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StModal>
        <div className="titlePart">
          <TextLine text="내 적립금 정보" fontsize={20} pc_fontsize={28} />
        </div>
        <div className="titleInfo">
          무료 적립금은 충전일로부터 최대 1년 동안 유효하며, 10C(10분) 단위로
          사용 가능해요. 예정된 시험을 취소하면 사용한 적립금이 자동으로
          환원돼요. 적립금 사용은 충전한 날짜순으로 순차적으로 차감되며,
          유효기간이 만료되면 소멸돼요.
          <br />
          <br />
          무료 이용 서비스의 적립금은 개인 회원이 직접 충전할 수 없지만
          프록토매틱 서비스의 이벤트나 쿠폰 코드를 통해 추가 충전은 가능해요.
        </div>
        <div className="middlePart">※ 주의 사항 </div>
        <ul>
          <li>소멸 예정일의 시간은 당일 자정(00:00)을 기준으로 해요.</li>
          <li>
            시험 취소 등의 사유로 유효기간이 지난 후 환불받을 적립금은 다시
            사용이 불가하여 환불되지 않고 자동 소멸돼요.
          </li>
          <li>적립금은 회원탈퇴 시 모두 소멸돼요.</li>
        </ul>
        <div
          className="confirmPart"
          onClick={() => {
            setPointInfoModal(false);
            window.history.back();
          }}
        >
          확인
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .titleInfo {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
    margin-top: 20px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
    margin-top: 20px;
  }
  .confirmPart {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    color: #0072de;
    margin: 0 auto;
  }
  ul {
    padding-left: 20px;
    margin-top: 4px;
    line-height: 24px;
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 53;
    width: calc(100% - 16px);
    border-radius: 24px;
    padding: 24px;
  }
  @media ${device.pc} {
    width: 580px;
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin: 48px 0 28px;
    }
    .confirmPart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      margin-top: 32px;
      color: black;
      border-radius: 43px;
      cursor: pointer;
    }
  }
`;

export default PointInfo;
