import axios from "axios";
import { baseURL } from "./handler";
import customAxios from "./handler";

const utcInfo = new Date().getTimezoneOffset();

/** 기업이 메일보내기 */
export const invitation = async (datas) => {
  const { data } = await customAxios.post(
    `${baseURL}/api/v1/users/enterprise/manager/invite`,
    datas
  );
  return data;
};

/** 문의사항 본문 글 지우기 */
export const deleteQnA = async ({ uid }) => {
  const { data } = await customAxios.delete(
    `${baseURL}/api/v1/questions/${uid}`
  );
  return data;
};

/** finding ID */
export const findingId = async (datas) => {
  const { data } = await customAxios.post(
    `${baseURL}/api/v1/hosts/find-host-email`,
    datas
  );
  return data;
};

/** deleteExams */
export const deleteExams = async (data) => {
  const { datas } = await customAxios.delete(`/api/v1/tests/${data.testId}`);
  return datas;
};

/** patch marketing */
export const marketingAxios = async (marketingData) => {
  const { data } = await customAxios.patch(
    `${baseURL}/api/v1/hosts/${marketingData.uid}/marketing`,
    marketingData
  );
  return data;
};

/** patch language */
export const languageAxios = (languageData) => {
  customAxios
    .patch(`${baseURL}/api/v1/hosts/${languageData.uid}/language`, languageData)
    .then(({ data }) => {
      return data;
    })
    .catch(({ response }) => {
      console.log(response);
    });
};

/** passwordChange after Login */
export const passwordChange = async (passwordData) => {
  const { data } = await customAxios.patch(
    `${baseURL}/api/v1/hosts/${passwordData.uid}/reset-password`,
    passwordData
  );
  return data;
};

/** 초대할 구성원의 회원 여부 */
export const isUserExist = async (datas) => {
  const { data } = await customAxios.post(
    `${baseURL}/api/v1/users/enterprise/manager/whether`,
    datas
  );
  return data;
};

/**인증번호 발송(회원가입용) */
export const sendSignUp = async (datas) => {
  // const { data } = await apis.sendSignUp(owner_email)
  const { data } = await axios.post(
    `${baseURL}/api/v1/verify?offset=${utcInfo}&lang=kr`,
    datas
  );
  return data;
};

/**인증번호 발송(비밀번호 재설정용 - 로그아웃된 상태) */
export const sendFindPassword = async (owner_email) => {
  // const { data } = await apis.sendFindPassword(owner_email)
  const { data } = await axios.post(
    `${baseURL}/api/verify/send/find-password-code?offset=${utcInfo}&lang=kr`,
    { owner_email }
  );
  return data;
};
