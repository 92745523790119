import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { baseURL } from "../api/handler";

// img
import whiteCheck from "../img/whiteCheck.svg";
import { useSetRecoilState } from "recoil";
import { userInfo } from "../atoms";
import { device } from "../hooks/device";
import customAxios from "../api/handler";
import useToast from "../hooks/useToast";

const Invitation = ({
  props,
  beforeAccept,
  cancelCheckAll,
  setCheckAll,
  setCheck,
  check,
  checkAll,
  count,
  removeMode,
  setAnswerInvitation,
  answerInvitation,
  removeList,
  setRemoveList,
}) => {
  // state
  const [state, setState] = useState("대기중");
  const [stateColor, setStateColor] = useState("#C83B38");
  const [removeChecked, setRemoveChecked] = useState(false);
  const setUserData = useSetRecoilState(userInfo);
  const showToast = useToast();

  /** 수락인지 거절인지에 따라 색 변경해주기 */
  useEffect(() => {
    if (state === "수락함") {
      setStateColor("#2276DC");
    } else {
      setStateColor("#C83B38");
    }
  }, [state]);

  /** 초대 메세지에 대해 수락 또는 거절 해주기 */
  const answer = (ans) => {
    customAxios
      .patch(`/api/v1/users/individual/approval`, {
        isVerifiedHostEmailInvite: ans,
        hostEmail: props?.hostEmail,
        hostGroupName: props?.hostGroupName,
      })
      .then(() => {
        customAxios
          .get(`${baseURL}/api/v1/hosts`)
          .then(({ data }) => {
            setUserData(data?.getHost);
          })
          .catch(({ response }) => {
            console.log(response);
          });
        setAnswerInvitation(!answerInvitation);
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-ROLE-003" ||
          response.data.errorCode === "HOST-ROLE-005" ||
          response.data.errorCode === "ENTERPRISE-006"
        ) {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
        setAnswerInvitation(!answerInvitation);
      });
  };

  useEffect(() => {
    if (props?.isVerifiedHostEmailInvite === "pending") setState("대기중");
    else if (props?.isVerifiedHostEmailInvite === "approval")
      setState("수락함");
    else if (props?.isVerifiedHostEmailInvite === "refusal") setState("거절함");
  }, [props]);

  // 전체 선택시 체크됨으로 바꿔줌
  useEffect(() => {
    if (checkAll) {
      setRemoveChecked(true);
    }
    if (cancelCheckAll) {
      setRemoveChecked(false);
    }
  }, [checkAll, cancelCheckAll]);

  /** 하나의 체크박스를 풀 경우 전체선택을 해제한다 */
  useEffect(() => {
    if (!removeChecked) {
      setCheckAll(false);
    }
  }, [removeChecked]);

  useEffect(() => {
    if (removeChecked === true) {
      setRemoveList((removeList) => [
        ...removeList,
        {
          hostEmail: props?.hostEmail,
          isVerifiedHostEmailInvite: props?.isVerifiedHostEmailInvite,
          hostGroupName: props?.hostGroupName,
        },
      ]);
    } else if (removeChecked === false) {
      removeFromCancelList();
    }
  }, [removeChecked]);

  /** 삭제할 리스트에서 제외시키기 */
  const removeFromCancelList = () => {
    let list = removeList;
    list?.forEach((value, index) => {
      if (value.hostEmail == props?.hostEmail) {
        list?.splice(index, 1);
      }
    });
    return list;
  };
  return (
    <>
      <StInvitation
        pending={props?.isVerifiedHostEmailInvite}
        stateColor={stateColor}
        removeMode={removeMode}
        removeChecked={removeChecked}
      >
        <div className="removeBox">
          {removeMode && (
            <div
              className="checkBox"
              onClick={() => {
                setRemoveChecked(!removeChecked);
                setCheck(!check);
              }}
            >
              <img src={whiteCheck} alt="" />
            </div>
          )}
          <div className="rightOnRemoveMode">
            <div className="upBox">
              <div className="headPart">
                <div>
                  {dayjs(props?.hostInviteAuthnumSendTime).format("YYYY-MM-DD")}
                </div>
                {props?.isVerifiedHostEmailInvite !== "pending" && (
                  <>
                    <div>ㆍ</div>
                    <span>{state}</span>
                  </>
                )}
              </div>
              <div className="hostGroupPart">{props?.hostGroupName}</div>
              <div className="hostInfoPart">
                {props?.hostName}({props?.hostEmail})님이 회원님을 기업
                구성원으로 초대하였어요.
              </div>
            </div>
          </div>
        </div>
        {props?.isVerifiedHostEmailInvite === "pending" && (
          <div className="downBox">
            <div
              onClick={() => {
                if (!removeMode) {
                  // setClicked(false);
                  answer("refusal");
                }
              }}
              style={{ cursor: "pointer" }}
            >
              거절
            </div>
            <div className="columnBar" />
            <div
              onClick={() => {
                if (!removeMode) {
                  beforeAccept([
                    props.hostEmail,
                    props.hostGroupName,
                    props.hostName,
                  ]);
                }
              }}
              style={{ cursor: "pointer" }}
            >
              수락
            </div>
          </div>
        )}
      </StInvitation>
    </>
  );
};

const StInvitation = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(17, 13, 13, 0.08);
  border-radius: 24px;
  @media ${device.pc} {
    justify-content: space-between;
    height: 202px;
    padding: 24px;
    .removeBox {
      .rightOnRemoveMode {
        .upBox {
          .headPart {
            margin-bottom: 16px;
          }
          .hostGroupPart {
            margin: 0px 0 6px;
          }
          .hostInfoPart {
            height: 38px;
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    padding: 14px 24px;
    margin-bottom: 12px;
    /* height: ${(props) =>
      props.pending === "pending" ? "163px" : "117px"}; */
    box-sizing: border-box;
    .removeBox {
      .rightOnRemoveMode {
        .upBox {
          .headPart {
            line-height: 18px;
          }
          .hostGroupPart {
            margin: 6px 0 3px;
          }
        }
      }
    }
  }
  .removeBox {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 16px;
    .checkBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      border: 0.6px solid #818181;
      border-radius: 4px;
      box-sizing: border-box;
      background-color: ${(props) => (props.removeChecked ? "#20315B" : "")};
      img {
        width: 22px;
        height: 10px;
      }
    }
    .rightOnRemoveMode {
      display: flex;
      flex-direction: column;
      /* width : 274px; */
      word-break: break-all;
      .upBox {
        .headPart {
          display: flex;
          font-size: 12px;
          div {
            color: #55595f;
          }
          span {
            color: ${(props) => props.stateColor};
          }
        }
        .hostGroupPart {
          font-size: 16px;
          font-family: "semiBold";
          line-height: 24px;
        }
        .hostInfoPart {
          font-size: 13px;
          font-family: "regular";
          line-height: 19px;
          color: #909090;
        }
      }
    }
  }
  .downBox {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    align-items: center;
    height: 36px;
    gap: 62px;
    margin-top: 10px;
    color: #0072de;
    opacity: ${(props) => (props.removeMode ? "0.5" : "")};
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    .columnBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
    }
  }
`;

export default Invitation;
