import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { languageIs, loginModal, userInfo } from "../atoms";

// img
import arrow from "../img/arrow.svg";
import arrow2 from "../img/arow.svg";
import invited from "../img/invited.svg";
import invitedNew from "../img/invitedNew.svg";
import servicePlan from "../img/ServicePlan.svg";
import lock from "../img/Lock.svg";
import headPhone from "../img/headPhone.svg";
import policy from "../img/policy.svg";

// modal

// component
import { device } from "../hooks/device";
import { useMutation } from "react-query";
import { marketAtom } from "../atoms";
import { marketingAxios } from "../api/axiosTwo";
import { getCookie, removeCookie } from "../Cookie";
import Bye from "../modal/Bye";
import QuitConfirm from "../modal/QuitConfirm";
import PasswordSetting from "../modal/PasswordSetting";
import customAxios from "../api/handler";
import { baseURL } from "../api/handler";
import LanguageModal from "../modal/LanguageModal";
import CreditConfirm from "../modal/CreditConfirm";
import ConnectionConfirm from "../modal/ConnectionConfirm";
import LeftTest from "../modal/ServicePlanLeftTest";
import useToast from "../hooks/useToast";
import useGetMyPageInfo from "../hooks/api/useGetMyPageInfo";
import getApi from "../api/getApi";
import { useTranslation } from "react-i18next";
// Noah
import useGetCreditUseMember from "../hooks/useGetCreditUseMember";

const MyPage = ({ myPageScroll, setMyPageScroll, setModalOn }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState(getCookie("token"));
  const { data: myPageData } = useGetMyPageInfo({ token });
  const navigate = useNavigate();

  const [width, setWidth] = useState(undefined);
  const user = useRecoilValue(userInfo);
  const uid = user?.hostUuid;

  // const setMarket = useSetRecoilState(marketAtom);
  const [market, setMarket] = useRecoilState(marketAtom);
  const [country, setCountry] = useState("/ko");
  const urlLang = useRecoilValue(languageIs);
  const resetUserData = useResetRecoilState(userInfo);
  const [plan, setPlan] = useState();
  const [bottomPlan, setBottomPlan] = useState("개인 회원");

  const [language, setLanguage] = useState(myPageData?.hostLanguage);
  const [showLanguage, setShowLanguage] = useState();
  const [connectData, setConnectData] = useState();
  const [creditData, setCreditData] = useState();

  // pc 왼쪽 메뉴
  const [pcMode, setPcMode] = useState(false);
  const [chosenMenu, setChosenMenu] = useState(1);

  // 토스트 메세지
  const showToast = useToast();

  // 모달 띄우기 용
  const [confirmModalOn, setConfirmModalOn] = useState(false);
  const [passwordModalOn, setPasswordModalOn] = useState(false);
  const [languageModalOn, setLanguageModalOn] = useState(false);
  const [byeModalOn, setByeModalOn] = useState(false);
  const setLoginModalOn = useSetRecoilState(loginModal);
  const [byeConnectionConfirmModal, setByeConnectionConfirmModal] =
    useState(false);
  const [byeCreditConfirmModal, setByeCreditConfirmModal] = useState(false);
  const [leftToPayModal, setLeftToPayModal] = useState(false);
  const [leftTestModal, setLeftTestModal] = useState(false);
  const [leftToPayManagerModal, setLeftToPayManagerModal] = useState(false);
  const [leftTestManagerModal, setLeftTestManagerModal] = useState(false);

  // Noah
  const { data: creditRecent } = user?.hostPlan === "managerPlan" ? useGetCreditUseMember(false) : {};

  // useEffect(() => {
  //   customAxios
  //     .get(`/api/v1/hosts/mypage`)
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch(() => {});
  // }, [uid]);

  /** 로그아웃 시 고객센터로 state 값 이동 */
  useEffect(() => {
    if (!token) {
      setChosenMenu(3);
    }
  }, [token]);

  useEffect(() => {
    if (!getCookie("token")) {
      setToken("");
    } else {
      setToken(getCookie("token"));
    }
  }, [getCookie("token")]);

  // useEffect(() => {
  //   if (uid) {
  //     customAxios
  //       .get(`${baseURL}/api/v1/hosts/${uid}`)
  //       .then(({ data }) => {
  //         setMyPageData(data?.getHostDataForMyPage);
  //       })
  //       .catch(({ data }) => {
  //         console.log(data);
  //       });
  //   }
  // }, [user, market, uid, utcInfo, token]);

  const yOffset = -60;
  const element = document.getElementById("center");
  let where =
    element?.getBoundingClientRect().top + window.pageYOffset + yOffset;

  useEffect(() => {
    setCountry(urlLang);
  }, [urlLang]);

  useEffect(() => {
    if (myPageScroll === "customerCenter") {
      if (window.innerWidth < 1140) {
        // customerCenter?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        window.scrollTo({ top: where, behavior: "smooth" });
        setMyPageScroll("");
      } else {
        setChosenMenu(3);
      }
    } else if (myPageScroll === "accountInfo") {
      setChosenMenu(2);
    }
    return setMyPageScroll();
  }, [myPageScroll, where, setMyPageScroll]);

  useEffect(() => {
    if (language === "KR") {
      setShowLanguage("KOR (한국어)");
    } else if (language === "EN") {
      setShowLanguage("ENG (English)");
    }
  }, [language]);

  useEffect(() => {
    if (user?.hostPlan === "individualPlan") {
      setPlan("무료 이용");
      setBottomPlan("개인 회원");
    } else if (user?.hostPlan === "managerPlan") {
      setPlan("기업용 (관리자)");
      setBottomPlan("기업 회원");
    } else if (user?.hostPlan === "memberPlan") {
      setPlan("기업용 (구성원)");
      setBottomPlan("기업 회원");
    }
  }, [user?.hostPlan]);

  // 언어 정보 바꾸기
  useEffect(() => {
    setLanguage(myPageData?.hostLanguage);
  }, [myPageData]);

  const [birth, setBirth] = useState();
  useEffect(() => {
    let temp;
    temp = myPageData?.hostBirthdayAt?.split("-");
    setBirth(temp?.[0] + "년 " + temp?.[1] + "월 " + temp?.[2] + "일");
  }, [myPageData?.hostBirthdayAt]);

  // 마케팅 정보 바꾸기
  useEffect(() => {
    setMarket(myPageData?.isAgreedOfMarketing);
  }, [myPageData?.isAgreedOfMarketing]);

  const { mutate: mutateMarketing } = useMutation(marketingAxios, {
    onSuccess: () => {
      // setMarket(!myPageData?.isAgreedOfMarketing);
      setMarket(!market);
    },
    onError: ({ response }) => {
      // setMarket(!myPageData?.isAgreedOfMarketing)
    },
  });

  const marketing = () => {
    mutateMarketing({
      isAgreedOfMarketing: !market,
      uid: uid,
    });
  };

  /** 회원탈퇴 전 미결제 금액 시험과 현재 진행중인 시험 유무 판단 */
  const beforeConvert = () => {
    if (user?.hostPlan === "managerPlan") {
      getApi
        .getProgressingTest()
        .then(() => {
          connectAccount();
        })
        .catch(({ response }) => {
          const errorCode = response.data.errorCode;
          if (errorCode === "ENTERPRISE-010" || errorCode === "TIMEZONE-007") {
            setLeftTestManagerModal(true);
          } else if (
            errorCode === "HOST-ROLE-001" ||
            errorCode === "HOST-ROLE-005"
          ) {
            showToast({ message: "관리자만 이용할 수 있어요." });
          } else {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
        });
    } else if (user?.hostPlan === "memberPlan") {
      getApi
        .getProgressingTest()
        .then(() => {
          connectAccount();
        })
        .catch(({ response }) => {
          if (
            response.data.errorCode === "ENTERPRISE-010" ||
            response.data.errorCode === "TIMEZONE-007"
          ) {
            setLeftTestModal(true);
          } else {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
        });
    }
  };

  /** 회원 탈퇴 버튼 클릭 시 연결된 구성원 확인(기업만 사용) */
  const connectAccount = () => {
    customAxios
      .get(`/api/v1/hosts/enterprise/delete/check-by-plan`)
      .then(({ data }) => {
        if (data?.connectedMember?.length === 0) {
          refundableCredit();
        } else {
          setByeConnectionConfirmModal(true);
          setConnectData(data?.connectedMember);
        }
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "HOST-ROLE-007") {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  /** 연결된 계정 확인 후 환불 가능 적립금 보유 여부 확인(기업만 사용) */
  const refundableCredit = () => {
    customAxios
      .get(`/api/v1/hosts/enterprise/manager/delete/credits`)
      .then(({ data }) => {
        if (
          data?.credits?.refundableCredit === 0 ||
          !data?.credits?.refundableCredit
        ) {
          setByeModalOn(true);
        } else if (
          data?.credits?.refundableCredit &
          (data?.credits?.refundableCredit !== 0)
        ) {
          setByeCreditConfirmModal(true);
          setCreditData(data?.credits);
        }
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (response.data.errorCode === "HOST-ROLE-001") {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        } else if (errorCode === "ENTERPRISE-009") {
          showToast({
            message:
              "관리자 계정을 찾을 수 없어요. (알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.)",
          });
        }
      });
  };

  /** 로그아웃 함수 */
  const logout = () => {
    removeCookie("token");
    resetUserData();
    navigate(`/`);
    showToast({ message: t(`errorCode.logout`) });
  };

  /** 적립금 충전 요청 */
  const chargeRequest = () => {
    customAxios
      .post(`/api/v1/payments/enterprise/member/credits/charge-request`)
      .then(() => {
        showToast({
          message: t(`errorCode.chargeRequest`),
        });
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "ENTERPRISE-009") {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
  }, []);

  useEffect(() => {
    if (window.innerWidth > 1140) {
      setPcMode(true);
    } else {
      setPcMode(false);
    }
  }, [width]);

  return (
    <>
      {byeModalOn && (
        <Bye
          setConfirmModalOn={setConfirmModalOn}
          setByeModalOn={setByeModalOn}
          connectAccount={connectAccount}
        />
      )}
      {confirmModalOn && (
        <QuitConfirm
          setMyPageScroll={setMyPageScroll}
          setConfirmModalOn={setConfirmModalOn}
        />
      )}
      {languageModalOn && (
        <LanguageModal
          setLanguageModalOn={setLanguageModalOn}
          setLanguage={setLanguage}
          language={language}
          uid={uid}
        />
      )}
      {passwordModalOn && (
        <PasswordSetting setPasswordModalOn={setPasswordModalOn} />
      )}
      {byeCreditConfirmModal && (
        <CreditConfirm
          creditData={creditData}
          setByeCreditConfirmModal={setByeCreditConfirmModal}
          setByeModalOn={setByeModalOn}
        />
      )}
      {byeConnectionConfirmModal && (
        <ConnectionConfirm
          connectData={connectData}
          setByeModalOn={setByeModalOn}
          setByeConnectionConfirmModal={setByeConnectionConfirmModal}
          setByeCreditConfirmModal={setByeCreditConfirmModal}
          refundableCredit={refundableCredit}
          props=""
        />
      )}
      {leftTestModal && (
        <LeftTest
          close={setLeftTestModal}
          title="개인 회원으로 전환 불가"
          textOne="기업 회원은 개인 회원으로 자동 전환 후에 탈퇴가 가능해요."
          textTwo="관리자 또는 구성원 중 ‘내 시험 관리’ 내 시작 직전(시작 시간 기준 3시간 전) 또는 현재 진행 중에 있는 시험이 있어 개인 회원으로 전환 할 수 없어요. 해당 시험의 모든 응시자들이 영상 업로드 단계까지는 완료해야만 회원 전환을 문제없이 진행할 수 있어요."
        />
      )}
      {leftTestManagerModal && (
        <LeftTest
          close={setLeftTestModal}
          title="개인 회원으로 전환 불가"
          textOne="기업 회원은 개인 회원으로 자동 전환 후에 탈퇴가 가능해요."
          textTwo="관리자 또는 구성원 중 ‘내 시험 관리’ 내 시작 직전(시작 시간 기준 3시간 전) 또는 현재 진행 중에 있는 시험이 있어 개인 회원으로 전환 할 수 없어요. 해당 시험의 모든 응시자들이 영상 업로드 단계까지는 완료해야만 회원 전환을 문제없이 진행할 수 있어요."
        />
      )}
      <StMyPage marketing={market}>
        <div className="container">
          <div className="pageHeader">
            <div className="headerLeft">
              <img src={arrow} alt="" onClick={() => navigate(`/`)} />
              <div className="title">마이 페이지</div>
            </div>
            {user.hostEmail && (
              <div className="headerRight" onClick={() => navigate(`/invited`)}>
                {myPageData?.hasMessage ? (
                  <img alt="" src={invitedNew} />
                ) : (
                  <img alt="" src={invited} />
                )}
                <div className="onlyForPc">메세지 수신함</div>
              </div>
            )}
          </div>
          <div className="pcForm">
            <div className="onlyForPc LeftMenu">
              {token && (
                <div
                  className="menuItem"
                  style={chosenMenu === 1 ? { backgroundColor: "#CCEAFF" } : {}}
                  onClick={() => {
                    setChosenMenu(1);
                  }}
                >
                  <img alt="" src={servicePlan} />
                  요금제
                </div>
              )}
              {token && (
                <div
                  className="menuItem"
                  style={chosenMenu === 2 ? { backgroundColor: "#CCEAFF" } : {}}
                  onClick={() => {
                    setChosenMenu(2);
                  }}
                >
                  <img alt="" src={lock} />
                  계정 정보
                </div>
              )}
              <div
                className="menuItem"
                style={chosenMenu === 3 ? { backgroundColor: "#CCEAFF" } : {}}
                onClick={() => {
                  setChosenMenu(3);
                }}
              >
                <img alt="" src={headPhone} />
                고객센터
              </div>
              <div
                className="menuItem"
                style={chosenMenu === 4 ? { backgroundColor: "#CCEAFF" } : {}}
                onClick={() => {
                  setChosenMenu(4);
                }}
              >
                <img alt="" src={policy} />
                약관 및 정책
              </div>
            </div>
            <div className="bodyForPc">
              {token && (
                <>
                  {chosenMenu === 1 || !pcMode ? (
                    <div className="formBox">
                      <div className="titlePart">요금제</div>
                      <div className="framePart">
                        <div
                          className="frameLineRow"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/servicePlan`);
                          }}
                        >
                          <div className="rowLeft">
                            <div className="rowLeftUp">서비스 플랜</div>
                            <div
                              className="rowLeftDown"
                              style={{ color: "#2276DC" }}
                            >
                              {plan}
                            </div>
                          </div>
                          <img alt="" src={arrow2} />
                        </div>
                        {user?.hostPlan === "memberPlan" && (
                          <>
                            <div className="rowGreyBar" />
                            <div className="frameLineColumn">
                              <div className="columnUp">플랜 관리자 정보</div>
                              <div className="columnDown">
                                {myPageData?.hostParentName} (
                                {myPageData?.hostParentEmail})
                              </div>
                            </div>
                          </>
                        )}
                        {user?.hostPlan !== "individualPlan" && (
                          <>
                            <div className="rowGreyBar" />
                            <div
                              className="frameLineRow"
                              style={
                                user?.hostPlan === "managerPlan"
                                  ? { cursor: "pointer" }
                                  : {}
                              }
                              onClick={() => {
                                if (user?.hostPlan === "managerPlan") {
                                  navigate(`/myCredit`);
                                }
                              }}
                            >
                              <div className="rowLeft">
                                <div className="rowLeftUp">기업 적립금</div>
                                {myPageData && (
                                  <div className="rowLeftDown">
                                    {myPageData &&
                                      Number(
                                        myPageData?.hostParentCredits
                                      )?.toLocaleString()}{" "}
                                    C
                                  </div>
                                )}
                              </div>
                              {user?.hostPlan === "managerPlan" && (
                                <img alt="" src={arrow2} />
                              )}
                              {(user?.hostPlan === "individualPlan") |
                              (user?.hostPlan === "memberPlan") ? (
                                <div
                                  className="rowRequest"
                                  onClick={() => {
                                    chargeRequest();
                                  }}
                                >
                                  충전 요청
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {/* <div className="rowGreyBar" />
                            <div
                              className="frameLineRow"
                              style={
                                user?.hostPlan === "managerPlan"
                                  ? { cursor: "pointer" }
                                  : {}
                              }
                              onClick={() => {
                                if (user?.hostPlan === "managerPlan") {
                                  navigate(`/myPoint`);
                                }
                              }}
                            >
                              <div className="rowLeft">
                                <div className="columnUp">기업 포인트</div>
                                {myPageData && (
                                  <div className="columnDown">
                                    {myPageData
                                      ? Number(
                                          myPageData?.hostParentPoints
                                        )?.toLocaleString()
                                      : 0}{" "}
                                    P
                                  </div>
                                )}
                              </div>
                              {user?.hostPlan === "managerPlan" && (
                                <img alt="" src={arrow2} />
                              )}
                            </div> */}
                          </>
                        )}
                        {user?.hostPlan !== "managerPlan" && (
                          <>
                            <div className="rowGreyBar" />
                            <div
                              className="frameLineRow"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/myPoint`);
                              }}
                            >
                              <div className="rowLeft">
                                <div className="rowLeftUp">내 적립금</div>
                                {myPageData && (
                                  <div className="rowLeftDown">
                                    {Number(
                                      myPageData.hostCredits
                                    )?.toLocaleString()}{" "}
                                    C
                                  </div>
                                )}
                              </div>
                              <img alt="" src={arrow2} />
                            </div>
                            {user?.hostPlan === "memberPlan" && (
                              <div className="rowGreyBar onlyForPc" />
                            )}
                          </>
                        )}
                        {/**
                           * 티켓링크 : https://www.notion.so/edint/web-257a7d321e334e9cbedaaf9d5f17cc10?pvs=4
                           * 주석작성자 : Noah
                           * 주석작성일자 : 2024.05.09
                           * 티켓내용 : 구성원 추가 메뉴를 찾기쉽게 수정
                           * 주석설명 : 구성원 관리하기 메뉴 추가
                           */}
                        {user?.hostPlan === "managerPlan" && (
                          <>
                            <div className="rowGreyBar" />
                            <div
                              className="frameLineRow"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(`/memberManage`)
                              }}
                            >
                              <div className="rowLeft">
                                <div className="rowLeftUp">구성원 관리하기</div>
                                {creditRecent && (
                                  <>
                                    <div className="rowLeftDown">
                                      <span
                                        style={{ paddingRight : "16px" }}
                                      >
                                        연결된 구성원
                                      </span>
                                      총{" "}
                                      <span
                                        style={{
                                          color: "#2276DC",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {
                                          creditRecent?.enterpriseCreditUsageList
                                          ?.enterpriseChildCount
                                        }
                                      </span>
                                      {" "}명
                                    </div>
                                  </>
                                )}
                              </div>
                              <img alt="" src={arrow2} />
                            </div>
                          </>
                        )}
                      </div>
                      {user?.hostPlan === "memberPlan" && (
                        <div className="paymentInfo">
                          * 기업 회원 상태에서는 ‘내 적립금’ 사용이 불가하며
                          조회만 가능해요.서비스 플랜에서 개인 회원으로 전환 시
                          언제든 다시 사용이 가능해요.{" "}
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {chosenMenu === 2 || !pcMode ? (
                    <div className="formBox">
                      <div className="titlePart">계정 정보</div>
                      <div className="framePart">
                        <div className="frameLineRow">
                          <div className="rowLeft">
                            <div className="rowLeftUp">로그인 ID</div>
                            <div className="rowLeftDown">
                              {myPageData?.hostEmail}
                            </div>
                          </div>
                          <div className="rowLogout" onClick={logout}>
                            로그아웃
                          </div>
                        </div>
                        <div className="rowGreyBar" />
                        <div className="frameLineColumn">
                          <div className="columnUp">구분</div>
                          <div className="columnDown">
                            {bottomPlan} {bottomPlan === "개인 회원" ? "" : "/"}{" "}
                            {myPageData?.hostGroupName}
                          </div>
                        </div>
                        <div className="rowGreyBar" />
                        <div className="frameLineColumn">
                          <div className="columnUp">주최자 이름</div>
                          <div className="columnDown">
                            {myPageData?.hostName}
                          </div>
                        </div>
                        {user?.hostPlan === "managerPlan" && (
                          <>
                            <div className="rowGreyBar" />
                            <div className="frameLineColumn">
                              <div className="columnUp">사업자 등록번호</div>
                              <div className="columnDown">
                                {myPageData?.hostGroupRegistryNumber}
                              </div>
                            </div>
                            <div className="rowGreyBar" />
                            <div className="frameLineColumn">
                              <div className="columnUp">직책/직함</div>
                              <div className="columnDown">
                                {myPageData?.hostJobPosition}
                              </div>
                            </div>
                            <div className="rowGreyBar" />
                            <div className="frameLineColumn">
                              <div className="columnUp">전화번호</div>
                              <div className="columnDown">
                                {myPageData?.hostPhoneNumber}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="rowGreyBar" />
                        <div className="frameLineColumn">
                          <div className="columnUp">생년월일</div>
                          <div className="columnDown">{birth}</div>
                        </div>
                        <div className="rowGreyBar" />
                        {/* <div
                          className="frameLineRow"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLanguageModalOn(true);
                          }}
                        >
                          <div className="rowLeft">
                            <div className="rowLeftUp">서비스 언어</div>
                            <div className="rowLeftDown">{showLanguage}</div>
                          </div>
                          <img alt="" src={arrow2} />
                        </div>
                        <div className="rowGreyBar" />
                        <div className="frameLineColumn">
                          <div className="columnUp">서비스 지역</div>
                          <div className="columnDown">
                            {myPageData?.hostRegion}
                          </div>
                        </div>
                        <div className="rowGreyBar" /> */}
                        <div
                          className="frameLineRow"
                          onClick={() => {
                            setPasswordModalOn(true);
                            window.history.pushState(
                              null,
                              "",
                              window.location.pathname
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="simple">비밀번호 재설정</div>
                          <img alt="" src={arrow2} />
                        </div>
                        <div className="rowGreyBar" />
                        <div
                          className="frameLineRow"
                          onClick={() => {
                            marketing();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="simple">마케팅 활용 및 광고 수신</div>
                          <div className="toggle">
                            <div className="circle" />
                          </div>
                        </div>
                        <div className="rowGreyBar" />
                        <div
                          className="frameLineRow"
                          onClick={() => {
                            if (user.hostPlan === "individualPlan") {
                              setByeModalOn(true);
                            } else {
                              beforeConvert();
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="simple">회원 탈퇴</div>
                          <img alt="" src={arrow2} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {chosenMenu === 3 || !pcMode ? (
                <div className="formBox" id="center">
                  <div className="titlePart">고객센터</div>
                  <div
                    style={{ width: "100%", height: "16px" }}
                    className="onlyForPc"
                  />
                  <div className="framePart">
                    <div className="frameLineColumn">
                      <div className="columnUpCustomerCenter">전화 상담</div>
                      <div
                        className="columnDownCustomerCenter"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText("07080650811");
                          showToast({ message: t(`errorCode.numberCopied`) });
                        }}
                      >
                        070-8065-0811
                      </div>
                    </div>
                    <div className="rowGreyBar onlyForTabMob" />
                    <div className="frameLineColumn">
                      <div className="columnUpCustomerCenter">이메일 문의</div>
                      <a
                        className="columnDownCustomerCenter"
                        href={"mailto:hiya@proctormatic.com"}
                      >
                        hiya@proctormatic.com
                      </a>
                    </div>
                    <div className="rowGreyBar onlyForTabMob" />
                    {pcMode && (
                      <div className="frameLineColumn">
                        <div className="columnUpCustomerCenter">
                          운영 시간 안내
                        </div>
                        <div>
                          <div
                            className="columnDownCustomerCenter"
                            style={{ marginBottom: "8px" }}
                          >
                            평일 (월~금) 10:00 ~ 17:00
                          </div>
                          <div
                            style={{
                              color: "#55595F",
                              fontSize: "14px",
                              fontFamily: "regular",
                              lineHeight: "21px",
                              paddingLeft: "24px",
                            }}
                          >
                            * 점심시간 12:00 ~ 13:00 / 토, 일, 공휴일 휴무
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="rowGreyBarThick onlyForPc" />
                    <div
                      className="frameLineRow"
                      onClick={() => {
                        if (token) {
                          navigate(`/qna/1`);
                        } else {
                          setLoginModalOn(true);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="simple">1:1 문의</div>
                      <img alt="" src={arrow2} />
                    </div>
                    <div className="rowGreyBar" />
                    <div
                      className="frameLineRow"
                      onClick={() => {
                        navigate(`/notice/1`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="simple">공지사항</div>
                      <img alt="" src={arrow2} />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {!pcMode && (
                <div className="infoBox onlyForTabMob">
                  <div>* 운영시간은 평일(월 ~ 금) 10:00 ~ 17:00 까지에요.</div>
                  <div>* 점심시간 12:00 ~ 13:00 / 토, 일, 공휴일 휴무 </div>
                </div>
              )}
              {chosenMenu === 4 || !pcMode ? (
                <div className="formBox">
                  <div className="titlePart">약관 및 정책</div>
                  <div className="framePart">
                    <div
                      className="frameLineRow"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `https://edint.notion.site/ba7e64b525b44c26939a5e15efd00b9f`
                        );
                      }}
                    >
                      <div className="simple">이용약관</div>
                      <img alt="" src={arrow2} />
                    </div>
                    <div className="rowGreyBar" />
                    <div
                      className="frameLineRow"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `https://edint.notion.site/edint/45222e54e558415487d672680ef1a310`
                        );
                      }}
                    >
                      <div className="simple">위치기반서비스 이용약관</div>
                      <img alt="" src={arrow2} />
                    </div>
                    <div className="rowGreyBar" />
                    <div
                      className="frameLineRow"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          `https://edint.notion.site/672a5db0a38c4299bc4ef72494b06b84`
                        );
                      }}
                    >
                      <div className="simple">개인정보처리방침</div>
                      <img alt="" src={arrow2} />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </StMyPage>
    </>
  );
};

const StMyPage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f0f1f2;

  .container {
    .pageHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
      .headerLeft {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
        }
      }
      .headerRight {
        display: flex;
        align-items: center;
        img {
          cursor: pointer;
        }
        div {
          font-size: 16px;
          font-family: "medium";
          line-height: 24px;
        }
      }
    }
    .formBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      .titlePart {
        display: flex;
        padding: 12px 24px 5px;
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        color: #909090;
      }
      .framePart {
        display: flex;
        flex-direction: column;
        .frameLineColumn {
          box-sizing: border-box;
        }
        .frameLineRow {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          line-height: 24px;
          .rowLogout {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 31px;
            border: 1px solid #d6d9dd;
            border-radius: 6px;
            font-size: 11px;
            font-family: "regular";
            line-height: 12px;
            cursor: pointer;
          }
          .rowRequest {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px;
            height: 31px;
            border-radius: 6px;
            font-size: 11px;
            font-family: "medium";
            line-height: 12px;
            color: white;
            background-color: #2276dc;
            cursor: pointer;
          }
          .rowLeft {
            display: flex;
            .rowLeftUp {
              font-family: "regular";
              line-height: 19px;
            }
            .rowLeftDown {
              font-size: 16px;
              font-family: "regular";
              line-height: 24px;
            }
          }
          .toggle {
            position: relative;
            width: 35px;
            height: 18.5px;
            background: ${(props) => (props.marketing ? "#4897E1" : "#A5A5A5")};
            border-radius: 9.25px;
            margin-left: 35px;
            .circle {
              position: absolute;
              top: 50%;
              left: ${(props) => (props.marketing ? "80%" : "20%")};
              transform: translate(-50%, -50%);
              transition: 0.5s;
              width: 22px;
              height: 22px;
              border-radius: 22px;
              background-color: #fafafa;
              border: ${(props) =>
                props.marketing ? "1px solid #0381FE;" : "1px solid #a5a5a5"};
              cursor: pointer;
            }
          }
        }
        .rowGreyBarThick {
          width: calc(100% - 24px);
          height: 3px;
          margin: 25.5px 0 20px auto;
          background: #000000;
        }
      }
    }
    .infoBox {
      display: grid;
      flex-direction: column;
      padding: 0 20px;
      margin: 16px 0 12px;
      gap: 6px;
      font-size: 11px;
      font-family: "regular";
      line-height: 13px;
      color: #444444;
    }
  }
  @media ${device.pc} {
    min-height: calc(100vh - 353px);
    /* min-width: 1440px; */
    .onlyForTabMob {
      display: none;
    }
    .container {
      min-width: 1228px;
      margin: 0 auto;
      padding: 80px 0;
      .pageHeader {
        margin: 0px 0 60px;
        .headerLeft {
          display: grid;
          grid-template-columns: auto auto;
          gap: 19px;
          font-size: 28px;
          line-height: 41px;
          img {
            width: 28px;
            height: 28px;
          }
        }
        .headerRight {
          /* width: 163px; */
          height: 41px;
          box-sizing: border-box;
          background-color: white;
          padding: 8.5px 22px;
          border-radius: 24px;
          cursor: pointer;
          img {
            margin-right: 12px;
            width: 20px;
            height: 14.17px;
          }
        }
      }
      .pcForm {
        display: flex;
        .LeftMenu {
          display: grid;
          grid-template-rows: repeat(4, 48px);
          flex-direction: column;
          gap: 15px;
          width: 223px;
          margin: 58px 15px 0 0;
          .menuItem {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
            padding: 12px 16px;
            box-sizing: border-box;
            border-radius: 0px 23px 23px 0px;
            cursor: pointer;
            img {
              margin-right: 12px;
            }
          }
        }
        .bodyForPc {
          flex-grow: 1;
          background-color: #ffffff;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          min-height: 493px;
          border-radius: 12px;
          padding: 40px;
          box-sizing: border-box;
          .formBox {
            .titlePart {
              font-size: 28px;
              font-family: "medium";
              line-height: 33px;
              color: black;
              padding: 20px 24px;
            }
            .framePart {
              .frameLineRow {
                .rowRequest {
                  font-size: 12px;
                  line-height: 19px;
                }
                .simple {
                  width: 280px;
                  height: 56px;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 16px 24px;
                  box-sizing: border-box;
                }
                .rowLeft {
                  display: flex;
                  align-items: center;
                  .rowLeftUp {
                    width: 280px;
                    height: 56px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                  }
                  .rowLeftDown {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                  }
                  .columnUp {
                    width: 280px;
                    height: 56px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                  }
                  .columnDown {
                    font-size: 16px;
                    line-height: 24px;
                    padding: 16px 24px;
                    box-sizing: border-box;
                  }
                }
                .rowLogout {
                  font-size: 12px;
                }
              }
              .frameLineColumn {
                display: flex;
                .columnUp {
                  width: 280px;
                  height: 56px;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 16px 24px;
                  box-sizing: border-box;
                }
                .columnDown {
                  font-size: 16px;
                  line-height: 24px;
                  padding: 16px 24px;
                  box-sizing: border-box;
                }
                .columnUpCustomerCenter {
                  width: 280px;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 0px 24px;
                  box-sizing: border-box;
                  margin-bottom: 12px;
                  font-size: 16px;
                  font-family: "regular";
                }
                .columnDownCustomerCenter {
                  font-size: 16px;
                  line-height: 24px;
                  padding: 0px 24px;
                  box-sizing: border-box;
                  margin-bottom: 12px;
                  font-size: 16px;
                  font-family: "regular";
                }
              }
              .rowGreyBar {
                width: calc(100% - 24px);
                height: 1px;
                background: rgba(217, 217, 217, 0.5);
                margin-left: auto;
              }
            }
            .paymentInfo {
              font-size: 12px;
              line-height: 18px;
              color: #444444;
              padding: 0 24px;
              margin-top: 18px;
            }
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .container {
      padding: 0 0 43px;
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      .pageHeader {
        padding: 24px 24px 16px;
        .headerLeft {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          gap: 12px;
          img {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
      }
      .formBox {
        .titlePart {
        }
        .framePart {
          background: #ffffff;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 24px;
          padding: 0 24px;
          .frameLineRow {
            padding: 14px 0px;
            .rowLeft {
              flex-direction: column;
              justify-content: center;
              .rowLeftUp {
                font-size: 13px;
                color: #909090;
              }
              .columnUp {
                font-size: 13px;
                color: #909090;
              }
            }
          }
          .frameLineColumn {
            display: flex;
            flex-direction: column;
            padding: 14px 0px;
            .columnUpCustomerCenter {
              font-size: 13px;
              font-family: "regular";
              line-height: 19px;
              color: #909090;
            }
            .columnDownCustomerCenter {
              font-size: 16px;
              font-family: "regular";
              line-height: 24px;
            }
            .columnUp {
              font-size: 13px;
              font-family: "regular";
              line-height: 19px;
              color: #909090;
            }
            .columnDown {
              font-size: 16px;
              font-family: "regular";
              line-height: 24px;
            }
          }
          .rowGreyBar {
            width: 100%;
            height: 1px;
            background: rgba(217, 217, 217, 0.5);
          }
        }
        .paymentInfo {
          margin-top: 12px;
          padding: 0 20px;
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
`;

export default MyPage;
