import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";

const EditNotAllowed = () => {
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title="'응시 종료' 시간 편집 불가" />
      <DivTwoPartImprove
        display="grid"
        font_size={16}
        lineheight={24}
        pc_font_size={18}
        pc_font_family="medium"
        pc_lineheight={27}
        margin="20px 0 24px"
        pc_margin="48px 0"
      >
        <TextLine text="이미 결제가 완료된 시험의 총 시험 시간은 편집이 불가해요. 따라서 시험 날짜와 시간은 '응시 시작' 시간만 변경할 수 있어요. " />
        <br />
        <TextLine text="총 시험 시간을 변경해야 하는 경우 해당 시험을 삭제한 후 새로운 시험을 예약해주세요." />
      </DivTwoPartImprove>
      <Button
        text="확인"
        font_size={18}
        font_color="#0072de"
        border="none"
        backgroundcolor="white"
        width="158px"
        height={35}
        margin="0 auto"
        pc_width="204px"
        pc_height={52}
        pc_border="1px solid black"
        pc_border_radius={43}
        pc_fontcolor="black"
        onclick={() => window.history.back()}
      />
    </ModalOneButtonTemplate>
  );
};

export default EditNotAllowed;
