import { useEffect, useState } from "react";

export const Body = ({ props }) => {
  const [faceColor, setColorOne] = useState(); // face
  const [handColor, setColorTwo] = useState(); // hand-arm
  const [bodyColor, setColorThree] = useState(); // body
  const [objectColor, setColorFour] = useState(); // object
  const [etcColor, setColorFive] = useState(); // etc

  useEffect(() => {
    for (let i = 0; i < 5; i++) {
      if (props?.[i]?.part === "Face") {
        settingColor(setColorOne, props?.[i]?.level);
      } else if (props?.[i]?.part === "Hand/Arm") {
        settingColor(setColorTwo, props?.[i]?.level);
      } else if (props?.[i]?.part === "Body") {
        settingColor(setColorThree, props?.[i]?.level);
      } else if (props?.[i]?.part === "Item") {
        settingColor(setColorFour, props?.[i]?.level);
      } else if (props?.[i]?.part === "Others") {
        settingColor(setColorFive, props?.[i]?.level);
      }
    }
  }, [props]);

  /** 레벨에 맞는 색 정해주기 */
  const settingColor = (setFunction, level) => {
    if (level === "cheat") {
      setFunction("#C54E4E");
    } else if (level === "abnormal") {
      setFunction("#FFD561");
    } else {
      setFunction("#8CE0AE");
    }
  };

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 264 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.71127 11.6818H7.97091V13.5902H2.71127V11.6818ZM1.78036 14.3582H8.90182V10.9254H1.78036V14.3582ZM2.75782 5.20036H7.90109V7.17854H2.75782V5.20036ZM5.81818 9.13345V7.94654H8.84364V4.43236H1.82691V7.94654H4.864V9.13345H0.605091V9.90145H10.0538V9.13345H5.81818Z"
        fill="#242424"
      />
      <path
        d="M18.2729 9.13707C18.2729 8.93624 18.4358 8.77344 18.6366 8.77344H77.6265V9.50071H18.6366C18.4358 9.50071 18.2729 9.3379 18.2729 9.13707Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.625 8.77246H79.0896C79.1868 8.77246 79.2799 8.81077 79.3486 8.87897L105.346 34.6971C105.489 34.8392 105.489 35.0694 105.346 35.2114C105.203 35.3534 104.971 35.3534 104.828 35.2114L78.938 9.49973H77.625V8.77246Z"
        fill="#BDBDBD"
      />
      <path
        d="M9.42545 40.5461C7.59855 40.2552 5.81818 38.9868 5.81818 37.5323V37.0668H4.84073V37.5323C4.84073 38.9868 3.08364 40.2552 1.22182 40.5461L1.60582 41.3024C3.18836 41.0115 4.68945 40.1272 5.32945 38.8704C5.98109 40.1155 7.48218 40.9999 9.05309 41.3024L9.42545 40.5461ZM2.78109 44.0137H1.82691V46.9926H9.01818V46.213H2.78109V44.0137ZM5.80655 42.3963V40.6275H4.85236V42.3963H0.605091V43.1643H10.0887V42.3963H5.80655ZM13.4348 48.4472H14.2145L17.6937 37.1366H16.9257L13.4348 48.4472ZM23.3634 38.2421H25.0274V40.9883C24.4688 41.0115 23.9103 41.0232 23.3634 41.0348V38.2421ZM27.471 40.8021C26.9823 40.8603 26.4703 40.9068 25.9466 40.9301V38.2421H26.9823V37.4857H21.4085V38.2421H22.4325V41.0581C21.9786 41.0581 21.5248 41.0581 21.1175 41.0581L21.2106 41.8493C23.0143 41.8377 25.4346 41.8144 27.5292 41.4886L27.471 40.8021ZM23.5146 45.2123H29.1815V42.7104H22.5488V43.4552H28.239V44.5024H22.5837V47.109H29.5655V46.3526H23.5146V45.2123ZM29.1815 39.0799V36.7875H28.2274V42.2101H29.1815V39.8712H30.7292V39.0799H29.1815Z"
        fill="#242424"
      />
      <path
        d="M39.2729 41.8646C39.2729 41.6638 39.4358 41.501 39.6366 41.501H77.8184V42.2282H39.6366C39.4358 42.2282 39.2729 42.0654 39.2729 41.8646Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.8184 41.5H79.2186C79.3114 41.5 79.4005 41.5383 79.4661 41.6065L87.525 49.5C87.6617 49.642 87.6617 49.8723 87.525 50.0143C87.3883 50.1563 87.1667 50.1563 87.03 50.0143L79.0736 42.2273H77.8184V41.5Z"
        fill="#BDBDBD"
      />
      <path
        d="M4.11927 68.9676H3.17673V70.8411C3.17673 72.7378 1.98982 74.7393 0.488727 75.5073L1.05891 76.2753C2.23418 75.6469 3.2 74.3436 3.65382 72.8193C4.13091 74.2505 5.07345 75.4724 6.17891 76.0658L6.77236 75.3094C5.30618 74.5764 4.11927 72.6796 4.11927 70.8411V68.9676ZM10.368 72.2956H8.64582V68.0716H7.69164V78.4978H8.64582V73.0869H10.368V72.2956ZM18.594 70.8294H13.474V69.1654H18.594V70.8294ZM19.5365 68.4091H12.5431V71.5858H19.5365V68.4091ZM20.7584 73.2265V72.4585H11.3096V73.2265H15.5569V74.2505H12.45V74.972H18.6056V75.9029H12.4849V78.3814H19.8624V77.6367H13.4158V76.6011H19.5482V74.2505H16.5111V73.2265H20.7584Z"
        fill="#242424"
      />
      <path
        d="M29.2729 73.1371C29.2729 72.9362 29.4358 72.7734 29.6366 72.7734H55.2729V73.5007H29.6366C29.4358 73.5007 29.2729 73.3379 29.2729 73.1371Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.2729 72.7656H56.6589C56.7508 72.7656 56.8389 72.8047 56.9039 72.8743L78.9093 96.0458C79.0446 96.1907 79.0446 96.4257 78.9093 96.5706C78.774 96.7155 78.5546 96.7155 78.4193 96.5706L56.5154 73.5077H55.2729V72.7656Z"
        fill="#BDBDBD"
      />
      <path
        d="M243.685 6.7829C243.685 5.86363 244.442 5.20036 245.466 5.20036C246.49 5.20036 247.246 5.86363 247.246 6.7829C247.246 7.71381 246.49 8.37708 245.466 8.37708C244.442 8.37708 243.685 7.71381 243.685 6.7829ZM250.248 7.15526V9.37781H251.202V4.07163H250.248V6.38726H248.119C247.921 5.21199 246.862 4.42072 245.466 4.42072C243.906 4.42072 242.778 5.38654 242.778 6.7829C242.778 8.1909 243.906 9.15672 245.466 9.15672C246.874 9.15672 247.933 8.35381 248.13 7.15526H250.248ZM245.442 13.6018V12.4614H251.202V9.94799H244.477V10.7044H250.26V11.7516H244.5V14.3582H251.598V13.6018H245.442ZM262.791 8.58654V7.81854H261.36C261.57 6.70145 261.57 5.81708 261.57 5.13054V4.4789H254.53V5.23526H260.627C260.627 5.91017 260.615 6.71308 260.394 7.81854H253.319V8.58654H257.555V9.93636H254.436V10.6927H260.65V11.7749H254.471V14.3698H261.86V13.6134H255.414V12.4614H261.604V9.93636H258.521V8.58654H262.791Z"
        fill="#242424"
      />
      <path
        d="M234.725 9.13707C234.725 8.93624 234.562 8.77344 234.361 8.77344H165.816V9.50071H234.361C234.562 9.50071 234.725 9.3379 234.725 9.13707Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.816 8.77246H164.362C164.265 8.77246 164.173 8.81077 164.105 8.87897L154.65 18.3335C154.508 18.4755 154.508 18.7058 154.65 18.8478C154.792 18.9898 155.022 18.9898 155.164 18.8478L164.512 9.49973H165.816V8.77246Z"
        fill="#BDBDBD"
      />
      <path
        d="M242.974 67.7336V68.5016H246.896C246.698 70.9802 245.301 72.9816 242.497 74.3198L243.009 75.0995C246.477 73.4005 247.861 70.7475 247.861 67.7336H242.974ZM249.968 66.6049V77.0427H250.922V66.6049H249.968ZM254.47 71.2478H258.147V70.4798H254.47V68.3271H258.38V67.5475H253.516V74.5293H254.33C256.297 74.5293 257.647 74.4595 259.253 74.1802L259.159 73.4122C257.623 73.6682 256.32 73.7496 254.47 73.7496V71.2478ZM262.813 70.794H261.091V66.6049H260.137V77.0311H261.091V71.5969H262.813V70.794Z"
        fill="#242424"
      />
      <path
        d="M234.468 71.682C234.468 71.4812 234.305 71.3184 234.104 71.3184H219.377V72.0456H234.104C234.305 72.0456 234.468 71.8828 234.468 71.682Z"
        fill="#BDBDBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.377 71.3184H217.922C217.826 71.3184 217.733 71.3567 217.665 71.4249L211.741 77.3496C211.599 77.4916 211.599 77.7218 211.741 77.8638C211.883 78.0058 212.113 78.0058 212.255 77.8638L218.073 72.0456H219.377V71.3184Z"
        fill="#BDBDBD"
      />
      <circle cx="200" cy="95.3179" r="14.5455" fill={etcColor} />
      <path
        d="M191.471 99.682H196.206V98.4194H192.978V96.4034H195.625V95.1409H192.978V93.3998H196.094V92.1474H191.471V99.682Z"
        fill="white"
      />
      <path
        d="M199.232 99.682H200.739V93.3998H202.877V92.1474H197.114V93.3998H199.232V99.682Z"
        fill="white"
      />
      <path
        d="M206.856 99.8245C207.833 99.8245 208.638 99.4376 209.259 98.7147L208.464 97.778C208.057 98.2158 207.548 98.5212 206.907 98.5212C205.715 98.5212 204.942 97.5336 204.942 95.8943C204.942 94.2754 205.797 93.298 206.937 93.298C207.507 93.298 207.945 93.5627 208.322 93.9292L209.116 92.9722C208.627 92.4631 207.864 92.0049 206.907 92.0049C205.003 92.0049 203.404 93.4609 203.404 95.9452C203.404 98.4602 204.952 99.8245 206.856 99.8245Z"
        fill="white"
      />
      <path
        d="M101.664 42.9587C101.664 42.9587 98.6054 42.5832 94.725 48.6508C90.8446 54.7183 81.2627 72.4002 81.2627 72.4002C81.2627 72.4002 92.4753 69.2999 97.3674 76.7723L103.081 60.0835L101.664 42.9587Z"
        fill={handColor}
      />
      <path
        d="M78.6441 78.7878C78.6441 78.7878 79.2724 74.4312 84.4435 74.108C89.4334 73.7848 93.1668 74.5987 96.7672 79.0152C100.283 83.324 105.817 84.3773 105.817 84.3773C105.817 84.3773 98.8695 87.8842 101.153 92.7436C103.437 97.6029 85.6155 89.117 83.1628 87.3456C80.9639 85.7657 77.9555 82.4264 78.6441 78.7878Z"
        fill={handColor}
      />
      <path
        d="M114.394 98.3449C120.72 98.3449 125.848 95.1702 125.848 91.254C125.848 87.3378 120.72 84.1631 114.394 84.1631C108.067 84.1631 102.939 87.3378 102.939 91.254C102.939 95.1702 108.067 98.3449 114.394 98.3449Z"
        fill={handColor}
      />
      <path
        d="M162.315 42.9587C162.315 42.9587 165.374 42.5832 169.255 48.6508C173.135 54.7183 182.717 72.4002 182.717 72.4002C182.717 72.4002 171.504 69.2999 166.612 76.7723L160.899 60.0835L162.315 42.9587Z"
        fill={handColor}
      />
      <path
        d="M185.254 78.7878C185.254 78.7878 184.625 74.4312 179.454 74.108C174.464 73.7848 170.731 74.5987 167.131 79.0152C163.615 83.324 158.081 84.3773 158.081 84.3773C158.081 84.3773 165.028 87.8842 162.745 92.7436C160.473 97.6029 178.282 89.117 180.735 87.3456C182.934 85.7657 185.942 82.4264 185.254 78.7878Z"
        fill={handColor}
      />
      <path
        d="M149.447 98.3449C155.773 98.3449 160.902 95.1702 160.902 91.254C160.902 87.3378 155.773 84.1631 149.447 84.1631C143.121 84.1631 137.993 87.3378 137.993 91.254C137.993 95.1702 143.121 98.3449 149.447 98.3449Z"
        fill={handColor}
      />
      <path
        d="M177.343 91.9067C162.59 98.8982 161.462 96.1137 161.462 96.1137C161.462 96.1137 158.092 100.224 149.534 100.465C143.279 100.67 138.939 97.4999 138.551 97.1142C138.163 96.7284 135.459 94.402 135.969 90.9424H128.125C128.61 94.402 125.774 96.8731 125.313 97.2588C125.083 97.4517 121.761 100.429 114.354 100.357C106.147 100.272 102.947 96.1016 102.947 96.1016C102.947 96.1016 101.177 98.3678 86.7509 91.8947L78.5439 110.579H185.453L177.343 91.9067Z"
        fill={objectColor}
      />
      <path
        d="M131.998 41.8636C141.036 41.8636 148.362 32.8279 148.362 21.6818C148.362 10.5357 141.036 1.5 131.998 1.5C122.961 1.5 115.635 10.5357 115.635 21.6818C115.635 32.8279 122.961 41.8636 131.998 41.8636Z"
        fill={faceColor}
      />
      <path
        d="M136.423 88.8536C136.853 87.8696 137.479 86.9585 138.254 86.2296C145.48 79.4752 156.589 83.3991 156.589 83.3991L160.362 41.5728L147.077 34.4054C147.077 34.4054 144.976 38.6208 140.847 41.2934C136.988 43.7838 133.584 44.1239 132.035 44.1239C130.487 44.1239 127.328 43.9174 123.15 41.2083C119.009 38.5357 116.919 34.3203 116.919 34.3203L103.635 41.4878L107.408 83.3262C107.408 83.3262 118.517 79.4023 125.743 86.1689C126.53 86.9099 127.169 87.8453 127.574 88.8658L136.423 88.8536Z"
        fill={bodyColor}
      />
    </svg>
  );
};
