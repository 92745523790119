import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import customAxios, { baseURL } from "../api/handler";
import useGetQnADetail from "../hooks/useGetQnADetail";
import cross from "../img/cross.png";
import ImgBox from "../components/ImgBox";
import { useRecoilValue } from "recoil";
import { bannerOn, userInfo } from "../atoms";
import CloseQnA from "./CloseQnA";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { ModalBack, ModalHeader } from "../components/Style";
import imgInput from "../img/imgInput.svg";
import useToast from "../hooks/useToast";

const ReAnswer = ({ setReAnswerModal, setCommentUpdate, commentUpdate }) => {
  const { uid } = useParams();
  const { data } = useGetQnADetail({ uid });
  const user = useRecoilValue(userInfo);
  const banneris = useRecoilValue(bannerOn);

  const showToast = useToast();

  const [context, setContext] = useState();
  const [modalLevel, setModalLevel] = useState(1);

  useEffect(() => {
    answerStepOne();
  }, []);

  const goBack = () => {
    console.log(modalLevel);
    if (modalLevel === 2) {
      setReAnswerModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    window.history.pushState(null, "", window.location.href);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  // 댓글에 대한 pre-uuid 받아오기
  const [questionComponentUid, setQuestionComponentUid] = useState();
  const answerStepOne = async () => {
    await customAxios
      .post(`${baseURL}/api/v1/questions/components/pre-uuid`, {
        hostUuid: user.hostUuid,
        hostEmail: user.hostEmail,
        questionUuid: uid,
      })
      .then(({ data }) => {
        setQuestionComponentUid(data.questionComponentUuid);
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-001" ||
          response.data.errorCode === "QUESTION-001"
        ) {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  // upload를 위한 이미지 받아오기 함수
  const [imgRawData, setImgRawDataList] = useState([]); // 이미지 원본 상태 값
  const [imgData, setImgData] = useState([]); // 이미지 처리 후 상태 값

  // 사진 올리기 버튼을 눌렀을 때 사진을 업로드
  const upload = (e) => {
    const files = e.target.files; // 현재 받아온 이미지들의 원본을 저장
    // console.log(newArr)
    let imgUrl = []; // 데이터 처리를 위한 빈 배열 생성

    for (
      let i = 0;
      i < (files.length < 6 ? files.length : 6 - imgData.length);
      i++
    ) {
      let reader = new FileReader();
      imgUrl.push(files[i]);
      reader.onload = () => {
        switch (reader.result?.split("/")[1]?.split(";")[0]) {
          case "jpg":
          case "jpeg":
          case "png":
          case "heic":
            imgUrl[i] = reader.result;
            setImgData((imgData) => [...imgData, imgUrl[i]]);
            answerStepTwo({
              hostUuid: user.hostUuid,
              hostEmail: user.hostEmail,
              questionUuid: uid,
              questionComponentUuid: questionComponentUid,
              image: files[i],
            });
            break;
          default:
            break;
        }
      };
      reader.readAsDataURL(files[i]);
    }
    // setImgData(tempImgDataArray)
  };

  // pre-signed uuid 받아온 후 S3 버킷에 업로드 함수 실행
  const [preSignedUrl, setPreSignedUrl] = useState();
  const [s3KeyList, setS3KeyList] = useState([]); // backend에 보내줄 s3KeyList
  // console.log(s3KeyList);
  const answerStepTwo = async ({
    hostUuid,
    hostEmail,
    questionUuid,
    questionComponentUuid,
    image,
  }) => {
    let tempS3 = s3KeyList;
    await customAxios
      .post(`${baseURL}/api/v1/questions/components/pre-signed-url`, {
        hostUuid,
        hostEmail,
        questionUuid,
        questionComponentUuid,
        offset: -540,
      })
      .then(({ data }) => {
        tempS3.push(data.preSignedUrl.imageS3Key);
        setS3KeyList(tempS3);
        setPreSignedUrl(data.preSignedUrl);
        uploadToS3({
          image: image,
          imageUploadUrl: data.preSignedUrl.imageUploadUrl,
        });
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-001" ||
          response.data.errorCode === "QUESTION-001"
        ) {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  const uploadToS3 = async ({ image, imageUploadUrl }) => {
    // console.log(image)
    await axios
      .put(`${imageUploadUrl}`, image, {
        headers: {
          "Content-Type": "image/*",
        },
      })
      .then((data) => {
        // console.log(data);
      })
      .catch(({ data }) => {
        console.log(data);
      });
  };

  // 최종 업로드
  const uploadFinal = async () => {
    await customAxios
      .post(`${baseURL}/api/v1/questions/components`, {
        offset: -540,
        context: context,
        questionUuid: uid,
        questionComponentUuid: questionComponentUid,
        confirmImageS3KeyList: s3KeyList,
      })
      .then(() => {
        setReAnswerModal(false);
        setCommentUpdate(!commentUpdate);
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-001" ||
          response.data.errorCode === "QUESTION-001" ||
          response.data.errorCode === "QUESTION-002"
        ) {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  // 최종 업로드 전 이미지 지우기
  let tempImgList = [];

  const removeImg = (index) => {
    tempImgList = imgData;
    tempImgList.splice(index, 1);
    let b = Array.from(tempImgList);
    setImgData(b);
  };

  // 최종 업로드 전 이미지 삭제시 백엔드로 보내줄 s3 키 리스트 수정하기
  let tempS3KeyList = [];

  const removeS3KeyList = (index) => {
    tempS3KeyList = s3KeyList;
    tempS3KeyList.splice(index, 1);
    let tempTwo = Array.from(tempS3KeyList);
    setS3KeyList(tempTwo);
  };

  // 저장하기 버튼 활성화
  const [isContentIn, setIsContentIn] = useState(true);
  useEffect(() => {
    if (context) {
      // console.log(isContentIn);
      setIsContentIn(false);
    } else {
      setIsContentIn(true);
    }
  }, [context]);

  // 작성 취소 모달
  const [modalClose, setModalClose] = useState(false);

  const modalClosing = () => {
    setModalClose(false);
  };

  useScrollLockThree();

  const modalOff = () => {
    setModalClose(true);
  };

  return (
    <>
      {modalClose && (
        <CloseQnA
          uid={uid}
          setModalLevel={setModalLevel}
          close={modalClosing}
          setReAnswerModal={setReAnswerModal}
        />
      )}
      <ModalBack />
      <StReAnswer
        banneris={banneris}
        isContentIn={isContentIn}
        imgLength={imgData.length}
      >
        <div className="wrapper">
          <ModalHeader title="답글 쓰기" off={modalOff} />
          <div className="contentsBox">
            <div className="title">답글 내용</div>
            <textarea
              className="textArea"
              placeholder="내용 입력 (최대 1500자)"
              onChange={(e) => {
                setContext(e.target.value);
              }}
            />
            <div className="imgBox">
              <div className="imgTitle">이미지 첨부</div>
              <div className="inBox">
                <input
                  className="imgInput"
                  id="file"
                  type="file"
                  onChange={upload}
                  multiple
                  accept="image/jpeg, image/jpg, image/png, image/heic"
                />
                {imgData &&
                  imgData.map((value, index) => {
                    return (
                      <ImgBox
                        url={value}
                        key={index}
                        s3KeyList={s3KeyList}
                        onClick={() => {
                          removeImg(index);
                          removeS3KeyList(index);
                        }}
                      />
                    );
                  })}
                {imgData.length === 0 ? (
                  <label className="whiteBox" htmlFor="file">
                    <img src={imgInput} alt="" />
                    <div>이미지 선택</div>
                  </label>
                ) : (
                  <label className="afterUpload" htmlFor="file">
                    <img src={cross} alt="" />
                  </label>
                )}
              </div>
            </div>
            <div className="tag">
              * 이미지 형식은 JPEG, JPG, PNG, HEIC만 가능하며 최대 6개까지 첨부
              가능해요.
            </div>
            <div className="buttonBox">
              <button
                className="cancelButton"
                onClick={() => {
                  // setReAnswerModal(false);
                  setModalClose(true);
                }}
              >
                취소
              </button>
              <button
                className="submitButton"
                onClick={uploadFinal}
                disabled={isContentIn}
              >
                저장하기
              </button>
            </div>
          </div>
        </div>
      </StReAnswer>
    </>
  );
};

const StReAnswer = styled.div`
  position: fixed;
  background: #ffffff;
  box-sizing: border-box;
  overflow: scroll;
  .contentsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 38px;
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
      margin-top: 29px;
      .submitButton,
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 42px;
        border-radius: 42px;
        border: none;
        font-size: 14px;
        font-family: "medium";
        line-height: 25px;
        cursor: pointer;
      }
      .submitButton {
        color: white;
        background-color: ${(props) =>
          props.isContentIn ? "#D9D9D9" : "#ED893E"};
      }
      .cancelButton {
        border: 1px solid black;
        background-color: #ffffff;
        color: black;
      }
    }
    .title {
      font-size: 13px;
      font-family: "medium";
      line-height: 18px;
      margin: 0 auto 10px 0;
    }
    .imgBox {
      width: 100%;
      margin: 28px 0 12px;
      .imgTitle {
        font-size: 13px;
        font-family: "medium";
        line-height: 18px;
      }
      .inBox {
        display: grid;
        grid-template-columns: ${(props) =>
          props.imgLength === 0 ? "1fr" : "repeat(auto-fill, 82px)"};
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        width: 100%;
        min-height: 114px;
        margin: 10px 0 12px;
        background-color: #f3f3f3;
        padding: 16px 18px;
        box-sizing: border-box;
        .imgInput {
          display: none;
        }
        .whiteBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 151px;
          height: 42px;
          margin: 0 auto;
          border-radius: 43px;
          padding: 11px 30px;
          box-sizing: border-box;
          background-color: #ffffff;
          font-size: 14px;
          line-height: 18px;
          font-family: "medium";
        }
        .afterUpload {
          display: ${(props) => (props.imgLength < 6 ? "flex" : "none")};
          justify-content: center;
          align-items: center;
          width: 82px;
          height: 82px;
          border-radius: 12px;
          border: 1px solid #bdbdbd;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
    .tag {
      font-size: 11px;
      color: #444444;
      line-height: 16px;
      text-align: center;
    }
    .textArea {
      width: 100%;
      min-height: 261px;
      border: 0.6px solid #818181;
      border-radius: 6px;
      padding: 11.5px 16px;
      font-size: 16px;
      line-height: 24px;
      box-sizing: border-box;
      font-family: "regular";
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
      &:focus {
        outline: none;
      }
      resize: vertical;
    }
  }
  .middleTitleBoxBox {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .middleTitleBox {
      display: flex;
      align-items: center;
      margin: 8px 0;
      .middleTitle {
        font-size: 19px;
        font-family: "bold";
      }
      .arrow {
        height: 18px;
        margin-right: 12px;
        cursor: pointer;
      }
    }
    .cancelImg {
      width: 14px;
      height: 14px;
      margin: auto 0;
      cursor: pointer;
    }
  }

  @media ${device.pc} {
    z-index: 52;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 580px;
    padding: 60px;
    box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
    .contentsBox {
      .title {
        font-size: 15px;
        line-height: 22px;
        margin-right: auto;
      }
      .imgBox {
        .imgTitle {
          font-size: 15px;
          font-family: "medium";
        }
        .inBox {
          width: 460px;
        }
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        gap: 21px;
        .submitButton,
        .cancelButton {
          width: 204px;
          height: 52px;
          font-size: 17px;
          line-height: 25px;
          color: black;
        }
        .submitButton {
          color: white;
        }
      }
    }
  }
  @media ${device.tabMob} {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    padding: 0 24px;
    height: calc(100% - 60px);
    z-index: 49;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      height: -webkit-fill-available;
      box-sizing: border-box;
    }
    .contentsBox {
      margin-top: 14px;
    }
  }
`;

export default ReAnswer;
