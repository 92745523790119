import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
// import useGetReport from '../hooks/useGetReport'

const Graph = ({ data }) => {
  const analysis = data;

  const [maxBarWidth, setMaxBarWidth] = useState(1);
  const wid = useRef();
  const [fullWidth, setFullWidth] = useState(1);
  const [realBarWidth, setRealBarWidth] = useState(0);

  useEffect(() => {
    let a = maxBarWidth;
    // eslint-disable-next-line
    analysis?.map((value) => {
      if (value.count > a) {
        a = value.count;
      }
    });
    while (a % 4 !== 0) {
      a++;
    }
    setMaxBarWidth(a);
  }, [analysis, maxBarWidth]);

  useEffect(() => {
    setFullWidth(wid?.current?.clientWidth);
  }, []);

  useEffect(() => {
    setRealBarWidth(fullWidth / maxBarWidth);
  }, [wid, fullWidth]);

  return (
    <StGraph>
      <div className="leftGraph">
        {analysis?.map((value, index) => {
          let temp;
          if (value?.level === "cheat") {
            temp = "#C83B38";
          } else if (value?.level === "abnormal") {
            temp = "#FFBC00";
          } else {
            temp = "#3C9C9C";
          }
          return (
            <div className="bodyParts" key={index}>
              <div className="redDot" style={{ background: `${temp}` }} />
              <div className="bodyPart">{value.partKr}</div>
            </div>
          );
        })}
      </div>
      <div ref={wid} className="middle">
        {analysis?.map((value, index) => {
          let width = 0;
          width = realBarWidth * value?.count;
          return (
            <div className="barGraph" key={index}>
              <StBar full={maxBarWidth} width={width} />
            </div>
          );
        })}
        <div className="columnLineOne">
          0<div className="lineForColumn" />
        </div>
        <div className="columnLineTwo">
          {maxBarWidth / 4}
          <div className="lineForColumn" />
        </div>
        <div className="columnLineThree">
          {(maxBarWidth / 4) * 2}
          <div className="lineForColumn" />
        </div>
        <div className="columnLineFour">
          {(maxBarWidth / 4) * 3}
          <div className="lineForColumn" />
        </div>
        <div className="columnLineFive">
          {maxBarWidth}
          <div className="lineForColumn" />
        </div>
      </div>
      <div className="rightGraph">
        {analysis?.map((value, index) => (
          <div key={index} className="timeGraph">
            {value.count} / {Math.floor(value.accDuration)}초
          </div>
        ))}
      </div>
    </StGraph>
  );
};

const StBar = styled.div`
  width: ${(props) => props.width}px;
  height: 5px;
  background-color: #008cff;
  @media ${device.pc} {
    height: 10px;
  }
`;

const StGraph = styled.div`
  display: flex;
  align-items: flex-end;
  .timeGraph {
    /* width : 80px; */
    line-height: 18px;
  }
  .leftGraph {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    gap: 8px;
    /* width: 13%; */
    height: 100%;
    margin-right: 25px;
    .bodyParts {
      width: 80px;
      display: flex;
      align-items: center;
      .bodyPart {
        display: flex;
        justify-content: flex;
        margin-left: 10px;
      }
      .redDot {
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }
  }
  .middle {
    position: relative;
    /* flex-grow: 1; */
    height: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    gap: 8px;
    .lineForColumn {
      width: 1px;
      height: 100%;
      /* margin-top : 10px; */
      background-color: #e9e9e9;
    }
    .columnLineOne,
    .columnLineTwo,
    .columnLineThree,
    .columnLineFour,
    .columnLineFive {
      position: absolute;
      top: -20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1px;
      height: 120%;
    }
    .columnLineOne {
      left: 0;
    }
    .columnLineTwo {
      left: 25%;
    }
    .columnLineThree {
      left: 50%;
    }
    .columnLineFour {
      left: 75%;
    }
    .columnLineFive {
      right: 0;
    }
    .barGraph {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }
  }
  .rightGraph {
    margin-left: 25px;
    height: 100%;
    /* width : 13%; */
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    gap: 8px;
  }
  @media ${device.pc} {
    margin-top: 20px;
    height: 200px;
    /* width : 551px; */
    /* flex-grow: 1; */
    .rightGraph {
      /* width: 70px; */
      text-align: end;
    }
    .leftGraph {
    }
    .middle {
      width: 370px;
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    margin: 50px auto 0;
    height: 122px;
    font-size: 12px;
    .middle {
      flex-grow: 1;
    }
    .leftGraph {
      width: 13%;
    }
    .rightGraph {
      font-size: 11px;
    }
  }
`;

export default Graph;
