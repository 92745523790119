import React, { useEffect, useRef, useState } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import TextLine from "../atom/TextLine";
import OnChangeInput from "../atom/OnchangeInput";
import AlertMessage from "../molecule/AlertMessage";
import WarningList from "../molecule/WarningList";

interface DirectChargeProps {
  setCharge: React.Dispatch<React.SetStateAction<number>>;
}

const DirectCharge: React.FC<DirectChargeProps> = (props) => {
  const [chargePrice, setChargePrice] = useState<number>(0);
  const [show, setShow] = useState<string>("");
  const inputRef = useRef<any>(null);

  /** input 내에서 세번째 숫자마다 "," 나오기 + 커서위치 고정시켜주기 + 충전할 금액 지정해주기 */
  const inputChargePrice = (price: string, cursorLocation: any) => {
    if (price.replace(/,|원/g, "").length > show.replace(/,|원/g, "").length) {
      setTimeout(() => {
        if (price.length > 2 && price.replace(/,|원/g, "").length % 3 === 1) {
          inputRef.current.selectionStart = cursorLocation + 1;
          inputRef.current.selectionEnd = cursorLocation + 1;
        } else {
          inputRef.current.selectionStart = cursorLocation;
          inputRef.current.selectionEnd = cursorLocation;
        }
      }, 0);
    } else {
      setTimeout(() => {
        if (price.length > 2 && price.replace(/,|원/g, "").length % 3 === 0) {
          inputRef.current.selectionStart = cursorLocation - 1;
          inputRef.current.selectionEnd = cursorLocation - 1;
        } else {
          inputRef.current.selectionStart = cursorLocation;
          inputRef.current.selectionEnd = cursorLocation;
        }
      }, 0);
    }
    setShow(Number(price.replace(/,|원/g, "")).toLocaleString());
  };

  useEffect(() => {
    setChargePrice(Number(show.replace(/원|,/g, "")) / 100);
    props.setCharge(Number(show.replace(/,|원/g, "")));
  }, [show]);

  return (
    <>
      <DivTwoPartImprove display="grid">
        <TextLine
          text="충전 할 금액 (원)"
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
          pc_margin="0 0 2px 0"
        />
        <OnChangeInput
          placeholderText="금액을 입력해주세요."
          lineheight={36}
          fontsize={17}
          border="1px solid #d9d9d9"
          border_radius={8}
          height={56}
          pc_height={60}
          padding="10px 16px"
          pc_padding="12px 16px"
          placeholdercolor="#BDBDBD"
          type="text"
          inputRef={inputRef}
          id="chargePrice"
          value={chargePrice > 0 ? show : ""}
          // value={chargePrice > 0 ? show + "원" : ""}
          onchange={(e) => {
            if (!isNaN(e.nativeEvent.data))
              inputChargePrice(e.target.value, inputRef.current.selectionStart);
          }}
          onblur={() => {
            setShow(
              (
                Math.ceil(Number(show.replace(/,|원/g, "")) / 10000) * 10000
              ).toLocaleString()
            );
          }}
        />
        <AlertMessage
          text="10,000원 단위로 충전이 가능합니다."
          pc_fontsize={16}
          fontsize={14}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove display="grid" margin="16px 0 0 0">
        <TextLine
          text="충전 할 적립금 (C)"
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
          pc_margin="0 0 2px 0"
        />
        <TextLine
          border="1px solid #d9d9d9"
          border_radius={8}
          lineheight={36}
          height={56}
          pc_height={60}
          fontcolor="#2276DC"
          padding="10px 16px"
          pc_padding="12px 16px"
          text={chargePrice > 0 ? chargePrice.toLocaleString() : ""}
          // text={chargePrice > 0 ? chargePrice.toLocaleString() + "c" : ""}
        />
        <AlertMessage
          text="100c 단위로 충전이 가능합니다."
          pc_fontsize={16}
          fontsize={14}
        />
      </DivTwoPartImprove>
    </>
  );
};

export default DirectCharge;
