import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SelectedToolTip, SelectedToolTipModal, modalPadding } from "../atoms";
import SelectedRecommBadge from "../img/SelectedRecommBadge.svg";
import SelectedUrgentBadge from "../img/SelectedUrgentBadge.svg";
/** 티켓ID 83 관련 추가 */
const SelectedToolTipBadge = ({
  videoLength
}) => {
  /** 티켓ID 77 관련 추가 */
  const modalOn = useRecoilValue(modalPadding);
  // const values = useRecoilValue(SelectedToolTip);
  const setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  let values;
  if(modalOn) {
    values = useRecoilValue(SelectedToolTipModal);
  } else {
    values = useRecoilValue(SelectedToolTip);
  }

  let Badge;
  if(values.level === 'cheat') {
    Badge = SelectedUrgentBadge;
  } else {
    Badge = SelectedRecommBadge;
  }

  // 언마운트 시 선택해제 추가
  useEffect(() => {
    return () => {
      setSelectedToolTip({isSelected: false});
    }
  }, [])

  return (
    <>
      {values.isSelected && (
        <BadgeContainer
          start={values.start}
          end={values.end}
          videoLength={videoLength}
          zIndex={8}
          level={values.level}
        >
          <div className="badgeBox">
            <img
              className="badgeImage"
              src={Badge}
            />
            <div className="textDiv">
              <span className="badgeText">
                {String(values.index + 1).padStart(2, "0")}
              </span>
            </div>
          </div>
        </BadgeContainer>
      )}
    </>
  )
}

const BadgeContainer = styled.div`
  position: absolute;
  left : calc( ${props=>props.start} * 100% / ${props=>props.videoLength} - 20px);
  top: 27px;
  /* opacity: 0.9; */
  z-index: ${props=>props.zIndex};
  .badgeBox {
    position: relative;
    .badgeImage {
      position: absolute;
    }
    .textDiv {
      display: flex;
      position: absolute;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      .badgeText {
        color: ${props=>props.zIndex === 9 ? "#FFFFFF" : (props=>props.level === "cheat" ? "#C83B38" : "#FFBC00")}
      }
    }
  }
  @media ${device.tabMob} {
    left : calc( ${props=>props.start} * 100% / ${props=>props.videoLength} - 14px);
    top: 42px;
    .badgeBox {
      .badgeImage {
        width: 28px;
        height: 33px;
      }
      .textDiv {
        width: 28px;
        height: 28px;
        .badgeText {
          font-size : 11px;
        }
      }
    }
  }
`;

export default SelectedToolTipBadge;