import React, { useState } from "react";
import styled from "styled-components";
import RefundSpecific from "../modal/RefundSpecific";
import { device } from "../hooks/device";

// img
import refundAlert from "../img/refundAlert.svg";
import arrow from "../img/arrow.svg";
import { useNavigate } from "react-router-dom";
import useGetCreditRefundList from "../hooks/useGetCreditRefundList";
import RefundError from "../modal/RefundError";

const RefundList = ({ setMyPageScroll }) => {
  const [refundSpecificModal, setRefundSpecificModal] = useState(false);
  const [refundErrorModal, setRefundErrorModal] = useState(false);
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState();
  const { data } = useGetCreditRefundList();
  const [refundId, setRefundId] = useState();

  return (
    <>
      {refundSpecificModal && (
        <RefundSpecific
          setMyPageScroll={setMyPageScroll}
          refundId={refundId}
          refundSpecificModal={refundSpecificModal}
          setRefundSpecificModal={setRefundSpecificModal}
        />
      )}
      {refundErrorModal && (
        <RefundError
          errorCode={errorCode?.split(":")[1].split(")")[0]}
          setRefundErrorModal={setRefundErrorModal}
        />
      )}
      <StRefundList>
        <div className="wrapper">
          <div className="pageHeader">
            <img
              onClick={() => {
                navigate(`/myCredit`);
              }}
              src={arrow}
            />
            <div>환불 신청내역</div>
          </div>
          <div className="container">
            <div className="listBodyThickLine onlyForPc" />
            <div className="listBodyHeader onlyForPc">
              <div>구분</div>
              <div>상세 내역</div>
              <div>환불 금액</div>
            </div>
            <div className="listBodyBlackLine onlyForPc" />
            {data?.refundList?.length > 0 ? (
              data?.refundList?.map((value, index) => (
                <div key={index}>
                  <div
                    className="item"
                    onClick={() => {
                      setRefundId(value?.groupData?.groupId);
                    }}
                  >
                    <div className="itemContainer">
                      <div className="itemAttribute">환불</div>
                      <div className="itemMiddle">
                        <div className="middleLeft">
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setRefundSpecificModal(true);
                            }}
                          >
                            {value?.paymentsCreditsTypeDetail}
                          </div>
                          {value?.groupData?.groupStatus === "환불 오류" && (
                            <img
                              src={refundAlert}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setErrorCode(value.paymentsCreditsTypeInfo);
                                setRefundErrorModal(true);
                              }}
                            />
                          )}
                        </div>
                        {value?.groupData?.groupStatus === "환불 오류" ? (
                          <div
                            className="middleRight"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setRefundSpecificModal(true);
                            }}
                          >
                            {value?.paymentsCreditsTradeAt} (오류코드 :{" "}
                            {value?.groupData?.groupId})
                          </div>
                        ) : (
                          <div
                            className="middleRight"
                            onClick={() => {
                              setRefundSpecificModal(true);
                            }}
                          >
                            {value?.paymentsCreditsTradeAt}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="itemTime">
                      {Number(value?.paymentsCredit).toLocaleString()}
                      <span>원</span>
                    </div>
                  </div>
                  {data?.refundList?.length !== index + 1 && (
                    <div
                      className="onlyForMobile"
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "#D9D9D9",
                      }}
                    />
                  )}
                </div>
              ))
            ) : (
              <div className="noListForPc onlyForPc">환불 내역 없음</div>
            )}
          </div>
          {data?.refundList?.length <= 0 && (
            <div className="noListForMobile onlyForMobile">환불 내역 없음</div>
          )}
        </div>
      </StRefundList>
    </>
  );
};

const StRefundList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f0f1f2;
  box-sizing: border-box;
  @media ${device.pc} {
    padding: 80px 0px;
    .wrapper {
      max-width: 1228px;
      width: 100%;
      margin: 0 auto;
    }
    .onlyForMobile {
      display: none;
    }
    .pageHeader {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      box-sizing: border-box;
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      gap: 19px;
      img {
        width: 28px;
        cursor: pointer;
      }
    }
    .container {
      width: 865px;
      min-height: 565px;
      padding: 60px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      margin: 60px auto;
      .noListForPc {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        color: #bdbdbd;
      }
      .listBodyHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;
        div {
          width: 160px;
          text-align: center;
          font-size: 16px;
          font-family: "medium";
          line-height: 19px;
        }
      }
      .listBodyThickLine {
        width: 100%;
        height: 4px;
        background-color: black;
      }
      .listBodyBlackLine {
        width: 100%;
        height: 1px;
        background-color: black;
      }
      .item {
        box-sizing: border-box;
        border-bottom: 1px solid #d9d9d9;
        .itemContainer {
          display: flex;
          align-items: center;
          min-height: 76px;
          padding: 14px 0px;
          box-sizing: border-box;
          .itemAttribute {
            width: 160px;
            text-align: center;
            font-size: 16px;
            font-family: "regular";
            line-height: 19px;
            color: #55595f;
          }
          .itemMiddle {
            display: grid;
            grid-template-rows: auto auto;
            gap: 4px;
            .middleLeft {
              display: flex;
              align-items: center;
              img {
                margin-left: 6px;
                z-index: 10;
              }
            }
            .middleRight {
              font-size: 14px;
              font-family: "regular";
              line-height: 20px;
              color: #55595f;
            }
          }
        }
        .itemTime {
          /* width: 100px; */
          text-align: center;
          font-size: 16px;
          font-family: "bold";
          line-height: 24px;
          padding-right: 25px;
          span {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    overflow: scroll;
    padding: 0 0 44px;
    .onlyForPc {
      display: none;
    }
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
    .noListForMobile {
      display: flex;
      flex-grow: 1;
      min-height: 500px;
      justify-content: center;
      align-items: center;
      color: #bdbdbd;
    }
    .pageHeader {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      padding: 24px;
      box-sizing: border-box;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
      gap: 12px;
      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .container {
      margin-top: 20px;
      .item {
        padding: 16px 24px;
        .itemContainer {
          .itemAttribute {
            line-height: 18px;
            font-size: 12px;
          }
          .itemMiddle {
            margin-top: 6px;
            display: grid;
            grid-template-rows: auto auto;
            gap: 2px;
            .middleLeft {
              display: grid;
              grid-template-columns: auto auto 1fr;
              align-items: center;
              gap: 6px;
            }
            .middleRight {
              font-size: 12px;
              font-family: "light";
              line-height: 20px;
              color: #55595f;
            }
          }
        }
        .itemTime {
          font-family: "semibold";
          line-height: 19px;
          min-width: 90px;
          text-align: right;
          span {
            font-size: 13px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .itemContainer {
        .itemAttribute {
          font-family: "regular";
          color: #55595f;
        }
        .itemMiddle {
          display: flex;
          flex-direction: column;
          .middleLeft {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
          }
        }
      }
      .itemTime {
        font-size: 16px;
        span {
          margin-left: 2px;
          font-family: "regular";
        }
      }
    }
  }
`;

export default RefundList;
