import jwt_decode from "jwt-decode";
import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getCookie, removeCookie, setCookie } from "../Cookie";
import {
  SetterOrUpdater,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { languageIs, examUpdate, userEmail, userInfo } from "../atoms";
import axios from "axios";
import { baseURL } from "../api/handler";

// img
import toggleBefore from "../img/toggle.svg";
import toggleAfter from "../img/toggleAfter.svg";
import ButtonOne from "./ButtonOne";
import notice from "../img/notice.svg";
import mypage from "../img/mypage.svg";
import LanguageChangeButton from "../atomic/molecule/LanguageChangeButton";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import Button from "../atomic/atom/Button";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

interface MobileHeaderProps extends MobileHeaderStyle {
  modalCloseAll: () => void;
  close: () => void;
  setNav: React.Dispatch<React.SetStateAction<(string | number | boolean)[]>>;
  shutModalOff: () => void;
  setLoginModalOn: SetterOrUpdater<boolean>;
  setResetPasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
  setFindIdModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSignUpChooseModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MobileHeaderStyle {
  dropdown: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({
  dropdown,
  modalCloseAll,
  close,
  setNav,
  shutModalOff,
  setLoginModalOn,
  setResetPasswordModal,
  setFindIdModal,
  setSignUpChooseModal,
}) => {
  const [clicked, setClicked] = useState(false);
  const [login, setLogin] = useState<any>(getCookie("token"));
  const navigate = useNavigate();
  const location = useLocation();
  const page = window.location.href.split("/")[3];
  const resetUserData = useResetRecoilState(userInfo);
  const resetUserEmailData = useResetRecoilState(userEmail);
  const users = useRecoilValue(userInfo);
  const update = useRecoilValue(examUpdate);

  // modal state
  const [langModal, setLangModal] = useState(false);

  const country = useRecoilValue(languageIs);
  const userData = useRecoilValue(userInfo);
  const setLang = useSetRecoilState(languageIs);
  const [buttonTextBeforeLogin, setButtonTextBeforeLogin] =
    useState<string>("시험 예약하기");
  const [buttonTextAfterLogin, setButtonTextAfterLogin] =
    useState<string>("지금 시작하기");
  useEffect(() => {
    if (getCookie("token")) {
      setLogin(jwt_decode(getCookie("token")));
    } else {
      setLogin(getCookie("token"));
    }
  }, [users]);

  const navigateKor = () => {
    setLangModal(false);
    setLang("/ko");
    setCookie("languageToken", "korean", {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
    // navigate(`/`);
    return navigating("ko");
  };

  const navigateEng = () => {
    setLangModal(false);
    setLang("/en");
    setCookie("languageToken", "english", {
      path: "/",
      maxAge: 60 * 60 * 24,
    });
    return navigating("en");
  };

  const navigating = (lang: string) => {
    let temp = location.pathname.split("/");
    let b = temp.splice(1, 1, lang);
    navigate(`${temp.join("/")}`);
  };

  useEffect(() => {
    if (dropdown) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dropdown]);

  useEffect(() => {
    if (country === "/ko") {
      setButtonTextBeforeLogin("지금 시작하기");
      setButtonTextAfterLogin("시험 예약하기");
    } else {
      setButtonTextBeforeLogin("Get Started Today");
      setButtonTextAfterLogin("Create event");
    }
  }, [country]);

  return (
    <>
      <StBack onClick={close} dropdown={dropdown} />
      <StBody className="fade" dropdown={dropdown}>
        <div className="wrapper">
          {login ? (
            <div className="upBox">
              <div
                className="welcome"
                onClick={() => {
                  setClicked(!clicked);
                }}
              >
                <div>
                  {country === "/ko" ? (
                    <div className="hello">
                      <span className="name">{userData?.hostName}</span> 님,
                      안녕하세요!
                    </div>
                  ) : (
                    <div className="hello">
                      Hello,&nbsp;
                      <span className="name">{userData?.hostName}</span>
                    </div>
                  )}
                  <div className="class">
                    {userData?.hostPlan === "individualPlan" && (
                      <TextTwoPart textKor="개인용" textEng="Individual Plan" />
                    )}
                    {userData?.hostPlan === "managerPlan" && (
                      <TextTwoPart
                        textKor="기업용 (관리자)"
                        textEng="Manager Plan"
                      />
                    )}
                    {userData?.hostPlan === "memberPlan" && (
                      <TextTwoPart
                        textKor="기업용 (구성원)"
                        textEng="Member Plan"
                      />
                    )}
                  </div>
                </div>
                {!clicked ? (
                  <img className="toggle" alt="" src={toggleAfter} />
                ) : (
                  <img className="toggle" alt="" src={toggleBefore} />
                )}
              </div>
              {clicked && (
                <>
                  <div
                    className="navigate"
                    onClick={() => {
                      navigate(`/myexam`);
                      close();
                    }}
                  >
                    <TextTwoPart
                      textKor="내 시험 관리"
                      textEng="View My Reports"
                    />
                  </div>
                  {userData?.hostPlan === "managerPlan" && (
                    <div
                      className="navigate"
                      onClick={() => {
                        navigate(`/myCredit`);
                        close();
                      }}
                    >
                      <TextTwoPart textKor="보유 적립금" textEng="My Credits" />
                    </div>
                  )}
                  <div
                    className="navigate"
                    onClick={() => {
                      navigate(`/invited`);
                      close();
                    }}
                  >
                    <TextTwoPart textKor="메시지 수신함" textEng="Messages" />
                  </div>
                  <div
                    className="navigate"
                    onClick={() => {
                      navigate(`/qna/1`);
                      close();
                    }}
                  >
                    <TextTwoPart textKor="1:1 문의" textEng="Q&A" />
                  </div>
                  <div
                    className="navigate"
                    onClick={() => {
                      removeCookie("token");
                      navigate(`/`);
                      // close();
                      setLogin(null);
                      resetUserData();
                      resetUserEmailData();
                      // setNav([1, document.location.href.split('/')[3]]);
                    }}
                  >
                    <TextTwoPart textKor="로그아웃" textEng="LogOut" />
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              className="login"
              onClick={() => {
                // navigate(`/login`);
                setLoginModalOn(true);
                setResetPasswordModal(false);
                setFindIdModal(false);
                setSignUpChooseModal(false);
                close();
              }}
            >
              <TextTwoPart textKor="로그인 / 가입" textEng="LogIn / Sign Up" />
            </div>
          )}
          <div className="line" />
          <DivTwoPartImprove display="grid">
            <Button
              onclick={() => {
                close();
                modalCloseAll();
                // navigate(location.pathname.length <= 1 ? `/createExam` : `${location.pathname}/createExam`);
                if (login) {
                  navigate(`/creatingExam`);
                } else {
                  setLoginModalOn(true);
                }
              }}
              text={login ? buttonTextAfterLogin : buttonTextBeforeLogin}
              width="249px"
              height={42}
              border_radius={42}
              font_size={14}
              font_family="medium"
              border="none"
              backgroundcolor={login ? "#20315B" : "#ED893E"}
              font_color="white"
              margin="14px auto"
            />
            <div
              className="navigate"
              onClick={() => {
                close();
                shutModalOff();
                setNav([3, document.location.href.split("/")[3]]);
                navigate(`/`);
              }}
            >
              <TextTwoPart textKor="서비스" textEng="Features" />
            </div>
            <div
              className="navigate"
              onClick={() => {
                close();
                shutModalOff();
                setNav([4, document.location.href.split("/")[3]]);
                navigate(`/`);
              }}
            >
              <TextTwoPart textKor="기술" textEng="Why Proctormatic?" />
            </div>
            <div
              className="navigate"
              onClick={() => {
                close();
                shutModalOff();
                setNav([5, document.location.href.split("/")[3]]);
                navigate(`/`);
              }}
            >
              <TextTwoPart textKor="이용방법" textEng="How To Use" />
            </div>
            <div
              className="navigate"
              onClick={() => {
                close();
                shutModalOff();
                setNav([7, document.location.href.split("/")[3]]);
                navigate(`/`);
              }}
            >
              <TextTwoPart textKor="도입문의" textEng="Contact Us" />
            </div>
          </DivTwoPartImprove>
          <div className="setting">
            <div className="settingLeft">
              <img
                className="settingImg"
                src={notice}
                alt=""
                onClick={() => {
                  navigate(`/notice/1`);
                  // setTimeout(() => {
                  close();
                  // }, 0);
                }}
              />
              {login && (
                <img
                  className="settingImg"
                  src={mypage}
                  alt=""
                  onClick={() => {
                    navigate(`/myPage`);
                    // setTimeout(() => {
                    //     close();
                    // }, 0);
                    close();
                  }}
                />
              )}
            </div>
            {/* <div className="settingRight">
              <LanguageChangeButton bottom={0} right={0} />
            </div> */}
          </div>
        </div>
      </StBody>
    </>
  );
};

const StBody = styled.div<MobileHeaderStyle>`
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateZ(0);
    }
    to {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  }
  animation: ${(props) =>
    props.dropdown
      ? "fadeIn 0.1s linear forwards"
      : "fadeOut 0.1s linear forwards"};
  display: flex;
  position: fixed;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;

  /* height: fill-available; */
  /* height: 100vh; */
  /* min-height: 675px; */
  width: 288px;
  background-color: white;
  z-index: 51;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* flex: 1 0 auto; */
    /* height: -webkit-fill-available; */

    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      margin-top: 100px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 1rem;
    }
  }
  .countingStar {
    margin-left: auto;
  }
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 14px;
    color: #2276dc;
    font-family: "Medium";
    cursor: pointer;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 10px 0;
  }
  .upBox {
    display: flex;
    flex-direction: column;
    .welcome {
      display: flex;
      width: 100%;
      /* height : 84px; */
      padding: 24px 24px 14px;
      box-sizing: border-box;
      cursor: pointer;
      .name {
        font-family: "Medium";
      }
      .hello {
        width: 100%;
        font-family: "Regular";
      }
      .class {
        font-size: 12.5px;
        color: #2276dc;
        margin-top: 3px;
      }
      .toggle {
        width: 9px;
        margin-left: auto;
      }
    }
  }
  .navigate {
    display: flex;
    width: 100%;
    height: 52px;
    padding: 14px 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .button {
    padding: 15px 11px;
    box-sizing: border-box;
    width: 240px;
    height: 42px;
    color: white;
    background-color: #20315b;
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Medium";
    font-size: 14px;
    margin: 14px auto;
    cursor: pointer;
  }
  .button2 {
    padding: 15px 11px;
    box-sizing: border-box;
    width: 240px;
    height: 42px;
    color: white;
    background-color: #ed893e;
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Medium";
    font-size: 14px;
    margin: 14px auto;
    cursor: pointer;
  }
  .setting {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 11px 24px;
    box-sizing: border-box;
    margin-top: auto;
    padding-right: 8px;
    border-top: 1px solid #d9d9d9;
    box-sizing: border-box;
    gap: 24px;
    .settingLeft {
      display: grid;
      grid-template-columns: auto auto;
      gap: 24px;
      img {
        cursor: pointer;
      }
    }
    .settingRight {
      position: relative;
      gap: 6px;
      margin-right: 24px;
      .beforeModal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #e0e0df;
        width: 87px;
        height: 38px;
        padding: 6px 12px;
        box-sizing: border-box;
        cursor: pointer;
      }
      .languageModal {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        .modalUnit {
          display: flex;
          padding: 15px 24px;
          justify-content: space-between;
          width: 188px;
          height: 54px;
          box-sizing: border-box;
          div {
            font-size: 16px;
            font-family: "regular";
            line-height: 24px;
          }
          img {
          }
        }
      }
    }
  }
  .settingImg {
    /* margin-right : 8px; */
  }
`;

const StBack = styled.div<MobileHeaderStyle>`
  position: fixed;
  display: ${(props) => (props.dropdown ? "flex" : "none")};
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: #000000;
  opacity: 0.25;
`;

export default MobileHeader;
