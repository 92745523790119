import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

//device size
import { device } from "../hooks/device";

//style
import styled from "styled-components";

// img
import { getCookie, setCookie } from "../Cookie";
import mLogo from "../img/mobileHeaderLogo.svg";
import menu from "../img/menu.svg";
import headerLogo from "../img/mainLogo.svg";

// components
import DropBox from "../modal/DropBox";
import MobileHeader from "./MobileHeader";
import { bannerOn, languageIs, loginModal, userInfo, userlang } from "../atoms";
import Login from "../modal/Login";
import SignUpChoose from "../modal/SignUpChoose";
import SignUp from "../modal/SignUp";
import ResetPassword from "../modal/ResetPassword";
import FindId from "../modal/FindId";
import Banner from "./Banner";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import LanguageChangeButton from "../atomic/molecule/LanguageChangeButton";
import Button from "../atomic/atom/Button";
import SignupRequestComplete from "../modal/SignupRequestComplete";

interface HeaderProps extends BoxStyleProps {
  setNav: React.Dispatch<React.SetStateAction<(number | boolean | string)[]>>;
  setModalOn: SetterOrUpdater<string>;
  setMyPageScroll: React.Dispatch<React.SetStateAction<string>>;
  banneris: boolean;
  setBannerIs: SetterOrUpdater<boolean>;
}

interface BoxStyleProps {
  lang: string;
  modalon: string;
}

const Header: React.FC<HeaderProps> = ({
  setNav,
  setModalOn,
  setMyPageScroll,
  modalon,
  banneris,
  setBannerIs,
}) => {
  const navigate = useNavigate();

  const [sideBar, setSideBar] = useState<boolean>();
  const [dropDown, setDropDown] = useState(false);
  const [menus, setMenus] = useState(false);
  const [type, setType] = useState<string>("individual");
  const page = window.location.href.split("/")[3];
  const token = getCookie("token");
  const userData = useRecoilValue(userInfo);
  const [lang, setLang] = useState<string>("");
  const [nowLang, setNowLang] = useRecoilState(userlang);

  const menuKor = ["서비스", "기술", "이용방법", "도입문의"];
  const menuEng = ["Features", "Why ProctorMatic?", "How To Use", "Contact Us"];
  const menuNum = [3, 4, 5, 7];

  // modal state
  const [modalLevel, setModalLevel] = useState<number>(1);
  const [loginModalOn, setLoginModalOn] = useRecoilState(loginModal);
  const [enterpriseSignupRequestComplete, setEnterpriseSignupRequestComplete] =
    useState<boolean>(false);
  const [signUpChooseModal, setSignUpChooseModal] = useState<boolean>(false);
  const [signUpInputModal, setSignUpInputModal] = useState<boolean>(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [findIdModal, setFindIdModal] = useState(false);

  const close = () => {
    setDropDown(false);
  };

  const menuClose = () => {
    setMenus(false);
  };

  const modalCloseAll = () => {
    setResetPasswordModal(false);
    setFindIdModal(false);
    setLoginModalOn(false);
    setSignUpChooseModal(false);
    setSignUpInputModal(false);
  };

  useEffect(() => {
    if (userData?.hostLanguage === "KR") {
      setLang("KOR");
    } else if (userData?.hostLanguage === "EN") {
      setLang("ENG");
    }
  }, [userData]);

  useEffect(() => {
    if (nowLang === "KR") {
      setLang("KOR");
    } else {
      setLang("ENG");
    }
  }, [nowLang]);

  const shutModalOff = () => {
    setLoginModalOn(false);
    setSignUpChooseModal(false);
    setSignUpInputModal(false);
    setFindIdModal(false);
    setResetPasswordModal(false);
  };

  return (
    <>
      {enterpriseSignupRequestComplete && (
        <SignupRequestComplete modalOn={setEnterpriseSignupRequestComplete} />
      )}
      {loginModalOn && (
        <Login
          setLoginModal={setLoginModalOn}
          setFindIdModal={setFindIdModal}
          setSignUpChooseModal={setSignUpChooseModal}
          setResetPasswordModal={setResetPasswordModal}
        />
      )}
      {signUpChooseModal && (
        <SignUpChoose
          setType={setType}
          setSignUpInputModal={setSignUpInputModal}
          setSignUpChooseModal={setSignUpChooseModal}
        />
      )}
      {signUpInputModal && (
        <SignUp
          modalLevel={modalLevel}
          setModalLevel={setModalLevel}
          type={type}
          setSignUpInputModal={setSignUpInputModal}
          setEnterpriseSignupRequestComplete={
            setEnterpriseSignupRequestComplete
          }
        />
      )}
      {resetPasswordModal && (
        <ResetPassword
          modalLevel={modalLevel}
          setModalLevel={setModalLevel}
          setResetPasswordModal={setResetPasswordModal}
          setLoginModalOn={setLoginModalOn}
        />
      )}
      {findIdModal && (
        <FindId
          setFindIdModal={setFindIdModal}
          setLoginModalOn={setLoginModalOn}
        />
      )}
      <StMobileHeader>
        {banneris && (
          <Banner
            setSignUpChooseModal={setSignUpChooseModal}
            setMyPageScroll={setMyPageScroll}
            setBannerIs={setBannerIs}
            bannerIs={banneris}
          />
        )}
        <div className="realMobileHeader">
          <img
            className="logo"
            onClick={() => {
              shutModalOff();
              setNav([1, document.location.href.split("/")[3]]);
              setSideBar(!sideBar);
              navigate(`/`);
            }}
            alt=""
            src={mLogo}
          />
          <img
            onClick={() => {
              setMenus(!menus);
            }}
            className="menu"
            alt=""
            src={menu}
          />
          <MobileHeader
            // menus={menus}
            dropdown={menus}
            shutModalOff={shutModalOff}
            setNav={setNav}
            close={menuClose}
            modalCloseAll={modalCloseAll}
            setResetPasswordModal={setResetPasswordModal}
            setFindIdModal={setFindIdModal}
            setSignUpChooseModal={setSignUpChooseModal}
            // loginModalOn={loginModalOn}
            setLoginModalOn={setLoginModalOn}
          />
        </div>
      </StMobileHeader>
      <Box lang={lang} modalon={modalon}>
        {banneris && (
          <Banner
            setSignUpChooseModal={setSignUpChooseModal}
            setMyPageScroll={setMyPageScroll}
            setBannerIs={setBannerIs}
            bannerIs={banneris}
          />
        )}
        <div className="realHeader">
          <img
            className="Logo"
            src={headerLogo}
            alt=""
            onClick={() => {
              setNav([1, document.location.href.split("/")[3]]);
              navigate(`/`);
            }}
          />
          <div className="menus">
            {menuKor.map((value, index) => (
              <TextTwoPart
                textKor={menuKor[index]}
                textEng={menuEng[index]}
                font_size={15}
                font_family="medium"
                lineheight={22}
                onclick={() => {
                  setNav([
                    menuNum[index],
                    document.location.href.split("/")[3],
                  ]);
                  navigate(`/`);
                }}
                cursor="pointer"
                key={index}
              />
            ))}
          </div>
          <div className="loginBox">
            {token ? (
              <div className="names">
                <div className="name">
                  <div
                    className="nameee"
                    onClick={() => {
                      setDropDown(!dropDown);
                    }}
                  >
                    {userData?.hostName}
                  </div>
                  {dropDown && <DropBox close={close} />}
                </div>
                <TextTwoPart textKor="님" />
              </div>
            ) : (
              <TextTwoPart
                textKor="로그인 / 가입"
                textEng="Login / Sign up"
                pc_font_color="#2276DC"
                font_family="medium"
                font_size={15}
                lineheight={22}
                onclick={() => setLoginModalOn(true)}
                cursor="pointer"
              />
            )}
            <Button
              onclick={() => {
                if (token) {
                  navigate(`/creatingExam`);
                } else {
                  setLoginModalOn(true);
                }
              }}
              width="135px"
              height={39}
              backgroundcolor="#20315B"
              font_color="white"
              font_family="medium"
              text="시험 예약하기"
              padding="11px"
              border="none"
              border_radius={43}
              cursor="pointer"
            />
            {/* <LanguageChangeButton top={0} right={0} /> */}
          </div>
        </div>
      </Box>
    </>
  );
};

const StMobileHeader = styled.div`
  @media ${device.tabMob} {
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    z-index: 51;
    .realMobileHeader {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      height: 60px;
      justify-content: space-between;
      padding: 18px 24px;
      box-sizing: border-box;
    }
    .logo {
      width: 159.7px;
      cursor: pointer;
      margin-right: auto;
    }
    .menu {
      width: 24px;
      margin-left: auto;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    display: none;
  }
  @media ${device.mobile} {
    .onlyForTab {
      display: none;
    }
  }
`;

const Box = styled.div<BoxStyleProps>`
  @media ${device.tabMob} {
    display: none;
  }
  @media ${device.pc} {
    position: fixed;
    top: 0;
    width: ${(props) => (props.modalon ? "calc(100% - 10px)" : "100%")};
    max-width: 100%;
    /* padding : 18px 30px; */
    background: #ffffff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    z-index: 50;
    .banner {
      display: grid;
      grid-template-columns: 50px 1fr 50px;
      width: 100%;
      height: 40px;
      padding: 10px 0 11px;
      box-sizing: border-box;
      background-color: black;
      color: white;
      font-size: 13px;
      font-family: "semibold";
      line-height: 19px;
      box-sizing: border-box;
      .rectangle {
        width: 0.7px;
        height: 10px;
        margin: auto 13px;
      }
      .arrowRight {
        width: 8px;
        height: 8px;
      }
      .bannerText {
        display: flex;
        justify-content: center;
        grid-column: 2/3;
        .blueText {
          color: #428efe;
        }
        .orangeText {
          color: #e39125;
        }
      }
      img {
        width: 14px;
        height: 14px;
        margin: auto 18px auto auto;
        cursor: pointer;
      }
    }
    .realHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 18px 30px;
      box-sizing: border-box;
    }
    .names {
      display: flex;
      font-size: 15px;
    }
    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      width: auto;
      cursor: pointer;
    }
    .menus {
      display: grid;
      grid-template-columns: auto auto auto auto;
      gap: 30px;
      /* justify-content: space-between; */
      min-width: 278px;
      font-family: "Medium";
    }
    .Logo {
      margin: 0 0 0 0px;
      width: 220px;
      cursor: pointer;
    }
    .loginBox {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;
      gap: 20px;
      .signUp {
        font-family: "Medium";
        font-size: 15px;
        color: #2276dc;
        cursor: pointer;
        position: relative;
      }
      .plan {
        color: #2276dc;
        font-size: 11px;
        margin-top: 3px;
      }
      .name {
        display: flex;
        position: relative;
        font-family: "Medium";
        color: #2276dc;
        margin-right: 5px;
        cursor: pointer;
      }
      .nameee {
        text-align: end;
        /* max-width: 120px; */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .line {
        display: flex;
        align-items: center;
        height: 40px;
        width: 225px;
        padding: 0 12px;
      }
      .grey {
        height: 1px;
        /* width : 225px; */
        background-color: #dfdfdf;
      }
    }
  }
`;

export default Header;
