import { useEffect, useState } from "react";
import styled from "styled-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import useGetMyCredit from "../../hooks/useGetMyCredit";
import useGetCreditUseMember from "../../hooks/useGetCreditUseMember";
import dayjs from "dayjs";

// img
import blueCheck from "../../img/blueCheck.svg";
import questionMark from "../../img/Q.svg";
import arrow from "../../img/arrow.svg";
import spread from "../../img/spread.svg";
import refundAlert from "../../img/refundAlert.svg";
import dots from "../../img/More_3 dot.svg";
import manage from "../../img/manage.svg";
import chargeImg from "../../img/chargeCredit.png";
import CreditQuestion from "../../modal/CreditQuestion";
import Refund from "../../modal/Refund";
import RefundInnerModal from "../../modal/RefundInnerModal";
import RefundInfo from "../../modal/RefundInfo";
import { device } from "../../hooks/device";
import Policy from "../../modal/Policy";
import RefundError from "../../modal/RefundError";
import { useOutletData } from "../../components/OutletDataContext";
import Img from "../../atomic/atom/Img";
import useGetTradeDetail from "../../hooks/api/useGetTradeDetailManager";
import useGetCreditUseMaster from "../../hooks/useGetTradeListManger";

const MyCredit = ({ setMyPageScroll, setModalOn }) => {
  const navigate = useNavigate();
  const forFilter = [
    "all",
    "charge",
    "use",
    "cancel",
    "refund",
    "extinct",
    "recovery",
  ];
  const [lang, setLang] = useState("kr");
  const [value, setValue] = useState(forFilter[0]);
  const [filterOn, setFilterOn] = useState(false);
  const filters = [
    "전체",
    "충전",
    "사용",
    "취소",
    "환불",
    "기간 만료",
    "충전 철회",
  ];
  const [charged, setCharged] = useState(false);
  const { data: credit } = useGetMyCredit({ charged });
  const { data: creditUseMaster } = useGetCreditUseMaster({
    value,
  });

  const { data: creditRecent } = useGetCreditUseMember({ charged });
  const [charge, setCharge] = useState(false);
  const [chargingCredit, setChargingCredit] = useState(0);
  const [filterChoose, setFilterChoose] = useState(0);
  const [refunded, setRefunded] = useState(false);
  const [refundError, setRefundError] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [refundErrorCode, setRefundErrorCode] = useState();
  const [merchantId, setMerchantId] = useState();
  const [preUid, setPreUid] = useState();
  const { setData } = useOutletData();
  const [tradeId, setTradeId] = useState();
  const [tradeType, setTradeType] = useState();

  const { data } = useGetTradeDetail({ tradeId, type: tradeType });

  useEffect(() => {
    setData({ charge, setCharge });
  }, [charge]);

  useEffect(() => {
    setMerchantId(`mid_${new Date().getTime()}`);
  }, []);

  /** preuid 발급코드 */
  function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  useEffect(() => {
    setPreUid(uuid());
  }, []);

  // modalState
  const [questionModal, setQuestionModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [refundInModal, setRefundInModal] = useState(false);
  const [refundInfoModal, setRefundInfoModal] = useState(false);
  const [policyModal, setPolicyModal] = useState(false);
  const [refundErrorModal, setRefundErrorModal] = useState(false);
  const [rightUp, setRightUp] = useState(false);

  return (
    <>
      {refundModal && (
        <Refund
          refunded={refunded}
          setRefunded={setRefunded}
          refundErrorCode={refundErrorCode}
          setRefundError={setRefundError}
          refundError={refundError}
          setRefundInfoModal={setRefundInfoModal}
          setRefundModal={setRefundModal}
          setRefundInModal={setRefundInModal}
          refundModal={refundModal}
        />
      )}
      {refundInModal && (
        <RefundInnerModal
          setRefundErrorCode={setRefundErrorCode}
          setRefundError={setRefundError}
          setRefunded={setRefunded}
          setRefundInModal={setRefundInModal}
          refundInModal={refundInModal}
        />
      )}
      {refundInfoModal && (
        <RefundInfo
          setRefundInfoModal={setRefundInfoModal}
          refundInfoModal={refundInfoModal}
        />
      )}
      {policyModal && <Policy setPolicyModal={setPolicyModal} />}
      {refundErrorModal && (
        <RefundError
          errorCode={errorCode?.split(":")[1].split(")")[0]}
          setRefundErrorModal={setRefundErrorModal}
        />
      )}
      <StMyPoint
        filterOn={filterOn}
        filterChoose={filterChoose}
        chargingCredit={chargingCredit}
      >
        <div className="wrapper">
          <div className="headerBox">
            <div className="headerLeft">
              <img
                style={{ cursor: "pointer" }}
                src={arrow}
                alt=""
                onClick={() => {
                  navigate(`/myPage`);
                }}
              />
              <div>보유 적립금</div>
            </div>
            <div className="headerRight">
              <img
                src={questionMark}
                alt=""
                onClick={() => {
                  navigate(`creditInfo`);
                }}
              />
              <div style={{ display: "flex", position: "relative" }}>
                <img
                  src={dots}
                  alt=""
                  onClick={() => {
                    setRightUp(true);
                  }}
                />
                {rightUp && (
                  <>
                    <div
                      onClick={() => setRightUp(false)}
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "10",
                      }}
                    />
                    <div className="rightModal">
                      <div
                        onClick={() => {
                          setRightUp(false);
                          // setRefundModal(true);
                          navigate("refund");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        환불하기
                      </div>
                      <div
                        onClick={() => {
                          setRightUp(false);
                          navigate(`/refundList`);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        환불 신청 내역
                      </div>
                      <div
                        onClick={() => {
                          setRightUp(false);
                          // setPolicyModal(true);
                          navigate("policy");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        취소/환원 정책
                      </div>
                      <div
                        onClick={() => {
                          setRightUp(false);
                          navigate(`/myPage`);
                          setMyPageScroll("customerCenter");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        고객센터
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="bodyBox">
            <div className="availablePointBox">
              <div className="pcPointHead">
                <div className="pointHeadPart">
                  <div className="availableTitle">
                    총 적립금 <span className="onlyForPc">사용 가능 금액</span>
                  </div>
                  {credit && (
                    <div className="availablePoints">
                      {Number(credit?.credits?.totalCredits).toLocaleString()}
                      <span>C</span>
                    </div>
                  )}
                  <div className="availableAmount onlyForMobile">
                    {" "}
                    사용 가능 적립금
                  </div>
                </div>
                <div className="columnLine onlyForPc" />
                <div className="availableVanish">
                  <div className="vanishUp">60일 이내 환불불가 예정 적립금</div>
                  {credit && (
                    <div className="vanishDown">
                      {Number(
                        credit?.credits?.willNotRefundableCredits
                      ).toLocaleString()}
                      <span>C</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="availableExplain">
                * 환불이 가능한 기간은 충전일로부터 최대 1년까지에요.
              </div>
              <div className="benefitPart">
                <div className="benefitHead">적립금 구분과 혜택</div>
                <div className="underLine" />
                {/* <div className='rowGrayBar'/> */}
                <div className="benefitBody">
                  <div className="benefitBodyItemBucket">
                    <div className="bodyItem">
                      <div className="itemLeft">환불 가능</div>
                      {credit && (
                        <div className="itemRight">
                          {Number(
                            credit?.credits?.refundableCredits
                          ).toLocaleString()}{" "}
                          C
                        </div>
                      )}
                    </div>
                    <div className="bodyItem">
                      <div className="itemLeft">환불 불가</div>
                      {credit && (
                        <div className="itemRight">
                          {Number(
                            credit?.credits?.notRefundableCredits
                          ).toLocaleString()}{" "}
                          C
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="benefitBodyItemBucket">
                    <div className="bodyItem">
                      <div className="itemLeft">최종 이용료 청구</div>
                      <div className="itemRight">실제 응시 인원만</div>
                    </div>
                    <div className="bodyItem">
                      <div className="itemLeft">기업회원 구매 혜택</div>
                      <div className="itemBlue">추가 혜택 제공</div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "0.5px",
                  background: "#BDBDBD",
                  margin: "48px 0 24px",
                }}
              />

              <div
                onClick={() => {
                  navigate(`chargeRequest`);
                }}
                className="chargingPartButton"
              >
                <Img src={chargeImg} width="18px" />
                <div>적립금 충전하기</div>
              </div>
            </div>
            {/**
               * 티켓링크 : https://www.notion.so/edint/web-257a7d321e334e9cbedaaf9d5f17cc10?pvs=4
               * 주석작성자 : Noah
               * 주석작성일자 : 2024.05.09
               * 티켓내용 : 구성원 추가 메뉴를 찾기쉽게 수정
               * 주석설명 : 연결된 구성원 박스 삭제
               */}
            {/* <div className="connectedMemberBox">
              <div className="connectedHeader">
                <div className="headLeft">연결된 구성원</div>
                <div className="headRight">
                  총{" "}
                  <span>
                    {
                      creditRecent?.enterpriseCreditUsageList
                        ?.enterpriseChildCount
                    }
                  </span>{" "}
                  명
                </div>
              </div>
              <div className="connectedBody">
                <div className="bodyHead">최근 사용 내역</div>
                {creditRecent?.enterpriseCreditUsageList
                  ?.enterpriseCreditUsageList?.length > 0 ? (
                  creditRecent?.enterpriseCreditUsageList?.enterpriseCreditUsageList?.map(
                    (value, index) => (
                      <div className="bodyBody" key={index}>
                        <div className="bodyLeft">
                          {dayjs(value.paymentsCreditsUsedAt).format("MM-DD")}
                        </div>
                        <div className="bodyMiddle">
                          {value.paymentsCreditsUsedHostName}
                        </div>
                        <div className="bodyRight">
                          <span>{value.paymentsCredit}</span> C
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="nothing">사용 내역이 없음</div>
                )}
              </div>
              <div className="rowGrayBar" />
              <div className="connectedLast">
                <img alt="" src={manage} />
                <div
                  onClick={() => navigate(`/memberManage`)}
                  style={{ cursor: "pointer" }}
                >
                  구성원 관리하기
                </div>
              </div>
            </div> */}
            <div className="pointSpendListBox">
              <div className="listHeader">
                <span>적립금 사용 내역</span>
                <div className="headRight">
                  <div
                    style={{ cursor: "pointer", color: "black" }}
                    onClick={() => {
                      setFilterOn(true);
                    }}
                  >
                    {filters[filterChoose]}
                    <img src={spread} alt="" />
                  </div>
                  <div className="filterModal">
                    <div
                      className="modalItemOne"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(0);
                        setValue(forFilter[0]);
                      }}
                    >
                      <div>전체</div>{" "}
                      {filterChoose === 0 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemTwo"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(1);
                        setValue(forFilter[1]);
                      }}
                    >
                      충전{" "}
                      {filterChoose === 1 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemThree"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(2);
                        setValue(forFilter[2]);
                      }}
                    >
                      사용{" "}
                      {filterChoose === 2 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemFour"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(3);
                        setValue(forFilter[3]);
                      }}
                    >
                      취소{" "}
                      {filterChoose === 3 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemFive"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(4);
                        setValue(forFilter[4]);
                      }}
                    >
                      환불{" "}
                      {filterChoose === 4 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemSix"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(5);
                        setValue(forFilter[5]);
                      }}
                    >
                      기간 만료{" "}
                      {filterChoose === 5 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemSeven"
                      onClick={() => {
                        setFilterOn(false);
                        setFilterChoose(6);
                        setValue(forFilter[6]);
                      }}
                    >
                      충전 철회{" "}
                      {filterChoose === 6 && <img src={blueCheck} alt="" />}
                    </div>
                  </div>
                  <div
                    className="filterBack"
                    onClick={() => setFilterOn(false)}
                  />
                </div>
              </div>
              {creditUseMaster?.enterpriseCreditsTradeList?.length > 0 ? (
                <div className="listBody">
                  <div className="listBodyThickLine onlyForPc" />
                  <div className="listBodyHeader onlyForPc">
                    <div>구분</div>
                    <div>상세 내역</div>
                    <div>적립금</div>
                  </div>
                  <div className="listBodyBlackLine onlyForPc" />
                  {creditUseMaster?.enterpriseCreditsTradeList?.map(
                    (value, index) => {
                      let temp = "사용";
                      switch (value.paymentsCreditsType) {
                        case "charge":
                          temp = "충전";
                          break;
                        case "use":
                          temp = "사용";
                          break;
                        case "cancel":
                          temp = "취소";
                          break;
                        case "refund":
                          temp = "환불";
                          break;
                        case "extinct":
                          temp = "기간 만료";
                          break;
                        case "recovery":
                          temp = "충전 철회";
                          break;
                        default:
                          break;
                      }
                      return (
                        <div key={index}>
                          <div
                            className="listItem"
                            onClick={() => {
                              setTradeId(value.paymentsCreditTradeId);
                              setTradeType(value.paymentsCreditsType);
                              navigate(`tradeDetail`);
                            }}
                          >
                            <div className="listItemLeft">
                              <div className="itemFor">{temp}</div>
                              <div className="itemDown">
                                <div className="middleLeft">
                                  {value.paymentsCreditsTypeDetail}
                                  {value?.groupData?.groupStatus ===
                                    "환불 오류" && (
                                    <img
                                      src={refundAlert}
                                      onClick={() => {
                                        setErrorCode(
                                          value?.paymentsCreditsTypeInfo
                                        );
                                        setRefundErrorModal(true);
                                      }}
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </div>
                                <div className="dateInfo">
                                  {value?.paymentsCreditsTradeAt} &nbsp;{" "}
                                  {value?.paymentsCreditsTypeInfo}
                                </div>
                              </div>
                            </div>
                            <div
                              className="middleRight"
                              style={
                                value?.paymentsCreditsType === "use" ||
                                value?.paymentsCreditsType === "refund" ||
                                value?.paymentsCreditsType === "recovery"
                                  ? { color: "#C83B38" }
                                  : {}
                              }
                            >
                              {Number(
                                value.paymentsCreditsAmount
                              ).toLocaleString()}
                              <span>C</span>
                            </div>
                          </div>
                          {index + 1 !==
                            creditUseMaster?.enterpriseCreditsTradeList
                              ?.length && (
                            <div className="greyBar onlyForMobile" />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "151px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#BDBDBD",
                    fontSize: "16px",
                    background: "",
                  }}
                >
                  사용 내역이 없음
                </div>
              )}
            </div>
          </div>
        </div>
      </StMyPoint>
      <Outlet
        context={{ tradeId: tradeId, tradeType: tradeType, tradeData: data }}
      />
    </>
  );
};

const StMyPoint = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto auto;
  background-color: #f0f1f2;
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #bdbdbd;
    margin: 24px 0;
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-family: "bold";
    .headerLeft {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 15.6px;
    }
    .headerRight {
      display: flex;
      align-items: center;
      .rightModal {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 11;
        width: 192px;
        background-color: #ffffff;
        border-radius: 26px;
        font-size: 16px;
        font-family: "regular";
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        div {
          display: flex;
          align-items: center;
          padding: 15px 24px;
          box-sizing: border-box;
          line-height: 24px;
        }
      }
      img {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
  }
  .connectedMemberBox {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .connectedHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 36px;
      .headLeft {
        font-size: 18px;
        font-family: "bold";
      }
      .headRight {
        font-size: 18px;
        font-family: "medium";
        span {
          font-family: "bold";
          color: #2276dc;
        }
      }
    }
    .connectedBody {
      display: grid;
      flex-direction: column;
      gap: 10px;
      .bodyHead {
        font-size: 15px;
        font-family: "medium";
        line-height: 18px;
      }
      .bodyBody {
        display: flex;
        font-family: "medium";
        font-size: 14px;
        color: #55595f;
        .bodyLeft {
          font-family: "light";
        }
        .bodyMiddle {
          margin-left: 12px;
        }
        .bodyRight {
          margin-left: auto;
          span {
            color: black;
          }
        }
      }
      .nothing {
        width: 100%;
        height: 67px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bdbdbd;
        font-size: 14px;
        font-family: "regular";
        line-height: 27px;
      }
    }
    .connectedLast {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      align-items: center;
      gap: 6px;
      img {
        width: 16px;
        height: 16px;
      }
      div {
        font-family: "medium";
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
  .availablePointBox {
    display: flex;
    flex-direction: column;
    margin: 12px auto 24px;
    background-color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    box-sizing: border-box;
    .chargingPartButton {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      margin: 0 auto;
      gap: 8px;
      font-size: 15px;
      font-family: "medium";
      line-height: 21px;
      cursor: pointer;
    }
    .chargingPart {
      .amountHead {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 6px;
        font-size: 13px;
        font-family: "semibold";
        line-height: 19px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .amountBody {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 10px;
        margin: 10px 0 12px;
        .leftCharge {
          display: flex;
          align-items: center;
          .inputPart {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 140px;
            height: 42px;
            border: 0.6px solid #818181;
            border-radius: 6px;
            padding: 9px 12px;
            box-sizing: border-box;
            .showPayment {
              font-size: 16px;
              line-height: 24px;
              color: ${(props) =>
                props.chargingCredit === 0 ? "#BDBDBD" : ""};
            }
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
            input {
              width: 86px;
              /* height : 42px; */
              font-size: 16px;
              line-height: 24px;
              border: none;
              &:focus {
                outline: none;
              }
              &::placeholder {
                font-size: 16px;
                font-family: "light";
                line-height: 24px;
                color: #bdbdbd;
              }
            }
          }
          span {
            font-size: 14px;
            font-family: "medium";
            line-height: 18px;
            margin-left: 6px;
            color: #818181;
          }
        }
        .rightCharge {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;
          img {
            cursor: pointer;
          }
        }
      }
      .amountChoose {
        display: flex;
        flex-wrap: wrap;
        /* grid-template-columns: repeat(auto-fill, auto); */
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 0.6px solid #aeaeae;
          border-radius: 16px;
          padding: 6px 9px;
          box-sizing: border-box;
          color: #55595f;
          font-size: 12px;
          font-family: "regular";
          line-height: 15px;
          margin: 0 6px 6px 0;
          cursor: pointer;
        }
      }
      .infoPart {
        display: flex;
        flex-direction: column;
        .amountInfo {
          word-break: break-all;
          font-size: 11px;
          font-family: "regular";
          line-height: 16px;
          color: #444444;
          span {
            color: #2276dc;
          }
          .mail {
            color: #2276dc;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .predict {
        display: grid;
        flex-direction: column;
        justify-content: center;
        gap: 7px;
        align-items: center;
        padding: 16px 24px;
        box-sizing: border-box;
        border: 0.6px solid #bdbdbd;
        border-radius: 8px;
        .predictHead {
          font-size: 12px;
          font-family: "medium";
          line-height: 14px;
          color: #818181;
        }
        .predictBody {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 19px;
          font-family: "bold";
          line-height: 23px;
          color: #2276dc;
          span {
            margin-left: 2px;
            font-family: "regular";
            color: black;
          }
        }
      }
      .predictInfo {
        display: flex;
        .infoLeft {
          color: #818181;
        }
        .infoRight {
          margin-left: auto;
          span {
            font-family: "regular";
            margin-left: 2px;
          }
        }
      }
      .howToHead {
        font-size: 13px;
        font-family: "semibold";
        line-height: 19px;
        margin-bottom: 10px;
      }
      .chargingButton {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin: 0 auto;
        width: 204px;
        height: 42px;
        border-radius: 42px;
        background-color: ${(props) =>
          props.chargingCredit ? "#ED893E" : "#D9D9D9"};
        margin-top: 24px;
        border: none;
      }
    }
    .pointHeadPart {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 36px;
      .availableTitle {
        font-size: 12px;
        font-family: "semibold";
        color: #818181;
        margin-bottom: 10px;
      }
      .availablePoints {
        font-family: "bold";
        font-size: 24px;
        color: #2276dc;
        margin-bottom: 4px;
        span {
          font-size: 16px;
          font-family: "bold";
          line-height: 28px;
          color: black;
          margin-left: 3px;
        }
      }
      .availableAmount {
        font-size: 14px;
        font-family: "regular";
        line-height: 17px;
      }
    }
    .availableVanish {
      display: flex;
      flex-direction: column;
      align-items: center;
      .vanishUp {
        font-size: 12px;
        font-family: "medium";
        color: #818181;
      }
      .vanishDown {
        font-size: 19px;
        font-family: "bold";
        margin-top: 7px;
        span {
          margin-left: 3px;
          font-size: 14px;
          font-family: "regular";
        }
      }
    }
    .availableExplain {
      font-size: 11px;
      font-family: "regular";
      color: #444444;
      margin-bottom: 36px;
    }
    .underLine {
      width: 100%;
      height: 0.5px;
      background-color: #bdbdbd;
      margin-top: 4px;
    }
    .benefitPart {
      display: flex;
      flex-direction: column;
      .benefitHead {
        font-family: "semibold";
        font-size: 13px;
        line-height: 19px;
      }
      .benefitBody {
        display: flex;
        margin-top: 24px;
        .bodyItem {
          display: flex;
          height: 24px;
          .itemLeft {
            font-size: 14px;
            font-family: "regular";
            line-height: 17px;
            color: #55595f;
          }
          .itemRight {
            font-size: 14px;
            font-family: "medium";
            line-height: 17px;
            color: black;
            margin-left: auto;
          }
          .itemBlue {
            font-size: 14px;
            font-family: "medium";
            line-height: 17px;
            color: #2276dc;
            margin-left: auto;
          }
        }
      }
    }
  }
  .pointSpendListBox {
    margin-top: 24px;
    padding-bottom: 38.5px;
    .listHeader {
      display: flex;
      justify-content: space-between;
      font-family: "medium";
      font-size: 14px;
      line-height: 21px;
      color: #909090;
      div {
        font-family: "regular";
        font-size: 14px;
        /* margin-right : 6px; */
        img {
          margin-left: 6px;
        }
      }
      .headRight {
        position: relative;
        font-size: 14px;
        font-family: "regular";
        img {
          margin-left: 10px;
        }
        .filterBack {
          position: fixed;
          display: ${(props) => (props.filterOn ? "" : "none")};
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
        .filterModal {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          display: ${(props) => (props.filterOn ? "flex" : "none")};
          flex-direction: column;
          width: 168px;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          font-size: 16px;
          line-height: 19px;
          cursor: pointer;
          img {
            margin-left: auto;
          }
          .modalItemOne,
          .modalItemTwo,
          .modalItemThree,
          .modalItemFour,
          .modalItemFive,
          .modalItemSix,
          .modalItemSeven {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
          }
          .modalItemOne {
            color: ${(props) =>
              props.filterChoose === 0 ? "#0381FE" : "black"};
          }
          .modalItemTwo {
            color: ${(props) =>
              props.filterChoose === 1 ? "#0381FE" : "black"};
          }
          .modalItemThree {
            color: ${(props) =>
              props.filterChoose === 2 ? "#0381FE" : "black"};
          }
          .modalItemFour {
            color: ${(props) =>
              props.filterChoose === 3 ? "#0381FE" : "black"};
          }
          .modalItemFive {
            color: ${(props) =>
              props.filterChoose === 4 ? "#0381FE" : "black"};
          }
          .modalItemSix {
            color: ${(props) =>
              props.filterChoose === 5 ? "#0381FE" : "black"};
          }
          .modalItemSeven {
            color: ${(props) =>
              props.filterChoose === 6 ? "#0381FE" : "black"};
          }
        }
      }
    }
    .listBody {
      border-radius: 24px;
      background-color: #ffffff;
      box-sizing: border-box;
      .listItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .middleRight {
          color: #2276dc;
          font-size: 16px;
          font-family: "bold";
          line-height: 24px;
          span {
            font-family: "regular";
            margin-left: 2px;
          }
        }
      }
      .greyBar {
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
      }
    }
  }
  @media ${device.pc} {
    .wrapper {
      max-width: 1228px;
      width: 100%;
      margin: 0 auto;
    }
    padding: 80px 0px;
    box-sizing: border-box;
    .onlyForMobile {
      display: none;
    }
    .headerBox {
      margin-bottom: 60px;
      font-size: 28px;
      line-height: 41px;
      .headerLeft {
        display: grid;
        gap: 19px;
        img {
          width: 28px;
          height: 28px;
        }
      }
      .headerRight {
        display: grid;
        grid-template-columns: auto auto;
        gap: 36px;
      }
    }
    .bodyBox {
      width: 100%;
      max-width: 865px;
      margin: 0 auto;
      .availablePointBox {
        padding: 60px 60px 32px;
        box-sizing: border-box;
        margin-bottom: 60px;
        .pcPointHead {
          display: grid;
          grid-template-columns: auto auto auto 1fr;
          gap: 60px;
          .pointHeadPart {
            min-width: 184px;
            .availableTitle {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
              color: black;
              margin-right: auto;
              span {
                font-size: 13px;
                font-family: "regular";
                line-height: 24px;
                color: #818181;
                margin-left: 10px;
              }
            }
            .availablePoints {
              margin-right: auto;
              font-size: 28px;
              span {
                font-size: 22px;
                font-family: "regular";
                line-height: 24px;
                margin-left: 4px;
              }
            }
          }
          .availableVanish {
            .vanishUp {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
              color: black;
            }
            .vanishDown {
              font-size: 28px;
              font-family: "semibold";
              line-height: 41px;
              margin-right: auto;
              span {
                font-size: 22px;
                font-family: "regular";
                line-height: 36px;
              }
            }
          }
          .columnLine {
            width: 0.5px;
            height: 68px;
            background-color: #bdbdbd;
          }
        }
        .availableExplain {
          font-size: 12px;
        }
        .benefitPart {
          .benefitHead {
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
          }
          .benefitBody {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 60px;
            .benefitBodyItemBucket {
              display: grid;
              gap: 14px;
              .bodyItem {
                width: 342.5px;
                .itemLeft {
                  font-size: 18px;
                  line-height: 27px;
                }
                .itemRight {
                  font-size: 18px;
                  line-height: 27px;
                }
                .itemBlue {
                  font-size: 18px;
                  line-height: 27px;
                }
              }
            }
          }
        }
        .chargingPartButton {
          display: flex;
          align-items: center;
          font-size: 18px;
        }
        .chargingPart {
          .amountHead {
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .amountBody {
            margin-bottom: 18px;
            .leftCharge {
              .inputPart {
                width: 280px;
                height: 42px;
                input {
                  width: 200px;
                }
              }
            }
          }
          .amountChoose {
            display: flex;
            .amountChooseItem {
              background-color: #f7f9fa;
              border: 0.6px solid #d6d9dd;
              height: 31px;
              padding: 8px 16px;
            }
          }
          .infoPart {
            display: grid;
            gap: 8px;
            margin: 10px 0 36px;
            .amountInfo {
              font-size: 12px;
              line-height: 18px;
              span {
                color: #2276dc;
              }
            }
          }
          .predict {
            .predictBody {
              display: flex;
              align-items: flex-end;
              div {
                font-size: 22px;
                font-family: "bold";
                line-height: 33px;
              }
              span {
                font-size: 15px;
                font-family: "regular";
                line-height: 28px;
              }
            }
          }
          .predictInfo {
            margin-top: 18px;
            .infoLeft {
              font-size: 18px;
              font-family: "regular";
              line-height: 27px;
            }
            .infoRight {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
            }
          }
          .littleLine {
            margin: 36px 0;
          }
          .chargingButton {
            height: 52px;
            font-size: 17px;
            font-family: "medium";
          }
          .howToHead {
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
            margin-bottom: 18px;
          }
        }
      }
      .connectedMemberBox {
        padding: 60px 60px 32px;
        box-sizing: border-box;
        margin-bottom: 60px;
        .connectedBody {
          display: grid;
          gap: 14px;
          .bodyHead {
            margin-bottom: 4px;
            font-size: 18px;
            line-height: 27px;
          }
          .bodyBody {
            display: grid;
            grid-template-columns: 55px auto 100px;
            font-size: 18px;
            .bodyMiddle {
              font-family: "regular";
            }
          }
          .nothing {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #bdbdbd;
            font-size: 16px;
            font-family: "regular";
            line-height: 27px;
          }
        }
        .connectedLast {
          display: grid;
          align-items: center;
          gap: 10.5px;
          img {
            width: 20px;
            height: 20px;
          }
          div {
            font-size: 18px;
          }
        }
      }
      .pointSpendListBox {
        padding: 60px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        .listHeader {
          margin-bottom: 16px;
          span {
            font-size: 18px;
            font-family: "semibold";
            line-height: 27px;
            color: black;
          }
          div {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
          }
          .headRight {
            .filterModal {
              .modalItemOne {
                width: 100%;
              }
              .modalItemTwo {
                width: 100%;
              }
              .modalItemThree {
                width: 100%;
              }
              .modalItemFour {
                width: 100%;
              }
              .modalItemFive {
                width: 100%;
              }
              .modalItemSix {
                width: 100%;
              }
              .modalItemSeven {
                width: 100%;
              }
            }
          }
        }
        .listBody {
          .listBodyThickLine {
            width: 100%;
            height: 4px;
            background-color: black;
          }
          .listBodyHeader {
            display: flex;
            justify-content: space-between;
            height: 57px;
            div {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 160px;
            }
          }
          .listBodyBlackLine {
            width: 100%;
            height: 1px;
            background-color: black;
          }
          .listItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 76px;
            border-bottom: 1px solid #d9d9d9;
            .listItemLeft {
              display: flex;
              .itemFor {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 160px;
                line-height: 19px;
                font-size: 16px;
                font-family: "regular";
                color: #55595f;
              }
              .itemDown {
                display: grid;
                flex-direction: column;
                width: 425px;
                gap: 4px;
                .middleLeft {
                  display: grid;
                  grid-template-columns: auto 1fr;
                  align-items: center;
                  gap: 6px;
                  font-family: "medium";
                  font-size: 16px;
                  line-height: 24px;
                }
                .dateInfo {
                  font-size: 12px;
                  font-family: "regular";
                  color: #55595f;
                }
              }
            }
            .middleRight {
              width: 160px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    box-sizing: border-box;
    .onlyForPc {
      display: none !important;
    }
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
    .headerBox {
      padding: 24px;
      font-size: 19px;
      .headerLeft {
        display: grid;
        align-items: center;
        line-height: 28px;
        gap: 12px;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .headerRight {
        display: grid;
        grid-template-columns: auto auto;
        gap: 12px;
      }
    }
    .bodyBox {
      .availablePointBox {
        width: calc(100% - 48px);
        padding: 24px;
        .pcPointHead {
          .availableVanish {
            border: 0.6px solid #bdbdbd;
            border-radius: 8px;
            padding: 16px 0;
            box-sizing: border-box;
            margin-bottom: 12px;
          }
        }
        .benefitPart {
          .benefitHead {
          }
          .benefitBody {
            flex-direction: column;
            .benefitBodyItemBucket {
              .bodyItem {
              }
            }
          }
        }
        .chargingPart {
          .littleLine {
            margin: 24px 0;
          }
        }
        .infoPart {
          display: grid;
          margin: 10px 0 24px;
          gap: 6px;
        }
        .predict {
          .predictBody {
            span {
              font-size: 14px;
              line-height: 23px;
            }
          }
        }
        .predictInfo {
          display: flex;
          margin-top: 10px;
          .infoLeft {
            font-size: 14px;
            font-family: "medium";
            line-height: 17px;
            color: #818181;
          }
          .infoRight {
            margin-left: auto;
            font-size: 14px;
            font-family: "bold";
            line-height: 20px;
            span {
              font-family: "regular";
              margin-left: 2px;
            }
          }
        }
      }
      .connectedMemberBox {
        width: calc(100% - 48px);
        margin: 0 auto;
        padding: 24px;
      }
      .pointSpendListBox {
        .listHeader {
          padding: 12px 24px 5px;
        }
        .listBody {
          filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
          .listItem {
            padding: 16px 24px;
            .listItemLeft {
              .itemFor {
                font-size: 12px;
                line-height: 18px;
                color: #55595f;
              }
              .itemDown {
                display: flex;
                flex-direction: column;
                margin: 6px 0 2px;
                .middleLeft {
                  display: grid;
                  grid-template-columns: auto 1fr;
                  align-items: center;
                  gap: 6px;
                  font-family: "medium";
                  font-size: 16px;
                  line-height: 24px;
                }
                .dateInfo {
                  font-size: 12px;
                  font-family: "regular";
                  color: #55595f;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default MyCredit;
