import React from "react";
import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../../../atomic/atom/ConfirmButton";

const Complete = () => {
  const navigate = useNavigate();
  return (
    <>
      <ModalOneButtonTemplate gap={48} tab_gap={24}>
        <ModalHeader title="도입 상담 문의 신청 완료" />
        <DivTwoPartImprove
          display="grid"
          pc_gap={30}
          gap={24}
          font_family="medium"
          pc_font_size={18}
          font_size={16}
        >
          <DivTwoPartImprove display="grid" lineheight={27}>
            <TextLine text="도입 상담 문의가 신청되었어요." />
            <TextLine text="담당자가 확인 후 빠르게 연락 드릴게요." />
          </DivTwoPartImprove>
          <TextLine text="* 최대 24시간 내에 연락드려요." />
        </DivTwoPartImprove>
        <ConfirmButton
          font_size={17}
          font_family="medium"
          margin="0 auto"
          text="확인"
          tab_width="158px"
          tab_height={35}
          tab_fontsize={18}
          tab_fontcolor="#0072DE"
          cursor="pointer"
          onclick={() => navigate("/")}
        />
      </ModalOneButtonTemplate>
    </>
  );
};

export default Complete;
