import React, { useEffect, useState } from "react";
import ModalPageTemplate from "../../../atomic/template/ModalPageTemplate";
import { ModalHeader } from "../../../components/Style";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import TextArea from "../../../atomic/atom/TextArea";
import ImageAttach from "../../../atomic/organism/ImageAttach";
import { useOutletContext } from "react-router-dom";
import Button from "../../../atomic/atom/Button";
import getApi from "../../../api/getApi";
import patchApi from "../../../api/patchApi";
import useToast from "../../../hooks/useToast";
import { useTranslation } from "react-i18next";

const CommentEdit = () => {
  const {
    questionUuid,
    questionComponentUuid,
    commentUpdate,
    setCommentUpdate,
  }: {
    questionUuid: string;
    questionComponentUuid: string;
    commentUpdate: boolean;
    setCommentUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  } = useOutletContext();

  const { t } = useTranslation();
  const showToast = useToast();
  const [context, setContext] = useState<string>("");
  const [s3KeyList, setS3KeyList] = useState<string[]>([]);
  const [imageData, setImageData] = useState<string[]>([]);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  /** 댓글 수정 전 정보 불러오기 */
  const getDetailInfo = () => {
    getApi
      .commentDetail({ questionUuid, questionComponentUuid })
      .then(({ data }) => {
        setContext(data.questionComponentForPatch.context);
        setS3KeyList(data.questionComponentForPatch.imageKeyList);
        setImageData(data.questionComponentForPatch.signedImageList);
      })
      .catch(() => {});
  };

  /** 내용과 이미지가 있을 경우에만 버튼 활성화 */
  const buttonDisabled = () => {
    if (context) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };

  /** 최종 댓글 수정 저장 */
  const commentSave = () => {
    patchApi
      .commentEdit({
        context: context,
        questionUuid: questionUuid,
        questionComponentUuid: questionComponentUuid,
        confirmImageS3KeyList: s3KeyList,
      })
      .then(() => {
        setCommentUpdate(!commentUpdate);
        showToast({ message: t(`errorCode.editReply`) });
        window.history.back();
      })
      .catch(() => {
        showToast({ message: "답글 수정을 실패하였습니다." });
      });
  };

  useEffect(() => {
    getDetailInfo();
    if (!questionComponentUuid) {
      window.history.back();
    }
  }, []);

  useEffect(() => {
    buttonDisabled();
  }, [context, imageData]);

  return (
    <>
      <ModalPageTemplate>
        <ModalHeader title="답글쓰기" cancelIs={false} off={false} />
        <DivTwoPartImprove
          display="grid"
          gap={10}
          pc_gap={12}
          margin="14px 0 0"
          pc_margin="48px 0 0"
        >
          <TextLine
            text="답글 내용"
            fontFamily="medium"
            fontsize={13}
            pc_fontsize={15}
            lineheight={18}
            pc_lineheight={22}
          />
          <TextArea
            value={context}
            onchange={(e) => {
              setContext(e.target.value);
            }}
            placeholderText="내용입력 (최대 1500자)"
            height={193}
            borderradius={6}
            border="0.6px solid var(--E_GREY, #818181);"
            padding="11.5px 16px"
          />
        </DivTwoPartImprove>
        <ImageAttach
          questionUuid={questionUuid}
          questionComponentUuid={questionComponentUuid}
          setS3KeyList={setS3KeyList}
          s3KeyList={s3KeyList}
          imageData={imageData}
          setImageData={setImageData}
        />
        <DivTwoPartImprove
          display="grid"
          grid_template_column="151px 151px"
          pc_grid_template_column="204px 204px"
          justify="center"
          gap={10}
          pc_gap={21}
          padding="34px 0 0"
        >
          <Button
            text="취소"
            width="151px"
            pc_width="204px"
            pc_height={52}
            pc_fontsize={17}
            height={42}
            border_radius={42}
            border="1px solid #000"
            backgroundcolor="white"
            font_family="medium"
            onclick={() => window.history.back()}
          />
          <Button
            text="저장하기"
            pc_width="204px"
            pc_height={52}
            pc_fontsize={17}
            width="151px"
            height={42}
            border_radius={42}
            border="none"
            font_color="white"
            backgroundcolor={buttonDisable ? "#d9d9d9" : "#ED893E"}
            font_family="medium"
            onclick={() => {
              commentSave();
            }}
            disabled={buttonDisable}
          />
        </DivTwoPartImprove>
      </ModalPageTemplate>
    </>
  );
};

export default CommentEdit;
