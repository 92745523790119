import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const TestSetting = ({ setTestSettingModal }) => {
  const goBack = () => {
    setTestSettingModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StAutonomy>
        <ModalHeaderD title="재접속 시 주변환경 재점검 진행 정보" />
        <div className="middleBox">
          항상 진행 시에는 응시자가 시험 시간 도중 접속이 끊겨 재접속을 하게
          되면 주변환경 점검부터 매번 다시 진행하게 되요. 반대로 진행 안함
          시에는 재접속을 하게 되면 카메라 세팅만 다시 진행하게 되어 절차를 줄일
          수 있어요.
          <br />
          <br />
          엄중한 감시가 필요한 시험에서는 ‘항상 진행’, 유연한 운영이 중요한
          시험에서는 ‘진행 안함’을 추천드려요.
        </div>
        <div className="buttonBox" onClick={() => window.history.back()}>
          확인
        </div>
      </StAutonomy>
    </>
  );
};

const StAutonomy = styled.div`
  position: fixed;
  background-color: white;
  @media ${device.pc} {
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    gap: 48px;
    .middleBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .listBox {
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        display: grid;
        flex-direction: column;
        gap: 5px;
        padding-left: 19px;
        margin-top: 4px;
        color: #444444;
        line-height: 24px;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 0 auto;
      border: 1px solid #000000;
      border-radius: 43px;
      font-size: 17px;
      font-family: "medium";
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: 16px;
    z-index: 53;
    transform: translate(-50%, 0);
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .middleBox {
      line-height: 24px;
    }
    .listBox {
      margin: 20px 0 24px;
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        margin-top: 4px;
        line-height: 24px;
        padding-left: 20px;
      }
    }
    .buttonBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      text-align: center;
      color: #0072de;
    }
  }
`;

export default TestSetting;
