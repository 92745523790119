import React from "react";
import styled from "styled-components";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import TextLine from "../atom/TextLine";
import ModalBack from "../atom/ModalBack";

interface DropDownProps extends DropDownStyle {
  list_open: boolean;
  set_list_open: React.Dispatch<React.SetStateAction<boolean>>;
  show: string;
  value: any;
  set_show: React.Dispatch<React.SetStateAction<string>>;
  showPlusText?: string;
  set_value: React.Dispatch<React.SetStateAction<any>>;
  show_list: string[];
  value_list: string[];
  // Noah
  divRef?: React.MutableRefObject<HTMLDivElement | null>;
}

interface DropDownStyle {
  justify?: string;
  width?: string;
  height?: number;
  padding?: string;
  border?: string;
  border_radius?: number;
  fontsize?: number;
  fontfamliy?: string;
  lineheight?: number;
  list_justify?: string;
  list_top?: number;
  list_left?: number;
  list_right?: number;
  list_gap?: number;
  list_width?: string;
  list_height?: number;
  list_border?: string;
  list_border_radius?: number;
  item_width?: string;
  item_height?: number;
  item_padding?: string;
  item_fontsize?: number;
  item_fontfamily?: string;
  item_lineheight?: number;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  return (
    <StDropDown>
      <DivTwoPartImprove
        display="flex"
        justify={props.justify}
        width={props.width}
        height={props.height}
        border={props.border}
        border_radius={props.border_radius}
        font_size={props.fontsize}
        font_family={props.fontfamliy}
        lineheight={props.lineheight}
        padding={props.padding}
        onclick={() => {
          props.set_list_open(false);
        }}
        cursor="pointer"
      >
        {props.show}
      </DivTwoPartImprove>
      {!props.list_open && (
        <>
          <ModalBack
            // background_color="red"
            z_index={9}
            onclick={() => props.set_list_open(true)}
          />
          <DivTwoPartImprove
            // Noah
            divRef={props.divRef}
            position="absolute"
            top={props.list_top ? props.list_top : 0}
            left={props.list_left ? props.list_left : undefined}
            right={
              typeof props.list_right === "number"
                ? props.list_right
                : undefined
            }
            z_index={10}
            display="grid"
            justify={props.list_justify}
            gap={props.list_gap}
            width={props.list_width}
            height={props.list_height}
            border={props.list_border}
            border_radius={props.list_border_radius}
            background_color="white"
            box_shadow="0px 5px 6px 0px rgba(0, 0, 0, 0.08);"
            overflowX="hidden"
          >
            {props?.show_list?.map((value, index) => (
              <TextLine
                key={index}
                text={value + props.showPlusText}
                padding={props.item_padding}
                width={props.item_width}
                height={props.item_height}
                fontsize={props.item_fontsize}
                fontFamily={props.item_fontfamily}
                lineheight={props.item_lineheight}
                onclick={() => {
                  props.set_list_open(true);
                  props.set_value(props.value_list[index]);
                  props.set_show(props.show_list[index]);
                }}
              />
            ))}
          </DivTwoPartImprove>
        </>
      )}
    </StDropDown>
  );
};


const StDropDown = styled.div<DropDownStyle>`
  position: relative;
`;

export default DropDown;
