import React from "react";
import styled from "styled-components";

//img
import progressImg from "../img/progress.svg";

const Loading = () => {
  return (
    <StBack>
      <img className="progress" src={progressImg} alt="" />
      <div className="word">데이터 불러오는 중...</div>
    </StBack>
  );
};

const StBack = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  font-size: 100px;
  background-color: white;
  .word {
    margin-top: 16px;
    font-size: 23px;
    font-family: "Medium";
  }
  .progress {
    margin-top: 14px;
    animation: rotate_image 1.5s linear infinite;
    transform-origin: 50% 50%;
  }

  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
