import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import Pagination from "../molecule/Pagination";
import Img from "../atom/Img";

// img
import toggleBefore from "../../img/toggle.svg";
import toggleAfter from "../../img/toggleAfter.svg";
import { useLocation, useSearchParams } from "react-router-dom";

interface ItemListBoxProps {
  listTitle?: string;
  nothingMessage: string;
  headerTextArr?: [{ title: string, flex: string }];
  children?: ReactNode;
  listCount: number;
  header_background_color?: string;
  pageListCount?: number;
  reportType: string;
}

const calLastPage = (number: number, pageCount: number) => {
  return Math.ceil(number / pageCount);
}

const ReportItemListBox: React.FC<ItemListBoxProps> = (props) => {
  const [toggleClick, setToggleClick] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageArr, setPageArr] = useState<number[]>([]);
  const [pageStart, setPageStart] = useState<number>(1);
  const pageListCount = props.pageListCount === undefined ? 10 : props.pageListCount;

  const lastPage: number = useMemo(() => {
    return calLastPage(props.listCount, pageListCount)
  }, [props.listCount]);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if(searchParams.has(props.reportType)) {
      setCurrentPage(Number(searchParams.get(props.reportType) as string));
    } else {
      setCurrentPage(1);
    }
  }, [location]);

  const updatePageArr = useCallback(() => {
    const newPageArr = [];
    for(let i = pageStart; i <= pageStart + 9 && i <= lastPage; i++) {
      newPageArr.push(i);
    }
    setPageArr(newPageArr);
  }, [pageStart, lastPage]);

  useEffect(() => {
    let start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    if(start !== pageStart) {
      setPageStart(start);
    }
  }, [currentPage, lastPage]);

  useEffect(() => {
    if(lastPage !== 0 && lastPage < currentPage) {
      setCurrentPage(lastPage);
    }
    updatePageArr();
  }, [pageStart, props.listCount])

  const modifyChildren = (child: ReactNode): ReactNode => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as ReactElement<any>, {
        ...child.props, 
        currentPage, // currentPage를 추가로 전달 
      }); 
    } 
    return child; 
  }; 
    
  const modifiedChildren = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child) && child.type === React.Fragment) {
      return React.Children.map(child.props.children, modifyChildren); 
    } else if(React.isValidElement(child) && child.type !== React.Fragment) {
      return React.Children.map(child, modifyChildren);
    } else {
      return modifyChildren(child);
    }
  });

  return (
    <>
      {/* Box */}
      <DivTwoPartImprove
        display="grid"
        flex_direction="column"
        gap={12}
        pc_gap={10}
        pc_background_color="#FFFFFF"
        pc_box_sizing="border-box"
        pc_border_radius={24}
        pc_padding="32px 24px 20px 24px"
        pc_width="1228px"
      >
        {/* 리스트 타이틀 */}
        <DivTwoPartImprove
          display="flex"
          align_items="center"
          margin="0 0 4px 0"
          pc_margin="0"
          pc_box_sizing="border-box"
          pc_padding="10px 8px 0px 8px"
        >
          <DivTwoPartImprove
            font_size={18}
            lineheight={21}
            font_family="Bold"
            pc_font_size={22}
            pc_lineheight={33}
          >
            {props.listTitle}
          </DivTwoPartImprove>
          {toggleClick ? (
            <Img
              display="flex"
              justify_content="center"
              align_items="center"
              tab_width="11px"
              tab_height="11px"
              margin="0 0 0 10px"
              pcMargin="0 0 0 15px"
              cursor="pointer"
              src={toggleAfter}
              onclick={() => {
                setToggleClick(false);
              }}
            />
          ) : (
            <Img
              display="flex"
              justify_content="center"
              align_items="center"
              tab_width="11px"
              tab_height="11px"
              margin="0 0 0 10px"
              pcMargin="0 0 0 15px"
              cursor="pointer"
              src={toggleBefore}
              onclick={() => {
                setToggleClick(true);
              }}
            />
          )}
        </DivTwoPartImprove>
        {toggleClick ? (
          <></>
        ) : (
          <>
            {props.listCount !== 0 ? (
              <>
                {/* griding */}
                <DivTwoPartImprove
                  display="grid"
                  grid_template_column="100%"
                  gap={12}
                  pc_display="flex"
                  pc_flex_direction="column"
                  pc_width="100%"
                  pc_margin="24px 0 0 0"
                  pc_gap={0}
                >
                  {/* 리스트 헤더 */}
                  <DivTwoPartImprove
                    display="none"
                    pc_display="flex"
                    pc_font_family="Medium"
                    pc_font_size={14}
                    // pc_background_color="#F0F1F2"
                    pc_background_color={props.header_background_color}
                    pc_padding="16px 0px"
                    pc_text_align="center"
                    pc_align_items="center"
                  >
                    {props.headerTextArr?.map((value, index) => (
                      <DivTwoPartImprove
                        key={index}
                        pc_flex={value.flex}
                        pc_padding="0px 8px"
                      >
                        {value.title}
                      </DivTwoPartImprove>
                    ))}
                  </DivTwoPartImprove>
                  {/* 리스트 메인 */}
                  {modifiedChildren}
                  {/* 페이지네이션 */}
                  <Pagination
                    pc_padding="24px 16px"
                    pc_gap={16}
                    pc_margin="10px 0 0 0"
                    lastPage={lastPage}
                    pageArr={pageArr}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    queryType={props.reportType}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                  />
                </DivTwoPartImprove>
              </>
            ) : (
              <DivTwoPartImprove
                display="flex"
                width="100%"
                height={90}
                justify="center"
                align_items="center"
                font_color="#bdbdbd"
                font_size={14}
                pc_font_size={18}
              >
                {props.nothingMessage}
              </DivTwoPartImprove>
            )}
          </>
        )}
      </DivTwoPartImprove>
    </>
  );
};

export default ReportItemListBox;