import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

//style
import style from "../modal/modal.module.css";
import styled from "styled-components";

//recoil
import { useRecoilState, useSetRecoilState } from "recoil";
import { userEmail, userInfo } from "../atoms";

//components
import Input from "./Input";
import Terms from "./Terms";
import BasicFinish from "./BasicFinish";

//axios
import { sendSignUp } from "../api/axiosTwo";

//hooks
import { device } from "../hooks/device";
import { useRef } from "react";
import { setCookie } from "../Cookie";
import axios from "axios";
import { baseURL } from "../api/handler";
import { korCode } from "./phoneCode";

// img
import blueCheck from "../img/blueCheckBox.svg";

import dayjs from "dayjs";
import SignUpEmail from "../modal/SignUpEmail";
import customAxios from "../api/handler";
import Timer from "./Timer";
import useToast from "../hooks/useToast";
import InputTextWithTitle from "../atomic/molecule/InputTextWithTitle";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import DropDown from "../atomic/molecule/DropDown";
import { useTranslation } from "react-i18next";
import focusing from "../hooks/focusing";

const SignUpContainer = ({
  type,
  setSignUpInputModal,
  completionStatus,
  setCompletionStatus,
  modalLevel,
  setTitleOn,
  setModalLevel,
  setEnterpriseSignupRequestComplete,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const utcInfo = new Date().getTimezoneOffset();
  // const [completionStatus, setCompletionStatus] = useState(false);
  const setUserData = useSetRecoilState(userInfo);
  const [emailErr, setEmailErr] = useState(null);
  const [errPwMessage, setErrPwMessage] = useState("");
  const [errPwChMessage, setErrPwChMessage] = useState("");
  const [modal, setModal] = useState(false);
  // const [checkBox1, setCheckBox1] = useState(false); //체크박스
  // const [checkBox2, setCheckBox2] = useState(false); //체크박스
  // const [checkBox3, setCheckBox3] = useState(false); //체크박스
  // const [checkBox4, setCheckBox4] = useState(false); //체크박스
  // const [checkBox5, setCheckBox5] = useState(false); //체크박스
  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-efcb61baf6c44e73a6f4c4cf9adbc368?pvs=4
   * 주석작성자 : Noah
   * 주석작성일자 : 2024.05.08
   * 티켓내용 : 프록토매틱 가입 시 ‘모두 동의’ 기능
   * 주석설명 : checkBox State를 Array에 담도록 변경
   */
  const [isAllChecked, setAllCheckBox] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));
  const [termsAndConditionsModal, setTermsAndConditionsModal] = useState(false);
  const [privacyPolicyModal, setPrivacyModalOn] = useState(false);
  const legPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  const emailRef = useRef() || "";
  const phoneRef = useRef();
  const passwordRef = useRef();
  const enterpriseRef = useRef();
  const enterRegistryNumRef = useRef();
  const jobPositionRef = useRef();
  const passwordCheckRef = useRef();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const birthRef = useRef();
  const [certified, setCertified] = useRecoilState(userEmail);
  const [year, setYear] = useState(2000);
  const [yearHide, setYearHide] = useState(true);
  const [month, setMonth] = useState(1);
  const [monthHide, setMonthHide] = useState(true);
  const [date, setDate] = useState(1);
  const [dateHide, setDateHide] = useState(true);

  // 전화번호 인증 전송 시간 관련 state
  const [min, setMin] = useState(5);
  const [sec, setSec] = useState(0);
  const [resendPhoneTime, setResendPhoneTime] = useState(0);
  const [phoneSent, setPhoneSent] = useState(false);
  const [smsComplete, setSmsComplete] = useState(false);

  const [hostGroupName, setHostGroupName] = useState();
  const [hostPhoneNumber, setHostPhoneNumber] = useState();
  const [planType, setPlanType] = useState("individualPlan");
  const [licenseNumber, setLicenseNumber] = useState();
  const [jobPosition, setJobPosition] = useState();

  const [countryCode, setCountryCode] = useState(
    korCode[28].number.replace("(", "").replace(")", "")
  );
  const [countryName, setCountryName] = useState(korCode[28].country);
  const [phoneOn, setPhoneOn] = useState(false);
  const [authNum, setAuthNum] = useState();
  const [selectedCountry, setSelectedCountry] = useState(28);
  const showToast = useToast();

  // errorCode
  const [smsErrorOne, setSmsErrorOne] = useState();
  const [smsErrorTwo, setSmsErrorTwo] = useState();
  const [enterpriseError, setEnterpriseError] = useState();

  // let years = Array.apply(null, Array(100));
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [dates, setDates] = useState([]);

  // Noah
  const maxYear = new Date().getFullYear() - 1;
  const dropDownListRef = useRef(null);

  useEffect(() => {
    if(!yearHide) {
      dropDownListRef.current.scrollTop = (maxYear - year) * 49;
    } else if(!monthHide) {
      dropDownListRef.current.scrollTop = (month - 1) * 49;
    } else if(!dateHide) {
      dropDownListRef.current.scrollTop = (date - 1) * 49;
    }
    
  }, [yearHide, monthHide, dateHide])
  /********************************************************** */


  /** 연도, 월 리스트 나타내기 */
  useEffect(() => {
    setYears([]);
    setMonths([]);
    for (let i = 0; i < 100; i++) {
      // setYears((temp) => [...temp, 2023 - i]);
      setYears((temp) => [...temp, maxYear - i]);
    }
    for (let i = 0; i < 12; i++) {
      setMonths((temp) => [...temp, 1 + i]);
    }
  }, []);

  /** 설정된 달에 따른 일수 계산 */
  useEffect(() => {
    setDates([]);
    if (month % 2 !== 0) {
      for (let i = 0; i < 31; i++) {
        setDates((temp) => [...temp, i + 1]);
      }
    } else if (month % 2 === 0 && month !== 2) {
      for (let i = 0; i < 30; i++) {
        setDates((temp) => [...temp, i + 1]);
      }
    } else if (month === 2) {
      for (let i = 0; i < 30; i++) {
        setDates((temp) => [...temp, i + 1]);
      }
    }
  }, [month]);

  /* 전화번호 재발송 시간 체크 */
  useEffect(() => {
    const timer = setInterval(() => {
      if (Number(resendPhoneTime) <= 10 && Number(resendPhoneTime) >= 1) {
        setResendPhoneTime(Number(resendPhoneTime) - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [resendPhoneTime]);

  useEffect(() => {
    return () => {
      setCertified((state) => ({ ...state, certified: false }));
    };
  }, []);

  const asia = [
    "AF",
    "YE",
    "BD",
    "BN",
    "BT",
    "CN",
    "HK",
    "ID",
    "IN",
    "JP",
    "KG",
    "KH",
    "KP",
    "KR",
    "KZ",
    "LA",
    "LK",
    "MM",
    "MO",
    "MV",
    "MY",
    "NP",
    "OM",
    "PH",
    "PK",
    "QA",
    "SG",
    "SY",
    "TH",
    "TJ",
    "TL",
    "TM",
    "TP",
    "TR",
    "TW",
    "UZ",
    "VN",
    "AZ",
  ];

  /** 회원가입 시 서버 설정을 위한 국가 체크 */
  const [country, setCountry] = useState("KR");

  const countryConfirm = async () => {
    const { data } = await axios.get("https://geolocation-db.com/json/");
    if (asia.includes(data.country_code)) {
      setCountry("KR");
    } else {
      setCountry("EN");
    }
  };

  useEffect(() => {
    countryConfirm();
  }, [country]);

  const emailErrAction = (text) => {
    dateErrScroll(emailRef);
    emailRef.current.focus();
    setEmailErr(text);
  };

  const pwCheckAction = (text) => {
    dateErrScroll(passwordRef);
    passwordRef.current.focus();
    setErrPwMessage(text);
  };

  const pwCkCheckAction = (text) => {
    dateErrScroll(passwordCheckRef);
    passwordCheckRef.current.focus();
    setErrPwChMessage(text);
  };

  //
  useEffect(() => {
    if (type === "individual") {
      setPlanType("individualPlan");
    } else if (type === "enterprise") {
      setPlanType("managerPlan");
    }
  }, [type]);

  //이메일 인증번호 발송(회원가입용)
  const sendSignUp_action = useMutation((email) => sendSignUp(email), {
    onSuccess: () => {
      setModal(true);
    },
    onError: ({ response }) => {
      if (response?.data?.errorCode === "HOST-002") {
        emailErrAction(t(`errorCode.host_002`));
      } else if (response?.data?.errorCode === "HOST-015") {
        emailErrAction(t(`errorCode.host_015`));
      } else if (response?.data?.errorCode === "JOI-EXCEPTION") {
        emailErrAction(t(`errorCode.emailform_error`));
      } else {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      }
    },
    // retry: 1
  });

  const emailModalOpen = () => {
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line no-useless-escape
    const regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (!regExp.test(emailRef.current.value)) {
      setEmailErr(t(`errorCode.emailform_error`));
      emailRef.current.focus();
    } else if (
      (emailRef?.current?.value || "")?.includes("encoded_") ||
      (emailRef?.current?.value || "")?.includes("edint_")
    ) {
      setEmailErr(t(`errorCode.invalid_email_character_error`));
      emailRef.current.focus();
    } else {
      sendSignUp_action.mutate({
        purpose: "host-email",
        email: emailRef.current.value,
      });
      setEmailErr(null);
    }
  };

  /** 전화번호 형식 통일화 */
  const phoneNumberUnion = (num) => {
    let temp = num.split("-");
    let phoneNumber = [];
    for (let i = 0; i < temp.length; i++) {
      phoneNumber = phoneNumber + temp[i];
    }
    setHostPhoneNumber(phoneNumber);
  };

  /** 회원가입 시켜주기 */
  const signUp = async (datas) => {
    if (type === "individual") {
      await axios
        .post(
          `${baseURL}/api/v1/hosts/individual/signup?offset=-540&lang=en`,
          datas
        )
        .then(({ data }) => {
          setCookie("token", data.accessToken, {
            path: "/",
          });
          setCompletionStatus(true);
          setSignUpInputModal(false);
        })
        .catch(({ response }) => {
          const errorCode = response.data.errorCode;
          if (response?.data?.errorCode === "HOST-002") {
            emailErrAction(t(`errorCode.host_002`));
            focusing({ ref: emailRef });
          } else if (errorCode === "INCORECT-PWD") {
            setErrPwChMessage(t(`errorCode.incorect_pwd`));
            focusing({ ref: passwordCheckRef });
          } else if (errorCode === "HOST-004") {
            setEnterpriseError(t(`errorCode.host_004`));
            focusing({ ref: enterpriseRef });
          } else if (errorCode === "VERIFY-001") {
            emailErrAction(t(`errorCode.verify_001`));
            focusing({ ref: emailRef });
          } else if (errorCode === "VERIFY-SMS-004") {
            setSmsErrorOne(t(`errorCode.verify_sms_004`));
            focusing({ ref: phoneRef });
          } else if (errorCode === "VERIFY-SMS-003") {
            setSmsErrorOne(t(`errorCode.verify_sms_003`));
            focusing({ ref: phoneRef });
          } else {
            showToast({
              message: t("errorCode.unknown_server_error"),
            });
          }
        });
    } else {
      await axios
        .post(
          `${baseURL}/api/v1/hosts/enterprise/manager/signup?offset=-540&lang=en`,
          datas
        )
        .then(() => {
          setSignUpInputModal(false);
          setEnterpriseSignupRequestComplete(true);
        })
        .catch(({ response }) => {
          const errorCode = response.data.errorCode;

          if (errorCode === "INCORECT-PWD") {
            setErrPwChMessage(t(`errorCode.incorect_pwd`));
            focusing({ ref: passwordCheckRef });
          } else if (errorCode === "HOST-004") {
            setEnterpriseError(t(`errorCode.host_004`));
            focusing({ ref: enterpriseRef });
          } else if (errorCode === "VERIFY-001") {
            emailErrAction(t(`errorCode.verify_001`));
            focusing({ ref: emailRef });
          } else if (errorCode === "VERIFY-SMS-004") {
            setSmsErrorOne(t(`errorCode.verify_sms_004`));
            focusing({ ref: phoneRef });
          } else if (errorCode === "VERIFY-SMS-003") {
            setSmsErrorOne(t(`errorCode.verify_sms_003`));
            focusing({ ref: phoneRef });
          } else {
            showToast({
              message: t("errorCode.unknown_server_error"),
            });
          }
        });
    }
  };

  /** 유저데이터 불러오기 */
  const getUserData = async () => {
    const { data } = await customAxios.get(`${baseURL}/api/v1/hosts`);
    setUserData(data?.getHost);
    return data;
  };

  /** 날짜 에러가 났을 때 날짜로 스크롤 해주기 */
  const dateErrScroll = (ref) => {
    focusing({ ref: ref });
  };

  /** 회원가입 전 에러 먼저 검수하기 */
  const signUpAction = (e) => {
    e.preventDefault();
    if (birthError) {
      focusing({ ref: birthRef });
      return;
    }
    signUp({
      hostType: type,
      hostPlan: type,
      hostGroupName: hostGroupName,
      hostEmail: emailRef.current.value,
      hostName: name,
      hostRegion: country,
      hostLanguage: "kr",
      hostPhoneNumber: hostPhoneNumber,
      hostPassword: password,
      hostPasswordCheck: passwordCheck,
      hostBirthdayAt: birth,
      // isAgreedOfMarketing: checkBox5,
      isAgreedOfMarketing: checkedState[4],
      purpose: "host-sms",
      hostCountry: countryName,
      hostCountryCode: countryCode,
      hostJobPosition: jobPosition,
      hostEntRegistryNumber: licenseNumber,
      authnum: authNum,
    });
  };

  const btnDisabled = () => {
    if (
      name &&
      // && certified
      password &&
      passwordCheck &&
      // && password === passwordCheck
      // checkBox1 &&
      // checkBox2 &&
      // checkBox3 &&
      // checkBox4
      checkedState[0] &&
      checkedState[1] &&
      checkedState[2] &&
      checkedState[3]
    ) {
      if (
        type === "enterprise" &&
        smsComplete &&
        jobPosition &&
        licenseNumber
      ) {
        return false;
      } else if (type === "individual") {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  /** onBlur 시 비밀번호 형식과 중복 체크 */
  const passwordCheckingOnBlur = () => {
    if (password) {
      if (!legPass.test(password)) {
        setErrPwMessage(t(`errorCode.pw_form_error`));
      } else {
        if (password === passwordCheck) {
          setErrPwMessage(null);
          setErrPwChMessage(null);
        } else {
          setErrPwChMessage(t(`errorCode.incorect_pwd`));
          setErrPwMessage(null);
        }
      }
    } else {
      setErrPwMessage(null);
      setErrPwChMessage(null);
    }
  };

  /** 전화번호로 인증번호 발송 */
  const phoneNumberSMS = () => {
    if (resendPhoneTime <= 0) {
      setSmsComplete(false);
      axios
        .post(`${baseURL}/api/v1/verify/sms?offset=${utcInfo}&lang=kr`, {
          purpose: "host-sms",
          phoneNumber: hostPhoneNumber,
          hostCountryCode: countryCode,
        })
        .then(() => {
          setMin(5);
          setSec(0);
          setPhoneSent(true);
          showToast({ message: t(`errorCode.verify_code_send`) });
          setResendPhoneTime(10);
        })
        .catch(({ response }) => {
          if (response.data.errorCode === "VERIFY-SMS-001") {
            setSmsErrorOne(t("errorCode.verify_sms_001"));
            focusing({ ref: phoneRef });
          } else if (response.data.errorCode === "VERIFY-SMS-002") {
            setSmsErrorOne(t("errorCode.verify_sms_002"));
            focusing({ ref: phoneRef });
          } else {
            showToast({
              message: t("errorCode.unknown_server_error"),
            });
          }
        });
    } else {
      showToast({
        message: `${t("errorCode.verify_code_error1")} ${resendPhoneTime}  ${t(
          "errorCode.verify_code_error2"
        )}`,
      });
    }
  };

  /** SMS 인증번호 확인 */
  const SMSCertificate = () => {
    customAxios
      .patch(`/api/v1/verify/sms`, {
        purpose: "host-sms",
        authnum: authNum,
        hostPhoneNumber: hostPhoneNumber,
        hostCountryCode: countryCode,
      })
      .then(({ data }) => {
        setSmsErrorOne();
        setSmsErrorTwo();
        setPhoneSent(false);
        setSmsComplete(true);
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "VERIFY-SMS-003") {
          setSmsErrorTwo(t("errorCode.verify_sms_003"));
        } else if (response.data.errorCode === "AWS-SMS-EXCEPTION") {
          setSmsErrorOne(t("errorCode.verify_sms_002"));
        }
        // toastOut();
      });
  };

  useEffect(() => {
    if (smsComplete) {
      setSmsErrorOne();
      setSmsErrorTwo();
    }
  }, [setSmsComplete]);

  const nowDate = dayjs();
  const [birth, setBirth] = useState(dayjs("2000-01-01"));
  const [birthError, setBirthError] = useState();
  const [yearError, setYearError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    setBirth(`${year}-${month}-${date}`);
  }, [year, month, date]);

  useEffect(() => {
    if (nowDate.diff(birth, "y") < 14) {
      setBirthError(t(`errorCode.birth_error`));
    } else {
      setBirthError();
    }

    /** 미성년자 구분하기 */
    if (
      dayjs(nowDate).subtract(14, "year").diff(birth, "d") <= -365 &&
      dayjs(nowDate).subtract(14, "year").diff(birth, "y") > -14
    ) {
      setYearError(true);
      setMonthError(false);
      setDateError(false);
    } else if (
      dayjs(nowDate).subtract(14, "year").diff(birth, "d") > -365 &&
      dayjs(nowDate).subtract(14, "year").diff(birth, "d") < -31
    ) {
      setYearError(false);
      setMonthError(true);
      setDateError(false);
    } else if (
      dayjs(nowDate).subtract(14, "year").diff(birth, "d") >= -31 &&
      dayjs(nowDate).subtract(14, "year").diff(birth, "d") <= 0
    ) {
      setYearError(false);
      setMonthError(false);
      setDateError(true);
    } else {
      setYearError(false);
      setMonthError(false);
      setDateError(false);
    }
  }, [birth]);

  return (
    <>
      <StSignUpContainer
        emailErr={emailErr}
        errPwMessage={errPwMessage}
        errPwChMessage={errPwChMessage}
        yearError={yearError}
        monthError={monthError}
        dateError={dateError}
        smsErrorOne={smsErrorOne}
        smsErrorTwo={smsErrorTwo}
      >
        {!completionStatus ? (
          <>
            <div className="titleBox">
              <div className="titleUp">
                AI 온라인 시험&nbsp;
                <br className="onlyForTabMob" />
                자동 관리감독 서비스
              </div>
              <div className="titleDown">
                어렵고 피곤한 시험 감시와 검증은 그만!
                <br />
                이젠 프록토매틱에게 맡기세요.
              </div>
            </div>
            <div></div>
            <form onSubmit={signUpAction} id="formAction">
              {type === "enterprise" && (
                <DivTwoPartImprove display="grid" margin="0 0 30px 0">
                  <InputTextWithTitle
                    title="기업 이름 (사업자등록증 기준)"
                    placeholder="기업명 입력"
                    title_fontfamily="medium"
                    inputRef={enterpriseRef}
                    title_pc_fontsize={15}
                    title_fontsize={13}
                    title_lineheight={19}
                    title_pc_lineheight={22}
                    input_border="none"
                    input_border_bottom="1px solid #dfdfdf"
                    input_border_radius={0}
                    input_padding="6px 0"
                    input_pc_lineheight={24}
                    input_pc_fontsize={16}
                    input_fontsize={16}
                    input_lineheight={24}
                    gap={5}
                    pc_gap={0}
                    errorText={enterpriseError}
                    padding="14px 0"
                    onchange={(e) => {
                      setHostGroupName(e.target.value);
                      setEnterpriseError();
                    }}
                  />
                  <DivTwoPartImprove display="flex" margin="0 0 14px 0">
                    <TextLine
                      text="* 주식회사 또는 (주)를 제외하고 입력해 주세요. "
                      pc_fontsize={12}
                      fontsize={11}
                      lineheight={16}
                    />
                  </DivTwoPartImprove>
                  <InputTextWithTitle
                    inputRef={enterRegistryNumRef}
                    title="사업자 등록번호"
                    placeholder="123-45-67890"
                    title_fontfamily="medium"
                    title_pc_fontsize={15}
                    title_fontsize={13}
                    title_lineheight={19}
                    title_pc_lineheight={22}
                    input_border="none"
                    input_border_bottom="1px solid #dfdfdf"
                    input_border_radius={0}
                    input_padding="6px 0"
                    input_pc_lineheight={24}
                    input_pc_fontsize={16}
                    input_fontsize={16}
                    input_lineheight={24}
                    input_type="text"
                    gap={5}
                    padding="14px 0"
                    value={licenseNumber}
                    onchange={(e) => {
                      if (
                        /^[0-9-]*$/.test(e.target.value) &&
                        e.target.value.length <= 12
                      ) {
                        setLicenseNumber(e.target.value);
                      }
                    }}
                  />
                  <InputTextWithTitle
                    title="직책/직함"
                    inputRef={jobPositionRef}
                    placeholder="직책 또는 직함 입력"
                    title_fontfamily="medium"
                    title_pc_fontsize={15}
                    title_fontsize={13}
                    title_lineheight={19}
                    title_pc_lineheight={22}
                    input_border="none"
                    input_border_bottom="1px solid #dfdfdf"
                    input_border_radius={0}
                    input_padding="6px 0"
                    input_pc_lineheight={24}
                    input_pc_fontsize={16}
                    input_fontsize={16}
                    input_lineheight={24}
                    onchange={(e) => {
                      setJobPosition(e.target.value);
                    }}
                    gap={5}
                    padding="14px 0"
                  />
                </DivTwoPartImprove>
              )}
              <InputTextWithTitle
                title={
                  type === "individual"
                    ? "주최자 이름"
                    : "주최자 이름 (플랜 관리자)"
                }
                placeholder="이름 입력"
                title_fontfamily="medium"
                title_pc_fontsize={15}
                title_fontsize={13}
                title_lineheight={19}
                title_pc_lineheight={22}
                input_border="none"
                input_border_bottom="1px solid #dfdfdf"
                input_border_radius={0}
                input_padding="6px 0"
                input_pc_lineheight={24}
                input_pc_fontsize={16}
                input_fontsize={16}
                input_lineheight={24}
                gap={5}
                padding="14px 0"
                onchange={(e) => {
                  setName(e.target.value);
                }}
              />
              <DivTwoPartImprove
                divRef={birthRef}
                display="grid"
                padding="14px 0"
              >
                <TextLine
                  text="생년월일"
                  pc_fontsize={15}
                  pc_lineheight={22}
                  lineheight={20}
                  fontsize={13}
                  fontFamily="medium"
                />
                <DivTwoPartImprove
                  display="grid"
                  grid_template_column="repeat(3,1fr)"
                  gap={9}
                  margin="10px 0 0"
                >
                  <DropDown
                    // Noah
                    divRef={dropDownListRef}
                    show={year + "년"}
                    showPlusText="년"
                    value={year}
                    set_list_open={setYearHide}
                    list_open={yearHide}
                    list_height={420}
                    padding="11.5px 6px"
                    item_padding="15px 24px"
                    item_width="168px"
                    item_height={49}
                    justify="center"
                    list_justify="center"
                    show_list={years}
                    value_list={years}
                    set_show={setYear}
                    set_value={setYear}
                    border={
                      yearError ? "1px solid #C83B38" : "0.6px solid #818181"
                    }
                    border_radius={6}
                  />
                  <DropDown
                    // Noah
                    divRef={dropDownListRef}
                    show={month + "월"}
                    showPlusText="월"
                    value={month}
                    set_list_open={setMonthHide}
                    list_open={monthHide}
                    list_justify="center"
                    list_height={420}
                    padding="11.5px 6px"
                    item_padding="15px 24px"
                    item_width="168px"
                    item_height={49}
                    justify="center"
                    show_list={months}
                    value_list={months}
                    set_show={setMonth}
                    set_value={setMonth}
                    border={
                      monthError ? "1px solid #C83B38" : "0.6px solid #818181"
                    }
                    border_radius={6}
                  />
                  <DropDown
                    // Noah
                    divRef={dropDownListRef}
                    show={date + "일"}
                    showPlusText="일"
                    value={date}
                    set_list_open={setDateHide}
                    list_open={dateHide}
                    list_justify="center"
                    list_height={420}
                    list_right={0}
                    padding="11.5px 6px"
                    item_padding="15px 24px"
                    item_width="168px"
                    item_height={49}
                    justify="center"
                    show_list={dates}
                    value_list={dates}
                    set_show={setDate}
                    set_value={setDate}
                    border={
                      dateError ? "1px solid #C83B38" : "0.6px solid #818181"
                    }
                    border_radius={6}
                  />
                </DivTwoPartImprove>
                {birthError && (
                  <TextLine
                    fontcolor="#c83b38"
                    lineheight={18}
                    fontsize={12}
                    margin="5px 0 0"
                    text={birthError}
                  />
                )}
              </DivTwoPartImprove>
              <DivTwoPartImprove
                font_size={11}
                pc_font_size={12}
                lineheight={16}
                font_color="#444"
                margin="0 0 14px 0"
              >
                * 생년월일 정보는 아이디 찾기에 필요한 정보로 활용돼요.
              </DivTwoPartImprove>
              {type === "enterprise" && (
                <div className="phoneBox">
                  <div className="title">전화번호</div>
                  <div className="phonePart">
                    <div className="frontNumber">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={() => {
                          setPhoneOn(true);
                        }}
                      >
                        {countryCode}
                      </div>
                      {phoneOn && (
                        <>
                          <div
                            className="phoneBack"
                            onClick={() => {
                              setPhoneOn(false);
                            }}
                          />
                          <div className="phoneModal">
                            {korCode.map((value, index) => (
                              <div
                                className="modalItem"
                                key={index}
                                style={
                                  index === selectedCountry
                                    ? { color: "#0381FE" }
                                    : {}
                                }
                                onClick={() => {
                                  setPhoneOn(false);
                                  setCountryCode(
                                    value.number
                                      .replace("(", "")
                                      .replace(")", "")
                                  );
                                  setCountryName(value.country);
                                  setSelectedCountry(index);
                                }}
                              >
                                <div>
                                  {value.country} {value.number}
                                </div>
                                {index === selectedCountry && (
                                  <img src={blueCheck} alt="" />
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <input
                      className="certificate"
                      ref={phoneRef}
                      placeholder="010-1234-5678"
                      onChange={() => {
                        setSmsErrorOne();
                        setSmsErrorTwo();
                      }}
                      onBlur={(e) => {
                        phoneNumberUnion(e.target.value);
                      }}
                    />
                    {!smsComplete ? (
                      <div
                        className="phoneButton"
                        onClick={() => {
                          phoneNumberSMS();
                        }}
                      >
                        인증번호 받기
                      </div>
                    ) : (
                      <div className="completeLine">
                        <img src={blueCheck} alt="" />
                        인증완료
                      </div>
                    )}
                  </div>
                  {smsErrorOne && (
                    <div className="errorMessage">{smsErrorOne}</div>
                  )}
                  {phoneSent & !smsComplete ? (
                    <div className="phonePart" style={{ margin: "15px 0 0" }}>
                      <input
                        onChange={(e) => {
                          setAuthNum(e.target.value);
                        }}
                        type="number"
                        className="certificateInput"
                        style={{ margin: "0 7px 0 0" }}
                        placeholder="인증번호 입력"
                      />
                      <div
                        className="phoneButton"
                        onClick={() => SMSCertificate()}
                      >
                        인증하기
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div className="greyBar" /> */}
                  {smsErrorTwo && (
                    <div className="errorMessage">{smsErrorTwo}</div>
                  )}
                  {phoneSent && (
                    <Timer
                      min={min}
                      setMin={setMin}
                      sec={sec}
                      setSec={setSec}
                    />
                  )}
                </div>
              )}
              <div className="emailBox">
                <div className="titlePart">이메일 주소 (로그인 ID)</div>
                <div className="inputPart">
                  <div className="firstInput">
                    <div className="firstLeft">
                      <input
                        ref={emailRef}
                        autoComplete="off"
                        onBlur={() => {
                          setEmailErr(null);
                        }}
                        onChange={() => setCertified(false)}
                        placeholder="이메일 주소 입력"
                      />
                      <div className="rowGrayBar" />
                    </div>
                    {!certified.certified ? (
                      <div
                        className="verifyButton"
                        onClick={() => {
                          emailModalOpen();
                        }}
                      >
                        이메일 인증
                      </div>
                    ) : (
                      <div className="complete">
                        <img src={blueCheck} />
                        <span>인증완료</span>
                      </div>
                    )}
                  </div>
                  {emailErr && <div className="errorCase">{emailErr}</div>}
                  <div className="restInputOne">
                    <input
                      ref={passwordRef}
                      value={password}
                      placeholder="비밀번호 입력"
                      autoComplete="new-password"
                      onBlur={() => {
                        passwordCheckingOnBlur();
                      }}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value.replace(/\s| /gi, ""));
                        setErrPwMessage(null);
                      }}
                    />
                    <div className="rowGrayBar" />
                  </div>
                  {errPwMessage && (
                    <div className="errorCase">{errPwMessage}</div>
                  )}
                  <div className="restInputTwo">
                    <input
                      ref={passwordCheckRef}
                      value={passwordCheck}
                      placeholder="비밀번호 확인"
                      autoComplete="new-password"
                      onBlur={() => {
                        passwordCheckingOnBlur();
                      }}
                      onChange={(e) => {
                        setPasswordCheck(e.target.value.replace(/\s| /gi, ""));
                        setErrPwChMessage(null);
                      }}
                      type="password"
                    />
                    <div className="rowGrayBar" />
                  </div>
                  {errPwChMessage && (
                    <div className="errorCase">{errPwChMessage}</div>
                  )}
                </div>
                <div className="infoPart">
                  <TextLine
                    fontsize={11}
                    pc_fontsize={12}
                    text="* 이메일 주소로 로그인 ID 생성 후 분석 결과보고서를
                    전달드려요."
                  />
                  <TextLine
                    fontsize={11}
                    pc_fontsize={12}
                    text="* 비밀번호는 문자, 숫자, 기호를 조합하여 8자 이상을
                    사용하세요."
                  />
                </div>
              </div>
              <div>
                <Terms
                  setModalLevel={setModalLevel}
                  modalLevel={modalLevel}
                  // checkBox1={checkBox1}
                  // setCheckBox1={setCheckBox1}
                  // checkBox2={checkBox2}
                  // setCheckBox2={setCheckBox2}
                  // checkBox3={checkBox3}
                  // setCheckBox3={setCheckBox3}
                  // checkBox4={checkBox4}
                  // setCheckBox4={setCheckBox4}
                  // checkBox5={checkBox5}
                  // setCheckBox5={setCheckBox5}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  isAllChecked={isAllChecked}
                  setAllCheckBox={setAllCheckBox}
                  termsAndConditionsModal={termsAndConditionsModal}
                  setTermsAndConditionsModal={setTermsAndConditionsModal}
                  privacyPolicyModal={privacyPolicyModal}
                  setPrivacyModalOn={setPrivacyModalOn}
                />
              </div>
              <div className="btnBox">
                <button
                  className={`${style.orangeButton} submitBtn`}
                  disabled={btnDisabled()}
                >
                  회원가입
                </button>
              </div>
            </form>
          </>
        ) : (
          <BasicFinish
            set
            content={`<span>${name}</span> 님, 반가워요!`}
            buttonText="가입 완료"
            setSignUpInputModal={setSignUpInputModal}
          />
        )}
      </StSignUpContainer>
      {modal && (
        <SignUpEmail
          setModal={setModal}
          email={emailRef?.current?.value}
          setModalLevel={setModalLevel}
          emailErrAction={emailErrAction}
          purpose="host-email"
        />
      )}
    </>
  );
};

const StSignUpContainer = styled.div`
  .titleBox {
    display: flex;
    flex-direction: column;
    text-align: center;
    .titleUp {
      font-family: "bold";
    }
    .titleDown {
      font-family: "regular";
      color: #6e6e6e;
    }
  }
  .emailBox {
    display: flex;
    flex-direction: column;
    padding: 14px 0 44px;
    box-sizing: border-box;
    .titlePart {
      font-size: 13px;
      font-family: "medium";
      line-height: 19px;
    }
    .inputPart {
      display: grid;
      margin-top: 5px;
      /* gap: 10px; */
      .errorCase {
        color: rgba(200, 59, 56, 1);
        font-size: 12px;
        line-height: 18px;
        font-family: "Regular";
        padding-top: 5px;
      }
      input {
        display: flex;
        flex-grow: 1;
        height: 37px;
        border: none;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-size: 16px;
          font-family: "light";
          line-height: 24px;
          color: #bdbdbd;
        }
      }
      .rowGrayBar {
        width: 100%;
        height: 1px;
        background-color: #dfdfdf;
      }
      .restInputOne {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .rowGrayBar {
          background-color: ${(props) => (props.errPwMessage ? "#C83B38" : "")};
        }
      }
      .restInputTwo {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .rowGrayBar {
          background-color: ${(props) =>
            props.errPwChMessage ? "#C83B38" : ""};
        }
      }
      .firstInput {
        width: 100%;
        display: flex;
        align-items: center;
        .complete {
          width: 70px;
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          color: #2276dc;
          font-size: 14px;
          font-family: "medium";
          gap: 5px;
          img {
            width: 12px;
            height: 10px;
          }
        }
        .firstLeft {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          input {
            flex-grow: 1;
          }
          .rowGrayBar {
            background-color: ${(props) => (props.emailErr ? "#C83B38" : "")};
          }
        }
        .verifyButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 31px;
          border-radius: 6px;
          background-color: #20315b;
          /* padding : 6px 12px; */
          box-sizing: border-box;
          color: #ffffff;
          font-size: 10px;
          font-family: "regular";
          line-height: 15px;
          cursor: pointer;
        }
      }
    }
    .infoPart {
      display: grid;
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
      div {
        line-height: 18px;
        font-size: 12px;
        font-family: "regular";
      }
    }
  }
  .phoneBox {
    display: flex;
    flex-direction: column;
    padding: 14px 0px;
    box-sizing: border-box;
    .title {
      font-size: 13px;
      font-family: "medium";
      line-height: 19px;
      margin-bottom: 10px;
    }
    .phonePart {
      display: flex;
      align-items: center;
      .certificate {
        font-size: 16px;
        padding: 6px 0;
        box-sizing: border-box;
        width: 159px;
        border-bottom: 1px solid
          ${(props) => (props.smsErrorOne ? "#C83B38" : "#dfdfdf")};
      }
      .certificateInput {
        border-bottom: 1px solid
          ${(props) => (props.smsErrorTwo ? "#C83B38" : "#dfdfdf")};
      }
      .frontNumber {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 42px;
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        padding: 11.5px 8px;
        box-sizing: border-box;
        border: 0.6px solid #818181;
        border-radius: 6px;
        cursor: pointer;
      }
      input {
        display: flex;
        align-items: center;
        line-height: 24px;
        height: 36px;
        border: none;
        border-bottom: 1px solid #dfdfdf;
        flex-grow: 1;
        margin: 0 7px 0 10px;
        &::placeholder {
          font-family: "light";
          font-size: 16px;
          color: #bdbdbd;
        }
        &:focus {
          outline: none;
        }
      }
      .phoneButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #20315b;
        color: white;
        font-size: 11px;
        line-height: 16px;
        /* width: 80px; */
        padding: 6px 10px;
        box-sizing: border-box;
        height: 31px;
        border-radius: 6px;
        cursor: pointer;
      }
      .completeLine {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 5px;
        color: #2276dc;
        font-size: 12px;
        font-family: "medium";
        line-height: normal;
        width: 70px;
        img {
          width: 12px;
          height: 10px;
        }
      }
      .phoneModal {
        position: absolute;
        z-index: 20;
        top: 0;
        left: 0;
        width: 312px;
        height: 416px;
        overflow-x: hidden;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        .modalItem {
          display: flex;
          /* flex-direction: column; */
          justify-content: space-between;
          padding: 15px 24px;
          box-sizing: border-box;
        }
        ::-webkit-scrollbar {
          width: 6px;
          height: 10px;
          margin-top: 100px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #c6c6c6;
          border-radius: 2px;
          width: 2px;
          height: 100px;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 1rem;
        }
      }
      .phoneBack {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: default;
      }
    }
    input {
      display: flex;
      align-items: center;
      line-height: 24px;
      height: 36px;
      border: none;
      &::placeholder {
        font-size: 16px;
        font-family: "light";
        color: #bdbdbd;
      }
      &:focus {
        outline: none;
      }
    }
    .greyBar {
      width: 100%;
      background-color: #dfdfdf;
      height: 1px;
      margin-bottom: 10px;
    }
    .info {
      font-size: 11px;
      line-height: 16px;
      font-family: "regular";
      color: #444444;
    }
  }
  height: 100%;
  .btnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }
  .errorMessage {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    color: #c83b38;
  }
  @media ${device.pc} {
    .onlyForTabMob {
      display: none;
    }
    .titleBox {
      display: grid;
      gap: 16px;
      margin-bottom: 48px;
      .titleUp {
        font-size: 26px;
        line-height: 38px;
      }
      .titleDown {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .emailBox {
      padding: 0;
      margin-bottom: 60px;
      .titlePart {
        font-size: 15px;
        font-family: "medium";
        line-height: 22px;
        margin-bottom: 5px;
      }
      .inputPart {
        margin-top: 5px;
        .firstInput {
          .firstLeft {
            flex-grow: 1;
            margin-right: 7px;
            input {
              font-size: 16px;
              flex-grow: 1;
            }
          }
          .verifyButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 101px;
            height: 37px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: "regular";
            line-height: 21px;
          }
        }
        .restInputOne {
          margin-top: 5px;
          input {
            font-size: 16px;
          }
        }
        .restInputTwo {
          margin-top: 5px;
          input {
            font-size: 16px;
          }
        }
      }
    }
    .phoneBox {
      padding: 0;
      margin-bottom: 32px;
      .title {
        font-size: 15px;
        font-family: "medium";
        line-height: 22px;
        margin-bottom: 15px;
      }
      .phonePart {
        .phoneButton {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #20315b;
          color: white;
          font-size: 14px;
          line-height: 21px;
          /* width: 101px; */
          padding: 6px 12px;
          height: 37px;
          border-radius: 6px;
        }
        .completeLine {
          font-size: 14px;
          img {
            width: 12px;
            height: 10px;
            /* margin: auto 0; */
          }
        }
      }
      input {
        font-size: 16px;
        font-family: "regular";
      }
    }
    .btnBox {
      margin-top: 48px;
      padding: 0;
    }
  }
  @media ${device.tabMob} {
    .emailBox {
      .inputPart {
        .firstInput {
          .complete {
            font-size: 12px;
          }
        }
      }
    }
    .errorMessage {
      font-size: 11px;
    }
    .titleBox {
      display: grid;
      gap: 15px;
      margin-bottom: 44px;
      .titleUp {
        font-size: 22px;
        line-height: 33px;
      }
      .titleDown {
        font-size: 15px;
        line-height: 22px;
      }
    }
  }
`;

export default SignUpContainer;
