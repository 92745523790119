import React from "react";
import styled from "styled-components";
import { device } from "../hooks/device";

const QnaTag = ({ category, title, date, uid, value, isAnswered }) => {
  return (
    <StTag>
      <div className="lineForPC">
        <div className="firstTag">
          <div>{category}</div>
        </div>
        <div>
          <div className="subjectM">{category}</div>
        </div>
        <div className="secondTagS">{title}</div>
        {isAnswered && <div className="subject">답변완료</div>}
        <div className="thirdTag">{date.split(" ").splice(0, 1)}</div>
      </div>
      <div>{isAnswered && <div className="subjectMM">답변완료</div>}</div>
    </StTag>
  );
};

const StTag = styled.div`
  cursor: pointer;
  @media ${device.pc} {
    .lineForPC {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .subjectMM,
    .subjectM {
      display: none;
    }
    .subject {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 58px;
      height: 26px;
      padding: 4px 8px;
      font-size: 12px;
      background-color: #444444;
      color: white;
      border-radius: 4px;
      font-size: 12px;
      box-sizing: border-box;
    }
    .firstTag {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 160px;
      height: 52px;
      padding: 14px 24px;
      color: #55595f;
      box-sizing: border-box;
    }
    .secondTagS {
      display: flex;
      align-items: center;
      width: 780px;
      height: 52px;
      padding: 14px 24px;
      box-sizing: border-box;
    }
    .secondTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 780px;
      height: 52px;
      padding: 14px 24px;
      box-sizing: border-box;
    }
    .thirdTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 52px;
      padding: 14px 24px;
      box-sizing: border-box;
    }
  }

  @media ${device.tabMob} {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    box-sizing: border-box;
    min-height: 102px;
    .lineForPC {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      /* min-height : 70px; */
    }
    .subjectMM {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 51px;
      height: 21px;
      padding: 3px 8px;
      box-sizing: border-box;
      background: #444444;
      border-radius: 4px;
      margin-left: 12px;
      font-weight: 400;
      font-size: 10px;
      line-height: 16px;
      color: white;
    }
    .subjectM {
      display: inline-flex;
      font-size: 12px;
      line-height: 18px;
      color: #55595f;
      height: 18px;
      margin-bottom: 6px;
    }
    .firstTag,
    .subject {
      display: none;
    }
    .secondTagS {
      display: flex;
      /* justify-content: center; */
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 2px;
      line-height: 24px;
    }
    .thirdTag {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-size: 12px;
      color: #888888;
      height: 20px;
    }
  }
`;

export default QnaTag;
