import { useQuery } from "react-query";
import apis from "../api/getApi";
import useToast from "./useToast";

const useGetEnterpriseChargeConfirm = ({ preUid }) => {
  const showToast = useToast();
  const fetcher = () => {
    const utc = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      apis
        .getEnterpristChargeConfirm({ utc, preUid })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.errorCode === "ENTERPRISE-009") {
            showToast({
              message: `알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요. (오류 코드 : ${response.data.errorCode})`,
            });
          } else if (response.data.errorCode === "PAYMET-006") {
            showToast({
              message: "gg",
            });
          } else {
            showToast({
              message: `알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요. (오류 코드 : ${response.data.errorCode})`,
            });
          }
          reject(response);
        });
    });
  };
  return useQuery(["chargeConfirm"], fetcher, {
    retry: 60,
    retryDelay: 1000,
  });
};

export default useGetEnterpriseChargeConfirm;
