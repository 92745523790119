import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";

const EventTable = ({
  sort,
  count,
  level,
  duration,
  index,
  eventListButton,
}) => {
  const [circleColor, setCircleColor] = useState();

  useEffect(() => {
    if (level === "cheat") {
      setCircleColor("#C83B38");
    } else if (level === "abnormal") {
      setCircleColor("#FFBC00");
    }
  }, [level]);

  if ((index > 2) & !eventListButton) {
    return;
  } else
    return (
      <StEventTable color={circleColor}>
        <div className="tableSort">
          <div className="tableLevel" />
          <div className="tableText">{sort}</div>
        </div>
        <div className="tableAccCount">{count}</div>
        <div className="tableAccDuration">{Math.floor(duration)} 초</div>
      </StEventTable>
    );
};

const StEventTable = styled.div`
  @media ${device.pc} {
    grid-template-columns: 522px 100px 100px;
    height: 40px;
    .tableSort {
      padding: 8px 20px;
      .tableLevel {
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
    }
  }
  @media ${device.tabMob} {
    grid-template-columns: 62% 1fr 1fr;
    min-height: 35px;
    font-size: 13px;
    line-height: 19px;
    .tableSort {
      padding: 6px 14px;
      word-break: break-all;
      .tableText {
        flex-grow: 1;
      }
      .tableLevel {
        min-width: 5px;
        height: 5px;
        border-radius: 5px;
      }
    }
  }
  display: grid;
  align-items: center;
  .tableSort {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    gap: 10px;
    .tableLevel {
      background-color: ${(props) => props.color};
      box-sizing: border-box;
    }
  }
  .tableAccCount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-left: 1px solid #d6d9dd;
    border-right: 1px solid #d6d9dd;
    text-align: center;
  }
  .tableAccDuration {
    text-align: center;
  }
`;

export default EventTable;
