import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Calendar from "../../components/Calendar.jsx";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../../atoms";

import startTimeClock from "../../img/timeStart.svg";
import endTimeClock from "../../img/timeEnd.svg";
import questionMk from "../../img/questionMark.svg";
import creatingExam from "../../img/creatingExam.svg";
import createExamCheck from "../../img/createExamCheck.png";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import dayjs, { locale } from "dayjs";
import { device } from "../../hooks/device.jsx";
import { PageHeader } from "../../components/Style.jsx";
import SettingInfo from "../../modal/CreatingExamSettingInfo.jsx";
import ExitInfo from "../../modal/CreatingExamExitInfo.jsx";
import customAxios from "../../api/handler.js";
import useToast from "../../hooks/useToast.js";
import { useTranslation } from "react-i18next";

const EditExam = ({ setMyPageScroll }) => {
  const { t } = useTranslation();
  const tempForm = "YYYY-MM-DD H:mm";
  const [startAlert, setStartAlert] = useState(false);
  const [endAlert, setEndAlert] = useState(false);
  const testTimeRef = useRef();
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const uid = useParams().uid;
  const utc = new Date().getTimezoneOffset();
  const showToast = useToast();
  const [endClick, setEndClick] = useState(false);

  // modal
  const [settingInfoModal, setSettingInfoModal] = useState(false);
  const [exitInfoModal, setExitInfoModal] = useState(false);

  // 유저 정보 불러오기
  const user = useRecoilValue(userInfo);

  // 시험 제목 저장하기
  const [testTitle, setTestTitle] = useState();

  // 응시자 자율 퇴장 여부
  const [leaving, setLeaving] = useState(true);

  // 재접속시 응시환경 재점검 진행
  const [reConfirm, setReConfirm] = useState(true);

  // 응원 메세지 상태
  const [courageMessage, setCourageMessage] = useState();

  // 시험 시간 및 날짜 관련 함수들

  let testDay = dayjs();

  const [startCalendar, setStartCalendar] = useState(false);
  const [endCalendar, setEndCalendar] = useState(false);
  const [tempStartTime, setTempStartTime] = useState(
    dayjs().add(1, "h").format(tempForm)
  );
  const [startHour, setStartHour] = useState("");
  const [startMin, setStartMin] = useState("");

  const [tempEndTime, setTempEndTime] = useState(
    dayjs().add(2, "h").format(tempForm)
  );
  const [endHour, setEndHour] = useState("");
  const [endMin, setEndMin] = useState("");
  const [startProcess, setStartProcess] = useState("");
  const [endProcess, setEndProcess] = useState("");
  const [diffMin, setDiffMin] = useState();
  let dayjsStartTime = dayjs(tempStartTime);
  let dayjsEndTime = dayjs(tempEndTime);

  // 정보 받아오기
  useEffect(() => {
    customAxios
      .get(`/api/v1/tests/patch/${uid}`)
      .then(({ data }) => {
        setBeforeData(data);
        setTempStartTime(data?.testData?.testStartTime);
        setTempEndTime(data?.testData?.testEndTime);
        setStartHour(dayjs(data?.testData?.testStartTime).format("hh"));
        setEndMin(dayjs(data?.testData?.testStartTime).format("mm"));
        setEndHour(dayjs(data?.testData?.testEndTime).format("hh"));
        setEndMin(dayjs(data?.testData?.testEndTime).format("mm"));
        setDiffMin(
          dayjs(data?.testData?.testEndTime).diff(
            data?.testData?.testStartTime,
            "m"
          )
        );
        setTestTitle(data?.testData?.testName);
        setLeaving(data?.testData?.isTesterSelfExit);
        setReConfirm(data?.testData?.isReActivateAroundSetting);
        setCourageMessage(data?.testData?.testComment);
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "TIMEZONE-012") {
          showToast({
            message: t(`errorCode.timezone_011_myexam`),
          });
          navigate(`/${country}/myexam`);
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
          navigate(`/${country}/myexam`);
        }
      });
  }, []);

  /** tempStartTime 이 변경될 경우 부수적인 값들도 모두 변경 */
  useEffect(() => {
    setStartHour(dayjsStartTime.format("hh"));
    setStartMin(dayjsStartTime.format("mm"));
    checkStartTimeWithHour();
    setTempEndTime(
      dayjs(tempStartTime).add(diffMin, "m").format("YYYY-MM-DD HH:mm")
    );
    // checkStartTimeWithTestTime();
    if (
      dayjsStartTime.format("A") === "AM" ||
      dayjsStartTime.format("A") === "오전"
    ) {
      setStartProcess("AM");
    } else {
      setStartProcess("PM");
    }
  }, [tempStartTime]);

  /** tempEndTime 이 변경될 경우 부수적인 값들도 모두 변경 */
  useEffect(() => {
    setEndHour(dayjs(tempEndTime).format("hh"));
    setEndMin(dayjs(tempEndTime).format("mm"));
    if (
      dayjs(tempEndTime).format("A") === "AM" ||
      dayjs(tempEndTime).format("A") === "오전"
    ) {
      setEndProcess("AM");
    } else {
      setEndProcess("PM");
    }
    // if (endClick) {
    //   checkEndTimeWithTestTime();
    //   setEndClick(false);
    // }
  }, [tempEndTime]);

  /** 종료 시간을 설정 시 총 시험시간이 30분 이하일 경우 시작 시간 기준 30분 추가 || 120 분 이상일 경우 시작 시간 기준 120분 추가 + 토스트 메세지 띄워줌 */
  // const checkEndTimeWithTestTime = () => {
  //   if (dayjsEndTime.diff(tempStartTime, "m") < 50) {
  //     setTempEndTime(dayjsStartTime.add(30, "m").format(tempForm));
  //     // console.log("you?");
  //     showToast({ message: "총 시험시간은 30분 이상으로만 설정 가능해요." });
  //   } else if (dayjsEndTime.diff(tempStartTime, "m") > 120) {
  //     setTempEndTime(dayjsStartTime.add(120, "m").format(tempForm));
  //     showToast({ message: "시험 시간은 총 120분을 넘을 수 없어요." });
  //   }
  // };

  /** 시작 시간 설정 시 시작 시간이 현재 시간보다 30분 뒤로 설정했는지 체크, 아닐 경우 시작시간을 30분 뒤로 보내줌 */
  const checkStartTimeWithHour = () => {
    if (dayjsStartTime.diff(testDay, "m") < 30) {
      setTempStartTime(testDay.add(30, "m").format(tempForm));
      showToast({
        message:
          "시작 시간은 현재 시간을 기준으로 30분 이후로만 설정 가능해요.",
      });
    }
  };

  /** 시작 시간 설정 시 총 시험시간이  120분을 초과 할 시에 종료시간을 시작 시간 기준 30분 뒤로 자동 조정*/
  // const checkStartTimeWithTestTime = () => {
  //   if (dayjsEndTime.diff(tempStartTime, "m") > 120) {
  //     setTempEndTime(dayjsStartTime.add(30, "m").format(tempForm));
  //   } else if (dayjsEndTime.diff(tempStartTime, "m") < 30) {
  //     setTempEndTime(dayjsStartTime.add(30, "m").format(tempForm));
  //   }
  // };

  /** 시작 시간 시를 입력하고 저장 ( 내부에서 오전 오후 처리를 다 해줌 // AM,PM 선택 시 또는 Hour onBlur시 사용됨 ) */
  const controlStartHour = (prop) => {
    const hour = Number(prop);
    if (startProcess === "AM" || startProcess === "오전") {
      if (hour >= 12) {
        setTempStartTime(dayjsStartTime.set("h", 11).format(tempForm));
        setStartHour(11);
        setStartMin(dayjsStartTime.format("mm"));
      } else if (hour <= 0) {
        setTempStartTime(dayjsStartTime.set("h", 0).format(tempForm));
      } else {
        setTempStartTime(dayjsStartTime.set("h", hour).format(tempForm));
      }
    } else {
      if (hour > 12) {
        setTempStartTime(dayjsStartTime.set("h", 23).format(tempForm));
        setStartHour(11);
        setStartMin(dayjsStartTime.format("mm"));
      } else if (hour <= 0) {
        setTempStartTime(dayjsStartTime.set("h", 12).format(tempForm));
      } else if (hour === 12) {
        setTempStartTime(dayjsStartTime.set("h", 12).format(tempForm));
      } else {
        setTempStartTime(dayjsStartTime.set("h", hour + 12).format(tempForm));
      }
    }
  };

  /** 응시 시작의 AM 또는 PM 버튼을 눌렀을 때 시간을 바꿔준다. */
  const controlStartAmMPm = (prop) => {
    if (
      (prop === "AM" || prop === "오전") &
      (dayjsStartTime.format("A") === "PM" ||
        dayjsStartTime.format("A") === "오후")
    ) {
      setTempStartTime(dayjsStartTime.subtract(12, "h").format(tempForm));
    } else if (
      (prop === "PM" || prop === "오후") &
      (dayjsStartTime.format("A") === "AM" ||
        dayjsStartTime.format("A") === "오전")
    ) {
      setTempStartTime(dayjsStartTime.add(12, "h").format(tempForm));
    }
  };

  /** 응시 종료의 AM 또는 PM 버튼을 눌렀을 때 시간을 바꿔준다. */
  // const controlEndAmPm = (prop) => {
  //   if (
  //     (prop === "AM" || prop === "오전") &
  //     (dayjsEndTime.format("A") === "PM" || dayjsEndTime.format("A") === "오후")
  //   ) {
  //     if (dayjsEndTime.subtract(12, "h").diff(tempStartTime, "m") > 120) {
  //       setTempEndTime(dayjsStartTime.add(2, "h").format(tempForm));
  //     } else {
  //       setTempEndTime(dayjsEndTime.subtract(12, "h").format(tempForm));
  //     }
  //   } else if (
  //     (prop === "PM" || prop === "오후") &
  //     (dayjsEndTime.format("A") === "AM" || dayjsEndTime.format("A") === "오전")
  //   ) {
  //     if (dayjsEndTime.add(12, "h").diff(tempStartTime, "m") > 120) {
  //       setTempEndTime(dayjsStartTime.add(2, "h").format(tempForm));
  //     } else {
  //       setTempEndTime(dayjsEndTime.add(12, "h").format(tempForm));
  //     }
  //   }
  // };

  /** 시작 시간 분을 입력하고 저장 */
  const controlStartMin = (prop) => {
    let min = Number(prop);
    if (min >= 60) {
      setTempStartTime(dayjsStartTime.set("m", 59).format(tempForm));
    } else {
      setTempStartTime(dayjsStartTime.set("m", min).format(tempForm));
    }
  };

  /** 종료 시간 시를 입력하고 저장 */
  // const controlEndHour = (prop) => {
  //   const hour = Number(prop);
  //   if (endProcess === "AM" || endProcess === "오전") {
  //     if (hour >= 12) {
  //       setTempEndTime(dayjsEndTime.set("h", 11).format(tempForm));
  //     } else if (hour <= 0) {
  //       setTempEndTime(dayjsEndTime.set("h", 0).format(tempForm));
  //     } else {
  //       setTempEndTime(dayjsEndTime.set("h", hour).format(tempForm));
  //     }
  //   } else {
  //     if (hour > 12) {
  //       setTempEndTime(dayjsEndTime.set("h", 23).format(tempForm));
  //     } else if (hour <= 0) {
  //       setTempEndTime(dayjsEndTime.set("h", 12).format(tempForm));
  //     } else if (hour === 12) {
  //       setTempEndTime(dayjsEndTime.set("h", 12).format(tempForm));
  //     } else {
  //       setTempEndTime(dayjsEndTime.set("h", hour + 12).format(tempForm));
  //     }
  //   }
  // };

  /** 종료 시간 분을 입력하고 저장 */
  // const controlEndMin = (min) => {
  //   if (min >= 60) {
  //     setTempEndTime(dayjsEndTime.set("m", 59).format(tempForm));
  //   } else {
  //     setTempEndTime(dayjsEndTime.set("m", min).format(tempForm));
  //   }
  // };

  /** 달력 선택시 날짜 변경해주기 */
  const settingDates = (date, ddd, thisIsFor) => {
    if (dayjs(date).diff(dayjs(), "M") >= 2) {
      setTempStartTime(dayjs().add(2, "M").format(tempForm));
      setTempEndTime(dayjs().add(2, "M").add(30, "minute").format(tempForm));
      showToast({
        message: t(`errorCode.timezone_010`),
      });
    } else {
      if (thisIsFor === "start") {
        setTempStartTime(date);
      } else if (thisIsFor === "end") {
        setTempEndTime(date);
      }
    }
  };

  const [beforeData, setBeforeData] = useState();

  const finalCheck = () => {
    if (
      testTitle !== beforeData?.testData?.testName ||
      beforeData?.testData?.testStartTime !==
        dayjs(tempStartTime).format("YYYY-MM-DD HH:mm:ss") ||
      beforeData?.testData?.testEndTime !==
        dayjs(tempEndTime).format("YYYY-MM-DD HH:mm:ss") ||
      beforeData?.testData?.isReActivateAroundSetting !== reConfirm ||
      beforeData?.testData?.isTesterSelfExit !== leaving ||
      beforeData?.testData?.testComment !== courageMessage
    ) {
      customAxios
        .patch(`/api/v1/tests/patch/${uid}`, {
          testName: testTitle,
          testComment: courageMessage,
          testStartTime: tempStartTime,
          testEndTime: tempEndTime,
          isReActivateAroundSetting: reConfirm,
          isTesterSelfExit: leaving,
        })
        .then(({ data }) => {
          navigate(`/myexam`);
        })
        .catch(({ response }) => {
          const errorCode = response.data.errorCode;
          if (errorCode === "TIMEZONE-003") {
            showToast({
              message: t(`errorCode.timezone_003`),
            });
          } else if (errorCode === "TIMEZONE-005") {
            showToast({
              message: t(`errorCode.timezone_005`),
            });
          } else if (errorCode === "TIMEZONE-011") {
            showToast({
              message: t(`errorCode.timezone_011`),
            });
          } else if (errorCode === "TIMEZONE-010") {
            showToast({
              message: t(`errorCode.timezone_010`),
            });
          } else if (errorCode === "TIMEZONE-012") {
            showToast({
              message: t(`errorCode.timezone_012`),
            });
          } else if (errorCode === "TEST-005") {
            showToast({
              message: t(`errorCode.test_005`),
            });
          } else if (errorCode === "TEST-007") {
            navigate("notAllowed");
          } else {
            showToast({
              message: t(`errorCode.unknown_server_error`),
            });
          }
        });
    } else {
      showToast({ message: t(`errorCode.test_005`) });
    }
  };

  return (
    <>
      {settingInfoModal && (
        <SettingInfo setSettingInfoModal={setSettingInfoModal} />
      )}
      {exitInfoModal && <ExitInfo setExitInfoModal={setExitInfoModal} />}
      <StCreateExam
        testTitle={testTitle}
        leaving={leaving}
        reConfirm={reConfirm}
        startProcess={startProcess}
        endProcess={endProcess}
        startAlert={startAlert}
        endAlert={endAlert}
      >
        <div className="mainFrame">
          <PageHeader title="시험 편집하기" page="myexam" />
          <div className="bucket">
            <div className="hostInfoBox">
              <div className="smallTitle">주최자 정보</div>
              <div className="hostInfo">
                {user?.hostName} ({user?.hostEmail})
              </div>
            </div>
            <div className="titleBox">
              <div className="smallTitle">시험제목</div>
              <input
                className="testInput"
                placeholder="시험 제목 입력"
                value={testTitle}
                onChange={(e) => {
                  setTestTitle(e.target.value);
                }}
              />
              <div className="underLine" />
            </div>
            <div className="testDateBox" ref={testTimeRef}>
              <div className="smallTitle">시험 날짜와 시간 (최대 120분)</div>
              <div className="testTimePart">
                <div className="testStartToFinish">
                  <div className="testStart">
                    <img className="endTimeClock" src={startTimeClock} alt="" />
                    <div>응시 시작 :</div>
                  </div>
                  <div className="times">
                    <div
                      className="dateToday"
                      onClick={() => {
                        setStartCalendar(true);
                      }}
                    >
                      {dayjs(tempStartTime).format("YYYY-MM-DD")}
                    </div>
                    <Calendar
                      tempTime={tempStartTime}
                      tempStart={tempStartTime}
                      tempEnd={tempEndTime}
                      setTime={settingDates}
                      isFor={"start"}
                      open={startCalendar}
                      setOpen={setStartCalendar}
                      setEndClick={setEndClick}
                    />
                    <div
                      className="testTimeOne"
                      onClick={() => {
                        setStartAlert(false);
                        setEndAlert(false);
                      }}
                    >
                      <div
                        className="startAM"
                        onClick={() => controlStartAmMPm("AM")}
                      >
                        AM
                      </div>
                      <div
                        className="startPM"
                        onClick={() => controlStartAmMPm("PM")}
                      >
                        PM
                      </div>
                      <div className="realTime">
                        <input
                          className="hourPart"
                          type="number"
                          value={startHour || ""}
                          max={24}
                          min={0}
                          onChange={(e) => setStartHour(e.target.value)}
                          onBlur={() => {
                            controlStartHour(startHour);
                          }}
                        />
                        <div className="between">:</div>
                        <input
                          className="minPart"
                          type="number"
                          value={startMin || ""}
                          max={59}
                          min={0}
                          onChange={(e) => setStartMin(e.target.value)}
                          onBlur={() => {
                            controlStartMin(startMin);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="testStartToFinish"
                  onClick={() => {
                    setStartAlert(false);
                    setEndAlert(false);
                  }}
                >
                  <div className="testStart">
                    <img className="endTimeClock" src={endTimeClock} alt="" />
                    <div>응시 종료 :</div>
                  </div>
                  <div className="times" onClick={() => navigate("notAllowed")}>
                    <div
                      className="dateToday"
                      style={{ cursor: "default" }}
                      onClick={() => {
                        // setEndCalendar(true);
                      }}
                    >
                      {dayjsEndTime.format("YYYY-MM-DD")}
                    </div>
                    {/* <Calendar
                      // tempTime={tempEndTime}
                      tempStart={tempStartTime}
                      // tempEnd={tempEndTime}
                      setTime={settingDates}
                      isFor={"end"}
                      open={endCalendar}
                      setOpen={setEndCalendar}
                      setEndClick={setEndClick}
                    /> */}
                    <div
                      className="testTimeTwo"
                      onClick={() => setEndAlert(false)}
                    >
                      <div
                        className="endAM"
                        style={{ cursor: "default" }}
                        onClick={() => {
                          // controlEndAmPm("AM");
                          setEndClick(true);
                        }}
                      >
                        AM
                      </div>
                      <div
                        className="endPM"
                        style={{ cursor: "default" }}
                        onClick={() => {
                          // controlEndAmPm("PM");
                          // setEndClick(true);
                        }}
                      >
                        PM
                      </div>
                      <div className="realTime">
                        <input
                          className="hourPart"
                          type="number"
                          value={endHour || ""}
                          max={24}
                          min={0}
                          style={{ cursor: "default" }}
                          // disabled
                          // onChange={(e) => {
                          //   setEndHour(e.target.value);
                          //   setEndClick(true);
                          // }}
                          // onBlur={() => {
                          //   // controlEndHour(endHour);
                          // }}
                        />
                        <div className="between">:</div>
                        <input
                          className="minPart"
                          value={endMin}
                          max={59}
                          min={0}
                          style={{ cursor: "default" }}
                          // disabled
                          // onChange={(e) => {
                          //   setEndMin(e.target.value);
                          //   setEndClick(true);
                          // }}
                          // onBlur={() => {
                          //   // controlEndMin(endMin);
                          // }}
                          maxLength="2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info">
                <div className="infoLine">
                  * 응시자는 시험 시작하기 30분 전부터 입장이 가능해요.
                </div>
                <div className="infoLine">
                  * 지각한 응시자는 시험 시작 후 15분 이내까지만 입장이
                  가능해요.
                </div>
              </div>
            </div>
            <div className="middleRow">
              <div className="middleRowLeft">
                <div className="checkBox">
                  <div className="smallTitle">
                    <div>응시자 자율 퇴장</div>
                    <img
                      src={questionMk}
                      alt=""
                      onClick={() => {
                        setExitInfoModal(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="checkTime">
                    <div
                      className="leavingCheckTrue"
                      onClick={() => {
                        setLeaving(true);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <div>허용</div>
                    </div>
                    <div
                      className="leavingCheckFalse"
                      onClick={() => {
                        setLeaving(false);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <div>허용 안함</div>
                    </div>
                  </div>
                </div>
                <div className="checkBox">
                  <div className="smallTitle">
                    <div>재접속 시 주변환경 재점검 진행</div>
                    <img
                      src={questionMk}
                      alt=""
                      onClick={() => setSettingInfoModal(true)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div className="checkTime">
                    <div
                      className="reConfirmTrue"
                      onClick={() => {
                        setReConfirm(true);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <div>진행 안함</div>
                    </div>
                    <div
                      className="reConfirmFalse"
                      onClick={() => {
                        setReConfirm(false);
                      }}
                    >
                      <div className="outerCircle">
                        <div className="innerCircle" />
                      </div>
                      <div>항상 진행</div>
                    </div>
                  </div>
                </div>
                <div className="fightingBox">
                  <div className="smallTitle">응원 메세지 (선택)</div>
                  <textarea
                    placeholder="내용 입력 (최대 50자)"
                    value={courageMessage ? courageMessage : ""}
                    onChange={(e) => {
                      setCourageMessage(e.target.value);
                    }}
                    maxLength="100"
                  />
                </div>
              </div>
              <div className="imgBox">
                <img className="creatingExam" src={creatingExam} alt="" />
              </div>
            </div>
            <div className="checkPointBox">
              <div className="checkPointTitle">
                <img src={createExamCheck} />
                <div>CHECK POINT</div>
              </div>
              <div className="check">
                1. 시험 생성 완료 시, 시험 당일 응시 및 녹화 영상 업로드가
                가능한{" "}
                <span className="bolding">전용 QR과URL 링크가 자동 발급</span>
                돼요.
              </div>
              <div className="check">
                2. 생성된 시험 정보(QR, URL 링크 등)는 주최자의{" "}
                <span className="bolding">
                  이메일 주소로 응시자용 가이드와 함께 발송
                </span>
                돼요.{" "}
              </div>
              <div className="check">
                3. 각 응시자는 프록토매틱 앱을 통해{" "}
                <span className="bolding">
                  시작 시간 30분 전부터 입장 가능하며, 응시환경에 대한 사전
                  점검(환경 점검, 카메라 거치)을 수행
                </span>
                할 수 있어요.
              </div>
              <div className="check">
                4. 시험 종료 후 모든 응시자의 영상 업로드가 완료되면,{" "}
                <span className="bolding">
                  자동 AI 영상분석을 거쳐 24시간 내 결과보고서를 작성
                </span>
                하여 주최자의 이메일 주소로 전달드려요.{" "}
              </div>
              <div className="check">
                5. 생성한 모든 시험은 프록토매틱 홈페이지의{" "}
                <span className="bolding">‘내 시험 관리’</span>를 통해 언제든
                다시 확인 가능해요.
              </div>
            </div>
            <div className="lastBox">
              서비스 이용에 어려움이 있으신가요? &nbsp;
              <br className="onlyForMob" />
              <span
                className="blueing"
                onClick={() => {
                  navigate(`/myPage`);
                  setMyPageScroll("customerCenter");
                }}
              >
                고객센터
              </span>
              를 통해 연락주시면 신속하게 도와드릴게요.
            </div>
            <div className="buttonBox">
              <button
                className="cancelButton"
                onClick={() => navigate(`/myexam`)}
              >
                취소
              </button>
              <button
                className="nextButton"
                disabled={!testTitle}
                onClick={() => {
                  finalCheck();
                }}
              >
                저장하기
              </button>
            </div>
          </div>
        </div>
      </StCreateExam>
      <Outlet />
    </>
  );
};

const StCreateExam = styled.div`
  padding: 0 24px 37px;
  box-sizing: border-box;
  .mainTitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 132px;
    align-items: center;
    padding: 22px 0 44px;
    box-sizing: border-box;
    text-align: center;
    line-height: 33px;
    font-size: 22px;
    font-family: "bold";
  }
  .hostInfoBox {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .smallTitle {
      font-family: "medium";
      font-size: 13px;
    }
    .hostInfo {
      color: #2276dc;
      line-height: 24px;
      margin-top: 11px;
    }
  }
  .titleBox {
    box-sizing: border-box;
    .smallTitle {
      font-family: "medium";
      font-size: 13px;
    }
    .testInput {
      border: none;
      padding: 0;
      line-height: 24px;
      margin-top: 11px;
      font-size: 16px;
      font-family: "regular";
      width: 100%;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
    }
    .underLine {
      width: 100%;
      height: 1px;
      background-color: #dfdfdf;
      margin-top: 6px;
    }
  }
  .testDateBox {
    box-sizing: border-box;
    margin-bottom: 44px;
    .modalForCalendar {
      position: relative;
    }
    .smallTitle {
      font-family: "medium";
      font-size: 13px;
      margin-bottom: 18px;
    }
    .times {
      width: 312px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      position: relative;
      align-items: center;
      gap: 8px;
    }
    .dateToday {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: "regular";
      width: 100%;
      height: 42px;
      border: 0.6px solid #818181;
      border-radius: 6px;
      box-sizing: border-Box;
    }
    .testStartToFinish {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      /* align-items: center; */
      box-sizing: border-box;
      margin-top: 12px;
      .testStart {
        display: flex;
        font-size: 14px;
        font-family: "medium";
        align-items: center;
        margin-bottom: 8px;
        .endTimeClock {
          margin-right: 10px;
        }
      }
      .testTimeOne {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 5px;
        width: 100%;
        height: 42px;
        padding: 7px 6px;
        box-sizing: border-box;
        border-radius: 6px;
        border: ${(props) =>
          props.startAlert ? "1px solid #C83B38" : "0.6px solid #818181"};
        .startAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.startProcess === "AM" ? "#20315B" : "#D9D9D9"};
          color: white;
          cursor: pointer;
        }
        .startPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.startProcess === "AM" ? "#D9D9D9" : "#20315B"};
          color: white;
          cursor: pointer;
        }
        .endAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.endProcess === "AM" ? "#20315B" : "#D9D9D9"};
          color: white;
          cursor: pointer;
        }
        .endPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.endProcess === "AM" ? "#D9D9D9" : "#20315B"};
          color: white;
          cursor: pointer;
        }
        .realTime {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: center;
          align-items: center;
          width: 75px;
          gap: 3px;
          .hourPart {
            width: 20px;
            border: none;
            line-height: 16px;
            font-size: 16px;
            font-family: "regular";
            &:focus {
              outline: none;
            }
            padding-top: 3px;
            /* background-color : blue; */
          }
          .between {
            line-height: 26px;
          }
          .minPart {
            width: 20px;
            border: none;
            padding-top: 3px;
            line-height: 16px;
            font-size: 16px;
            font-family: "regular";
            &:focus {
              outline: none;
            }
          }
        }
      }
      .testTimeTwo {
        display: grid;
        grid-template-columns: repeat(3, auto);
        gap: 5px;
        width: 100%;
        height: 42px;
        padding: 7px 6px;
        box-sizing: border-box;
        border-radius: 6px;
        border: 0.6px solid
          ${(props) => (props.endAlert ? "#C83B38" : "#818181")};
        .startAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.startProcess === "AM" ? "#20315B" : "#D9D9D9"};
          color: white;
          cursor: pointer;
        }
        .startPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.startProcess === "AM" ? "#D9D9D9" : "#20315B"};
          color: white;
          cursor: pointer;
        }
        .endAM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.endProcess === "AM" ? "#20315B" : "#D9D9D9"};
          color: white;
          cursor: pointer;
        }
        .endPM {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 11px;
          font-family: "medium";
          line-height: 16px;
          width: 28px;
          height: 28px;
          border-radius: 4px;
          background-color: ${(props) =>
            props.endProcess === "AM" ? "#D9D9D9" : "#20315B"};
          color: white;
          cursor: pointer;
        }
        .realTime {
          display: grid;
          grid-template-columns: repeat(3, auto);
          justify-content: center;
          align-items: center;
          width: 75px;
          gap: 3px;
          .hourPart {
            width: 20px;
            border: none;
            padding-top: 3px;
            font-size: 16px;
            font-family: "regular";
            &:focus {
              outline: none;
            }
          }
          .between {
          }
          .minPart {
            width: 20px;
            border: none;
            padding-top: 3px;
            font-size: 16px;
            font-family: "regular";
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
    .info {
      display: grid;
      flex-direction: column;
      gap: 6px;
      margin-top: 14px;
      .infoLine {
        line-height: 16px;
        font-size: 11px;
        color: #444444;
      }
    }
  }
  .checkBox {
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    box-sizing: border-box;
    .smallTitle {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      font-family: "medium";
      font-size: 13px;
      gap: 6px;
    }
    .checkTime {
      display: grid;
      flex-direction: column;
      gap: 15px;
      margin-top: 15px;
      .leavingCheckTrue {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.leaving ? "#0381FE" : " #8C8C8C")};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.leaving ? "#0381FE" : "")};
          }
        }
      }
      .leavingCheckFalse {
        display: grid;
        grid-template-columns: auto 1fr;

        gap: 12px;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.leaving ? "#8C8C8C" : "#0381FE")};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.leaving ? "" : "#0381FE")};
          }
        }
      }
      .reConfirmTrue {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.reConfirm ? "#0381FE" : "#8C8C8C")};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.reConfirm ? "#0381FE" : "")};
          }
        }
      }
      .reConfirmFalse {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        .outerCircle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18.43px;
          height: 18.43px;
          border-radius: 18.43px;
          border: 1px solid
            ${(props) => (props.reConfirm ? "#8C8C8C" : "#0381FE")};
          box-sizing: border-box;
          .innerCircle {
            display: flex;
            width: 10.4px;
            height: 10.4px;
            border-radius: 10.4px;
            background-color: ${(props) => (props.reConfirm ? "" : "#0381FE")};
          }
        }
      }
    }
  }
  .fightingBox {
    padding: 14px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    .smallTitle {
      font-family: "medium";
      font-size: 13px;
    }
    textarea {
      padding: 11.5px 16px;
      height: 89px;
      font-size: 16px;
      font-family: "regular";
      border: 0.6px solid #818181;
      border-radius: 6px;
      box-sizing: border-box;
      resize: none;
      margin-top: 10px;
      &::placeholder {
        color: #bdbdbd;
        font-family: "light";
      }
    }
  }
  .imgBox {
    box-sizing: border-box;
    max-width: 354px;
    .creatingExam {
      width: 100%;
      max-width: 280px;
    }
  }
  .checkPointBox {
    display: grid;
    flex-direction: column;
    background-color: #fff4d8;
    padding: 40px 24px;
    box-sizing: border-box;
    border-radius: 12px;
    gap: 24px;
    .checkPointTitle {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      color: #2276dc;
      font-size: 18px;
      font-family: "bold";
      gap: 8px;
      margin-bottom: 24px;
    }
    .check {
      font-size: 15px;
      line-height: 22px;
      text-indent: -14px;
      padding-left: 23px;
    }
    .bolding {
      font-family: "bold";
    }
  }
  .lastBox {
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    margin-top: 16px;
    .blueing {
      color: #2276dc;
      text-decoration: underline;
    }
  }
  .buttonBox {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    margin-top: 40px;
    gap: 21px;
    .nextButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 151px;
      height: 42px;
      background-color: #20315b;
      border: none;
      color: white;
      border-radius: 42px;
      cursor: pointer;
    }
    .cancelButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 151px;
      height: 42px;
      border: 1px solid #000000;
      background-color: white;
      border-radius: 43px;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: #f0f1f2;
    padding: 0;
    .onlyForMob {
      display: none;
    }
    .mainFrame {
      width: 1228px;
      padding: 80px 0px;
      box-sizing: border-box;
      margin: 0 auto;
      .bucket {
        width: 865px;
        background-color: white;
        margin: 60px auto 0;
        padding: 60px;
        box-sizing: border-box;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        .hostInfoBox {
          .smallTitle {
            font-size: 15px;
            font-family: "semibold";
            line-height: 27px;
          }
        }
        .titleBox {
          margin: 34px 0;
          .smallTitle {
            font-size: 15px;
            font-family: "semibold";
            line-height: 27px;
          }
        }
        .testDateBox {
          .smallTitle {
            font-size: 15px;
            font-family: "semibold";
            line-height: 27px;
          }
          .testTimePart {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
          }
        }
        .checkPointBox {
          padding: 40px;
        }
        .middleRow {
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 48px;
          margin-bottom: 72px;
          .middleRowLeft {
            display: grid;
            flex-direction: column;
            gap: 34px;
            width: 464px;
            .fightingBox {
              margin: 0;
              .smallTitle {
                font-size: 15px;
                font-family: "semibold";
                line-height: 27px;
              }
            }
            .checkBox {
              padding: 0;
              .smallTitle {
                display: flex;
                align-items: center;
                font-size: 15px;
                font-family: "semibold";
                line-height: 27px;
                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
          .imgBox {
            margin-top: auto;
            img {
              width: 233px;
              height: 233px;
            }
          }
        }
        .buttonBox {
          .cancelButton {
            width: 204px;
            height: 52px;
            font-size: 17px;
            font-family: "medium";
          }
          .nextButton {
            width: 204px;
            height: 52px;
            font-size: 17px;
            font-family: "medium";
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .mainFrame {
      width: 100%;
      max-width: 752px;
      box-sizing: border-box;
      margin: 0 auto;
    }
    .bucket {
      .hostInfoBox {
        height: 88px;
        padding: 14px 0;
        .smallTitle {
        }
      }
      .titleBox {
        height: 88px;
        padding: 14px 0;
        .smallTitle {
        }
      }
      .testDateBox {
        padding: 14px 0;
        .smallTitle {
        }
        .testTimePart {
        }
      }
      .imgBox {
        margin: 0 auto;
        padding: 14px 37px;
      }
    }
  }
`;

export default EditExam;
