import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../../hooks/device";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import { ModalBackD } from "../../../components/Style";
import TextLine from "../../../atomic/atom/TextLine";
import { useTranslation } from "react-i18next";

const CreditInfo = () => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g5_1_5";

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={false} />
      <StModal>
        <div className="titlePart">
          <TextLine text="기업 적립금 정보" />
        </div>
        <div className="titleInfo">
          적립금의 환불 유효기간은 충전일로부터 최대 1년이며, 10C(10분) 단위로
          사용 가능해요. 예정된 시험을 취소하면 사용한 적립금이 자동으로
          환원돼요. 적립금 사용은 기업회원 우대 혜택 등으로 지급받은 추가
          적립금을 포함하여 충전한 날짜순으로 순차적으로 차감돼요.
          <br />
          <br />
          환불 유효기간이 지난 적립금의 경우, 환불이 불가한 적립금으로 자동
          전환되나 소멸되지 않으며 일반 적립금과 동일하게 사용할 수 있어요.
        </div>
        <div className="middlePart">※ 주의 사항 </div>
        <ul>
          <li>환불 유효기간 : 구매일로부터 최대 6개월</li>
          <li>
            환불 유효기간 만료일의 시간은 당일 자정(00:00)을 기준으로 해요.{" "}
          </li>
          <li>
            환불 불가 적립금은 기존 적립금과 동일하게 사용이 가능하나 환불은
            가능하지 않아요.
          </li>
        </ul>
        <div
          className="confirmPart"
          onClick={() => {
            window.history.back();
          }}
        >
          확인
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .titleInfo {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
    margin-top: 20px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
    margin-top: 20px;
  }
  .confirmPart {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    color: #0072de;
    margin: 0 auto;
  }
  ul {
    padding-left: 24px;
    margin-top: 4px;
    line-height: 24px;
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 53;
    width: calc(100% - 16px);
    border-radius: 24px;
    padding: 24px;
  }
  @media ${device.pc} {
    width: 580px;
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    .titlePart {
      font-size: 28px;
      line-height: 41px;
    }
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .confirmPart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      margin-top: 32px;
      color: black;
      border-radius: 43px;
      cursor: pointer;
    }
  }
`;

export default CreditInfo;
