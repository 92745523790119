import React, { useState } from "react";
import styled from "styled-components";
import notFound from "../img/notFound.png";
import { device } from "../hooks/device";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import spinner from "../img/spinner.svg";

const TestUrlPage = () => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const [loading, setLoading] = useState(true);
  const waitOneSec = () =>
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  waitOneSec();

  // clearTimeout(waitOneSec);

  return (
    <>
      {loading ? (
        <StSpinner>
          <img className="spinner" src={spinner} alt="" />
        </StSpinner>
      ) : (
        <StNotFound>
          <img src={notFound} alt="" />
          <div className="textBox">
            <div className="middlePart">
              <div>시험 전용 URL입니다 :)</div>
            </div>
            <div className="lastPart">
              <div>
                프록토매틱 앱에서 해당 URL을 통해 시험에 응시할 수 있습니다.
              </div>
              <div>* 프록토매틱 앱 접속 &#62; URL로 접속하기</div>
            </div>
          </div>
          <div className="homeButton" onClick={() => navigate(`/`)}>
            홈으로 돌아가기
          </div>
        </StNotFound>
      )}
    </>
  );
};

const StNotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f0f1f2;
  img {
    width: 152px;
    height: 160px;
  }
  .textBox {
    margin: 32px 0;
    text-align: center;
    .middlePart {
      font-size: 22px;
      font-family: "bold";
      line-height: 33px;
      color: #444444;
    }
    .lastPart {
      font-size: 12px;
      font-family: "medium";
      line-height: 18px;
      color: #aeaeae;
      margin-top: 16px;
    }
  }
  .homeButton {
    width: 204px;
    height: 42px;
    padding: 15px 11px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "medium";
    color: white;
    background-color: #15254e;
    border-radius: 43px;
    cursor: pointer;
  }
  @media ${device.pc} {
    height: calc(100vh - 353px);
    min-height: 761px;
    img {
      width: 191px;
      height: 200px;
    }
    .textBox {
      margin: 40px 0;
      .middlePart {
        font-size: 38px;
        line-height: 56px;
      }
      .lastPart {
        font-size: 18px;
        line-height: 27px;
        margin-top: 24px;
      }
    }
    .homeButton {
      height: 52px;
      font-size: 17px;
      line-height: 25px;
    }
  }
  @media ${device.tabMob} {
    min-height: 656px;
  }
`;

const StSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .spinner {
    animation: rotate_image 2s linear infinite;
    transform-origin: 50% 50%;
  }
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${device.pc} {
    height: calc(100vh - 353px);
    min-height: 761px;
  }
  @media ${device.tabMob} {
    min-height: 656px;
  }
`;

export default TestUrlPage;
