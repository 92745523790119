import React, { ChangeEvent, useEffect, useState } from "react";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import styled from "styled-components";
import { device } from "../hooks/device";
import style from "../modal/modal.module.css";
import whiteCheck from "../img/white_ch.svg";
import { SetterOrUpdater } from "recoil";

interface SendingPrivacyCheckProps {
  setSendingPrivacyModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSendingConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOn: SetterOrUpdater<boolean>;
}

interface SendingPrivacyCheckStyle {
  whiteCheck: string;
}

const SendingPrivacyCheck: React.FC<SendingPrivacyCheckProps> = ({
  setSendingPrivacyModal,
  setSendingConfirmModal,
  setModalOn,
}) => {
  const [privacyCheck, setPrivacyCheck] = useState(false);

  const modalOff = () => {
    window.history.back();
  };

  const goBack = () => {
    setSendingPrivacyModal(false);
  };

  const onChangeSetCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    setPrivacyCheck(!privacyCheck);
  }

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={modalOff} />
      <StSendingPrivacyCheck whiteCheck={whiteCheck}>
        <ModalHeaderD title="응시자의 영상(개인정보) 처리 유의사항" />
        <div className="wrapper">
          <div className="privacyList">
            <ol>
              <li>
                응시자의 영상(개인정보)은 해당 시험에서 응시자가 부정행위를 하였는지 여부를
                판단하기 위한 목적으로만 사용할 수 있습니다.
              </li>
              <li>
                응시자 본인의 동의 없이 영상을 응시자 외 제3자에게 공유/제공/전송하거나
                열람하게 하는 경우,{" "}
                <span className="underline">개인정보보호법상 5년 이하의 징역 또는 5천만 원 이하의 벌금형</span>{" "}
                에 처해질 수 있습니다.
              </li>
              <li>
                또한 위반행위를 한 행위자를 처벌하는 것 외에,{" "}
                <span className="underline">그 법인에게도 7천만 원 이하의 벌금형</span>{" "}
                이 부과될 수 있으며,{" "}
                <span className="underline">전체 매출액의 3% 이내에서 과징금</span>{" "}
                을 부과할 수 있습니다.
              </li>
            </ol>
            <br />
            본인은 위 유의사항을 충분히 인지하였으며 이를 확인합니다.
          </div>
          <div className="checkDiv">
            <div className="checkBox">
              <input
                type="checkbox"
                id="privacyCheck"
                onChange={(e) => onChangeSetCheckBox(e)}
              />
              <label htmlFor="privacyCheck" />
            </div>
            <p>확인합니다.</p>
          </div>
          <div className="buttonBox">
            <button
              className="cancelButton"
              onClick={goBack}
            >
              취소
            </button>
            <div className="greyBar" />
            <button
              className="confirmButton"
              disabled={!privacyCheck}
              onClick={() => {
                setSendingPrivacyModal(false);
                setSendingConfirmModal(true);
                /**
                 * 모달이 꺼지고 새로운 모달이 켜지는 경우 recoil 변수 업데이트
                 * 하지 않으면 스크롤락이 풀리는 현상 발생
                 */
                setModalOn(false);
              }}
            >
              계속하기
            </button>
          </div>
        </div>
      </StSendingPrivacyCheck>
    </>
  );
};

const StSendingPrivacyCheck = styled.div<SendingPrivacyCheckStyle>`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .underline {
    text-decoration-line: underline;
  }
  @media ${device.pc} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    width: 580px;
    box-sizing: border-box;
    display: grid;
    gap: 48px;
    ol {
      padding-inline-start: 25px;
    }
    .wrapper {
      display: grid;
      flex-direction: column;
      gap: 48px;
    }
    .privacyList {
      font-family: "medium";
      font-size: 18px;
      line-height: 27px;
    }
    .checkDiv {
      display: flex;
      font-family: "medium";
      font-size: 18px;
      line-height: 27px;
      gap: 8px;
      align-items: center;
    }
    .checkBox {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label:before {
      //체크 안된 상태의 라벨
      content: "";
      display: flex;
      /* width: 22px; */
      /* height: 22px; */
      width: 1.5rem;
      height: 1.5rem;
      border: 0.6px solid #818181;
      appearance: none;
      /* border-radius: 4px; */
      border-radius: 50%;
      background-color: #fff;
      box-sizing: border-box;
    }
    input[type="checkbox"]:checked + label:before {
      //체크된 상태의 라벨
      content: "";
      display: flex;
      /* width: 22px; */
      /* height: 22px; */
      width: 1.5rem;
      height: 1.5rem;
      border: none;
      /* border-radius: 4px; */
      border-radius: 50%;
      background-color: #0381FE;
      background-image: url(${(props) => props.whiteCheck});
      background-repeat: no-repeat;
      background-position: center;
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 21px;
    }
    .cancelButton {
      padding: 0;
      width: 204px;
      height: 52px;
      color: #000;
      border: 1px solid #000000;
      background-color: #fff;
      border-radius: 3000px;
      box-sizing: border-box;
      font-size: 17px;
      line-height: 25px;
      font-family: "Medium";
      cursor: pointer;
    }
    .confirmButton {
      padding: 0;
      width: 204px;
      height: 52px;
      background-color: #20315b;
      color: #fff;
      border-radius: 43px;
      border: none;
      font-size: 17px;
      line-height: 25px;
      font-family: "Medium";
      cursor: pointer;
    }
    .confirmButton:disabled {
      background-color: #d9d9d9;
      cursor: auto;
    }
    .greyBar {
      display: none;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    width: 344px;
    /* height: 207px; */
    padding: 24px;
    box-sizing: border-box;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 26px;
    ol {
      padding-inline-start: 24px;
    }
    .wrapper {
      display: grid;
      flex-direction: column;
      gap: 24px;
    }
    .privacyList {
      font-family: "regular";
      font-size: 16px;
      line-height: 24px;
    }
    .checkDiv {
      display: flex;
      font-family: "regular";
      font-size: 16px;
      line-height: 24px;
      gap: 12px;
      align-items: center;
    }
    .checkBox {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"] + label {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 0.6px solid #818181;
      position: relative;
      vertical-align: middle;
      margin-right: 5px;
    }
    input[type="checkbox"]:checked + label {
      border-color: #0381FE;
    }
    input[type="checkbox"]:checked + label:before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #0381FE;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cancelButton, 
    .confirmButton {
      width: 147.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      color: #0072de;
      cursor: pointer;
      background-color: white;
      border: none;
      font-family: "medium";
      font-size: 18px;
      line-height: 27px;
    }
    .confirmButton:disabled {
      opacity: 0.4;
      cursor: auto;
    }
    .greyBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
    }
  }
`;

export default SendingPrivacyCheck;