import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";

const ExitInfo = ({ setExitInfoModal }) => {
  const goBack = () => {
    setExitInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StPaymentInfo>
        <ModalHeaderD title="응시자 자율 퇴장 정보" />
        <div className="bodyPart">
          허용 시에는 문제 풀이를 모두 마친 응시자는 ‘촬영 종료’를 통해 시험
          시간 도중에도 자율적으로 퇴장이 가능해요. 반대로 허용 안함 시에는 시험
          종료 시간까지 모든 응시자는 퇴장이 불가능해요.
        </div>
        <div className="warningPart">
          <span>※ 주의 사항</span>
          <ul>
            <li>
              허용 안함 시, 응시자는 문제 풀이를 미리 마쳤어도 시험이 끝날
              때까지 녹화 영상에 얼굴과 양손, PC 스크린이 보이게 유지될 수
              있도록 자세에 대한 안내가 필요해요.
            </li>
            <li>
              그렇지 않을 경우, 문제 풀이 이후에 일어난 행동들에 대해 자칫
              부정행위 또는 이상행동 이벤트로 오인되어 감지될 수 있어요. <br />
              예) 엎드려 잠, 팔짱을 끼고 있음 등
            </li>
          </ul>
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          확인
        </div>
      </StPaymentInfo>
    </>
  );
};

const StPaymentInfo = styled.div`
  position: fixed;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  z-index: 53;
  @media ${device.pc} {
    width: 580px;
    padding: 60px;
    top: 50%;
    transform: translate(-50%, -50%);
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 48px auto 0;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
    }
    .bodyPart {
      margin: 48px 0;
      font-family: "medium";
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    bottom: 16px;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyPart {
      margin-bottom: 20px;
    }
    .confirmButton {
      color: #0072de;
    }
  }
  .bodyPart {
    line-height: 24px;
  }
  .warningPart {
    line-height: 24px;
    span {
      font-family: "semibold";
    }
    ul {
      display: grid;
      flex-direction: column;
      gap: 4px;
      padding-left: 20px;
      margin-top: 4px;
      color: #444444;
    }
  }
  .confirmButton {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }
`;

export default ExitInfo;
