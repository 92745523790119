import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../Cookie";
//components

// img
import greyX from "../img/delete.png";
import inputReset from "../img/inputClean.svg";

//hooks
import { device } from "../hooks/device";

//style
import style from "../modal/modal.module.css";
import styled from "styled-components";
import { useRef } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { bannerOn, languageIs, userInfo, userlang } from "../atoms";
import { ModalBack } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import axios from "axios";
import { baseURL } from "../api/handler";
import customAxios from "../api/handler";
import useModal from "../hooks/useModal";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";

const Login = ({
  setLoginModal,
  setSignUpChooseModal,
  setResetPasswordModal,
  setFindIdModal,
}) => {
  useModal(setLoginModal);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const banneris = useRecoilValue(bannerOn);

  useEffect(() => {
    if (getCookie("token")) {
      navigate(`/${country}`);
    }
  }, []);

  useScrollLockThree();

  const utcInfo = new Date().getTimezoneOffset();
  const [hostEmail, setHostEmail] = useState(false);
  const [hostPassword, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState(null);
  const [pwErr, setPwErr] = useState(null);
  const [emailFocus, setEmailFocus] = useState(false);
  const [pwFocus, setPwFocus] = useState(false);
  const setUserData = useSetRecoilState(userInfo);
  const emailRef = useRef();
  const passwordRef = useRef();
  const setUserLang = useSetRecoilState(userlang);
  const loginSubmitRef = useRef();
  const showToast = useToast();

  // modal state

  const mutate = async () => {
    // eslint-disable-next-line no-useless-escape
    const regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (regExp.test(hostEmail)) {
      setEmailErr(null);
    } else {
      setEmailErr(t("errorCode.emailform_error"));
      emailRef.current.focus();
      setEmailFocus(true);
      return;
    }
    axios
      .post(`${baseURL}/api/v1/hosts/login?offset=${utcInfo}`, {
        hostEmail,
        hostPassword,
      })
      .then(({ data }) => {
        setCookie("token", data?.login.accessToken, {
          path: "/",
          // expires: new Date(Date.now() + 60000),
          // expires: new Date(Date.now() + 2592000),
        });
        /**
         * 티켓링크 : https://www.notion.so/edint/Web-06cf63836381449ea1b0aa43569e29df?pvs=4
         * 주석작성자 : Noah
         * 주석작성일자 : 2024.05.09
         * 티켓내용 : 메인화면 네비게이션 개선
         * 주석설명 : 로그인 시 내 시험 페이지로 이동
         */
        // navigate(`/`);
        navigate(`/myexam`);
        setLoginModal(false);
        customAxios.get(`${baseURL}/api/v1/hosts`).then(({ data }) => {
          setUserData(data?.getHost);
          setUserLang(data?.getHost?.hostLanguage);
        });
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "HOST-001") {
          setEmailErr(t("errorCode.host_001"));
          emailRef.current.focus();
          setPwFocus(true);
        } else if (errorCode === "INCORECT-DB-PWD") {
          setPwErr(t("errorCode.incorect_db_pwd"));
          passwordRef.current.focus();
        } else {
          showToast({
            message: t("errorCode.unknown_server_error"),
          });
        }
      });
  };

  const onChangeReset = (setFc) => {
    setFc("");
  };

  const submit = (e) => {
    if (e.key === "Enter") {
      mutate();
    }
  };

  const btnDisabled = () => {
    if (hostEmail && hostPassword) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.addEventListener("click", (e) => {
      if (
        e.target !== emailRef.current &&
        e.target !== loginSubmitRef.current
      ) {
        setEmailFocus(false);
        setEmailErr(null);
        // if (e.target !== loginSubmitRef.current) {
        //     setEmailErr(false)
        // }
      }
      if (e.target !== passwordRef.current) {
        setPwFocus(false);
        setPwErr(null);
      }
    });
  }, []);

  // const setVh = () => {
  //   document.documentElement.style.setProperty(
  //     "--vh",
  //     `${window.innerHeight}px`
  //   );
  // };
  // window.addEventListener("resize", setVh);
  // setVh();

  return (
    <>
      <ModalBack />
      <Container banneris={banneris}>
        <div className="wrapper">
          <div className="headerBox">
            <img
              className="cancelButton"
              src={greyX}
              onClick={() => {
                setLoginModal(false);
              }}
              style={{ cursor: "pointer" }}
              alt=""
            />
          </div>
          <div className="titleInfoBox">
            <div className="upLine">
              AI 온라인 시험&nbsp;
              <br className="onlyForTabMob" />
              자동 관리감독 서비스
            </div>
            <div className="downLine">
              어렵고 피곤한 시험 감시와 검증은 그만!
              <br />
              이젠 프록토매틱에게 맡기세요.
            </div>
          </div>
          <div className="loginInputSection">
            <p className={style.subTitle}>이메일 주소 (로그인 ID)</p>
            <div>
              <input
                className={`email ${emailErr ? "err" : ""} ${
                  style.input
                } fontplz`}
                type="text"
                placeholder="이메일 주소 입력"
                value={hostEmail || ""}
                onChange={(e) => {
                  setHostEmail(e.target.value);
                  onChangeReset(setEmailErr);
                }}
                onKeyPress={submit}
                ref={emailRef}
                id={"target"}
                onFocus={() => setEmailFocus(true)}
              />
              {hostEmail && emailFocus && (
                <img
                  src={inputReset}
                  className="resetBtnImg"
                  alt=""
                  onClick={() => setHostEmail("")}
                />
              )}
            </div>

            {emailErr && <span className="errMessage">{emailErr}</span>}
            <div style={{ marginTop: "5px" }}>
              <input
                className={`passwordInput ${pwErr ? "err" : ""} ${style.input}`}
                type="password"
                placeholder="비밀번호 입력"
                onChange={(e) => {
                  setPassword(e.target.value.replace(/\s| /gi, ""));
                  onChangeReset(setPwErr);
                }}
                value={hostPassword}
                onKeyPress={submit}
                autoComplete="off"
                ref={passwordRef}
                onFocus={() => {
                  setPwFocus(true);
                }}
              />
              {hostPassword && pwFocus && (
                <img
                  src={inputReset}
                  className="resetBtnImg"
                  alt=""
                  onClick={() => setPassword("")}
                />
              )}
            </div>
            {pwErr && <span className="errMessage">{pwErr}</span>}
          </div>
          <div className="subBtnBox">
            <span
              onClick={() => {
                setSignUpChooseModal(true);
                setLoginModal(false);
                // showToast({ message: "서비스 준비중입니다!" });
              }}
            >
              회원가입
            </span>
            <div className="finding">
              {/* <div className="between" /> */}
              <span
                onClick={() => {
                  setFindIdModal(true);
                  setLoginModal(false);
                }}
              >
                아이디 찾기
              </span>
              &nbsp;ㆍ&nbsp;
              <span
                onClick={() => {
                  setResetPasswordModal(true);
                  setLoginModal(false);
                }}
              >
                비밀번호 재설정
              </span>
            </div>
          </div>
          <section className="loginButtonContent">
            <button
              className={style.orangeButton}
              onClick={() => mutate()}
              disabled={btnDisabled()}
              ref={loginSubmitRef}
            >
              로그인
            </button>
          </section>
        </div>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  .fontplz {
    font-family: "regular";
    background-color: white;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height : 100%; */
    overflow-x: hidden;
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .subBtnBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .finding {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #bdbdbd;
      .between {
        width: 1px;
        height: 15px;
        background-color: #c4c4c4;
        margin-right: 12px;
      }
    }
    span {
      font-family: "regular";
      color: #2276dc;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    span:first-child {
      /* padding-right: 12px; */
      ::after {
        /* content: ''; */
        display: inline-block;
        width: 1px;
        height: 15px;
        background-color: #c4c4c4;
        margin-left: 12px;
      }
      @media ${device.tablet} {
        ::after {
          /* content: ''; */
          height: 12px;
        }
      }
      @media ${device.mobile} {
        ::after {
          /* content: ''; */
          height: 12px;
        }
      }
    }
  }
  .loginInputSection {
    margin-bottom: 39px;
    div {
      display: flex;
      align-items: center;
      position: relative;

      img {
        position: absolute;
        right: 0;
      }
    }
  }

  section:nth-child(2) {
    margin-bottom: 67px;
  }
  .subBtnBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      /* font-size: 15px; */
      line-height: 19px;
      font-family: "regular";
      color: #2276dc;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  input {
    outline: none;
    border: none;
    border-bottom: 1px solid #dfdfdf;
    width: 100%;
    padding: 7px 25px 7px 0;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 19px;
  }

  input.passwordInput {
    /* font-size:${(props) => (props.size ? "30px" : "15px")}; */
    /* letter-spacing: -1.8px; */
    /* margin-top: 5px; */
    @media ${device.tabMob} {
      margin-top: 10px;
    }
  }

  input.passwordInput&::placeholder {
    color: #bdbdbd;
    font-family: "light";
    letter-spacing: auto;
    letter-spacing: 0;
  }

  input&::placeholder {
    font-size: 15px;
    line-height: 22px;
    font-family: "light";
    color: #bdbdbd;
  }

  .errMessage {
    padding: 5px 0;
    font-size: 12px;
    line-height: 18px;
    color: #c83b38;
    font-family: "Regular";
  }
  .loginButtonContent {
    margin: 111.5px auto 0.5px;
    @media ${device.tablet} {
      /* margin: 0; */
      display: flex;
      height: 100%;
      align-items: flex-end;
      /* padding: 173px 0 22px; */
      justify-content: center;
    }
    @media ${device.mobile} {
      /* margin: 0; */
      display: flex;
      height: 100%;
      align-items: flex-end;
      /* padding: 173px 0 22px; */
      justify-content: center;
    }
  }

  .resetBtnImg {
    @media (max-width: 1139px) {
      width: 18px;
    }
  }

  .err {
    border-bottom: 1px solid #c83b38;
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    z-index: 51;
    .onlyForTabMob {
      display: none;
    }
    .headerBox {
      display: flex;
      width: 100%;
      padding: 8.5px 0;
      img {
        width: 24px;
        height: 24px;
        margin-left: auto;
      }
    }
    .titleInfoBox {
      display: grid;
      flex-direction: column;
      gap: 16px;
      margin: 48px 0 67px;
      width: 100%;
      text-align: center;
      .upLine {
        font-size: 26px;
        font-family: "bold";
        line-height: 38px;
      }
      .downLine {
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        color: #6e6e6e;
      }
    }
    .loginInputSection {
      width: 360px;
    }
    .cancelButton {
      margin-left: auto;
    }
    .subBtnBox {
      width: 360px;
      span {
        font-size: 15px;
        line-height: 22px;
      }
    }
    .loginInputSection {
      /* width : 100%; */
      /* padding : 0 50px; */
    }
  }
  @media ${device.tabMob} {
    z-index: 49;
    bottom: 0px;
    left: 0;
    width: 100%;
    min-width: 360px;
    box-sizing: border-box;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    padding: 8px 0 0;
    /* overflow: scroll; */
    .wrapper {
      padding: 0 24px 15px;
      box-sizing: border-box;
      height: -webkit-fill-available;
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .cancelButton {
      display: none;
    }
    span {
      font-size: 12.5px;
      line-height: 18px;
    }
    .titleInfoBox {
      margin: 44px 0;
      text-align: center;
      display: grid;
      flex-direction: column;
      gap: 15px;
      .upLine {
        font-size: 22px;
        font-family: "bold";
        line-height: 33px;
      }
      .downLine {
        font-size: 15px;
        font-family: "regular";
        color: #6e6e6e;
        line-height: 22px;
      }
    }
    .loginInputSection {
      max-width: 704px;
      width: 100%;
      box-sizing: border-box;
    }
    .subBtnBox {
      max-width: 704px;
      width: 100%;
      font-size: 13px;
      line-height: 19px;
    }
  }
`;

export default Login;
