import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import customAxios, { baseURL } from "../api/handler";
import { userInfo } from "../atoms";
import ImgBox from "../components/ImgBox";
import { getCookie } from "../Cookie";
import { device } from "../hooks/device";
import arrow from "../img/arrow.svg";
import arrowDown from "../img/arrowDown.png";
import cross from "../img/cross.png";
import imgInput from "../img/imgInput.svg";
import blueCheck from "../img/blueCheck.svg";
import QnAClose from "../modal/QnAClose";
import useToast from "../hooks/useToast";

const Support = () => {
  const navigate = useNavigate();
  const hi = useRef();
  const context = useRef();
  const title = useRef();

  const [imgList, setImgList] = useState([]);

  let tempImgList = [];

  const askCategory = [
    // "선택하세요",
    "회원정보",
    "결제/환불",
    "서비스",
    "쿠폰/포인트",
    "기타",
  ];
  const [showCategory, setShowCategory] = useState(false);
  const [category, setCategory] = useState();
  const [questionUuid, setQuestionUuid] = useState(null);
  const [questionComponentUid, setQuestionComponentUuid] = useState(null);
  const [titleState, setTitleState] = useState();
  const [contextState, setContextState] = useState();
  const [modalClose, setModalClose] = useState(false);
  const showToast = useToast();

  const removeImg = (index) => {
    tempImgList = imgList;
    tempImgList.splice(index, 1);
    let b = Array.from(tempImgList);
    setImgList(b);
  };

  // 사진 삭제시 백엔드로 보내줄 s3 키 리스트 수정하기
  let tempS3KeyList = [];

  const removeS3KeyList = (index) => {
    tempS3KeyList = s3KeyList;
    tempS3KeyList.splice(index, 1);
    let tempTwo = Array.from(tempS3KeyList);
    setS3KeyList(tempTwo);
  };

  const userData = useRecoilValue(userInfo);

  // uuid 받아오기 step One
  const qnaFirstStep = async () => {
    await customAxios
      .post(`${baseURL}/api/v1/questions/pre-uuid`, {
        hostUuid: userData.hostUuid,
        hostEmail: userData.hostEmail,
      })
      .then(({ data }) => {
        setQuestionUuid(data?.questionUuid);
        setQuestionComponentUuid(data?.questionComponentUuid);
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "HOST-001") {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };

  let s3Key = [];
  const [s3KeyList, setS3KeyList] = useState([]);

  const qnaSecondStep = async ({
    questionUuid,
    questionComponentUid,
    image,
    id,
  }) => {
    await customAxios
      .post(`${baseURL}/api/v1/questions/pre-signed-url`, {
        offset: -540,
        hostUuid: userData.hostUuid,
        hostEmail: userData.hostEmail,
        questionUuid: questionUuid,
        questionComponentUuid: questionComponentUid,
      })
      .then(({ data }) => {
        s3Key[id] = data?.preSignedUrl?.imageS3Key;
        setS3KeyList((s3keys) => [...s3keys, s3Key[id]]);
        uploadS3({
          url: data?.preSignedUrl?.imageUploadUrl,
          image: image,
        });
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-001" ||
          response.data.errorCode === "QUESTION-001" ||
          response.data.errorCode === "QUESTION-002"
        ) {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  };
  // console.log(s3KeyList)

  // s3 에 업로드
  const uploadS3 = async ({ url, image }) => {
    await axios
      .put(`${url}`, image, {
        headers: {
          "Content-Type": "image/jpg",
        },
      })
      .catch(() => {});
  };

  // 문의 작성 종합 보내기
  const qnaThirdStep = async () => {
    const token = getCookie("token");
    // console.log(title,context,showCategory,questionComponentUid,questionUuid,s3KeyList)
    await customAxios
      .post(`${baseURL}/api/v1/questions`, {
        title: title.current.value,
        context: context.current.value,
        offset: -540,
        category: category,
        questionUuid: questionUuid,
        questionComponentUuid: questionComponentUid,
        confirmImageS3KeyList: s3KeyList,
      })
      .then(() => {
        navigate(`/qnaDetail/${questionUuid}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 이미지 업로드 종합 함수

  useEffect(() => {
    qnaFirstStep();
  }, []);

  // image upload and preview
  const [firstState, setFirstState] = useState(true); // 확장자가 맞지 않을 경우
  const [secondState, setSecondState] = useState(true);
  const [volumeFirst, setVolumeFirst] = useState(true); // 이미지 5mb 이상일 경우 error
  const [volumeSecond, setVolumeSecond] = useState(true);

  const upload = (e) => {
    const a = e.target.files;
    let imgUrl = [];
    for (
      let i = 0;
      i < (a.length + imgList.length < 6 ? a.length : 6 - imgList.length);
      i++
    ) {
      let reader = new FileReader();
      // console.log(a[i])
      if (a[i].size < 5242880) {
        imgUrl.push(a[i]);
        reader.onload = () => {
          switch (reader.result?.split("/")[1]?.split(";")[0]) {
            case "jpg":
            case "jpeg":
            case "png":
            case "heic":
              imgUrl[i] = reader.result;
              setImgList((img) => [...img, imgUrl[i]]);
              qnaSecondStep({
                questionUuid: questionUuid,
                questionComponentUid: questionComponentUid,
                image: a[i],
                id: i,
              });
              break;
            default:
              setFirstState(false);
              break;
          }
        };
        // reader.addEventListener('loadend',function(){
        //     // console.log(reader.readAsDataURL(a[i]))
        // })
        reader.readAsDataURL(a[i]);
        // console.log(reader.readAsDataURL(e))
      } else {
        setVolumeFirst(false);
        console.log("용량이 크다 human");
      }
    }
    setSecondState(firstState);
    setFirstState(true);
    setVolumeSecond(volumeFirst);
    setVolumeFirst(true);
    // setK(...imgUrl);
  };

  const [isContentsIn, setIsContentsIn] = useState(true);

  useEffect(() => {
    if (titleState && contextState && category) {
      setIsContentsIn(false);
    } else {
      setIsContentsIn(true);
    }
  }, [titleState, contextState, category]);

  console.log(isContentsIn);

  return (
    <>
      {modalClose && <QnAClose setModalClose={setModalClose} />}
      <StSupport
        category={category}
        nowCategory={category}
        showCategory={showCategory}
        imgLength={imgList?.length}
        isContentsIn={isContentsIn}
      >
        <div className="container">
          <div className="title">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/qna/1`);
              }}
              src={arrow}
              alt=""
            />
            문의하기
          </div>
          <div className="inbox">
            <div className="middleTitleBoxBox">
              <div className="middleTitleBox">
                <img
                  className="arrow"
                  onClick={() => {
                    setModalClose(true);
                  }}
                  src={arrow}
                  alt=""
                />
                <div className="middleTitle">문의하기</div>
              </div>
              {/* <img className='cancelImg' onClick={()=>{setModalClose(true)}} src={x} alt=''/> */}
            </div>
            <div className="bottomBox">
              <div className="categoryBox">
                <div className="categoryTitle">문의 유형</div>
                <div className="categoryContent">
                  <div
                    className="categoryChoose"
                    onClick={() => setShowCategory(!showCategory)}
                  >
                    <div>{category ? category : "선택하세요"}</div>
                    <img className="arrowDown" src={arrowDown} alt="" />
                  </div>
                  <div>
                    {showCategory && (
                      <div
                        className="listBackground"
                        onClick={() => {
                          setShowCategory(false);
                        }}
                      />
                    )}
                    <div className="categoryList">
                      {askCategory.map((value, index) => (
                        <div
                          className="category"
                          style={value === category ? { color: "#2276DC" } : {}}
                          key={index}
                          onClick={() => {
                            setCategory(value);
                            setShowCategory(false);
                          }}
                        >
                          {value}
                          {category === value && <img src={blueCheck} alt="" />}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="titleBox">
                <div className="titleTitle">문의 내용</div>
                <input
                  className="titleContent"
                  ref={title}
                  placeholder="제목 입력 (최대 60자)"
                  onChange={(e) => {
                    setTitleState(e.target.value);
                  }}
                  maxLength={60}
                />
                <textarea
                  className="contentContent"
                  ref={context}
                  placeholder="내용 입력 (최대 1500자)"
                  maxLength={1500}
                  onChange={(e) => setContextState(e.target.value)}
                />
                <div className="greyBar" />
              </div>
              <div className="imageBox">
                <div className="imageTitle">이미지 첨부</div>
                <div className="imageContent">
                  <div className="imageContentInBox">
                    {imgList &&
                      imgList.map((value, index) => {
                        return (
                          <ImgBox
                            url={value}
                            key={index}
                            s3KeyList={s3KeyList}
                            onClick={() => {
                              removeImg(index);
                              removeS3KeyList(index);
                            }}
                          />
                        );
                      })}
                    {imgList.length !== 0 ? (
                      <label className="afterUpload" htmlFor="file">
                        <img src={cross} alt="" />
                      </label>
                    ) : (
                      <label className="beforeUpload" htmlFor="file">
                        <img src={imgInput} alt="" /> <div>이미지 선택</div>
                      </label>
                    )}
                    <input
                      ref={hi}
                      id="file"
                      type="file"
                      multiple
                      onChange={upload}
                      accept="image/jpeg, image/jpg, image/png, image/heic"
                    />
                  </div>
                </div>
                <div className="imageDescribeOne">
                  파일을 드래그해서 박스 위에 놓거나 직접 파일을 선택 해주세요.
                </div>
                <div className="imageDescribeTwo">
                  * 이미지는 JPEG, JPG, PNG, HEIC만 가능하며 최대 6개까지 첨부
                  가능합니다.
                </div>
              </div>
              <div className="buttonBox">
                <div
                  className="cancelButton"
                  onClick={() => setModalClose(true)}
                >
                  취소
                </div>
                <button
                  className="confirmButton"
                  onClick={() => {
                    qnaThirdStep();
                  }}
                  disabled={isContentsIn}
                >
                  저장하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </StSupport>
    </>
  );
};

const StSupport = styled.div`
  @media ${device.pc} {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding: 80px 0;
    margin: 0 auto;
    background-color: #f0f1f2;
    min-height: calc(100vh - 353px);
    box-sizing: border-box;
    .middleTitleBox {
      display: none;
    }
    .container {
      width: 1228px;
      margin: 0 auto;
      .title {
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: "Bold";
        line-height: 41px;
        .arrow {
          width: 28px;
          height: 28px;
          margin: 0 20px 0 0;
          cursor: pointer;
        }
      }
      .inbox {
        display: flex;
        flex-direction: column;
        margin: 60px auto 0;
        padding: 60px 64px;
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        min-height: 731px;
        .bottomBox {
          display: grid;
          flex-direction: column;
          gap: 32px;
        }
        .greyBar {
          width: 100%;
          height: 1px;
          background-color: #dfdfdf;
        }
        .categoryBox {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          .categoryTitle {
            font-size: 15px;
            font-family: "medium";
            width: 100%;
            height: 18px;
            margin-bottom: 10px;
          }
          .categoryContent {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 220px;
            height: 47px;
            padding: 11.5px 16px;
            box-sizing: border-box;
            border: 0.6px solid #818181;
            border-radius: 6px;
            .categoryChoose {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: "regular";
              /* color: ${(props) =>
                props.nowCategory === "선택하세요" ? "#BDBDBD" : ""}; */
              cursor: pointer;
              .arrowDown {
                width: 12px;
                height: 7.2px;
              }
            }
            .listBackground {
              position: fixed;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }
            .categoryList {
              position: absolute;
              top: -1px;
              left: -1px;
              z-index: 10;
              display: ${(props) => (props.showCategory ? "flex" : "none")};
              flex-direction: column;
              width: 220px;
              color: black;
              box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
              border-radius: 6px;
              background-color: white;
              .category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 24px;
                cursor: pointer;
              }
            }
          }
        }
        .titleBox {
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          .titleTitle {
            font-size: 15px;
            font-family: "medium";
            line-height: 22px;
            width: 100%;
            height: 18px;
            margin-bottom: 5px;
          }
          .titleContent {
            height: 47px;
            line-height: 24px;
            font-size: 16px;
            font-family: "regular";
            :placeholder-shown {
              color: grey;
            }
            &::placeholder {
              color: #bdbdbd;
              font-family: "light";
              font-size: 16px;
              line-height: 24px;
            }
            &:focus {
              outline: none;
            }
            border: 0.6px solid #818181;
            border-radius: 6px;
            padding: 11.5px 16px;
            box-sizing: border-box;
            margin-top: 12px;
          }
          .contentContent {
            resize: none;
            min-height: 111px;
            padding: 11.5px 16px;
            line-height: 24px;
            font-family: "DemiLight";
            margin-top: 12px;
            font-size: 16px;
            font-family: "regular";
            line-height: 22px;
            &::placeholder {
              color: #bdbdbd;
              font-family: "light";
              font-size: 16px;
              line-height: 22px;
              /* align-items: first baseline; */
            }
            &:focus {
              outline: none;
            }
            border: 0.6px solid #818181;
            border-radius: 6px;
          }
          .greyBar {
            display: none;
          }
        }
        .imageBox {
          display: flex;
          flex-direction: column;
          .imageTitle {
            height: 22px;
            font-size: 15px;
            font-family: "medium";
          }
          .imageContent {
            display: flex;
            justify-content: ${(props) =>
              props.imgLength > 0 ? "" : "center"};
            align-items: center;
            padding: 16px 18px;
            box-sizing: border-box;
            height: 114px;
            background-color: #f3f3f3;
            margin: 12px 0;
            label {
              font-size: 14px;
              font-family: "medium";
              display: flex;
              justify-content: center;
              align-items: center;
              width: 141px;
              height: 42px;
              background-color: #ffffff;
              border-radius: 43px;
              cursor: pointer;
            }
            input[type="file"] {
              display: none;
            }
            .imageContentInBox {
              display: grid;
              grid-template-columns: repeat(6, auto);
              gap: 15px;
              /* margin-right : auto; */
              .beforeUpload {
                grid-column: 2 / 3;
                font-size: 14px;
                font-family: "medium";
                display: grid;
                grid-template-columns: auto auto;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                gap: 7px;
                /* width : 125px; */
                height: 42px;
                background-color: #ffffff;
                border-radius: 43px;
                font-family: "medium";
                font-size: 15px;
                cursor: pointer;
              }
              .afterUpload {
                display: ${(props) => (props.imgLength > 5 ? "none" : "flex")};
                justify-content: center;
                align-items: center;
                width: 82px;
                height: 82px;
                border: 1px solid #bdbdbd;
                border-radius: 12px;
                background-color: rgba(0, 0, 0, 0);
              }
            }
          }
          .uploadBox {
            display: flex;

            .ImgBox {
              position: relative;
              width: 82px;
              height: 82px;
              margin-right: 10px;
              .cancelImg {
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
              .previewImg {
                width: 82px;
                height: 82px;
                border-radius: 12px;
                background-color: #59749d;
              }
            }
          }
          .imageDescribeOne {
            display: none;
          }
          .imageDescribeTwo {
            text-align: center;
            margin: 0 auto;
            font-size: 11px;
            font-family: "regular";
          }
        }
        .buttonBox {
          display: grid;
          grid-template-columns: auto auto;
          gap: 21px;
          margin-top: 8px;
          justify-content: center;

          .cancelButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 204px;
            height: 52px;
            border: 1px solid #000000;
            border-radius: 42px;
            box-sizing: border-box;
            font-size: 16px;
            font-family: "medium";
            line-height: 25px;
            cursor: pointer;
          }
          .confirmButton {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 204px;
            height: 52px;
            background-color: ${(props) =>
              props.isContentsIn ? "#D9D9D9" : "#ED893E"};
            color: white;
            border-radius: 42px;
            border: none;
            font-size: 16px;
            font-family: "medium";
            line-height: 25px;
            cursor: ${(props) => (props.isContentsIn ? "" : "pointer")};
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-width: 360px;
    /* background-color:  #F0F1F2; */
    .title {
      display: none;
    }
    .middleTitleBoxBox {
      display: flex;
      justify-content: space-between;
      align-content: center;
      .middleTitleBox {
        display: flex;
        align-items: center;
        width: 300px;
        margin: 8px 0;
        padding: 16px 0;
        line-height: 28px;
        .middleTitle {
          font-size: 19px;
          font-family: "bold";
        }
        .arrow {
          height: 18px;
          margin-right: 12px;
          cursor: pointer;
        }
      }
      .cancelImg {
        width: 14px;
        height: 14px;
        margin: auto 0;
        cursor: pointer;
      }
    }
    .inbox {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 0 24px 24px;
      box-sizing: border-box;
      background-color: #ffffff;
      min-height: 731px;
      .greyBar {
        width: 100%;
        height: 1px;
        background-color: #dfdfdf;
      }
      .categoryBox {
        display: flex;
        flex-direction: column;
        height: 98px;
        padding: 14px 0;
        box-sizing: border-box;
        .categoryTitle {
          font-size: 13px;
          font-family: "medium";
          width: 100%;
          height: 18px;
          margin-bottom: 10px;
        }
        .categoryContent {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 220px;
          height: 42px;
          border: 0.6px solid #818181;
          box-sizing: border-box;
          border-radius: 6px;
          color: #bdbdbd;
          .categoryChoose {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: ${(props) => (props.category === 0 ? "#BDBDBD" : "black")};
            width: 100%;
            height: 100%;
            padding: 11.5px 16px;
            box-sizing: border-box;
            cursor: pointer;
            .arrowDown {
              width: 12px;
              height: 10px;
            }
          }
          .categoryList {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 10;
            display: ${(props) => (props.showCategory ? "flex" : "none")};
            flex-direction: column;
            width: 220px;
            color: black;
            box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            background-color: white;
            .category {
              display: flex;
              justify-content: space-between;
              padding: 15px 24px;
              cursor: pointer;
            }
          }
          .listBackground {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 9;
          }
        }
      }
      .titleBox {
        display: flex;
        flex-direction: column;
        padding: 14px 0;
        box-sizing: border-box;
        .greyBar {
          display: none;
        }
        .titleTitle {
          font-size: 13px;
          font-family: "medium";
          width: 100%;
          height: 18px;
          margin-bottom: 5px;
        }
        .titleContent {
          line-height: 24px;
          font-size: 16px;
          font-family: "regular";
          border: 0.6px solid #818181;
          border-radius: 6px;
          padding: 11.5px 16px;
          box-sizing: border-box;
          :placeholder-shown {
            color: grey;
          }
          &::placeholder {
            font-family: "light";
            color: #bdbdbd;
          }
          &:focus {
            outline: none;
          }
        }
        .contentContent {
          resize: none;
          min-height: 193px;
          line-height: 24px;
          font-family: "regular";
          font-size: 16px;
          margin-top: 10px;
          border: 0.6px solid #818181;
          border-radius: 6px;
          padding: 11.5px 16px;
          &::placeholder {
            font-family: "light";
            color: #bdbdbd;
          }
          &:focus {
            outline: none;
          }
        }
      }

      .imageBox {
        display: flex;
        flex-direction: column;
        padding: 14px 0;
        margin-bottom: 23px;
        .imageTitle {
          height: 22px;
          font-size: 13px;
          font-family: "medium";
          margin-bottom: 10px;
        }
        .imageContent {
          display: flex;
          /* justify-content: center; */
          align-items: center;
          min-height: 114px;
          background-color: #f3f3f3;
          margin-bottom: 12px;
          .imageContentInBox {
            display: flex;
            flex-wrap: wrap;
            place-items: center center;
            row-gap: 15px;
            column-gap: 15px;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 16px 18px;
            width: 100%;
            .beforeUpload {
              grid-column: 2 / 3;
              font-size: 14px;
              font-family: "medium";
              display: grid;
              grid-template-columns: auto auto;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              gap: 7px;
              width: 125px;
              height: 42px;
              background-color: #ffffff;
              border-radius: 43px;
              cursor: pointer;
            }
            .afterUpload {
              display: ${(props) => (props.imgLength < 6 ? "flex" : "none")};
              justify-content: center;
              align-items: center;
              width: 82px;
              height: 82px;
              border-radius: 12px;
              border: 1px solid #bdbdbd;
              box-sizing: border-box;
              cursor: pointer;
            }
            .imageButton {
              font-size: 14px;
              font-family: "medium";
              display: flex;
              justify-content: center;
              align-items: center;
              width: 141px;
              height: 42px;
              background-color: #ffffff;
              border-radius: 43px;
            }

            input[type="file"] {
              display: none;
            }
          }
        }
        .imageDescribeOne {
          display: none;
        }
        .imageDescribeTwo {
          text-align: center;
          margin: 0 auto;
          font-size: 11px;
          font-family: "regular";
        }
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        justify-content: center;
        padding: 15px 0;
        .cancelButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          border: 1px solid #000000;
          border-radius: 42px;
          box-sizing: border-box;
          font-size: 14px;
          font-family: "medium";
          cursor: pointer;
        }
        .confirmButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          background-color: ${(props) =>
            props.isContentsIn ? "#D9D9D9" : "#ED893E"};
          color: white;
          border: none;
          border-radius: 42px;
          font-size: 14px;
          font-family: "medium";
          cursor: pointer;
        }
      }
    }
    .container {
      width: 100%;
      max-width: 731px;
    }
  }
`;

export default Support;
